import React, { useEffect, useState } from 'react'
import axios from '../../axios'

import useFirstLoad from '../../hooks/useFirstLoad'

const CartContext = React.createContext()

export const useCartContext = () => 
  React.useContext(CartContext)

let updateCart = false;

export default function CartContextProvider({children}) {
  const [cartItems, setCartItems] = useState([])
  const [itemQty, setItemQty] = useState(0)
  const [forLater, setForLater] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  const [ firstLoad, setFirstLoad ] = useFirstLoad()


  const addItem = async (item) => {
    // console.log(item);
    let add = false;

    setCartItems(x => {
      let wigId = x.find(el => el.wigId === item.wigId)
      if (wigId) return x;
      // console.log(x);
      add = true;
      return x.concat({...item, 
        price: item.sale.subtotal,
        currentlyAt: item.currentlyAt?.name || "", 
        check: true})
    })

    if (add) {
      updateCart = true
      setItemQty(x => x + 1)
    }
  }

  const removeItem = (id, check) => {
    updateCart = true
    if (check) {
      setCartItems(x => x.filter(wig => wig.wigId !== id))
      setItemQty(x => x - 1)
    } else {
      setForLater(x => x.filter(wig => wig.wigId !== id))
    }
  }

  const saveForLater = (id, check) => {
    updateCart = true;
    if (check) {
      let row = cartItems.find(el => el.wigId === id)
      setCartItems(x => x.filter(wig => wig.wigId !== id))
      setItemQty(x => x - 1)
      setForLater(x => x.concat({...row, check: false}))
    } else {
      let row = forLater.find(el => el.wigId === id)
      setForLater(x => x.filter(wig => wig.wigId !== id))
      setCartItems(x => x.concat({...row, check: true}))
      setItemQty(x => x + 1)
    }
  }

  const clearCart = () => {
    updateCart = true;
    setCartItems([])
    setItemQty(0)
  }

  const clearAll = () => {
    updateCart = true;
    setCartItems([])
    setItemQty(0)
    setForLater([])
  }

  const loadCart = async () => {
    try {

      const { data } = await axios.get("/users/cart")
      setCartItems(data.cart.cart)
      setForLater(data.cart.sfl)
      setItemQty(data.cart?.cart?.length)
    } catch (e) {
      
    }
  }

  useEffect(() => {
    if (!isLoaded) {
      loadCart()
    }
  }, [])

  useEffect(() => {
    if (!firstLoad && updateCart) {
      // console.log("hehehehehe");
      const { data } = axios.put('/users/cart', {cart: {
        cart: cartItems,
        sfl: forLater
      }
    })
  } 
  updateCart = false;
  if (firstLoad) {
    setFirstLoad(false)
  }
  }, [cartItems, forLater])

  const cart = {
    cartItems,
    itemQty,
    addItem,
    removeItem,
    forLater,
    saveForLater,
    clearAll,
    clearCart
  }

  return (
    <CartContext.Provider value={cart}>
      {children}
    </CartContext.Provider>
  )
}