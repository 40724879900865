import React from 'react';
import styled from 'styled-components';

import BreadCrumbs from './breadCrumbs';

const Container = styled.div`
  width: 90%;
  height: auto;
  margin: 3rem auto;

  @media ${props => props.theme.breakpoints.down('xl')} {
    width: 90%;
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
  }
`

const Header = styled.div`
  height: 10rem;
 `

const Title = styled.h2`
  font-size: 2.6rem;
  padding-top: 1rem;
  font-weight: 500;
  /* text-align: center; */
`

const Main = styled.div`
  margin-top: 1rem;
`

 
export default ({children, title, crumbs}) => {
 
 return (
 <Container >
   <BreadCrumbs links={crumbs} />
    <Header>
      <Title>{title}</Title>
    </Header>
    <Main>{children}</Main>
 </Container>
 )
}