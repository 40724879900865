import React from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import StyleRoundedIcon from '@material-ui/icons/StyleRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import StoreMallDirectoryRoundedIcon from '@material-ui/icons/StoreMallDirectoryRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';

// panels

import Styles from "./panels/styles/Styles";
import ShipmentSettings from './panels/shipmentSettings/ShipmentSettings';
import InventorySettings from './panels/inventoryValues/InventorySettings';
import PricesSettings from './panels/inventoryPrices';

const Container = styled.div`
  /* width: 95%; */
  margin: 0 auto;
`

const Title = styled.h4`
  width: 95%;
  margin: 1.5rem auto;
  font-weight: 500;
  font-size: 1.8rem;
`
const Sections = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: calc(100vh - 15rem);
`
const OptionPanel = styled.ul`
  margin: 0;
  padding: 0;
  min-width: 30rem;
  background-color: white;
  margin-right: 1.5rem;
`
const OptionItemStyles = styled.li`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 2.5rem 3.5rem;
  /* padding-right: 2.5rem; */
  border-bottom: 1px solid ${props => props.theme.colors.border};

  .icon {
    margin-right: 2rem;

    svg {
      fill: ${props => props.theme.colors.lightTextGray};
    }
  }

  .title {
    flex-grow: 1;

    h4 {
      margin: 0;
      font-weight: 600;
      margin-bottom: .5rem;
    }
    h6 {
      margin: 0;
      color: ${props => props.theme.colors.lightTextGray};
      font-size: 1rem;
    }
  }
`
const DisplayPanel = styled.div`
  flex-grow: 1;
  background-color: white;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;

  &.active {
    .icon {
      svg {
        fill: ${props => props.theme.colors.primaryMain};
      }
    }
    .title {
      h4, h6 {
        color: ${props => props.theme.colors.primaryMain};
      }
      h6 {
        opacity: .8;
      }
    }
  }
`;

const panels = [
  {
    title: "Manage Styles",
    icon: <StyleRoundedIcon />,
    desc: "Manage the list of inventory colors",
    // desc: "",
    url: "styles"
  },
  {
    title: "Shipment Settings",
    icon: <LocalShippingRoundedIcon />,
    desc: "Manage shipment profiles",
    // desc: "",
    url: "shipments"
  },
  {
    title: "Inventory Settings",
    icon: <StoreMallDirectoryRoundedIcon />,
    desc: "Manage allowed inventory values",
    // desc: "",
    url: "inventory"
  },
  {
    title: "Inventory Prices",
    icon: <AttachMoneyRoundedIcon />,
    desc: "Manage system cost and price values",
    // desc: "",
    url: "inventory-prices"
  },
]

const OptionItem = ({ title, icon, desc }) => {
  return (<OptionItemStyles>
    <div className='icon'>{icon}</div>
    <div className='title'>
      <h4>{title}</h4>
      <h6>{desc}</h6>
    </div>
  </OptionItemStyles>)
}

const SystemSetup = (props) => {

  const path = props.match.url

  return (

    <Container>
      <Title>System Setup</Title>
      <Sections>
        <OptionPanel>
          {panels.map(({ title, icon, desc, url }) => 
          <StyledNavLink to={`/setup/${url}`} key={title}>
            <OptionItem title={title} icon={icon} desc={desc} />
          </StyledNavLink>
            )}
        </OptionPanel>

        <DisplayPanel>

          <Switch>
            <ProtectedRoute path={`${path}/styles`} guard='writeSettings' component={Styles} />
            <ProtectedRoute path={`${path}/shipments`} guard='writeSettings' component={ShipmentSettings} />
            <ProtectedRoute path={`${path}/inventory`} guard='writeSettings' component={InventorySettings} />
            <ProtectedRoute path={`${path}/inventory-prices`} guard='writeSettings' component={PricesSettings} />
            <Route path="/setup">
              <Styles />
            </Route>
          </Switch>
        </DisplayPanel>
      </Sections>
    </Container>
  )
}

export default SystemSetup;