import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, 
  CircularProgress, FormHelperText, Switch, FormControlLabel} from '@material-ui/core';
import { TextField, } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, buildFormData } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import AddImage from '../../images/add-image.png';

import EditForm from '../../utils/editForm';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`

const LogoHeader = styled.span`
  display: block;
  margin-left: .3rem;
  margin-bottom: .5rem;
  /* font-size: 1.6rem; */
  letter-spacing: 1px;
`

const FileButton = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  height: 200px;
  border-radius: 3px;
  padding: 10px;
  color: #111;
  text-align: center;
  /* margin-top: 2rem; */
  margin-bottom: 1rem;
  background-color: #ddd;
  /* background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: center center; */
  transition: all .3s;

  img {
    display: block;
    opacity: .5;
  }

  span {
    display: block;
    line-height: 1.8rem;
    letter-spacing: 1px;
    color: black;
  }

  &:hover {
    background-color: #bbb;
  }
`

const SelectedLogo = styled.span`
  display: block;
  margin-top: .5rem;
  color: #444;
  font-weight: bold;
`

const SectionHeader = styled.div`
  margin: 4rem 0;
  font-size: 1.6rem;
  hr {
    opacity: .3;
    height: 2px;
    border-style: solid;
    background-color: black;
  }
`
 
export default (props) => {

  const {id} =  props.match.params;
 
  const [info, SetInfo] = useState({})
  const [fileSelect, setFileSelect] = useState();
  const [loading, setLoading] = useState(false);

  // isDefault switch
  const handleSwitch = (e, setFieldValue) => {
    setFieldValue('isDefault', e.target.checked)
  };


  const loadData = async () => {
    const {data} = await axios.get(`/brands/${id}`)
    // const initValues = {
    //   name: data.name,
    //   prefix: data.prefix,
    // }
    SetInfo(data);
  }

  useEffect(() => {
    if (id) 
      loadData();
  }, [])

  const onImportFile = async (e) => {
    setLoading(true)
    const file = e.target.files[0];

    setFileSelect(file);
  }


  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Brand successfully updated" : "Brand successfully added";

  let crumbs = [{path: '/brands/', text: 'Brands'}];

  if (action === "edit")
    crumbs.push({path: `/brands/view/${id}`, text: 'View'})

  crumbs.push({path: '/brands/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
    name: Yup
      .string()
      .required('Name is required.'),
    prefix: Yup
      .string()
      .required('Prefix is required.'),
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    let details = userInfo

    if (!fileSelect && action === 'add') {
      return handleClick('error', "Logo required, Please select image to upload")
    }

    /* FormData */

    if (action === 'add') {

    details = new FormData(); 
     
      details.append( 
        "logo", 
        fileSelect, 
        fileSelect.name
      ); 

    buildFormData(details, userInfo)
  }


    /*  End of FormData */

    try {
      let query = !id ? axios.post('/brands/', details) : axios.put(`/brands/${id}`, details);

      const {data} = await query;

      // console.log( data);
      setSubmitting(false)
      if (action === 'add') {
        document.getElementById("import-button").value = ""
      }
      handleClick('success', successMsg)
      props.history.push(`/brands/view/${id || data._id}`)
    } catch (e) {
      // console.log(e, "whats the problem");
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }
 
 return (
 <EditForm title={action === "edit" ? "Edit Brand" : "New Brand"} header="Brand" crumbs={crumbs}>
   <Formik
    enableReinitialize
    initialValues={
      info.name ? {...info} : {
        name: '',
        prefix: '',
        brandInfo: {
          email: "",
          phone: "",
          address: {
            street: "",
            city: "",
            state: "",
            zip: ""
          }
        },
        companyInfo: {
          name: "",
          email: "",
          phone: "",
          address: {
            street: "",
            city: "",
            state: "",
            zip: "",
            country: ""
          }
        },
        isDefault: false,
        notes: ""
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, setFieldValue, values}) => (
        <Form encType="multipart/form-data">
          <Grid container spacing={4}>
          {/* {action === 'add' ? <> */}
          <Row item xs={12} sm={8}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="Name"
              variant="outlined"
              fullWidth
            />
            </Row>
            <Row item xs={12} sm={4}>
            <Field
              component={TextField}
              name="prefix"
              type="text"
              label="Brand Code"
              variant="outlined"
              fullWidth
            />
            </Row>
            {/* </> : null} */}

           {action === 'add' ?  <Row item xs={12}>
              {/* <form encType="multipart/form-data"> */}
               <LogoHeader>Brand Logo</LogoHeader> 
                <FileButton htmlFor="import-button">
                    <input
                        id="import-button"
                        // inputProps={{
                          accept=
                            ".jpg,.png"
                        // }}
                        onChange={onImportFile}
                        style={{display: "none"}}
                        type="file"
                    />
                    <img src={AddImage} alt="" />
                     <span>{fileSelect?.name ? <>Image selected</> : <>Select Image <br /> to upload</>}</span>
                </FileButton>
                <SelectedLogo>{fileSelect?.name || ""}</SelectedLogo>
                {/* </form> */}
            </Row> : null}

            <Row item xs={12} sm={6}>
            <Field
            component={TextField}
            name="brandInfo.email"
            type="email"
            label="Email to display"
            variant="outlined"
            fullWidth
          />
          <FormHelperText>This Email will appear on all invoices</FormHelperText>
            </Row>
            <Row item xs={12} sm={6}>
            <Field
            component={TextField}
            name="brandInfo.phone"
            type="text"
            label="Phone number to display"
            variant="outlined"
            fullWidth
          />
          <FormHelperText>This Phone number will appear on all invoices</FormHelperText>
            </Row>
            <Row item xs={12} >
            <Field
            component={TextField}
            name="brandInfo.address.street"
            type="text"
            label="Address"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="brandInfo.address.city"
            type="text"
            label="City"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="brandInfo.address.state"
            type="text"
            label="State"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="brandInfo.address.zip"
            type="text"
            label="Zip"
            variant="outlined"
            fullWidth
          />
          </Row>

          <Row item xs={12} sm={3}>
            <FormControlLabel
            control={
              <Switch
                checked={values.isDefault}
                onChange={(e) => handleSwitch(e, setFieldValue)}
                name="isDefault"
                color="primary"
              />
            }
            label="Default"
            />
            </Row>

          </Grid>

        <SectionHeader>
          Manufacturing Factory Contact Info
          <hr />
        </SectionHeader>
          

          <Grid container spacing={4}>

          <Row item xs={12} sm={4}>
            <Field
            component={TextField}
            name="companyInfo.name"
            type="text"
            label="Company Name"
            variant="outlined"
            fullWidth
          />
          <FormHelperText>This Information is private</FormHelperText>
            </Row>
            
            <Row item xs={12} sm={4}>
            <Field
            component={TextField}
            name="companyInfo.email"
            type="email"
            label="Company Email"
            variant="outlined"
            fullWidth
          />
          <FormHelperText>This Information is private</FormHelperText>
            </Row>
            <Row item xs={12} sm={4}>
            <Field
            component={TextField}
            name="companyInfo.phone"
            type="text"
            label="Company Phone"
            variant="outlined"
            fullWidth
          />
          <FormHelperText>This Information is private</FormHelperText>
            </Row>
            <Row item xs={12} >
            <Field
            component={TextField}
            name="companyInfo.address.street"
            type="text"
            label="Address"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="companyInfo.address.city"
            type="text"
            label="City"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="companyInfo.address.state"
            type="text"
            label="State"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="companyInfo.address.zip"
            type="text"
            label="Zip"
            variant="outlined"
            fullWidth
          />
          </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="companyInfo.address.country"
            type="text"
            label="Country"
            variant="outlined"
            fullWidth
          />
          </Row>
           
            <Row item xs={12} sm={6}>
            <Field
            component={TextField}
            name="notes"
            type="text"
            label="Notes"
            multiline
            minRows={4}
            variant="outlined"
            fullWidth
          />
            </Row>
            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
 </EditForm>
 )
}