import React, { useEffect } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';


import BreadCrumbs from './breadCrumbs';

const Container = styled.div`
  width: 90%;
  height: auto;
  margin: 3rem auto 0 auto;
  /* padding: 2rem; */
  /* text-align: center; */

  .submit {
    text-align: right;

    .button_reset {
      margin-right: 3rem;
    }
  }


  @media ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }

`

const FormContainer = styled(Paper)`
  /* width: 90%; */
  height: auto;
  /* margin: 3rem auto; */
  /* padding: 2rem; */
  /* text-align: center; */
  border: 1px solid rgb(224, 224, 224);
  overflow: hidden;


  .submit {
    text-align: right;

    .button_reset {
      margin-right: 2rem;
    }
  }


  @media ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }

`

const Header = styled.div`
  /* height: 15rem; */
  margin-bottom: 3rem;
`

const FormHeader = styled.div`
  border-bottom: 1px solid rgb(224, 224, 224);
  font-weight: 600;
  padding: 2rem;
  margin-bottom: 3rem;
`

const Title = styled.h2`
  font-size: 2.4rem;
  /* padding-top: 1rem; */
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row;
  /* text-align: center; */
`

const Main = styled.div`
  margin: 0 2rem 5rem 2rem;

  @media ${props => props.theme.breakpoints.down('lg')} {
    margin: 0 4rem 5rem 4rem;
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    margin: 0 2rem 5rem 2rem;
  }
`

 
export default ({children, title, crumbs, header, sidebar}) => {

 
 return (
  <Container>
    <Header>
      <BreadCrumbs links={crumbs} />
      <Title>{title}</Title>
    </Header>
    <Grid container spacing={2}>
      <Grid item xs={12} md={sidebar ? 9 : 12}>
        <FormContainer elevation={0} >
          <FormHeader>
            {header} Details
          </FormHeader>
          <Main>
          {children}
          </Main>
        </FormContainer>
      </Grid>
      {sidebar ? <Grid item xs={false} md={3}>
        {sidebar}
      </Grid> : null
      }
    </Grid>
 </Container>
 )
}