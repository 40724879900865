import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {Formik, Form, Field} from 'formik';
import { TextField } from 'formik-material-ui'

import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';
import * as Yup from 'yup';

import { useSBContext } from '../../utils/snackbar';

import FormDialog from '../../utils/dialog';

import { handleErrors } from '../../utils/util';

// import Accordion from './AccordionWigs';
// import SimpleTable from '../../utils/simpleTable';

const Info = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .email {
    font-style: italic;
  }
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

// const AccordionContainer = styled.div`
//   margin-top: 2rem;
// `

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const Row = styled(Grid)`
  height: 110px;
`

const LinkHeader = styled.span`
  display: block;
  margin: 1rem auto;
`
 
export default (props) => {

  const [loading, setLoading] = useState(false);

  const [emailLoading, setEmailLoading] = useState(false);

  const [passLoading, setPassLoading] = useState(false);

  const [info, setInfo] = useState({});

  const {id} = props.match.params;

  // console.log(id);
 
  const crumbs = [{path: '/users/', text: 'Users'}, 
  {path: '/users/view', text: 'View'}];

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/users/${id}`);
    setInfo(data);
    setLoading(false)
  }


  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/users');
  }, []);


  const {handleClick} = useSBContext()

  const changePassword = async (values, setErrors, errors) => {
    const keys = Object.keys(errors)
    if (keys.length) return;

    setPassLoading(true)
    try {
      await axios.put(`/users/changePassword/user/${id}`, {...values})
      handleClick('success', 'Password successfully changed.')
      setPassLoading(false)
    } catch (e) {
      setPassLoading(false)
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)

      return;
    }

    return 'change'
  }

  const sendLink = async (e, setup = "pass") => {
    setEmailLoading(true);

    try {
      const {data} = await axios.put(`/users/setPassword/${id}?setup=${setup}`);
      setEmailLoading(false);
      handleClick('success', 'Email link sent successfully')
    } catch (e) {
      setEmailLoading(false)
      handleClick('error', 'Error sending Email')
    }
  }

  const PassSchema = Yup.object().shape({
    oldPassword: Yup
      .string()
      .required('Old password is required.'),
    password: Yup
      .string()
      .required('Password is required.')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, 'Password must be eight characters with at least one uppercase letter and a number'),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });

 return (
  <ViewInfo title="View User Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Grid container spacing={5}>

      <Grid item xs={5}>

        <Card variant="outlined">
          <CardHeader title="User Details"
          action={
            <IconLink to={`/users/edit/${info._id}`}><EditIcon /></IconLink>
          } />
          <CardContent>
            <Info><InfoLabel>Name:</InfoLabel>{info.name}</Info>
            <Info><InfoLabel>Email:</InfoLabel><span className="email">{info.email}</span></Info>
            <Info><InfoLabel>Display Name:</InfoLabel>{info.displayName}</Info>
            <Info><InfoLabel>Role:</InfoLabel>{info.role?.name}</Info>
            <Info><InfoLabel>Username:</InfoLabel>{info.username}</Info>
            <Info><InfoLabel>Status:</InfoLabel>{info.isActive ? 'Active' : 'Not Active'}</Info>
          </CardContent>
        </Card>

      </Grid>
     
      <Grid item xs={5}>
        
        <Card variant="outlined">
        <CardHeader title="Change Password"
           />
          <CardContent>
            {emailLoading ? <LinearProgress /> : 
            <>

            <Formik
              enableReinitialize 
              initialValues={{
                oldPassword: '',
                password: '',
                confirmPassword: ''
              }}
              validationSchema={PassSchema}
            >
              {({ values, setSubmitting, errors, setErrors}) => (
                <Form>

            <LinkHeader>Change User Password</LinkHeader>
            <FormDialog 
            toggle={
                <Button 
                color="secondary"
                // variant="contained"
                >Change Password</Button>
              } 
              title="Change Password"
              onSave={() => changePassword(values, setErrors, errors)}
              save="Change Password"
              size="xs"
              >

            {passLoading ? <LinearProgress /> :

              <Grid container>

                <Row item xs={12}>
                <Field
                  component={TextField}
                  name="oldPassword"
                  type="password"
                  label="Old Password"
                  variant="outlined"
                  fullWidth
                />
                </Row>

                <Row item xs={12}>
                <Field
                  component={TextField}
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                />
                </Row>

                <Row item xs={12}>
                <Field
                  component={TextField}
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                />
                  </Row>

              

                </Grid>
              }
                      
              </FormDialog>

            </Form>
          )}

        </Formik>

          

            <Divider style={{margin: '20px 0'}} />

            <LinkHeader>Email links to user</LinkHeader>
            <Grid item container xs={12} spacing={2} justifyContent="center">

              <Grid item xs={6}>
                <Button 
                color="secondary"
                variant="contained"
                size='small'
                onClick={sendLink}
                >Change Password</Button>
              </Grid>

              <Grid item xs={6}>
                <Button 
                color="primary"
                variant="contained"
                size='small'
                onClick={(e) => sendLink(e, "setup")}
                >Account Setup</Button>
              </Grid>
            
            </Grid>
            
            </>
          }
          </CardContent>
        </Card>

      </Grid>
      
    </Grid>
    }
  </ViewInfo>
 )
}