import React from 'react';
import styled from 'styled-components';

const Container = styled.div`

`;

const Title = styled.h3`
  width: 95%;
  margin: 2rem auto;
  font-weight: 500;
  font-size: 1.8rem;
`

const Main = styled.section`
  width: 95%;
  margin: 2rem auto;
`

const PanelForm = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>

      <Main>
        {children}
      </Main>
    </Container>
  )
}

export default PanelForm;