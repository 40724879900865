import React, {useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from '../../axios';

import {debounce} from 'lodash';


import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SimpleAutoComplete from '../../utils/simpleAutoComplete';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress'

import FormDialog from '../../utils/dialog';

import { theme } from '../../utils/theme';

import Redeem from '@material-ui/icons/Redeem';
import CreditCard from '@material-ui/icons/CreditCard';
import WigIcon from '../../utils/WigIcon';
import RoomIcon from '@material-ui/icons/Room';
import Search from '@material-ui/icons/Search';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Status from '../../utils/Status';

import { Flex, CommonFlex, FlexLine, Title, FullDivider } from '../../utils/common'

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import { currencyFormat, capitalize, formatDateStr } from '../../utils/util';

import { ResultContainer, Ribbon } from './Tracking'

import {useSBContext} from '../../utils/snackbar';

import WigDisplay from '../inventory/WigDisplay';

const IconFlex = styled(CommonFlex)`
  a {
    margin-left: 1rem;
  }
`

const WigInfo = styled(Card)`
  padding: 1rem 2rem 2rem 2rem;
` 

const Info = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .number {
    letter-spacing: 1px;
    /* font-weight: 600; */
  }
`

const InfoHeader = styled.span`
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.lightText};

 &.sale {
   margin-top: 2rem;
 }
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const Hr = styled(Divider)`
  margin-bottom: 1rem;
`

const TLContainer = styled(Paper)`
  padding: 1rem 2rem 4rem 2rem;
`

const AssignedIcon = styled(Avatar)`
  background-color: ${props => props.theme.colors.secondaryMain};
`

const AssignedStatus = styled.h4`
 font-size: 1.6rem;
 font-weight: 500;
 margin: .3rem;
 /* margin-bottom: 1rem; */
`

const HistoryTitle = styled.h4`
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 4rem;
`

const CurrentContainer = styled.div`
  display: flex;
  align-items: center;
  .MuiAvatar-colorDefault {
    background-color: ${props => props.theme.palette.primary.main};
  }
  
  .location-symbol {
     position: relative;
     top: .5rem;
     margin-right: 2rem;
     font-size: 4rem;
   }
`

const CurrentStatus = styled.h4`
 font-size: 1.8rem;
 font-weight: 500;
 margin-left: 2rem;
 margin-bottom: 1rem;
`
const CurrentSub = styled.h5`
   font-size: 1.6rem;
   font-weight: 500;
`

const Message = styled.div`

  h4 {
    font-size: 1.6rem;
    margin-bottom: .3rem;
    font-weight: 500;
  }

  span {
    font-size: 1.4rem;
    font-style: italic;
  }
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.primary.main};
  position: relative;
  top: .7rem;
  /* padding-right: .2rem; */
`

const FieldTitle = styled.div`
  min-width: 15rem;
  margin-right: 3rem;
`

const Icon = props => {

  let chooseIcon;

  let choose = props.choose

  if (choose.includes('stock')){
    chooseIcon = <WigIcon />
   } else if (choose.includes('consignment')) {
     chooseIcon = <Redeem />
   } else {
     chooseIcon = <CreditCard />
   }

  return (
    <>
    {chooseIcon}
    </>
  )
}


const LinkItem = ({op, linkId, shipment, children}) => {
  let collection = ""
  let isAddedToStock = op === 'added to stock' ? true : false
  switch (op) {
    case "added to stock": 
      collection = "shipments"
      break;
    case "returned":
      collection = "consignments"
      break;
    case "sent on consignment":
      collection = "consignments"
      break;
    case "sold":
      collection = "orders"
      break;
    default:
      break;
  }
  return <Link to={`/${collection}/view/${isAddedToStock ? shipment : linkId}`} target="_blank">{children}</Link>
}

const FieldRow = ({title, val, name, handler}) => {
  return <Flex dir="row" justify="flex-start" pad="0" padY="10px"><FieldTitle>{title}:</FieldTitle> <TextField onChange={handler} variant='outlined' fullWidth name={name} defaultValue={val} /></Flex>
}
 
export default ({loading, panel, idOptions, setIdOptions, idSearch}) => {


  const [editLoading, setEditLoading] = useState(false)

  const {info = {}, wigs, edits} = idOptions

  const handleChangeWigs = useCallback(debounce(async val => {
    const {data} = await axios.get(`/inventory?term=${encodeURIComponent(val)}&status=sold`)
    setIdOptions(x => {return {...x, wigs: data}});
  }, 250), [])

  const handleWigsSelect = (val) => {
    // console.log(val, "cccccc");
    setIdOptions(x => {return {...x, wigsValues: val}});
  }

  const currently = {
    status: info.status !== 'sold' ? `In ${capitalize(info.status)}` : `${capitalize(info.status)}`,  
    name: info.status !== "stock" ? `${info?.currentlyAt?.name}` : ""
  }

  const handleEdit = e => {
    const {name, value} = e.target;
    setIdOptions(x => ({...x, edits: {...x.edits, [name]: value}}))
  }

  const {handleClick} = useSBContext();

  const submitEdits = async () => {
    setEditLoading(true)
    try {
      const {data} = await axios.put(`/inventory/${info._id}`, {
        ...edits
      })
      setEditLoading(false)
      handleClick('success', 'Updates successfully saved')
      idSearch()
      return 'done';
    } catch (e) {
      setEditLoading(false)
      handleClick('error', 'Error saving changes')
    }
    
  }

  const current = info?.history?.at(-1) || {}

  if (panel !== 2) return <div></div>;


  let color, bgColor, statusText;

  if (info.status === "sold") {
    if (info.sale?.paid) {
      color = theme.colors.icon2; bgColor = theme.colors.iconBg2; statusText = "Paid"
    } else {
      if (info.sale?.paidAmount > 0) {
        color = theme.colors.iconCaution; bgColor = theme.colors.iconBgCaution; statusText = "Partially Paid"
      } else {
        color = theme.colors.iconRed; bgColor = theme.colors.iconBgRed; statusText = "Not Paid"
      }
    }
  }

 return (
      <>
       <Ribbon variant='outlined'>
          <Grid container spacing={4} alignItems="center">
      <Grid item md={8}>
      <SimpleAutoComplete 
          list={wigs} name="search-wigID" label="Wig ID"
          getOptionLabel={(opt) => opt.wigId || ''}
          getOptionSelected={({wigId}, val) => wigId === val.wigId}
          renderOption={(opt) => <span>{opt.wigId} &nbsp; - &nbsp; {opt.skuDisplay || opt.sku} &nbsp; - &nbsp; {opt.status} ({formatDateStr(opt.lastLogDate)}) {opt.status !== 'stock' ? `  -  ${opt.currentlyAt.name}` : ''} {opt.isAssigned ? ` / Assigned To ${opt.assignedTo?.name}` : ""} </span>}
          autoHighlight
          autoSelect
          handleChange={handleChangeWigs}
          handleSelect={handleWigsSelect}
          />
      </Grid>

      <Grid item xs={3}>
        <Button 
          color="primary"
          variant="contained"
          onClick={idSearch}
          >Search</Button>
      </Grid>
      </Grid>
      </Ribbon>

      <ResultContainer>
      {!!info.wigId && <WigDisplay info={info} loading={loading} submitEdits={submitEdits} editLoading={editLoading} handleEdit={handleEdit} match={{params: {id: info._id}}} />
      }
      </ResultContainer> 
       </>
 )
}