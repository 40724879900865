import React, { useState, useEffect } from "react";
import styled from 'styled-components';

import { FormHelperText, Paper, Grid, Select, MenuItem, FormControl, InputLabel, FormLabel, Button, TextField, LinearProgress } from "@material-ui/core";

import SimpleAutoComplete from "../../../utils/simpleAutoComplete";

import CollapsibleTable from "../CollapsibleTable";

import { useSBContext } from "../../../utils/snackbar";

import axios from '../../../axios'

const Container = styled.div`
  
`

const headers = [
  { id: '_id', label: 'Customer Name' },
  { id: 'current.total', currency: true, noZero: true, expand: true, expandLabel: "current", label: 'Current' },
  { id: 'under30.total', currency: true, noZero: true, expand: true, expandLabel: "under30", label: '1 - 30' },
  { id: 'under60.total', currency: true, noZero: true, expand: true, expandLabel: 'under60', label: '31 - 60' },
  { id: 'under90.total', currency: true, noZero: true, expand: true, expandLabel: 'under90', label: '61 - 90' },
  { id: 'over90.total', currency: true, noZero: true, expand: true, expandLabel: 'over90', label: '90+' },
  // { id: 'currentlyAt.name', label: 'Customer' },
  { id: 'total', currency: true, total: true, label: 'Total Balance' },
];

const detailHeaders = [
  {id: 'orderId', label: "Sale No."},
  {id: 'to.name', label: "Customer"},
  {id: 'qty', label: "Qty"},
  {id: 'total', currency: true, label: "Total"},
  {id: 'balance', currency: true, label: "Open Balance"},
  {id: 'dueDate', date: true, label: "Due Date"},
]

const AgingTopBar = ({ brands, agingOptions, setAgingOptions }) => {

  const defaultBrand = brands.length > 1 ? brands?.find(el => el.isDefault)?.name : "all";
  // if (brands.length > 1) {
  //   setBrand(brand.name)
  // } else {
  //   setBrand("all")
  // }

  const { balance, brand } = agingOptions;
  const handleSelect = (e, field) => {
    setAgingOptions(x => ({...x, [field]: e.target.value}));
  }
  return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
      {brands?.length > 1 ? <Grid item xs={3} md={3}>
          <FormControl variant="outlined" fullWidth 
          // error={brandError ? true : false}
          >
            <InputLabel>Select Brand</InputLabel>
            <Select
              defaultValue={defaultBrand}
              // value={brand}
              name="brand"
              onChange={(e) => handleSelect(e, 'brand')}
              label="Select Brand"
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                // transformOrigin: {
                //   vertical: "top",
                //   horizontal: "left"
                // },
                getContentAnchorEl: null
              }}
            >
              {brands?.map(el => 
                  <MenuItem key={el.name} value={el.name}>{el.name}</MenuItem>
                )}
              <MenuItem value="all">All</MenuItem>
            </Select>
          </FormControl>
        </Grid> : null }

        <Grid item xs={3}>
          <TextField 
            type="number"
            name = "balance"
            label="Balance Greater Than"
            defaultValue={balance}
            onChange={(e) => handleSelect(e, "balance")}
            variant="outlined"
          />
        </Grid>
        </Grid>
      </Grid>
  )
}

const AgingReport = ({ agingResults, loading }) => {
  return (
    <Container>
     { loading ? <LinearProgress /> : <>
      {agingResults.length ? <CollapsibleTable headers={headers} rows={agingResults} detailHeaders={detailHeaders} /> : null }
      </> }
    </Container>
  )
}

export {  AgingTopBar, AgingReport };