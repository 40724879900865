import React from 'react';

import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { isEmpty, truncate } from 'lodash'

import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import Publish from '@material-ui/icons/Publish'

import Lottie from '../../utils/Lotties';
import ValidateLottie from '../../images/lotties/file-validation.json';


import { Button } from '@material-ui/core';

import { theme } from '../../utils/theme';

import { Header } from '../../utils/common'

import { tableHeaders } from './headers';

const Container = styled.div`
 margin: 0 3rem;
`

// const Header = styled.h2`
//   font-weight: 400;
// `

const NoticeHeader = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const RowLength = styled.div`
  text-align: right;
  padding-right: .5rem;
  font-weight: 500;
`

const ButtonContainer = styled.div`
 width: 20rem;
 margin-bottom: 1rem;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.darkText};
  
`

const Notices = styled.div`
  /* width: 85%; */
  /* margin: 3rem 0; */
`

const NoticeRow = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;

  .text {
    margin-left: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.lightText};
  }
`

const Table = styled.div`
  border-top: 1px solid ${props => props.theme.colors.border};
  /* width: 85%; */
  margin: 0 auto;
  margin-top: 1rem;
`

const MainTable = styled.div`
  height: 45vh;
  overflow-y: scroll;
  overflow-x: auto;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  width: calc(100% + 2px);

  
   /* width */
   ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaa;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-top: none;
`

const Cell = styled.div`
  width: ${props => props.len ? `calc(100% / ${props.len})` : "25%" };
  /* width: calc(100% / 4); */
  flex-grow: 1;
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.border};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  ${props => props.header ? css`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.3rem;
    background-color: #efefef;
    border-bottom: 1px solid ${props => props.theme.colors.border};
  ` : ""}

  ${props => props.err ? css`
    background-color: ${props.theme.colors.iconBgRed};
  ` : ""}

  ${props => props.warning ? css`
    background-color: ${props.theme.colors.iconBgCaution};
  ` : ""}

`

const warnings1 = {
  // date: "No Order Date",
  // length: "Length missing on some lines"
}

const errors1 = {
  // style: "Some Styles not found"
}

const rows1 =  [{
  sku: "2",
  lengthField: "",
  capSize: "S",
  cost: 730,
  "warning": {
      lengthField: true
  }
},
{
  sku: "4g",
  lengthField: "16\"",
  capSize: "S",
  cost: 730,
  "error": {
      sku: true
  }
},
{
  sku: "2",
  lengthField: "16\"",
  capSize: "S",
  cost: 730
},
{
  sku: "2",
  lengthField: "16\"",
  capSize: "S",
  cost: 730
}]

// const tableHeaders = {
//   freight: "Freight",
//   date: "Date",
//   orderNo: "Order No.",
//   brand: "Brand",
//   // color: "color",
//   // rooting: "rooting",
//   sku: "Style",
//   wigId: "Wig ID",
//   lengthField: "Length",
//   title: "Title",
//   description: "Description",
//   hairStyle: "Hair Style",
//   highlights: "Highlights",
//   capSize: "Cap Size",
//   base: "Material",
//   cost: "Cost",
//   retail: "Price"
// }

const ShipmentTable = ({ errors = errors1, warnings = warnings1, rows = rows1, importFunc, summary, loading }) => {
  const containerXY = { 
    containerHeight: "25rem", 
    containerWidth: "auto" 
  };
  const xy = {
    height: 350,
    width: ""
  }
  return (
    <>
   {loading ? <Lottie lottieFile={ValidateLottie} xy={xy} containerXY={containerXY} /> : <Container>
      {isEmpty(summary) ? 
      <>
        {rows.length || !isEmpty(errors) || !isEmpty(warnings) ? <Header size="large">Preview Sheet</Header> : null }

        <NoticeHeader>
          {isEmpty(errors) && isEmpty(warnings) && rows.length ? <NoticeRow><DoneAllRoundedIcon htmlColor={theme.colors.dollarGreen} /><div className='text'>Sheet looks great!</div></NoticeRow> : 
          <Notices>

            {!rows.length ? <NoticeRow><CancelRoundedIcon htmlColor={theme.colors.iconRedLight} /> <div className='text'>No rows found</div></NoticeRow> : null}
            
            {Object.keys(errors).map(el => 
              <NoticeRow><CancelRoundedIcon htmlColor={theme.colors.iconRedLight} /> <div className='text'>
                {errors[el]}
                {el === "style" ? <>. To add new Style <StyledLink to={`/setup/styles/edit/`} target="_blank">Click here</StyledLink></> : ""}
                {errors[el].includes("not allowed") && el !== "style" ? <>. To add new values <StyledLink to={`/setup/inventory/`} target="_blank">Click here</StyledLink></> : ""}
              </div></NoticeRow>
              )}
            {Object.keys(warnings).map(el => 
              <NoticeRow><WarningRoundedIcon htmlColor={theme.colors.iconCaution} /> <div className='text'>{warnings[el]}</div></NoticeRow>
              )}
          </Notices> 
          } 
          <ButtonContainer>
          <Button variant="contained" color="secondary" startIcon={<PostAddRoundedIcon />} disabled={!isEmpty(errors)} onClick={(e) => importFunc(e, "import")}>
            Import Shipment
          </Button>
          </ButtonContainer>  
        </NoticeHeader>
      </> : null }

        {rows.length ? <RowLength>{rows.length} Wigs</RowLength> : null}
       {rows.length ? 
      <Table>
        <Row>
          {rows[0] && Object.keys(rows[0]).filter(el => el !== "warning" && el !== "error").map(el => 
            <Cell len={rows[0]?.length} header>{truncate(tableHeaders[el], {length: 10})}</Cell>
            )}
        </Row>
        <MainTable>
          {rows.map(el => 
            <Row>
              {console.log(el, Object.values(el))}
              {Object.entries(el).filter(([key, value]) => !value || typeof value !== "object").map(([key, value]) => 
                <Cell len={rows[0]?.length} err={el.error && el.error[key]} warning={el.warning && el.warning[key]}>{value || value === 0 ? truncate(value, {length: 10 }) : "-"}</Cell>
                )}
            </Row>
          )}
        </MainTable>
      </Table> : null }
    </Container>
   }
   </>
  )
}

export default ShipmentTable;