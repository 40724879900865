import React, {useState} from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

import {useAuthContext} from '../../auth'

import {Drawer,  List, Divider,
  ListItem, ListItemIcon, ListItemText, Hidden, IconButton, Collapse} from "@material-ui/core";

import {ChevronLeft, ChevronRight, LocalShipping, Contacts, 
 Redeem, CreditCard, Settings, MyLocation, VerifiedUser, ExpandLess, ExpandMore, Person, ListAlt, Assessment,
MonetizationOn, Style} from '@material-ui/icons';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import TableChartRoundedIcon from '@material-ui/icons/TableChartRounded';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import SettingsInputComponentRoundedIcon from '@material-ui/icons/SettingsInputComponentRounded';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded';
import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded';
import HealingRoundedIcon from '@material-ui/icons/HealingRounded';
import SyncAltRoundedIcon from '@material-ui/icons/SyncAltRounded';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';



// import WigIcon from '../../images/wig.svg'

const SideDrawer = styled(Drawer)`
  width: ${props => props.open ? '25rem' : `0`};
  min-height: 100%;
  transition: all .25s;

  .MuiDrawer-paper {
    width: 25rem;
    background-color: #152136;
  }
`

const DrawerHeader = styled.div`
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #101724;
`

const Logo = styled.div`
  width: 90%;
  text-align: center;
  span {
  color: #ccc;
  letter-spacing: 1px;
  word-spacing: 3px;
  font-size: 1.6rem;
  }
   img {
     display: block;
     margin: 0 auto;
     width: 10rem;
   }
`

const DrawerMain = styled.div`
  /* margin-top: 2rem; */
  padding-left: .5rem;
  overflow-y: auto;
  overflow-x: hidden;

  img {
    width: 2.2rem;
  }

  svg {
    color: ${props => props.theme.colors.fadedWhite};
  }

  #Layer_2 {
    fill: currentColor;
    width: 1em;
    height: 1em;
    /* display: inline-block; */
    font-size: 2.4rem;
  }

  span {
    color: ${props => props.theme.colors.fadedWhite};
  }

  .list-item {
    margin: 1.5rem 0;
  }

  .list-item-parent {
    margin: 1.5rem 0 1rem 0;
  }

  .list-item-indented {
    margin: 1.5rem 0;
    margin-left: 1rem;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #24385c;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #324f81;
  }
  /* overflow: hidden; */
`

const WigSvg = () => (
  <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 894 965" style={{enableBackground: "new 0 0 894 965"}} xmlSpace="preserve">
  <path d="M372.5,42.5c0,0-90-5-163,101c-33,41-39,113-39,120s-5,62-32,75s-57,78-44,121s1,101-21,117s-49,45-41,92c4,24,17,25,11,44
  s-33,24-25,88c12,51,25,62,25,62s-4-57,8-61s55,79,36,87c-14,12,82-41,122-42s130-11,158-98c19-91,11-93,11-93s-114-93-112-152
  c0,0-5-39,4-79s8-61,8-61s51-65,54-82c22,27,12,74-4,84s30-3,46-44s-1-78-1-78s12-34,28-35c13,25,57,92,142,101c0,0,23,97,85,118
  c0,0,10,153-91,226c0,0-1,101,39,143s110,56,128,54c0,0-16,51-1,73s41,31,41,31s-9-50,13-68s37-16,37-16l26,30c0,0,19-9,36-60
  s4-78,4-78s-44-20-47-59c4-39,6-50,6-50s11-8,13-28s-26-34-29-42s-4-19,15-38s16-56,10-75s-37-75-61-96c0,0-1-99-4-117
  s-35-106-125-181c-48-32-106-58-106-58s-68-15-103-8S376.5,25.5,372.5,42.5z"/>
</svg>
)
 
export default ({open, toggle}) => {

  const [expandAdmin, setAdminExpanded] = useState(false);
  const [expandAccounting, setAccountingExpanded] = useState(false);

  const [expand, setExpand] = useState({
    
  });

  const handleClick = (func) => {
    func(x => !x);
  };

  const handleExpand = (nav) => {
    setExpand(x => ({
      ...x,
      [nav]: !x[nav]
    }))
  }

  const {hasPermission} = useAuthContext()

  const dashList = [
    {
      text: 'Dashboard',
      icon: <DashboardRoundedIcon />,
      href: '/dashboard/',
      permission: 'readDashboard'
    },
    {
      text: 'Shipments',
      icon: <LocalShipping />,
      href: '/shipments/',
      permission: 'readShipment'
    },
    {
      text: 'Customers',
      icon: <Contacts />,
      href: '/customers/',
      permission: 'readCustomer'
    },
    {
      text: "Vendors",
      icon: <StorefrontRoundedIcon />,
      href: '/vendors/',
      permission: 'readVendor',
      expandText: 'vendor',
      list: [
        {
          text: 'Vendor List',
          icon: <ViewListRoundedIcon />,
          href: '/vendors/',
          permission: 'readVendor'
        },
        // {
        //   text: 'Work Orders',
        //   icon: <DescriptionRoundedIcon />,
        //   href: '/production',
        //   permission: 'readProduction'
        // }
      ]
    },
    // {
    //   text: 'Brands',
    //   icon: <Style />,
    //   href: '/brands/',
    //   permission: 'readBrand'
    // },
    {
      text: 'Inventory',
      icon: <WigSvg />,
      href: '/inventory/',
      permission: 'readInventory'
    },
    {
      text: 'Consignments',
      icon: <Redeem />,
      href: '/consignments/',
      permission: 'readConsignment'
    },
    {
      text: 'Sales',
      icon: <CreditCard />,
      href: '/orders/',
      permission: 'readOrder'
    },
    {
      text: 'Repairs',
      icon: <PaletteRoundedIcon />,
      href: '/repairs/',
      permission: 'readRepair',
      expandText: 'repair',
      list: [
        {
          text: 'Transfers',
          icon: <SyncAltRoundedIcon />,
          href: '/repair_transfers/',
          permission: 'readRepair'
        },
        {
          text: 'Services',
          icon: <BuildRoundedIcon />,
          href: '/services/',
          permission: 'readServices'
        },
        {
          text: 'Repair List',
          icon: <ListAltRoundedIcon />,
          href: '/repairs/',
          permission: 'readRepair'
        },
      ]
    },
    {
      text: 'Payments',
      icon: <MonetizationOn />,
      href: '/payments/',
      permission: 'readPayment'
    },
    {
      text: 'Track',
      icon: <MyLocation />,
      href: '/track/',
      permission: 'readInventory'
    }
  ];
 
 return (
  <SideDrawer 
  variant="persistent"
  anchor="left"
  open={open}>
  <DrawerHeader>
    <Logo>
      {/* <img src={UtopiaLogo} /> */}
      <span>NOVO TRACKER</span>
    </Logo>
    <IconButton onClick={toggle}>
      <ChevronLeft />
    </IconButton>
  </DrawerHeader>
  <Divider />
  <DrawerMain>
    <List>
      {
        dashList.map(({text, icon, href, permission, list, expandText}) => {
          if (!hasPermission(permission)) return (<div></div>);
          if (!list) {
            return (
            <ListItem component={Link} to={href} className="list-item" button key={text}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text}/>
            </ListItem>
            )
          } else {
              return (
                <React.Fragment key={text}>
                  <ListItem className={expand[expandText] ? "list-item-parent" : 'list-item'} onClick={() => handleExpand(expandText)}  button>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                    {expand[expandText] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={expand[expandText]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {list.map(({text, icon, href, permission}) => {
                        if (!hasPermission(permission)) return (<div></div>);
                        return (
                          <ListItem key={text} button component={Link} to={href} className="list-item-indented">
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={text} />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              )
            }
        })
      }
          
          

      <ListItem className={expandAccounting ? "list-item-parent" : 'list-item'} onClick={() => handleClick(setAccountingExpanded)}  button>
        <ListItemIcon><Assessment /></ListItemIcon>
        <ListItemText primary='Accounting'/>
        {expandAccounting ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
     

      <Collapse in={expandAccounting} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          
          {hasPermission("readAccounting") ? 
          <ListItem button component={Link} to='/accounting/' className="list-item-indented">
            <ListItemIcon>
              <BarChartRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Accounting Reports" />
          </ListItem>
          : null }

          {hasPermission("readCustomer") ? 
          <ListItem button component={Link} to='/reports/' className="list-item-indented">
            <ListItemIcon>
              <TableChartRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="General Reports" />
          </ListItem>
          : null }

          {hasPermission("writeAccounting") ? 
          <ListItem button component={Link} to='/expenses/' className="list-item-indented">
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Expenses" />
          </ListItem>
          : null}

        </List>
      </Collapse>


      {hasPermission("readUser") || hasPermission("readRoles") ? 
      <ListItem  onClick={() => handleClick(setAdminExpanded)}  button>
        <ListItemIcon><VerifiedUser /></ListItemIcon>
        <ListItemText primary='Admin'/>
        {expandAdmin ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      : null }

      <Collapse in={expandAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          
          {hasPermission("readUser") ? 
          <ListItem button component={Link} to='/users/' className="list-item indented">
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          : null }

          {hasPermission("readRole") ? 
          <ListItem button component={Link} to='/roles/' className="list-item indented">
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary="Roles" />
          </ListItem>
          : null}

          {hasPermission("readSettings") ? 
          <ListItem button component={Link} to='/setup/' className="list-item indented">
            <ListItemIcon>
              <SettingsInputComponentRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="System Setup" />
          </ListItem>
          : null}

        </List>
      </Collapse>

    </List>
  </DrawerMain>
</SideDrawer>
 )
}