import React from 'react';
import styled, { css } from 'styled-components'

import { Select, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'

const Label = styled(InputLabel)`
  &.MuiInputLabel-outlined {
    color: ${props => props.labelColor ? props.labelColor : ""};
  }
`

const SelectInput = styled(Select)`
  /* width: 100%; */

.MuiInputBase-input {
  color: ${props => props.textColor ? props.textColor : ""};
  background-color: ${props => props.bgColor ? props.bgColor : ""};
  width: calc(100% - 46px);
  /* width: auto; */

  ${props => props.button ? css`
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 90%;
  ` : ""}
}

  .MuiSelect-iconOutlined {
    color: ${props => props.textColor ? props.textColor : ""};

    ${props => props.button ? css`
      color: ${props => props.bgColor ? props.bgColor : ""};
    ` : ""}
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.borderColor ? props.borderColor : ""};
    border-width: 2px;
  }
`

// const useOutlinedInputStyles = makeStyles(theme => ({
//   root: {
//     "& $notchedOutline": {
//       borderColor: "red"
//     },
//     "&:hover $notchedOutline": {
//       borderColor: "blue"
//     },
//     "&$focused $notchedOutline": {
//       borderColor: "green"
//     }
//   },
//   focused: {},
//   notchedOutline: {}
// }));

const OutlinedSelect = ({children, label, fullWidth = true, size = "medium", color, textColor, variant = "outlined", val, onChange, name, bgColor, button = false}) => {
  return (
    <FormControl variant={variant} fullWidth={fullWidth} size={size}>
      {label ? <Label id="cust-search-options-label" labelColor={textColor ? textColor : color}>{label}</Label> : null }
      <SelectInput
        button={button}
        borderColor={color}
        textColor={textColor ? textColor : color}
        bgColor={bgColor}
        labelId="cust-search-options-label"
        id="cust-search-options"
        value={val}
        onChange={onChange}
        label={label}
        fullWidth={fullWidth}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          // transformOrigin: {
          //   vertical: "top",
          //   horizontal: "left"
          // },
          getContentAnchorEl: null
        }}
        input={<OutlinedInput
          name={name}
          label={label}
        >
        </OutlinedInput>}
      >
        {children}
      </SelectInput>
    </FormControl>
  )
}

export default OutlinedSelect;