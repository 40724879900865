import React from 'react';
import styled from 'styled-components';

import CustomChip from '../../../utils/CustomChip';

import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';


const ChipContainer = styled.div`
  width: max-content;
`

const ChipFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
`;

const ChipBlock = ({level, fontSize, text, icon}) => {
  return (
    <ChipContainer>
      <CustomChip level={level} fontSize={fontSize} size="medium">
        <ChipFlex>
        {icon} <span>{text}</span>
        </ChipFlex>
      </CustomChip>
    </ChipContainer>
  )
}

const getChip = (status, size="small") => {
  switch(status) {
    case 'in-progress':
      return <ChipBlock level="info" fontSize={size} text="In Progress" icon={<AccessTimeRoundedIcon />} />
    case 'complete':
      return <ChipBlock level="success" fontSize={size} text="Complete" icon={<AssignmentTurnedInRoundedIcon />} />
    case 'cancelled':
      return <ChipBlock level="error" fontSize={size} text="Cancelled" icon={<BlockRoundedIcon />} />
    default:
      return <ChipBlock level="warning" fontSize={size} text="Pending" icon={<HourglassEmptyRoundedIcon />} />
  }
}

export default getChip;