import { sumBy } from 'lodash';

// This function updates the payments array to remove any payments that are more than the total of the wig in the lineItems array. NEW!!!
const updatePayments = (lineItems, payments = []) => {
  // check if wig payments are not more than the wig total in lineitmes. if so, remove the extra payments. NEW!!!
  let updatedPayments = []
  lineItems.forEach(el => {
   let wigPayments = payments.filter(pay => pay.paymentWigId === el.wigId)

    if (wigPayments.length) {
      let paidTotal = sumBy(wigPayments, 'paymentAmount')
      if (paidTotal > el.total) {
        let diff = paidTotal - el.total
        let newPayments = wigPayments.map((cur, i) => {
          let payObj = {...cur}
          if (diff <= 0) {
            // if diff is 0 or less, we stop modifying the payments
            return payObj
          }
          // we remove the diff amount from the first payment. If there is still a diff, we remove the next payment and so on
          diff = diff - payObj.paymentAmount; // might be negative amount. We will remove the payment if it is
          payObj.paymentAmount = diff >= 0 ? 0 : Math.abs(diff)
          return {...payObj}
        }).filter(el => el.paymentAmount > 0)
        updatedPayments = updatedPayments.concat(newPayments)
      } else {
          updatedPayments = updatedPayments.concat(wigPayments)
      }
    }
  })

  let remainingPayments = payments.filter(pay => !pay.paymentWigId)
  updatedPayments = updatedPayments.concat(remainingPayments)
  return updatedPayments;
}

export { updatePayments };