import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../../utils/ProtectedRoute';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import { debounce, get } from 'lodash'

import Table from '../../utils/table-server';

import AddConsignment from './AddConsignment';
import ViewConsignment from './ViewConsignment';

import { Switch as Switcher, FormControlLabel} from '@material-ui/core'

import { useSBContext } from '../../utils/snackbar';

import Indicator from '../../utils/Indicator';

import CustomChip from '../../utils/CustomChip';

import FilterFields from '../../utils/common/filters';
import { createFilterQuery } from '../../utils/util';

const SwitchContainer= styled(FormControlLabel)`
  width: 300px;
  margin-left: 30px;
`

const ChipContainer = styled.div`
  width: max-content;
`
 
export default (props) => {
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(0);

  const [switcher, setSwitch] = useState(false);

  const [tabFilter, setTabFilter] = useState("")

  const [search, setSearch] = useState({
    p: '', rpp: '', sp: '', so: ''
  });

  const [query, setQuery] = useState('/consignments')

  const { handleClick } = useSBContext();

  const newDate = new Date()

  const [filter, setFilter] = useState({
    startDate: newDate,
    endDate: newDate,
    useFromDate: false
  })

  const handleFilter = (e, field, input) => {
    let val = input === "check" ? e.target.checked : input === "date" ? e : e.target.value;
    setFilter({ ...filter, [field]: val });
  };

  const applyFilter = () => {
    const {p, rpp, sp, so} = search;
    load(p, rpp, sp, so)
  }

  const load = async (page, rowsPerPage, sortProp, sortOrder, queryStr = query) => {
    let active = switcher ? `&includeClosed=true` : ""
    let filters = createFilterQuery(filter)
    // console.log(page, rowsPerPage,sortProp, sortOrder,);
    setSearch({p: page, rpp: rowsPerPage, sp: sortProp, so: sortOrder});
    let sort = sortOrder === 'asc' ? `${sortProp}` : `-${sortProp}`
    setLoading(true)
    let queryString = queryStr === '/consignments' ? `/consignments?sort=${sort}&page=${page}&limit=${rowsPerPage}&status=${tabFilter}&${filters}` : `${queryStr}&sort=${sort}&page=${page}&limit=${rowsPerPage}&status=${tabFilter}&${filters}`;
    queryString = queryString + active

    try {
      const {data} = await axios.get(queryString)
      setLoading(false)

      setRows(data.docs || []);
      setCount(data.pages || 0)

      if (!data.docs)
        handleClick('error', 'Error retrieving data')
    } catch (e) {
      setLoading(false)
      setRows([])
    }
  }

  const searchCust = useCallback(debounce(async (val, setPage) => {
    let query = switcher ? `&includeClosed=true` : ""
    let filters = createFilterQuery(filter)
    const {rpp} = search
    let encodedVal = encodeURIComponent(val)
    const {data} = await axios.get(`/consignments?term=${encodedVal}&page=0&limit=${rpp}${query}&status=${tabFilter}&${filters}`)
    setPage(0)
    setQuery(`/consignments?term=${encodedVal}`);
    setRows(data.docs || []);
    setCount(data.pages || 0)
  }, 250), [search, switcher, tabFilter])

  // useEffect(() => {
  //   if (props.location.pathname !== '/consignments/') return
  //     load();
  // }, [props.location.pathname])

  useEffect(() => {
    const {p, rpp, sp, so} = search
    // console.log(p, "chchchch");
    if (sp)
      load(0, rpp, sp, so, query)
  }, [switcher, tabFilter])

  const headers = [
    { id: 'consignmentId', label: 'Consignment ID' },
    { id: 'qty', label: 'Qty' },
    { id: 'to.name', render: (row) => row?.isCheckIn ? row?.from?.name ? row?.from?.name : "-" : row?.to?.name, label: 'Customer'},
    {id: "status", label: "Status", render: (row) => {
      let status, text;
      if (row.activeWigs === 0) {
        status = 'fail'
        text = "Closed"
      } else {
      if (row.activeWigs === row.qty) {
        // if (row.status !== 'draft') {
        //   status = 'success'
        //   text = "Open"
        // } else {
        //   status = 'draft'
        //   text = "Draft"
        // }
        status = 'success'
        text = "Open"
      } else {
        status = "partial"
        text = "Open"
      }
    }
      return <Indicator status={status} text={text} />
    }},
    { id: "type", render: (row) => {
      let type;
      if (row.status === 'draft') {
        type = <CustomChip level="info" fontSize="small">Draft</CustomChip>
      } else if (row.isCheckIn) {
        type = <CustomChip level="icon3" fontSize="small">Check-In</CustomChip>
      } else {
        type = <CustomChip fontSize="small" level="icon4">Consignment</CustomChip>
      }
      return <ChipContainer>{type}</ChipContainer>
    }, label: "Type"},
    { id: 'date', date: true, label: 'Date'}
  ];

  const path = props.match.url;

  const urgent = row => {
    if (get(row, "to.name") === "Stock") {
      return true
    } else {
      return false
    }
  }

  const handleSwitch = (e) => { 
    setSwitch(e.target.checked);
  };

  const handleTabs = (filter) => {
    setTabFilter(filter)
  }

  const tabs = [
    {
      label: "All",
      title: "",
      active: tabFilter === "" ? true : false
    },
    {
      label: "Consignments",
      default: true,
      title: "active",
      active: tabFilter === "active" ? true : false
    },
    {
      label: "Check-Ins",
      title: "stock",
      active: tabFilter === "stock" ? true : false
    },
    {
      label: "Drafts",
      title: "draft",
      active: tabFilter === "draft" ? true : false
    }
  ]

 return (
  <Switch>
  <ProtectedRoute path={`${path}/edit/:id`} guard='writeConsignment' component={AddConsignment} />
  <ProtectedRoute path={`${path}/edit`} guard='writeConsignment' component={AddConsignment} />
  <ProtectedRoute path={`${path}/view/:id`} guard='readConsignment' component={ViewConsignment} />
  <Route path="/consignments">
   <Table loading={loading} load={load} tabs={tabs} tabHandler={handleTabs} title="Consignments" headers={headers} rows={rows} 
   urgent = {!tabFilter  ? urgent : () => {}}
    count={count} ord="desc" ordField="date"
      link="consignments"
      switchField={<SwitchContainer
        control={
          <Switcher
            checked={switcher}
            onChange={handleSwitch}
            name="closed"
            color="primary"
          />
        }
        label="Include Closed"
        />}
      filterItems={
        <FilterFields filter={filter} handleFilter={handleFilter} applyFilter={applyFilter} enableBalance={false} />
      }
      search={setPage => <TextField name="search" label="Search by Consignment ID, Customer Name, Wig ID"
        variant="outlined" size="small" fullWidth onChange={(e) => searchCust(e.target.value, setPage)}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <Search color="primary" />
            </InputAdornment>
        }}
        />} 
      />
  </Route>
</Switch>

  
 )
}