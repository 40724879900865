// list of colors to choose from
export default [
  "#26a9e1",
  "#e25628",
  "#e28528",
  "#e2b328",
  "#e2e228",
  "#b3e228",
  "#85e228",
  "#56e228",
  "#28e228",
  "#28e256",
  "#28e285",
  "#28e2b3",
  "#28e2e2",
  "#28b3e2",
  "#2885e2",
  "#2856e2",
  "#2828e2",
  "#5628e2",
  "#8528e2",
  "#b328e2",
  "#e228e2",
  "#e228b3",
  "#e22885",
  "#e22856"
]
