import styled from 'styled-components';

export const Grid = styled.div`
  box-sizing: border-box;
  display: grid;
  /* width: 100%; */
  margin: ${props => props.marginY || props.margin || "0"} ${props => props.marginX || props.margin || "0"};
  padding: ${props => props.paddingY || props.padding || "0"} ${props => props.paddingX || props.padding || "0"};
  grid-template-columns: repeat(12, 1fr);
  gap: ${props => props.spacing || '1rem'};
  row-gap: ${props => props.rowGap || props.spacing || '1rem'};
  column-gap: ${props => props.columnGap || props.spacing || '1rem'};
  /* grid-auto-rows: minmax(${props => props.height || '10rem'}, auto); */
`

export const Row = styled.div`
  grid-column: ${props => props.xs ? `span ${props.xs}` : 'span 12'};

  @media ${props => props.theme.breaks.up('smp')} {
    grid-column: ${props => props.smp ? `span ${props.smp}` : props.xs ? `span ${props.xs}` : 'span 12'};
  }
  @media ${props => props.theme.breaks.up('sm')} {
    grid-column: ${props => props.sm ? `span ${props.sm}` : props.xs ? `span ${props.xs}` : 'span 12'};
  }
  @media ${props => props.theme.breaks.up('md')} {
    grid-column: ${props => props.md ? `span ${props.md}` : props.xs ? `span ${props.xs}` : 'span 12'};
  }
  @media ${props => props.theme.breaks.up('lg')} {
    grid-column: ${props => props.lg ? `span ${props.lg}` : props.xs ? `span ${props.xs}` : 'span 12'};
  }
  @media ${props => props.theme.breaks.up('xl')} {
    grid-column: ${props => props.xl ? `span ${props.xl}` : props.xs ? `span ${props.xs}` : 'span 12'};
  }
`