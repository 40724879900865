import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

const LottieContainer = styled.div`
  height: ${props => props.containerHeight ? props.containerHeight : 'auto'};
  width: ${props => props.containerWidth ? props.containerWidth : 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LottieAnimation = ({ lottieFile, options, xy = {}, containerXY = {} }) => {
  const { height, width } = xy;
  const { containerHeight, containerWidth } = containerXY;

  const defaultOptions = (data) => (
    options ? options : {
    loop: true,
    autoplay: true,
    speed: 1,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
      // preserveAspectRatio: "none slice"
    }
  });

  return (
    <LottieContainer containerHeight={containerHeight} containerWidth={containerWidth} >
      <Lottie
        options={defaultOptions(lottieFile)}
        height={height ? height : {}}
        width={width ? width : {}}
      />
    </LottieContainer>
  );
}

export default LottieAnimation;