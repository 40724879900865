import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ViewInfo from '../../../utils/viewInfo';
import axios from '../../../axios';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';   

import displayPdf from '../../../utils/displayPdf';

import FormDialog from '../../../utils/dialog';

import OutlinedSelect from '../../../utils/OutlinedSelect';

import { useSBContext } from '../../../utils/snackbar';


import { currencyFormat, formatPhone, formatCalendar, capitalizeWords, capitalize} from '../../../utils/util';

import { getChipInfo, WigInfo, WigActions, WigInfoItemHeader, WigInfoItem, ItemContainer, Chip, ItemAccordion,
Repairs, ItemRepairList, ItemRepairListContainer, ItemRepairListHandleContainer, ItemRepairListHandle, getRepairStatus, getTransferType } from './common';


import { FullDivider, SectionHeader, Flex, Header } from '../../../utils/common';

import { theme } from '../../../utils/theme';

import SimpleTable from '../../../utils/simpleTable';

import { Switch, FormControlLabel, FormHelperText } from '@material-ui/core';


import StatCard from '../../../utils/StatCard';

const TooltipContainer = styled(Flex)`

`;

const SelectContainer = styled.div`
  margin-bottom: 3rem;
`;

const Info = styled.span`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  align-items: center;

  .email {
    font-style: italic;
  }
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primaryMain};
  font-weight: 500;
`

const RepairName = styled.div`
  /* color: ${props => props.color || "inherit"}; */
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: .6rem;
`;

const ColoredDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: ${props => props.color || "inherit"};
`;

const SelectHeader = styled(Header)`
  font-size: 1.2rem;
  margin-bottom: .5rem;
`;

const NotesLabel = styled.div`
  font-weight: 500;
  margin-top: 1.8rem;
  /* color: ${props => props.theme.palette.grey[600]}; */
  /* font-style: italic; */
  font-size: 1.2rem;
`;

const Notes = styled.div`
  font-style: italic;
  background-color: ${props => props.theme.palette.grey[100]};
  padding: .5rem 1rem;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-radius: 3px;
  min-height: 80px;
  margin-top: .8rem;
`;

const RepairLine = styled.div`
  display: flex;
  width: calc(100% - 8px);
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .4rem 10px;
  position: relative;
  /* width: 97%; */
  border: 1px solid ${props => props.theme.colors.border};
  /* border-left: 3px solid ${props => props.theme.colors.secondary75}; */
  border-top: none;
  /* border-radius: 5px; */
  background-color: ${props => props.theme.palette.grey[100]};

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;


 
const ViewTransfer = (props) => {

  const [loading, setLoading] = useState(false)

  const [info, setInfo] = useState({});

  const [repairs, setRepairs] = useState([]);
  const [recipient, setRecipient] = useState("from");

  const {id} = props.match.params;

  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [eLoading, setELoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const [itemExpanded, setItemExpanded] = useState({

  });

  const sendEmail = async () => {
    if (!email) return setEmailErr(true);
    setELoading(true)
    try {
      await axios.post(`/repair_transfers/${id}/invoice?email=${email}&recipient=${recipient}`)
      handleClick('success', 'Email successfully sent')
      setInvoiceLoading(false)
    } catch (e) {
      handleClick('error', 'Error sending email')
      setELoading(false)
    }
    return 'sent'
  }

  const printInvoice = async () => {
    setInvoiceLoading(true)
    try {
      const {data} = await axios({
        method: "POST",
        url: `/repair_transfers/${id}/invoice?pdf=true&recipient=${recipient}`,
        responseType: 'blob',
        timeout: 60000,
        headers: {"Content-Type": "application/pdf"}
      });
      displayPdf(data, `Transfer Sheet`)
    } catch (e) {
      console.log(e);
    } finally {
      setInvoiceLoading(false)
    }
    
  }

  const handleEmailChange = (e) => {
    setEmailErr(false)
    setEmail(e.target.value)
  }
  
  const handleExpand = (wigId) => {
    setItemExpanded(expanded => ({...expanded, [wigId]: !expanded[wigId]}));
  }

  const handleSelectRecipient = (e) => {
    setRecipient(e.target.value);
    let invoiceRecipient = e.target.value === "from" ? info.from?.id : info.to?.id;
    if (invoiceRecipient && invoiceRecipient.email) {
      setEmail(invoiceRecipient.email);
    }
  }

  const { handleClick } = useSBContext();


  // console.log(id);
 
  const crumbs = [{path: '/repair_transfers/', text: 'Transfers'}, 
  {path: '/repair_transfers/view', text: 'View'}];

  const loadData = async () => {
    setLoading(true)
    try {
      const {data} = await axios.get(`/repair_transfers/${id}`);
      console.log(data, "data");
      setInfo(data.transfer);
      let invoiceRecipient = ["rthq", "sthq"].includes(data.transfer.transferType) ? data.transfer.from?.id : data.transfer.to?.id;
      let recipient = ["rthq", "sthq"].includes(data.transfer.transferType) ? "from" : "to";
      setEmail(invoiceRecipient?.email);
      setRecipient(recipient);
      setRepairs(data.repairs);
    } catch (e) {
      handleClick('error', 'Error loading data')
    } finally {
      setLoading(false)
    }
  }



  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/repair_transfers/');
  }, [])

  const getStatus = (status) => {
    if (status === 'in-progress')
    return 'In Progress';

    return capitalize(status);
  }

 return (
  <ViewInfo title="View Transfer Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Grid container spacing={3}>

      <Grid item xs={5}>

        <Card variant="outlined">
          <CardHeader title="General Details"
          />
          <CardContent>
            <Info><InfoLabel>Transfer ID:</InfoLabel>{info.transferId}</Info>
            <Info><InfoLabel>Source:</InfoLabel>{info.from?.name || "HQ"}</Info>
            <Info><InfoLabel>Destination:</InfoLabel>{info.to?.name || "HQ"}</Info>
            <Info><InfoLabel>Qty:</InfoLabel>{info.qty}</Info>
            <Info><InfoLabel>Date:</InfoLabel>{formatCalendar(info.date)}</Info>
            <Info><InfoLabel>Status Updated:</InfoLabel>{formatCalendar(info.status_updated)}</Info>
           
            <FullDivider y="1.5rem" />
            {/* <Info><InfoLabel>Notes:</InfoLabel>{info.notes}</Info> */}
            <NotesLabel>Notes</NotesLabel>
            <Notes>{info.notes}</Notes>
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={4}>

        <Card variant="outlined">

          <CardHeader title="Transfer Details" action={
            <TooltipContainer dir="row" pad="0" justify="space-between">
            <Tooltip title="Print Receipt">
            <IconButton onClick={printInvoice}>
              {invoiceLoading ? <CircularProgress size={25} /> :
              <PrintIcon color="primary" /> }
            </IconButton>
            </Tooltip>
            
            <FormDialog toggle={
                <Tooltip title="Email Receipt">
                <IconButton>
                  <EmailIcon color="primary" />
                </IconButton>
                </Tooltip>
              } 
              title="Add Email Address"
              onSave={sendEmail}
              save={eLoading ? <CircularProgress size={25} /> : "Send Email"}
              size="sm"
              >
              <Grid container justify-content="center" spacing={3}>
                <Grid item xs={8}>
                  <TextField error={emailErr} variant="outlined" label="Email" name="email" type="email" fullWidth defaultValue={email} onChange={handleEmailChange}
                  helperText={emailErr ? 'Email Required' : ''} 
                  />
                </Grid>
              </Grid>
              </FormDialog>
              </TooltipContainer>
          } />
          <CardContent>
          {info?.transferType === "rtr" ? <SelectContainer>
            <SelectHeader>Recipient Selected</SelectHeader>
            <OutlinedSelect size="small" 
              color={theme.colors.secondary75} 
              bgColor={theme.colors.secondary75}
              textColor={theme.colors.secondary25}
              button
                val={recipient} 
                onChange={handleSelectRecipient}
              >
                <MenuItem value="from">{info?.from?.id?.name}</MenuItem>
                <MenuItem value="to">{info?.to?.id?.name}</MenuItem>
              </OutlinedSelect>
            </SelectContainer> : null }
            <Info><InfoLabel>Transfer Type:</InfoLabel>{getTransferType(info?.to)}</Info>
           {!!repairs.length && 
           <>
            <Info><InfoLabel>No of Repairs:</InfoLabel>{repairs.length}</Info>
            <Info><InfoLabel>Total Cost:</InfoLabel>{currencyFormat(repairs.reduce((acc, cur) => acc + cur.total, 0))}</Info>
           </>
          }
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardHeader title={`Wigs on transfer - ${info.qty || 0}`} />

          <CardContent>
          {info.wigs?.map(wig => {
            let repairList = repairs.filter(el => el.wig?.wigId === wig.wigId);
           return <ItemAccordion key={wig.wigId}>
              <ItemContainer err={wig.err}>
                <WigInfo>
                  <WigInfoItem>
                    <WigInfoItemHeader>Wig ID</WigInfoItemHeader>
                    <span>{wig.wigId}</span>
                  </WigInfoItem> 
                  <WigInfoItem>
                    <WigInfoItemHeader>Style</WigInfoItemHeader>
                    <span>{wig.sku}</span>
                  </WigInfoItem>
                  <WigInfoItem>
                    <WigInfoItemHeader>Status</WigInfoItemHeader>
                    <span><Chip fontSize='small' level={getChipInfo(wig.status)}>{capitalize(wig.status)}</Chip></span>
                  </WigInfoItem>
                  {wig.status !== 'stock' && <WigInfoItem>
                    <WigInfoItemHeader>Customer</WigInfoItemHeader>
                    <span>{wig.currently_at?.name || "HQ"}</span>
                  </WigInfoItem> }
                  {!!repairList.length && <WigInfoItem>
                    <WigInfoItemHeader>Repairs</WigInfoItemHeader>
                    <span>{repairList.length}</span>
                  </WigInfoItem> }
                </WigInfo>
                <WigActions>
                </WigActions>
              </ItemContainer>

              {/* {repairs.length && 
              <ItemRepairListContainer expanded={itemExpanded[wig.wigId]}>
              <ItemRepairList expanded={itemExpanded[wig.wigId]}>
               <Repairs>
                {repairs.filter(item => item.wigId === wig.wigId).map(({repair: {name, price, duration, notes, key}}) =>
                <RepairLine id={key} name={name} duration={duration} notes={notes} price={price}  handleChangeRepair={handleChangeRepair} removeRepair={removeRepair} setExistingRepairs={setExistingRepairs} setNewRepairs={setNewRepairs} isEdit={true} />
                )}
                </Repairs> 
              </ItemRepairList>
              <ItemRepairListHandleContainer>
                <ItemRepairListHandle onClick={() => handleExpand(wig.wigId)}>
                <span>Repairs</span> {itemExpanded[wig.wigId] ? <KeyboardArrowDownRoundedIcon fontSize='small' /> : <KeyboardArrowRightRoundedIcon fontSize='small' /> }
                </ItemRepairListHandle>
              </ItemRepairListHandleContainer>
            </ItemRepairListContainer>
            } */}
            {repairs.filter(el => el.wig?.wigId === wig.wigId).map(el => <RepairLine>
              <WigInfo>
                <WigInfoItem gap=".3rem">
                  <WigInfoItemHeader>Repair Type</WigInfoItemHeader>
                  <span>{el.repair?.name}</span>
                </WigInfoItem> 
                <WigInfoItem gap=".3rem">
                  <WigInfoItemHeader>Price</WigInfoItemHeader>
                  <span>{currencyFormat(el.repair?.price)}</span>
                </WigInfoItem>
                <WigInfoItem gap=".3rem">
                  <WigInfoItemHeader>Status</WigInfoItemHeader>
                  <span><Chip fontSize='small' level={getRepairStatus(el.repair?.status)}>{getStatus(el.repair?.status)}</Chip></span>
                </WigInfoItem>
                {wig.status !== 'stock' && <WigInfoItem gap=".3rem">
                  <WigInfoItemHeader>Notes</WigInfoItemHeader>
                  <span>{el.notes}</span>
                </WigInfoItem> }
              </WigInfo>
            </RepairLine>
            )}
            </ItemAccordion>
          }
              )}
          </CardContent>
        </Card>

      </Grid>

      
      
    </Grid>
    }
  </ViewInfo>
 )
}

export default ViewTransfer;