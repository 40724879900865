import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 3rem;
    font-weight: normal;
    color: salmon;
  }
`

const PageNotFound = () => {
 return (
   <Container>
     <h1>404 Page not found.</h1>
   </Container>
 );
}

export default PageNotFound;