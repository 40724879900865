import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  /* border-radius: 1rem; */
  /* box-shadow: 0 0 10px 0 rgba(0,0,0,0.1); */

  & button:first-child {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem;
    border-left: 1px solid ${props => props.theme.colors.border};
  }
  & button:last-child {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem;
  }
`;

const Button = styled.button`
  padding: 1.2rem 2.4rem;
  outline: none;
  border: 1px solid ${props => props.theme.colors.border};
  border-left: none;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 600;
  color: ${props => props.selected ? "#585858" : "#969696"};
  background-color: ${props => props.selected ? props.theme.colors.background : props.theme.colors.headerBg};

  &:hover {
    background-color: ${props => props.selected ? "#cdcdcd" : props.theme.colors.border};
  }
`;


const ToggleButtons = ({ buttons = [], val, setFunc, toggleAllowed = () => true, overwriteVal }) => {

  const [selected, setSelected] = useState(val || buttons[0].value);

  const handleClick = (value) => {
    if (toggleAllowed(value)) {
      setSelected(value);
    }
    if (setFunc) {
      setFunc(value);
    }
  }

  useEffect(() => {
    if (overwriteVal) {
      setSelected(overwriteVal);
    }
  }, [overwriteVal])

  return (
    <Container>
      {buttons.map(({ value, component }, index) => (
        <Button key={index} type="button" onClick={() => handleClick(value)} selected={selected === value}>{component}</Button>
      ))}
    </Container>
  )
}

export default ToggleButtons;
