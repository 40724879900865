import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import AddPayment from '../payments/AddPayment';

import {theme} from '../../utils/theme';

import {Field, ErrorMessage} from 'formik'

import { Button, Grid, MenuItem, InputLabel, FormControl, IconButton, Tooltip, TextField as TextInput, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import { Add, ErrorSharp, TouchAppRounded, AccountBalanceWalletRounded } from '@material-ui/icons';

import { TextField, Select, CheckboxWithLabel } from 'formik-material-ui'

import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import { DatePicker } from 'formik-material-ui-pickers';

import Indicator from '../../utils/Indicator';

import Autocomplete from '../../utils/autoComplete';

import { CustomErrMsg, currencyFormat, fxn } from '../../utils/util'

import { Flex, FlexLine } from '../../utils/common'


import FormDialog from '../../utils/dialog';

import DollarCheck from '../../images/dollar-check'

const WalletTotal = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-right: 2rem;
`

const IncreaseIcon = styled(FlexLine)`
  gap: 1rem;
  font-size: 1.4rem !important;
`

const LineItemActions = styled(Flex)`

`

const LineItems = styled.div`
  min-height: 6rem;
  max-height: 26.5rem;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #0000001f;
  /* padding: 3.5rem 0; */
  border-radius: 5px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ccc;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #777;
  }

  .circular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
  }
`

const LineItem = styled(Flex)`
  border-bottom: ${props => props.last ? "none" : "1px solid #ccc"};
  color: ${props => props.disabled ? props.theme.colors.lightText : "inherit"};
`
const PaidStatus = styled(Flex)`
  text-align: center;
`

const AmountItem = styled(FlexLine)`

`
const AmountHeader = styled.span`
  font-size: 1rem;
`

const WigChargeLine = styled.span`
  display: block;
  font-size: 1.2rem;
`

const ErrorLine = styled.div`
  font-size: 1.2rem;
  color: salmon;
`

const BalanceInfo = styled.div`
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: .5rem;
`
 
export default ({saveFunc, dateValue, handleDateChange, wigs, setter, errors, resetErr, cust, payments, allPayments = [], buttonVariant = "contained", lineItems = [], charges = [], paymentState, loading}) => { 

  const calcCharges = (charges, wigId, wigs, price = 0) => {
    let chargeList = [], chargeTotal = 0, discountTotal = 0;
      let surcharges = charges.filter(el => el.chargeType === "surcharge" && el.wigId === wigId)
      let discounts = charges.filter(el => el.chargeType === 'discount' && (el.wigId === wigId || !el.wigId))


      chargeTotal = surcharges.reduce((acc, cur) => {
        let newAmount = cur.calculated;
        chargeList.push(<WigChargeLine key={cur.description}>{cur.description} - {currencyFormat(newAmount)}</WigChargeLine>)

        return fxn(acc + newAmount, 2)
      }, price)


      discountTotal = discounts.reduce((acc, cur) => {
        let newAmount;
        if (cur.wigId) {
          newAmount = cur.calculated
        } else {
          if (cur.rateType === "percentage") {
            newAmount = (cur.amount / 100) * price
          } else {
            newAmount = cur.calculated / wigs
          }
        }
        chargeList.push(<WigChargeLine>{cur.description} - {currencyFormat(newAmount)}</WigChargeLine>)
        return fxn(acc - newAmount, 2)
      }, chargeTotal)

      // console.log(surcharges, chargeTotal, discountTotal,  "WAHAHAHAH");

      return {total: discountTotal, chargeList}
  }

  const [wallet, setWallet] = useState(cust && cust.wallet || 0)

  const { paymentObj, setPaymentObj } = paymentState

  const [paymentTotal, setPaymentTotal] = useState(0)

  const [shutDialog, setShutDialog] = useState(false)

  useEffect(() => {
    let amount = 0;
    let w = cust && cust.wallet ? cust.wallet : 0;
    if (payments.length) {
      amount = payments.reduce((acc, cur) => acc + cur.paymentAmount, 0)
    }
    // console.log(payments, w, amount, "AMMAMMAMAMOUOU");
    setWallet(w - amount);
  }, [payments, cust])

  useEffect(() => {
    let li = [] // lineItems
    let orderPayments = 0, remainingPayment = 0, orderSubtotal = 0;
    if (allPayments.length) {
      orderPayments = allPayments.filter(el => !el.paymentWigId && !el.remainingBalance).reduce((acc, cur) => acc + cur.paymentAmount, 0)
      remainingPayment = allPayments.filter(el => el.remainingBalance).reduce((acc, cur) => acc + cur.paymentAmount, 0)
    }

    if (wigs.length) {
      li = wigs.map(el => {
        let {total, chargeList} = calcCharges(charges, el.wigId, wigs.length, el.price)
        let price = total
        let wigPayment = allPayments.filter(cur => cur.paymentWigId === el.wigId).reduce((acc, cur) => acc + cur.paymentAmount, 0)
        let paid = wigPayment
        if (paid !== total) {
          let addTotal = wigPayment + orderPayments
          paid = Math.min(addTotal, total)
          if (total - addTotal >= 0) {
            orderPayments = 0
          } else {
            orderPayments = addTotal - total
          }
        }
        orderSubtotal += total;

        // console.log({price, paid, wigPayment, allPayments, orderPayments});

        let findOld = lineItems.find(cur => cur.wigId === el.wigId)
        if (findOld) {
          return {
            ...findOld,
            charges: chargeList.map(el => el),
            total: price, 
            price: el.price,
            paid: paid, 
            balance: price - paid,
            amount: price - paid,
          }
        } else {
          if (el.wigId) {
            return {
              check: false, 
              wigId: el.wigId, 
              charges: chargeList.map(el => el),
              sku: el.sku, 
              total: price, 
              price: el.price,
              paid: paid, 
              balance: price - paid,
              amount: price - paid,
            }
          } else {
            return {}
          }
        }
      })
    }
    if (charges.length) {
      let orderCharges = charges.filter(el => el.chargeType === "surcharge" && !el.wigId)
      let orderDiscounts = charges.filter(el => el.chargeType === "discount" && !el.wigId && el.rateType === "percentage")

      let chargeList = []

      if (orderCharges.length) {

      let orderChargeTotal = orderCharges.reduce((acc, cur) => {
        let newCharge = cur.calculated;
        let random = Math.random()
        chargeList.push(<WigChargeLine key={random}>{cur.description} - {currencyFormat(newCharge)}</WigChargeLine>)
        return acc + newCharge
      }, 0)

      // let finalTotal = orderDiscounts.reduce((acc, cur) => {
      //   let newCharge = (cur.amount / 100) * orderChargeTotal;
      //   chargeList.push(<WigChargeLine>{cur.description} - {currencyFormat(-newCharge)}</WigChargeLine>)
      //   return acc - newCharge
      // }, orderChargeTotal)

      let findRemaining = lineItems.find(cur => cur.wigId === "Remaining Balance")

      let paidTowardBalance = Math.max(allPayments.filter(el => !el.remainingBalance).reduce((acc, cur) => acc + cur.paymentAmount, 0) - orderSubtotal, 0)

      if (findRemaining && findRemaining.total === orderChargeTotal) {
        li.push({
          ...findRemaining,
          charges: chargeList.map(el => el),
          total: orderChargeTotal, 
          paid: remainingPayment + paidTowardBalance, 
          balance: orderChargeTotal - (remainingPayment + paidTowardBalance),
          amount: orderChargeTotal,
        })
      } else {
        li.push({
          check: false, 
          wigId: "remain", 
          charges: chargeList.map(el => el),
          total: orderChargeTotal, 
          paid: remainingPayment + paidTowardBalance, 
          balance: orderChargeTotal - (remainingPayment + paidTowardBalance),
          amount: orderChargeTotal,
          remaining: true
        })
      }
    }

    }
    setter("lineItems", li)
  }, [wigs, charges, allPayments, payments])

  const checkCount = lineItems.filter(x => x).reduce((a,c) => a + +c.check, 0)
  const amountTotal = lineItems.reduce((a,c) => a + (c.amount * +c.check), 0)
  useEffect(() => {
    let payObj = {}
    if (lineItems.length) {
      lineItems.forEach((el, i) => {
          payObj[el.wigId] = el?.check ? (el?.amount || 0) : 0
      })
      setPaymentObj(payObj)
    }
    setPaymentTotal(Object.keys(payObj).reduce((acc, cur) => acc + payObj[cur], 0))
  }, [checkCount, amountTotal])

  let custObj = cust?._id ? {_id: cust._id, name: cust.name} : {}


  const updateTotal = (amount) => {
    setWallet(x => x + amount)
    // close the dialog from the add payment dialog, then enable it again
    setShutDialog(true)
    setShutDialog(false)
  }

  const autoSelect = (e) => {
    let avail = wallet
    let li = lineItems.map(el => {
      if (e.target.checked) {
      if (avail > 0 && el.balance > 0) {
        avail -= el.balance
        if (avail >= 0) {
          return { ...el, amount: el.balance, balance: 0, check: true }
        } else {
          return { ...el, amount: el.balance + avail, balance: 0, check: true }
        }
      } else {
        return { ...el, check: false }
      }
    } else {
      let balance = el.total - el.paid
      return { ...el, amount: balance, balance: balance, check: false}
    }

    })
    setter("lineItems", li)
  }


 return (
  <FormDialog toggle={
    <Button
    variant={buttonVariant}
    color="primary"
    startIcon={<Add />}
  >
    Add Payment
  </Button>
  } 
  // forceOpen={true}
  title="Add Payment"
  onSave={() => saveFunc(wallet)}
  size="lg"
  save={loading ? <CircularProgress color="primary" /> : "Save"}
  >
    <Grid container spacing={3} alignItems="center">
      
      <Grid item xs={12}>
        <Flex dir="row" justify="center" pad="0">
          <WalletTotal>Amount available in Customer account: {`${currencyFormat(wallet)}`}</WalletTotal>
          <FormDialog
            toggle={
            <Tooltip title="Add Money to customer account">
              <Button 
                variant="contained"
                color="secondary"
                startIcon={<IncreaseIcon dir="row" pad="0" justify="center"><AccountBalanceWalletRounded /> Add</IncreaseIcon>}>
              </Button>
            </Tooltip>
            }
            shutDown={shutDialog}
            cancel='Close'
            title="Add Payment"
            fullScreen
          >
            <AddPayment customer={custObj} updateTotal={updateTotal} />
          </FormDialog>
        </Flex>
        
       
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="paymentType">Payment Type</InputLabel>
          <Field
            component={Select}
            name="paymentType"
            variant="outlined"
            fullWidth
            label="Payment Type"
            inputProps={{
              id: 'paymentType',
            }}
          >
            <MenuItem value={"cash"}>Cash</MenuItem>
            <MenuItem value={"Quick Pay"}>Quick Pay</MenuItem>
            <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
            <MenuItem value={"Check"}>Check</MenuItem>
            <MenuItem value={"Bank Account"}>Bank Account</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Field>
        </FormControl>
      </Grid>

      {/* <Grid item xs={1}></Grid> */}

      {/* <Grid item xs={3}>
      <CustomErrMsg errors={errors} touched={touched} name="paymentAmount" />
      <Field
        component={TextField}
        type="number"
        label="Amount"
        onChange={(e) => {resetErr([]); handleChange(e)}}
        name="paymentAmount"
        variant="outlined"
        fullWidth
      />
      </Grid> */}
      
      <Grid item xs={4}>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
        variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
        label="Select Payment Date" name="date" value={dateValue} onChange={handleDateChange} inputVariant='outlined' fullWidth />
      </MuiPickersUtilsProvider>

      </Grid>

      <Grid item xs={4}>
      <Field
        component={TextField}
        type="text"
        label="Payment Info"
        name="paymentInfo"
        variant="outlined"
        fullWidth
      />

      </Grid>

      <Grid item xs={12}>
        <LineItemActions height="5rem" dir="row" justify="space-between" pad="0">
          Amount Applied: {currencyFormat(paymentTotal)}
          <FormControlLabel
          control={<Checkbox 
          onChange={(e) => autoSelect(e)} 
          // checkedIcon={<DollarCheck color={theme.colors.dollarGreen} />}
          name="autoSelect"
          color="primary" 
          />}
          label="Auto Select"
          />
        </LineItemActions>
        <LineItems>
            {lineItems.filter(el => el.total).map((el, i) => {
            let totalPaid = el.amount + el.paid;
            let status, text;
            if (el.paid >= el.total || (totalPaid >= el.total && el.check)) {
              status = "success"; text = "Paid"
            } else if (el.paid > 0 || (totalPaid >= 0 && el.check)) {
              status = "partial"; text = "Partially Paid"
            } else {
              status = "fail"; text = "Not Paid"
            }
            return (
              <LineItem key={el.wigId} dir="row" justify="space-between" last={i === lineItems.length - 1} disabled={el.balance <= 0|| (wallet - paymentTotal <= 0 && !lineItems[i].check)}>
                <Flex dir="row" justify="flex-start" width="35rem" pad="0">
                <Field
                  name={`lineItems.${i}.check`}
                >
                  {({
                  field : {onChange, value, ...fieldBag}, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => {
                  return (
                  <Checkbox 
                    type="checkbox"
                    checkedIcon={<DollarCheck color={theme.colors.dollarGreen} />}
                    checked={value}
                    disabled={el.balance <= 0 || (wallet - paymentTotal <= 0 && !lineItems[i].check)}
                    onChange={(e) => {
                      let checked = e.target.checked
                      let amount = lineItems[i].amount;
                      let totalLeft = wallet - paymentTotal
                      if (checked) {
                        if (totalLeft < amount) {
                          setter(`lineItems.${i}.amount`, totalLeft)
                        }
                      }
                      resetErr({})
                      onChange(e)
                    }}
                    {...fieldBag}
                  />
                )}}
                </Field>
                 <FlexLine width="28rem" align="flex-start" pad="0">
                 <AmountHeader>{el.remaining ? "Remaining Balance" : "Wig ID"}</AmountHeader>
                  {!el.remaining ? el.wigId : ''} {el?.sku ? `- ${el?.sku}` : ""} {el.charges?.length && !el.remaining ? `- ${currencyFormat(el.price)}` : ""}
                  {el.charges ? el.charges : ""}
                 </FlexLine> 
                </Flex> 
                <PaidStatus width="20%"> <Indicator text={text} status={status} /> </PaidStatus>
                <AmountItem width="12rem" align="flex-start" pad="0">
                  <AmountHeader>Total</AmountHeader>
                  {currencyFormat(el.total)}
                  </AmountItem>
                <AmountItem width="12rem" align="flex-start" pad="0">
                  <AmountHeader>Open Balance</AmountHeader>
                  {currencyFormat(el.balance)}
                  </AmountItem>
                <div>
                <Field
                  name={`lineItems.${i}.amount`}
                >
                {({
                  field : {onChange, onBlur, ...fieldBag}, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
               <TextInput 
               {...fieldBag}
               onChange={(e) => {
                let newAmount = e.target.value
                let paidTotal = Object.keys(paymentObj).filter(cur => cur !== el.wigId).reduce((acc, cur) => acc + paymentObj[cur], 0)
                let totalLeft = wallet - paidTotal
                if (newAmount > el.balance) {
                  e.target.value = el.balance
                }
                if (el.check && totalLeft < newAmount) {
                  e.target.value = totalLeft
                }
                onChange(e)
               }}
                type="number"
                label="Select Amount"
                variant="outlined"
                fullWidth
                size="small"
               />
             )}
                </Field>
                </div>
              </LineItem>
            )}

            )}
        </LineItems>
      </Grid>

      <Grid item xs={12}>
       {errors.paymentAmount ? <ErrorLine>* {errors.paymentAmount}</ErrorLine> : ""}
      </Grid>


      {/* {wigs && wigs.length ? <Grid item xs={4}>
        <Autocomplete
          list={wigs} name="paymentWigId" field="wigId" label="Wig ID"
          getOptionLabel={({wigId}) =>  wigId || ""} 
          getOptionSelected={({wigId}, val) =>  wigId == val.wigId}
          renderOption={(wig) => <span>{wig.wigId}</span>}
          setter={setter}
          updates={[{name: `paymentAmount`, field: "price"}]}
            />
        </Grid> : null
      } */}

      <Grid item xs={6}>
       <Field
       component={TextField}
       variant="outlined"
       fullWidth
       multiline
      //  rows={3}
        rows={4}
       name="paymentNotes"
       label="Notes"
       />
    
      </Grid>

    </Grid>
  </FormDialog>
 )
}