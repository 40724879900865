import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'

import { FormControlLabel, Checkbox, TextField, IconButton, Tooltip} from '@material-ui/core'

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@material-ui/icons/UnfoldLessRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import DollarCheck from '../../images/dollar-check'

// date picker
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";


import Indicator from '../../utils/Indicator';

import { Grid, Row } from '../../utils/Grid'

import { Flex, FlexLine } from '../../utils/common'

import { theme } from '../../utils/theme';

import { currencyFormat, getIndicatorStatus } from '../../utils/util'

const DialogBox = styled.div`
  /* border: 1px solid #e9e9e9; */
`

const OrderContainer = styled(Row)`
  height: 75vh;
  overflow-y: auto;
  /* padding-right: 2rem; */
/* 
  .dollar-check {
    height: 2rem !important;
    width: 2rem;
    } */

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ccc;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
`

const AmountAvailableHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
`

const AmountAvailable = styled.div`
  font-size: 3.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.secondaryMain};
`

const StatText = styled.span`
  text-transform: uppercase;
  font-size: 1.2rem;
`

const Order = styled.div`

`
const AmountField = styled(TextField)`
  margin-right: 3.5rem;
`

const CheckedIcon = styled(DollarCheck)`
  
`

const FirstRow = styled(Flex)`
  border-top: 3px solid #e9e9e9;
`

const OrderRowStyles = styled.div`
  padding: 0;
  background-color: #f4f4f4; // #efefef
  box-shadow: 0 4px 15px 2px rgba(0, 0, 0, .09);
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 1.5rem;
  border-left: 3px solid ${props => props.theme.colors.secondaryMain};
  /* border-right: 3px solid ${props => props.theme.colors.secondaryMain}; */
  border-radius: 3px;
`
const WigRowStyles = styled.div`
  padding: 1rem 0;
  padding-left: 2rem;
  border-bottom: 1px solid #e9e9e9;
  color: ${props => props.disabled ? props.theme.colors.lightText : "inherit"};
`

const AppliedOrder = styled(FlexLine)`
  width: ${props => !props.contract ? "38rem" : "55rem"};
  /* max-width: ${props => !props.contract ? "40rem" : "80rem"}; */
`

const Applied = styled(FlexLine)`
  width: 55rem;
  margin-left: 5rem;
  /* max-width: 80rem; */
`

const AmountFlexStyles = styled(Flex)`
  /* width: 25rem; */
`

const Amount = styled.span`
  font-weight: 500;
  font-size: 1.6rem;
`
const LineLabel = styled.span`
  font-size: 1rem;
`
const ID = styled.span`
  font-weight: ${props => props.type === "sale" ? "500" : "400"};
`

const TitleFlexStyles = styled(Flex)`
  /* border-left: 1px solid #ccc;
  padding-left: 2rem; */
`

const ChargeLine = styled.span`
  display: block;
  font-size: 1.2rem;
`

const StatLineStyles = styled(FlexLine)`
  border: 1px solid #e9e9e9;
  border-left: 3px solid ${props => props.theme.colors.secondaryMain};
  padding: .6rem;
  margin: .5rem 0;
  border-radius: 3px;
`

const StatLine = ({children}) => {
  return (
  <StatLineStyles dir="row" justify="space-between" pad="0" padY=".5rem" width="100%">
    {children}
  </StatLineStyles>)
}

const AmountFlex = ({amount, title, type}) => {
  let isBalance = type === 'balance'
  return (
    <AmountFlexStyles justify="center" align="flex-start" pad="0">
      <LineLabel>{isBalance ? "Open Balance" : title ? title : "Total" }</LineLabel>
      <Amount>{currencyFormat(amount)}</Amount>
    </AmountFlexStyles>
  )
}

const TitleFlex = ({id, title, sku, type, price, charges = [], discounts = []}) => {
  const isSale = type === "sale"
  return (
    <TitleFlexStyles justify="center" align="flex-start" pad="0" padY=".5rem" width="30rem">
      <LineLabel>{isSale ? "" : title ? title : "Wig ID" }</LineLabel>
      <ID type={type}>{id} {sku ? `- ${sku}` : ''} {charges.length && price ? `- ${currencyFormat(price)}` : ''}</ID>
      {charges.map(el => <ChargeLine>{el.description} - {currencyFormat(el.calculated)}</ChargeLine>)}
    </TitleFlexStyles>
  )
}


const OrderRow = ({order, handleContract, contract, add, selectOrder, fields}) => {
  return (
    <OrderRowStyles>
      <Flex dir="row" justify="space-between" pad="0">
        <Flex dir="row" justify="flex-start" pad="0" width="25rem">
          <IconButton onClick={() => handleContract(order.orderId)}>{contract ? <ChevronRightRoundedIcon /> : <ExpandMoreRoundedIcon /> }</IconButton>
          {contract ? <FormControlLabel
          control={<Checkbox 
          onChange={(e) => selectOrder(e, order.orderId, fields[`order-${order.orderId}`])} 
          name={`c-order-${order.orderId}`} 
          color="primary" 
          />}
          label=""
          /> : null }
          <TitleFlex id={'#' + order.orderId} type="sale" />
        </Flex>

        <AppliedOrder dir="row" justify="flex-end" pad="0" padY="1.5rem" contract={contract}>
          <AmountFlex amount={order.total} />
          <AmountFlex amount={order.balance} type="balance" />
         {/* {contract &&  <AmountFlex amount={order.totalPaidOnOrder} title="Paid toward Order"/> } */}
         {contract &&  <AmountField name={`order-${order.orderId}`} label={`Select Amount`}
            variant="outlined" type="number" defaultValue={order.balance}
             fullWidth onChange={(e) => add(e, order.balance)}
            size='small' 
            /> }
        </AppliedOrder>
          
      </Flex>
    </OrderRowStyles>
  )
}


const WigRow = ({wig="", order, disable, addWig, add, fields, selected, available}) => {
  let charges = wig ? order.charges.filter(elem => elem.wigId === wig?.wigId && elem.chargeType === "surcharge") : order.charges.filter(elem => !elem.wigId && elem.chargeType === "surcharge")
  let discounts = wig ? order.charges.filter(elem => (elem.wigId === wig?.wigId || !elem.wigId) && elem.chargeType === "discount") : order.charges.filter(elem => !elem.wigId && elem.chargeType === "discount")

  let wigId = wig ? wig?.wigId : "remain"

  let wigsTotal = 0, wigPayments = 0;

  if (wig) {

  } else {
    wigsTotal = order.wigs.reduce((acc, cur) => {
      let total = cur.total
      wigPayments += cur.paid
      return acc + total
    }, 0)
  }

  let price = wig ? wig?.total : order.total - wigsTotal
  let paid = wig ? wig?.paid : order.totalPaid - wigPayments;
  let paySelect = selected[order.orderId]?.[wigId] || 0;
  let balance = price - paid
  let finalBalance = balance - paySelect
  let locked = paySelect <= 0 && available <= 0
  let disabled = balance === 0 || disable || locked
  let {text, status} = getIndicatorStatus(finalBalance, price);

  let propBag = {
    charges,
    discounts
  }
  if (wig) {
    propBag = {
      ...propBag,
      id: wigId,
      sku: wig?.sku,
      price: wig?.price
    }
  } else {
    propBag = {...propBag, title: "Remaining Sale Balance"}
  }
 
  return (
    <WigRowStyles disabled={disabled}>
      <Flex dir="row" justify="space-between" pad="0">

        <Flex dir="row" justify="flex-start" pad="0" width="30rem">
          <FormControlLabel
          control={<Checkbox 
          onChange={(e) => addWig(e, order.orderId, wigId, fields[`order-${order.orderId}-${wigId}`])} 
          disabled={disabled}
          checked={selected[order.orderId]?.[wigId] > 0}
          checkedIcon={<DollarCheck color={theme.colors.secondaryMain} />}
          name={`c-order-${order.orderId}-${wigId}`} 
          color="primary" 
          />}
          label=""
          />
          <TitleFlex {...propBag} />
        </Flex>

        <Indicator status={status} text={text} />

        <Applied dir="row" justify="space-between" pad="0" padY="1.5rem">
          <AmountFlex amount={price} />
          <AmountFlex amount={finalBalance} type="balance" />
          {/* <AmountFlex amount={paid} title={wig ? "Paid toward Wig" : "Paid toward Remaining"} /> */}

          <AmountField name={`order-${order.orderId}-${wigId}`} label={`Select Amount`}
            variant="outlined" type="number" disabled={disabled} value={fields[`order-${order.orderId}-${wigId}`]}
            fullWidth onChange={(e) => add(e, balance)}
            size='small' 
            />
        </Applied>
          
      </Flex>
  </WigRowStyles>
  )
}


const ApplyPayments = ({orders, calculated, calculate, original, selected, setSelected, date}) => {

  /* SET TEXTFIELDS WITH INITIAL VALUES BASED ON OPEN BALANCE */
  const getFieldValues = useMemo(() => {
    let obj = {};
    orders.forEach(el => {
      obj = {...obj, [`order-${el.orderId}`]: el.balance}

    // let orderPaid = el.payments.filter(cur => !cur?.paymentWigId).reduce((acc, cur) => acc + cur?.paymentAmount, 0)

    let wigsTotal = 0, wigPayments = 0;
    if (el.wigs.length) {
      el.wigs.forEach(cur => {
        let price = cur.total
        let paid = cur.paid
        let balance = price - paid
        obj = {...obj, [`order-${el.orderId}-${cur.wigId}`]: balance}

        // calculate total price for wigs and total payments for wigs
        wigsTotal += price
        wigPayments += cur.paid
      })
    }

    let remainPrice = el.total - wigsTotal
    let remainPaid = el.totalPaid - wigPayments
    if (remainPrice > 0) {
      obj = {...obj, [`order-${el.orderId}-remain`]: remainPaid < 0 ? remainPrice : remainPrice - remainPaid}
    }
    })
    return obj
  }, [orders])

  const { applyDate, setApplyDate } = date

  const { available, applied, totalWigs, totalInvoices } = calculated

  // const [selected, setSelected] = useState({

  // })

  const [fields, setFields] = useState(getFieldValues)


  const [contract, setContract] = useState({})

  const handleContract = (id) => {
    setContract(x => ({...x, [id]: !x[id]}))
  }

  const toggleContractAll = (action) => {
    let obj = {}
    orders.forEach(el => {
      obj[el.orderId] = action === "close" ? true : false
    })
    setContract(obj)
  }

  /* ONCHANGE HANDLER OF TEXTFIELDS
  * UPDATES BOTH THE FIELD OBJ AND WHEN THE CHECKBOX IS SELECTED
  * ALSO UPDATES TOTALS 
  */
  const addFieldValue = (e, price) => {
    let { value, name } = e.target
    let split = name.split("-")
    split.shift()
    let [orderId, wigId] = split
    let select = selected?.[orderId]?.[wigId]
    let amount = Math.min(+value, price)
    setFields(x => ({...x, [name]: amount}))
    if (select > 0) {
      setSelected(x => ({...x, [orderId]: {...x[orderId], [wigId]: amount}}))
    }
  }

  /* ONCHANGE HANDLER FOR CHECKBOXES, IF THE CURRENT SELECTION'S TEXTFIELD
  * HAS MORE MONEY THAN AVAILABLE FOR SELECTION, UPDATE THE TEXTFIELD TO REFLECT
  * CURRENT AVAILABLE AMOUNT
  */
  const addWig = (e, order, wigId, price) => {
    let { checked } = e.target
    let amount = checked ? price : 0
    if (available - amount < 0) {
      amount = available
      setFields(x => ({...x, [`order-${order}-${wigId}`]: available}))
    }
    if (!available <= 0 || !checked) {
      setSelected(x => ({...x, [order]: {...x[order], [wigId]: amount}}))
    }
  }

  const availCalc = (avail, amount, field, orderAmount) => {
    let total = avail - amount < 0 ? avail : amount
    if (orderAmount) {
      total = Math.min(total, orderAmount)
    }
    setFields(x => ({...x, [field]: total}))
    return total
  }

  const selectOrder = (e, orderId, total) => {
    if (e.target.checked) {
      let avail = available
      const isAvail = () => avail > 0 && total > 0
      let order = orders.find(el => el.orderId === orderId)
      let orderObj = {}
      order.wigs.forEach(cur => {
        let wigField = fields[`order-${orderId}-${cur.wigId}`]
        if (isAvail() && wigField > 0) { // money still left in payment
          orderObj = {...orderObj, [cur.wigId]: availCalc(avail, wigField, `order-${orderId}-${cur.wigId}`, total)}
          avail -= wigField
          total -= wigField
          console.log(cur.wigId, total, avail, "enddddd");
        }
      })
      let remain = fields[`order-${orderId}-remain`]
      if (remain && isAvail()) {
        orderObj.remain = availCalc(avail, remain, `order-${orderId}-remain`, total)
        avail -= remain
        total -= remain
        console.log(orderId, avail);
      }

      setSelected(x => ({...x, [orderId]: {...orderObj}}));
    } else {
      setFields(getFieldValues)
      setSelected({})
    }
  }

  const autoSelect = (e) => {
    if (e.target.checked) {
      let obj = {}
      let avail = available
      const isAvail = () => avail > 0
      orders.forEach(el => {
        let order = {}
        let id = el.orderId
        el.wigs.forEach(cur => {
          let wigField = fields[`order-${id}-${cur.wigId}`]
          if (isAvail() && wigField > 0) { // money still left in payment
            order = {...order, [cur.wigId]: availCalc(avail, wigField, `order-${id}-${cur.wigId}`)}
            avail -= wigField
            console.log(cur.wigId, order[cur.wigId], avail, "enddddd");
          }
        })
        let remain = fields[`order-${id}-remain`]
        if (remain && isAvail()) {
          order.remain = availCalc(avail, remain, `order-${id}-remain`)
          avail -= remain
          console.log(id, avail);
        }
        obj = {...obj, [id]: {...order}}
      })

      setSelected(obj);
    } else {
      setFields(getFieldValues)
      setSelected({})
    }
  }

  /* RUNS EVERY TIME SELECTION STATE IS MODIFIED, ALWAYS RECALCULATES 
  * ALL FIELD IN OBJECT TO REFLECT CORRECT TOTAL.
  */
  useEffect(() => {
    let total = 0
    let wigs = 0
    let invoices = 0
    for (const order in selected) {
      let orderTotal = 0
      let selectedOrder = orders.find(el => el.orderId === order)
      let totalByWigs = selectedOrder.wigs.reduce((acc, cur) => acc + (cur?.total), 0)

     if (order) {
      if (selected[order].order > 0) {
        total += selected[order].order
      } else {
        for (let wig in selected[order]) {
          let balance;
          if (wig === "remain") {
            let price = selectedOrder.total - totalByWigs
            balance = price - (selectedOrder.totalPaid - totalByWigs)
          } else {
            let selectedWig = selectedOrder.wigs.find(cur => cur.wigId === wig)
            let price = selectedWig.total || selectedWig.price
            balance = price - (selectedWig.paid + +selected[order][wig])
          }
         

          if (selected[order][wig] > 0 && wig !== "order") {
              total += +selected[order][wig]
              if (balance <= 0) {
                wigs += 1
              }
              orderTotal += +selected[order][wig]
            }
          }
      }
     
      }
      if (orderTotal >= selectedOrder.balance) {
        invoices += 1
      }
    }
    calculate({
      available: original - total,
      applied: total,
      totalWigs: wigs,
      totalInvoices: invoices
    })
  }, [selected])

  return (
    <Grid columnGap="3rem" marginX=".5rem">
      
      <OrderContainer xs={10}>
        <Flex dir="row" justify="flex-start" pad="0" height="10rem">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
            variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
            label="Select Payment Date" name="date" value={applyDate} onChange={(e) => setApplyDate(e)} inputVariant='outlined' />
          </MuiPickersUtilsProvider>
        </Flex>
      <FirstRow dir="row" justify="space-between" pad="0" height="6rem">
        <FormControlLabel
          control={<Checkbox 
          onChange={(e) => autoSelect(e)} 
          // disabled={disabled}
          name="autoSelect"
          color="primary" 
          />}
          label="Auto Select"
          />
          <Flex dir="row" justify="flex-end" pad="0" width="20rem">
          <Tooltip title="Expand All"><IconButton onClick={() => toggleContractAll("open")}><UnfoldMoreRoundedIcon color="secondary" /></IconButton></Tooltip>
          <Tooltip title="Close All"><IconButton onClick={() => toggleContractAll("close")}><UnfoldLessRoundedIcon color="secondary" /></IconButton></Tooltip>
          </Flex>
        </FirstRow>
        <DialogBox>{orders.map(el => (
        <Order>
          <OrderRow order={el} contract={contract[el.orderId]} selectOrder={selectOrder} handleContract={handleContract} add={addFieldValue} fields={fields} />

          {!contract[el.orderId] && el.wigs?.map(cur => (
            <WigRow wig={cur} addWig={addWig} order={el} add={addFieldValue} fields={fields} selected={selected} available={available} />
          ))}
          {!contract[el.orderId] && el.wigs && el.charges.filter(cur => !cur.wigId && cur.chargeType !== "discount").length ? 
            <WigRow order={el} addWig={addWig} add={addFieldValue} fields={fields} selected={selected} available={available} /> : null
          }
        </Order>
        ))}</DialogBox>
      </OrderContainer>
      <Row xs={2}>
        <Flex justify="flex-start" align="end" pad="0" height="30rem">
          <AmountAvailableHeader>Amount Available</AmountAvailableHeader>
          <AmountAvailable>{currencyFormat(available)}</AmountAvailable>
          <StatLine>
            <StatText>Amount Applied</StatText><span>{currencyFormat(applied)}</span>
          </StatLine>
          <StatLine>
            <StatText>Invoices Paid In Full</StatText><span>{totalInvoices}</span>
          </StatLine>
          <StatLine>
            <StatText>Wigs Paid In Full</StatText><span>{totalWigs}</span>
          </StatLine>
        </Flex>
        
      </Row>
    </Grid>
  )
}

export default ApplyPayments;