import styled, { css } from 'styled-components';
import { Grid, Divider} from '@material-ui/core';
import { Link } from 'react-router-dom'

export const SectionHeaderRow = styled(Grid)`
  height: 2.5rem;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  `

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`


export const Header = styled.div`
  font-weight: ${props => props.size === "large" ? "500" : "600"};
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
  font-size: ${props => props.size === "large" ? "2rem" : "1.2rem"};
`

export const SectionHeader = styled.h5`
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  color: #787878;
  font-weight: normal;
`

export const FullDivider = styled(Divider)`
  margin: auto -50px;
  margin-top: ${props => props.y ? props.y : props.t ? props.t : "auto"};
  margin-bottom: ${props => props.y ? props.y : props.b ? props.b : "auto"};
`

export const Title = styled.span`
  display: ${props => props.disp ? props.disp : "inline"};
  font-size: ${props => props.fs ? props.fs : "1.2rem"};
  text-transform: uppercase;
  font-weight: ${props => props.bold ? props.bold : "500"};
  color: ${props => props.light ? props.theme.colors.lightText : "inherit"};
`

export const BasicFlex = styled.div`
  display: flex;
  flex-direction: ${props => props.dir || "row"};
  justify-content: ${props => props.justify ? props.justify : "space-between"};
  align-items: ${props => props.align ? props.align : props.dir === "column" ? "start" : "center"};
  width: ${props => props.width || "100%"};
  margin: ${props => props.my || "0"} ${props => props.mx || "0"};
  gap: ${props => props.gap || "0"};
`

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.dir || "column"};
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "auto"};
  gap: ${props => props.gap || "0"};
  padding: ${props => props.pad || "1.5rem"};
  padding-bottom: ${props => props.padY || "auto"};
  padding-top: ${props => props.padY || "auto"};
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.align || "center"};
`

export const FlexLine = styled.div`
  display: flex;
  flex-direction: ${props => props.dir || "column"};
  width: ${props => props.width || "100%"};
  gap: ${props => props.gap || "0"};
  height: ${props => props.height ? props.height : props.dir && props.dir === "row" ? "auto" : "100%"};
  padding: ${props => props.pad || "1.5rem"};
  padding-bottom: ${props => props.padY || "auto"};
  padding-top: ${props => props.padY || "auto"};
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.align || "center"};

  & * {
    display: block;
  }
`

export const CommonFlex = styled.div`
  display: flex;
  flex-direction: ${props => props.dir || "row"};
  width: ${props => props.width || "100%"};
  gap: ${props => props.gap || "0"};
  height: ${props => props.height ? props.height : props.dir && props.dir === "row" ? "auto" : "100%"};
  padding: ${props => props.pad || "0"};
  padding-bottom: ${props => props.padY || "auto"};
  padding-top: ${props => props.padY || "auto"};
  justify-content: ${props => props.justify || "space-between"};
  align-items: ${props => props.align || "center"};

  & * {
    display: block;
  }
`

export const IconBox = styled.div`
  border-radius: 6px;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.button && css`
    cursor: pointer; 
    box-shadow: ${props.theme.scShadows.light};

    &:hover {
      box-shadow: ${props.theme.scShadows.hover};
    }
    `}

  ${props => props.size === "large" && css`
    margin-right: 2rem;
    min-width: 5.5rem;
    min-height: 5.5rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  ` }
  ${props => props.size === "medium" &&  css`
    min-width: 4rem;
    min-height: 4rem;
    margin-right: 1rem;

    svg {
      min-width: 2rem;
      min-height: 2rem;
    }
  `}

  ${props => props.size === "small" &&  css`
    /* min-width: 3.5rem;
    min-height: 3.5rem;
    margin-right: 1rem; */
    padding: .3rem;

    svg {
      min-width: 2rem;
      min-height: 2rem;
    }
  `}
`;

export const NoValues = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${props => props.theme.colors.border};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.headerBg};
  padding: 1rem;
`;