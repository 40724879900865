import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import axios from "../../../axios";

import { Grid, Select, MenuItem, FormControl, InputLabel, LinearProgress, Card, CardHeader, CardContent, Checkbox, FormControlLabel, Tooltip, Icon, IconButton, CircularProgress, ListItemText, TextField, FormHelperText } from "@material-ui/core";

import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import StyleRoundedIcon from '@material-ui/icons/StyleRounded';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';

import MuiField from "../../../utils/MuiField";

import displayPDF from "../../../utils/displayPdf";

import Chip from '../../../utils/Chip';

import { theme } from "../../../utils/theme";

import { isEmpty } from "lodash";

import { DateRangePicker, DateField, DoughnutChart } from "./common";

import FormDialog from "../../../utils/dialog";

import { Flex, CommonFlex, BasicFlex, FullDivider, SectionHeader, SectionHeaderRow } from "../../../utils/common";

import { currencyFormat, formatNiceDate } from "../../../utils/util";

import CollapsibleTable from "../CollapsibleTable";

import OutlinedSelect from "../../../utils/OutlinedSelect";
import { Form } from "formik";

const Container = styled.div`
  
`

const Summary = styled(Card)`
  background-color: ${props => props.theme.colors.secondary95};
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.5rem;
  height: 4.5rem;
  background-color: ${props => props.bg};
  border-radius: 5px;
  margin-right: 1.5rem;
`

const StatHeader = styled.h4`
  margin: 0;
  margin-bottom: 1.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => props.theme.colors.lightText};

  &.icon-header {
    margin-bottom: .5rem;
    font-weight: 600;
  }
  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StatLabel = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.lightText};
`

const StatInfo = styled.span`
  font-weight: 500;
`

const ChipContainer = styled(Flex)`
  gap: 1rem;
  flex-wrap: wrap;
`

const CustomerList = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  min-height: 6rem;
  max-height: 19.4rem;
  border-radius: 5px;
  overflow-y: auto;
`;

const CustomerRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: .4rem .6rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: .5 1.1rem; */
  margin-bottom: 1rem;
`;

const BottomBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: .5rem;
  font-weight: 500;
  color: ${props => props.theme.colors.primaryMain};
`;

const SearchBox = styled.div`
  width: 45rem;
`;

const CustomCheckbox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  accent-color: ${props => props.theme.colors.primaryMain}; 
  cursor: pointer;
`;


const headers = [
  { id: 'name', label: 'Customer Name', expand: true, expandLabel: "list", subLabel: "List of Inventory" },
  { id: 'qty', noZero: false, label: 'Qty of Inventory' },
  { id: 'total', currency: true, total: true, label: 'Total Cost' },
];

const detailHeaders = [
  {id: 'wigId', label: "Wig ID"},
  {id: 'sku', label: "Details"},
  {id: 'price', currency: true, label: "Price"},
  {id: 'duration', label: "Duration"},
  {id: 'lastLogDate', date: true, label: "Date Consigned"},
]

const IBCTopBar = ({ brands, IBCOptions, setIBCOptions, customerOptions }) => {

  const defaultBrand = brands.length > 1 ? brands?.find(el => el.isDefault)?.name : "all";

  const { date, duration, sortBy, range } = IBCOptions;

  const {customers = {}, setCustomers} = customerOptions;

  const [selectedCustomers, setSelectedCustomers] = useState(0);

  const [displayCustomers, setDisplayCustomers] = useState([]);

  const [custLoading, setCustLoading] = useState(false);

  const handleSelect = (e, field) => {
    let val = e.target.value;
    setIBCOptions(x => ({...x, [field]: val}));
  }

  const filterCustomers = (val) => {
    if (val) {
    setDisplayCustomers(Object.values(customers).filter(el => el.name.toLowerCase().includes(val.toLowerCase())));
    } else {
      setDisplayCustomers(Object.values(customers));
    }
  }

  const loadCustomers = async () => {
    setCustLoading(true);
    try {
      const { data } = await axios.get('/customers');
      let custObj = {};
      data.forEach(el => {
        custObj[el._id] = {id: el._id, name: el.name, selected: true}
      });
      setCustomers(custObj);
      setDisplayCustomers(data.map(el => ({id: el._id, name: el.name})));
    } catch (e) {
      console.log(e);
    } finally {
      setCustLoading(false);
    }
  }

  const handleCustomerSelect = (id, e) => {
    console.log("heee")
    let val = e.target.checked;
    setCustomers(x => ({...x, [id]: {...x[id], selected: val}}));
  }

  const toggleAllCustomers = (flag = true) => {
    console.log("toggle all")
    setCustomers(x => {
      let obj = {};
      Object.keys(x).forEach(key => {
        obj[key] = {...x[key], selected: flag}
      })
      return obj;
    })
  }

  useEffect(() => {
    if (isEmpty(customers)) {
      console.log("loading customers")
      loadCustomers();
    } else {
     setDisplayCustomers(Object.values(customers));
    }
  }, [])

  console.log( "customers")

  useEffect(() => {
    console.log("change detected")
    setSelectedCustomers(Object.values(customers).filter(el => el.selected).length);
  }, [customers])

  return (
      <Grid item xs={12}>
        <Grid container spacing={3}>

        {brands?.length > 1 ? <Grid item xs={3} md={3}>
          <FormControl variant="outlined" fullWidth 
          >
            <InputLabel>Select Brand</InputLabel>
            <Select
              defaultValue={defaultBrand}
              name="brand"
              onChange={(e) => handleSelect(e, 'brand')}
              label="Select Brand"
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {brands?.map(el => 
                  <MenuItem key={el.name} value={el.name}>{el.name}</MenuItem>
                )}
              <MenuItem value="all">All</MenuItem>
            </Select>
          </FormControl>
        </Grid> : null }

        <Grid item xs={3}>
          <TextField
            label="Duration"
            variant="outlined"
            fullWidth
            value={duration}
            onChange={(e) => handleSelect(e, 'duration')}
          />
          <FormHelperText>Default is 90 Days</FormHelperText>
        </Grid>

        <Grid item xs={12} md={12}>
          <FullDivider />
        </Grid>

        <SectionHeaderRow item xs={12}>
          <SectionHeader>Select Customers</SectionHeader>
        </SectionHeaderRow>

        <Grid item xs={12}>
          <TopBar>
            <SearchBox>
            <TextField
              label="Search Customers"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => filterCustomers(e.target.value)}
              InputProps={{
                endAdornment: (
                  <SearchIcon color="primary" />
                )
              }}
            />
            </SearchBox>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={Object.values(customers)?.every(el => el.selected)}
                onChange={(e) => toggleAllCustomers(e.target.checked)}
                name="all"
                color="primary"
              />
            }
            label="Select All Customers"
          />
          
          </TopBar>
          <CustomerList>
          {custLoading ? <Spinner><CircularProgress /></Spinner> :

            displayCustomers?.map(({ id, name }) =>
              <CustomerRow key={id}>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                    size="small"
                      checked={selected}
                      onChange={(e) => handleCustomerSelect(_id, e)}
                      name={name}
                      color="primary"
                    />
                  }
                  label={name}
                /> */}

                <CustomCheckbox
                  type="checkbox" 
                  checked={customers?.[id]?.selected} 
                  onChange={(e) => handleCustomerSelect(id, e)} 
                  name={name} 
                  label={name} 
                />

                <span>{name}</span>
                
              </CustomerRow>
            )}
          </CustomerList>
          <BottomBar>
          { selectedCustomers ? <div>
            {selectedCustomers} Selected
          </div> : null }
          </BottomBar>
        </Grid>

        {/* <Grid item xs={2} md={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="base-label">Select Payment Method</InputLabel>
          <Select
            labelId="base-label"
            id="base-search"
            multiple
            value={paymentType}
            onChange={(e) => handleSelect(e, "paymentType")}
            label="Select Payment Method"
            fullWidth
            renderValue={(selected) => (
              // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <ChipContainer dir="row" justify="flex-start" pad="0">
                {selected.map((value) => (
                    <Chip key={value} bgcolor={theme.colors.success} label={value} />
                ))}
              </ChipContainer>
              // </Box>
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
          {paymentMethods?.map(({ label, value }) => 
              <MenuItem value={value} key={value}>
                <Checkbox 
                  checked={paymentType.indexOf(value) > -1} 
                />
                <ListItemText primary={label} />
                </MenuItem>
            )}
          </Select>
        </FormControl>
        </Grid> */}

        </Grid>
      </Grid>
  )
}

const IBCReport = ({ IBCResults, loading, snack, options, customers }) => {

  let { date, sortBy, brand, duration = 90 } = options;
  console.log("customers", customers)
  date = date ? formatNiceDate(date) : null;
  
  const [invoiceLoading, setInvoiceLoading] = useState({
    email: false,
    print: false
  });

  const [missingEmail, setMissingEmail] = useState(false);

  const [email, setEmail] = useState('');

  const [detailed, setDetailed] = useState(false)

  const handleInvoiceLoading = (type, bool) => {
    setInvoiceLoading(x => ({...x, [type]: bool}));
  }

  
  const printInvoice = async () => {
    console.log("printing")
    handleInvoiceLoading('print', true);
    try {
      const {data} = await axios({
        method: "POST",
        url: `/reports/inventory-by-customer?date=${date}&brand=${encodeURIComponent(brand)}&sortBy=${sortBy}&detailed=${detailed}&duration=${duration}&pdf=true`,
        responseType: 'blob',
        timeout: 60000,
        headers: {"Content-Type": "application/json"},
        data: {
          customers: Object.values(customers),
          sample: "hello"
        }
      });
      displayPDF(data, `customer-payments`)
    } catch (e) {
      console.log(e);
    } finally {
      handleInvoiceLoading('print', false);
    }
  }

  // const sendEmail = async () => {
  //   handleInvoiceLoading('email', true);
  //   if (!email) {
  //     setMissingEmail(true);
  //     return handleInvoiceLoading('email', false);
  //   }
  //   try {
  //     await axios.get(`/reports/payments-by-customer?startDate=${startDate}&endDate=${endDate}&paymentType=${paymentType}&min=${min}&max=${max}&sortBy=${sortBy}&email=${email}&detailed=${detailed}`);
  //     snack('Email successfully sent', 'success');
  //   } catch (e) {
  //     snack('Error sending email', 'error');
  //   } finally {
  //     handleInvoiceLoading('email', false);
  //     return "done"
  //   }
  // }

  
  return (
    <Container>
      { loading ? <LinearProgress /> : 
      IBCResults.results ? (
        <Grid container spacing={3}>

        <Grid item xs={12}>  
          <Summary elevation={4}>
            <CardHeader title="Summary" subheader={
              <span></span>
            } 
            action={
              <BasicFlex>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={detailed}
                      onChange={(e) => setDetailed(e.target.checked)}
                      name="detailed"
                      color="primary"
                    />
                  }
                  label="Detailed Report"
                />
              <Tooltip title="Print">
              <IconButton onClick={printInvoice}>
               {invoiceLoading.print ? <CircularProgress size={20} /> : <PrintIcon color="primary" /> }
              </IconButton>
              </Tooltip>
              {/* <FormDialog toggle={
                <Tooltip title="Email">
                <IconButton>
                  <EmailIcon color="primary" />
                </IconButton>
                </Tooltip>
              } 
              title="Add Email Address"
              onSave={sendEmail}
              save={invoiceLoading.email ? <CircularProgress size={20} /> : "Send Email"}
              size="xs"
              >
          
              <MuiField 
                label="Email Address" 
                defaultValue={email} 
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                type="email"
                err={missingEmail}
                errMsg="Email Address is required"
                />
               
              </FormDialog> */}
              </BasicFlex>
            }
            />
            <CardContent>
              {/* <Grid container spacing={8}> */}

              <BasicFlex justify="flex-start" gap="4rem">

              <BasicFlex width="max-content" >
                <IconBox bg={theme.colors.iconBg2}><SupervisorAccountRoundedIcon htmlColor={theme.colors.icon2} /></IconBox>
                <BasicFlex dir="column" align="flex-start" justify="center">
                  <StatHeader className="icon-header">Customers with Inventory</StatHeader>
                  <StatInfo>{IBCResults.custNum}</StatInfo>
                </BasicFlex>
              </BasicFlex>

              <BasicFlex width="max-content" >
                <IconBox bg={theme.colors.iconBg3}><StyleRoundedIcon htmlColor={theme.colors.icon3} /></IconBox>
                <BasicFlex dir="column" align="flex-start" justify="center">
                  <StatHeader className="icon-header">Wigs Over {duration || 90} Days</StatHeader>
                  <StatInfo>{IBCResults.wigCount}</StatInfo>
                </BasicFlex>
              </BasicFlex>

              </BasicFlex>


              {/* </Grid> */}
            </CardContent>
          </Summary>
        </Grid>

        <Grid item xs={12}
        // md={8} xl={9}
        >
          <CollapsibleTable headers={headers} rows={IBCResults.results} detailHeaders={detailHeaders} noView={true} /> 
        </Grid>

        {/* <Grid item xs={12} md={4} xl={3}>
            <Card elevation={4}>
              <CardHeader title="Sales Qty By Top 20 Customers" />
              <CardContent>
                <DoughnutChart data={PBCResults.chartData} title="Sales By Customer" />
              </CardContent>
            </Card>
        </Grid> */}

        </ Grid> 
      )
      : null }
    </Container>
  )
}

export {  IBCTopBar, IBCReport };