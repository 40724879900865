import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';

import { currencyFormat, formatPhone } from '../../utils/util';

import SimpleTable from '../../utils/simpleTable';
import AddImage from '../../images/add-image.png';


const Info = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .email {
    font-style: italic;
  }
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 35rem;
  display: block;
  object-fit: contain;
  margin: 1rem 0;
  border: 1px solid #0002;
  border-radius: 3px;
  padding: 1rem;
`


 
export default (props) => {

  const [loading, setLoading] = useState(false)

  const [info, setInfo] = useState({});

  const {id} = props.match.params;

  // console.log(id);
 
  const crumbs = [{path: '/brands/', text: 'Brands'}, 
  {path: '/brands/view', text: 'View'}];

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/brands/${id}`);
    setInfo(data);
    setLoading(false)
  }


  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/brands');
  }, [])

 return (
  <ViewInfo title="View Brand Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Grid container spacing={5}>

      <Grid item xs={5}>

        <Card variant="outlined">
          <CardHeader title="Brand Info"
          action={
            <IconLink to={`/brands/edit/${info._id}`}><EditIcon /></IconLink>
          } />
          <CardContent>
            <Info><InfoLabel>Name:</InfoLabel>{info.name}</Info>
            <Info><InfoLabel>Brand Code:</InfoLabel>{info.prefix}</Info>
            <Info><InfoLabel>Default Brand:</InfoLabel>{info.isDefault ? "True" : "False"}</Info>
            <Info><InfoLabel>Display Email:</InfoLabel><span className="email">{info?.brandInfo?.email}</span></Info>
            <Info><InfoLabel>Display Phone:</InfoLabel>{formatPhone(info?.brandInfo?.phone)}</Info>
            <Info><InfoLabel>Address:</InfoLabel>{info?.brandInfo?.address?.street}</Info>
            <Info><InfoLabel>City, State, Zip:</InfoLabel>{info?.brandInfo?.address?.city} {info?.brandInfo?.address?.state}{info?.brandInfo?.address?.zip ? `, ${info?.brandInfo?.address?.zip}` : ''}
            <Divider style={{margin: '10px 0'}} />
            <Info><InfoLabel>Notes:</InfoLabel>{info.notes}</Info>
            </Info>
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={4}>

        <Card variant="outlined">
          <CardHeader title="Brand Logo"
           />
          <CardContent>
            {info.logo ? <LogoImage src={info?.logo?.url} alt="logo placeholder" /> : null }
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={6}>

        <Card variant="outlined">
          <CardHeader title="Manufacturer Details Overview"
          action={
            <IconLink to={`/brands/edit/${info._id}`}><EditIcon /></IconLink>
          } />
          <CardContent>
            <Info><InfoLabel>Name:</InfoLabel>{info?.companyInfo?.name}</Info>
            <Info><InfoLabel>Email:</InfoLabel><span className="email">{info?.companyInfo?.email}</span></Info>
            <Info><InfoLabel>Phone:</InfoLabel>{formatPhone(info?.companyInfo?.phone)}</Info>
            <Info><InfoLabel>Address:</InfoLabel>{info?.companyInfo?.address?.street}</Info>
            <Info><InfoLabel>City, State, Zip:</InfoLabel>{info?.companyInfo?.address?.city} {info?.companyInfo?.address?.state}{info?.companyInfo?.address?.zip ? `, ${info?.companyInfo?.address?.zip}` : ''}
            </Info>
          </CardContent>
        </Card>

      </Grid>
     
      
    </Grid>
    }
  </ViewInfo>
 )
}