export const tableHeaders = {
  freight: "Freight",
  date: "Date",
  orderNo: "Order",
  brand: "Brand",
  // color: "color",
  // rooting: "rooting",
  sku: "Style",
  wigId: "Serial",
  lengthField: "Length",
  title: "Title",
  description: "Description",
  hairstyle: "Hairstyle",
  highlights: "Highlights",
  capSize: "Cap",
  base: "Material",
  cost: "Cost",
  retail: "Price"
}

export const basicHeaders = {
  freight: "Freight",
  date: "Date",
  orderNo: "Order",
  brand: "Brand",
  sku: "Style",
  wigId: "Serial",
  lengthField: "Length",
  capSize: "Cap",
  cost: "Cost",
  retail: "Price"
}

export const sampleValues = {
  freight: [ 50, "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  date: ["2024-01-01", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  orderNo: ["MK07", "MK07", "MK07", "MK07", "MK07", "MK06", "MK06", "MK06", "MK06", "MK06"],
  brand: ["MK", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  sku: ["1/2/4", "1/2/4", "1/2/4","1/2/4","1/2/4", "7/4/1B", "7/4/1B", "7/4/1B", "7/4/1B", "7/4/1B"],
  wigId: ["MK5204", "MK5205", "MK5206", "MK5207", "MK5208", "MK5209", "MK5210", "MK5211", "MK5212", "MK5213"],
  lengthField: ["16", "16", "16", "16", "18", "18", "18", "18", "20", "20"],
  title: ["Novo-Wig", "Novo-Wig", "Novo-Wig", "Novo-Wig", "Novo-Wig", "Novo-Wig", "Novo-Wig", "Novo-Wig", "Novo-Wig", "Novo-Wig"],
  description: ["Short Wavy", "Short Wavy", "Short Wavy", "Short Wavy", "Short Wavy", "Short Wavy", "Short Wavy", "Short Wavy", "Short Wavy", "Short Wavy"],
  hairstyle: ["Wavy", "Curly", "Straight", "Wavy", "Curly", "Straight", "Wavy", "Curly", "Straight", "Wavy"],
  highlights: ["Yes", "Yes", "Yes", "Yes", "Yes", "No", "No", "No", "No", "No"],
  capSize: ["S", "S", "S", "S", "S", "M", "M", "M", "L", "L"],
  base: ["Lace Front", "Lace Top", "No Lace", "Lace Front", "Lace Top","No Lace", "Lace Front", "Lace Top","No Lace", "Lace Front", "Lace Top","No Lace"],
  cost: [730, 730, 730, 730, 730, 730, 730, 730, 730, 730],
  retail: [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000]
}