import React from 'react';

import styled from 'styled-components'

const statuses = {
  success: "rgb(82, 196, 26)",
  fail: "rgb(255, 77, 79)",
  partial: "rgb(250, 173, 20)",
  draft: "#1a45c7"
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  margin: 0 auto;
  /* background-color: green; */
`
const Circle = styled.div`
  height: .8rem;
  width: .8rem;
  border-radius: 50%;
  background-color: ${props => statuses[props.status] || statuses.success};
  margin-right: 1rem;
`

const Text = styled.div`
 font-size: 1.4rem;
`

const Indicator = ({ status, text }) => {
  return (
    <Container className='indicator'>
     <Circle status={status} />
     <Text>{text}</Text>
    </Container>
  )
}


export default Indicator;