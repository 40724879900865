import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import { Button, Grid, TextField } from '@material-ui/core';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import FormDialog from '../../utils/dialog';
import { calculateTotal } from '../../utils/util'

 
export default ({saveFunc, wigs = [], charges = [], payments = []}) => {


  const [wigId, setWigId] = useState('')
  const [amount, setAmount] = useState('')
  const [refundedAmount, setRefundedAmount] = useState('')
  const [paymentType, setPaymentType] = useState('Credit Card')
  const [notes, setNotes] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [paid, setPaid] = useState(false);  // boolean: originally paid?
  const [amountPaid, setAmountPaid] = useState('');
  const [returnStatus, setReturn] = useState(true);
  const [payment, setPayment] = useState(""); // found payment with refund id

  // error states
  const [wigE, setWigE] = useState(false);
  const [paidE, setPaidE] = useState(false)
  // const [paidAmountE, setPaidAmountE] = useState(false)
  const [refundE, setRefundE] = useState(false)
  const [refundHighE, setRefundHighE] = useState(false)
  const [reminder, setReminder] = useState("") // remind to adjust payments manually


  const selectChange = useCallback((e) => {
    setWigE(false)
    const val = e.target.value
    const wig = wigs.find(el => el.wigId === val)
    // console.log(charges);
    const wigCharges = charges.filter(el => el.wigId === val)
    const {total, subtotal} = calculateTotal([wig], wigCharges);
    setWigId(val)
    setAmount(total)
    // setRefundedAmount(0)
    const payment = payments.find(el => el.paymentWigId === val)
    if (payment) {
      setPayment(payment.paymentWigId)
      setPaid(true)
      setAmountPaid(payment.paymentAmount)
      setReminder("")
    } else {
      setPayment("");
      setPaid(false);
      setAmountPaid("");
      setReminder("No payment found with this Wig ID. Please adjust payments manually")
    }
  }, [payments, charges, wigs])


  const handleChange = (event, func) => {
    func(event.target.value);
    setWigE(false)
    // setPaidE(false)
    // setPaidAmountE(false)
    setRefundE(false)
    setRefundHighE(false)
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleBoolRadio = (e, func) => {
    func(e.target.value === "yes" ? true : false)
  }

  const save = () => {
    if (!wigId) {
      setWigE(true)
      return;
    }
    // if (!paid) {
    //   setPaidE(true)
    //   return;
    // }
    // if (paid && !amountPaid) {
    //   setPaidAmountE(true)
    //   return;
    // }
    if (paid && !refundedAmount) {
      setRefundE(true)
      return;
    }
    if (paid  && refundedAmount > amountPaid) {
      setRefundHighE(true)
      return;
    }
    saveFunc({
      wigId,
      paymentMade: paid,
      amount,
      refundedAmount: !paid ? 0 : +refundedAmount,
      paymentType,
      amountPaid: !paid ? 0 : +amountPaid,
      date: selectedDate.toISOString(),
      location: returnStatus ? "in stock" : "by customer",
      notes
     })
  }
 
 return (
  <FormDialog toggle={
    <Button
    variant="contained"
    color="secondary"
    startIcon={<MoneyOffIcon />}
  >
    Refund Item
  </Button>
  } 
  // forceOpen={true}
  title="Refund Wig"
  onSave={save}
  save="Refund"
  size="lg"
  >
    <Grid container spacing={5} alignItems="center">
      <Grid item xs={6}>
      <FormControl variant="outlined" error={wigE} fullWidth>
        <InputLabel id="search-wig-label">Select Wig</InputLabel>
        <Select
          labelId="search-wig-label"
          id="search-wig"
          value={wigId}
          onChange={selectChange}
          label="Select Wig"
          fullWidth
        >
          {wigs ? wigs.map((el) => (
            <MenuItem key={el.wigId} value={el.wigId}>{el.wigId}</MenuItem>
          )) : null}
        </Select>
        {wigE ? 
        <FormHelperText error={wigE}>Wig ID is required for refund</FormHelperText>
        : null }
        <FormHelperText>{reminder}</FormHelperText>
        </FormControl>
      </Grid>

      {/* <Grid item xs={1}></Grid> */}

      <Grid item xs={6}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Should the wig be returned to stock?</FormLabel>
        <RadioGroup aria-label="return-status" name="returnStatus" value={returnStatus ? "yes" : "no"} onChange={(e) => handleBoolRadio(e, setReturn)} row>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        {/* {paidE ? 
        <FormHelperText error={paidE}>Please select Yes or No</FormHelperText>
        : null } */}
      </FormControl>
      </Grid>

      {paid ? 
      <>
      <Grid item xs={4}>
      <TextField
        type="number"
        label="Wig total including charges"
        name="amount"
        value={amount}
        variant="outlined"
        fullWidth
      />
      </Grid>

      <Grid item xs={4}>
      <TextField
        type="number"
        label="Amount Paid"
        name="amountPaid"
        value={amountPaid}
        // onChange={(e) => handleChange(e, setAmountPaid)}
        variant="outlined"
        disabled
        fullWidth
      />
      {/* {paidAmountE ? 
        <FormHelperText error={paidAmountE}>Please enter amount paid</FormHelperText>
        : null } */}
      </Grid>

      

      <Grid item xs={4}>

      <TextField
        type="number"
        label="Refund Amount"
        name="refundedAmount"
        value={refundedAmount}
        onChange={(e) => handleChange(e, setRefundedAmount)}
        variant="outlined"
        fullWidth
      />
      {refundE ? 
        <FormHelperText error={refundE}>Please enter the amount you wish to refund, or 0</FormHelperText>
        : null }

      {refundHighE ? 
        <FormHelperText error={refundHighE}>
          Refund amount can't be more than amount paid
        </FormHelperText>
        : null }

      </Grid>

      <Grid item xs={7}>
      <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="paymentType">Payment Type</InputLabel>
          <Select
            name="paymentType"
            id = 'paymentType'
            value = {paymentType}
            onChange = {(e) => handleChange(e, setPaymentType)}
            variant="outlined"
            fullWidth
            label="Payment Type"
          >
            <MenuItem value={"cash"}>Cash</MenuItem>
            <MenuItem value={"Quick Pay"}>Quick Pay</MenuItem>
            <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
            <MenuItem value={"Check"}>Check</MenuItem>
            <MenuItem value={"Bank Account"}>Bank Account</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      </>

      : null}

      <Grid item xs={5}>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker disableToolbar
      value={selectedDate} onChange={handleDateChange} variant="inline" label="Date" name="date" fullWidth />
      </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12}>
       <TextField
       variant="outlined"
       fullWidth
       multiline
       rows={3}
       value={notes}
       onChange={(e) => handleChange(e, setNotes)}
       name="notes"
       label="Notes"
       />
    
      </Grid>

    </Grid>
  </FormDialog>
 )
}