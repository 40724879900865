import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../../../axios';

import ProtectedRoute from '../../../../utils/ProtectedRoute';
import { Switch, Route, Link } from 'react-router-dom'

//icons
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Add from '@material-ui/icons/Add';

import styled from 'styled-components';

import { debounce } from 'lodash'

import { useSBContext } from '../../../../utils/snackbar';

import PanelForm from '../../PanelForm';

import AddShipmentSettings from './AddShipmentSettings';

import SimpleTable from '../../../../utils/simpleTable';

import { OutlinedInput, Button } from '@material-ui/core'

const Container = styled.div`

`

const MainContainer = styled.div`
  width: 90%;
  margin-top: 6rem;
`

const ButtonContainer = styled.div`
  width: 25rem;
  margin-left: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const CreateButton = styled(Button)`
  /* height: ; */
`

const Search = styled(OutlinedInput)`
  border-radius: 20px;
  flex-grow: 1;
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6rem;
`

const ShipmentSettings = (props) => {

  {console.log(props.location.pathname, "props") }

  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [trashLoading, setTrashLoading] = useState(false)

  const { handleClick } = useSBContext()

  const loadProfiles = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get('/system_settings/profiles')
      setProfiles(data)
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadProfiles()
  }, [props?.location?.pathname])

  const searchProfiles = useCallback(debounce(async (val) => {
    const {data} = await axios.get(`/styles?term=${encodeURIComponent(val)}`)
    setProfiles(data)
  }, 250), [])

  const headers = [
    {id: "field", label: "Profile Name"},
    {id: "description", label: "Description"}
  ]

  const trashFunc = async (index) => {
    const find = profiles[index]
    if (!find) {
      handleClick('error', "Profile not found")
      return "fail"
    }
    try {
      setTrashLoading(true)
      axios({
        url: `/system_settings/profiles/${find._id}`,
        method: "DELETE"
      })
      handleClick('success', "Successfully removed Profile")
      loadProfiles()
    } catch (e) {
      handleClick('error', e.message)
    } finally {
      setTrashLoading(false)
      return "done"
    }
  }

  const path = props?.match?.url;
  return (
    <Container>
        <Switch>
          <ProtectedRoute path={`${path}/edit/:id`} guard='writeSettings' component={AddShipmentSettings} />
          <ProtectedRoute path={`${path}/edit`} guard='writeSettings' component={AddShipmentSettings} />
          <Route path="/setup/shipments">
          <PanelForm title="Shipment Profile List">

             <MainContainer>
              <SearchBar>
               <Search 
                variant="outlined"
                fullWidth
                endAdornment={<SearchRoundedIcon color="primary" />}
                onChange={(e) => searchProfiles(e.target.value)}
                placeholder="Search By Profile Name"
               />
               <ButtonContainer>
                <Link to={`${path}/edit/`}>
                <CreateButton variant="contained" color="primary" startIcon={<Add />} >
                  New Profile
                </CreateButton>
               </Link>
               </ButtonContainer>
              </SearchBar>
              <SimpleTable headers={headers} title="Shipment Profiles" rows={profiles} link="setup/shipments" view={false} write={true} trash={true} rpp={30} dense={true} trashFunc={trashFunc} />
            </MainContainer>
          </PanelForm>

          </Route>
        </Switch>
    </Container>
  )
}

export default ShipmentSettings;