import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import {Switch, Route} from 'react-router-dom';
import ProtectedRoute from '../../utils/ProtectedRoute';

import useFirstLoad from '../../hooks/useFirstLoad';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';

import { debounce } from 'lodash'

import Table from '../../utils/table-server';

import AddExpense from './AddExpense';
// import ViewPayment from './ViewPayment';

import { useSBContext } from '../../utils/snackbar';

import { currencyFormat } from '../../utils/util'

const SliderLabel = styled.span`
  font-size: 1.5rem;
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 500;
`

const SliderContainer = styled.div`
  width: 50rem;
  min-height: 6rem;
  padding: 1rem;

  .slider {
    padding: 0 2rem;
  }

  .labels {
    display: flex;
    width: 100%;
    height: 2.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .ranges {
    display: block;
    font-size: 1.4rem;
    font-weight: 300;
  }

`

export default (props) => {


  const [rows, setRows] = useState([]);

  const [minMax, setMinMax] = useState([0, 100000])

  const [loading, setLoading] = useState(false)

  const [count, setCount] = useState(0);

  const [search, setSearch] = useState({
    p: '', rpp: '', sp: '', so: ''
  });

  const [query, setQuery] = useState('/expenses')

  const [filter, setFilter] = useState({
    slider: [0, 100000],
    db: true
  })

  const [firstLoad, setLoad] = useFirstLoad()


  const [marks, setMarks] = useState([
      {
        value: 0,
        label: `$0.00`,
      },
      {
        value: 5000,
        label: '$5000.00',
      },
      {
        value: 10000,
        label: '$10,000.00',
      }
    ])


  // const handleChange = (event, newValue) => {
  //   setSliderValue(newValue);
  // };

  const { handleClick } = useSBContext();


  const valuetext = (value) => {
    return `$${value}`;
  }
  

  const load = async (page, rowsPerPage, sortProp, sortOrder, filterList, queryStr = query) => {
    let filtered = [];
    let filters = '';
    let fl = filterList ? filterList : filter;
      let keys = Object.keys(fl);
      if (keys.length) {
        keys.forEach(el => {
          if (Array.isArray(fl[el])) {
            filtered.push(`filter[${el}]=${fl[el][0]}&filter[${el}]=${fl[el][1]}`)
          } else {
            filtered.push(`filter[${el}]=${fl[el] ? 'true' : 'false'}`)
          }
        })
        filters = filtered.join('&')
      }

      // console.log(fl);
    

    setSearch({p: page, rpp: rowsPerPage, sp: sortProp, so: sortOrder});
    let sort = sortOrder === 'asc' ? `${sortProp}` : `-${sortProp}`
    setLoading(true)
    let queryString = queryStr === '/expenses' ? `/expenses?sort=${sort}&page=${page}&limit=${rowsPerPage}&${filters}` : `${queryStr}&sort=${sort}&page=${page}&limit=${rowsPerPage}&${filters}`;
    try {
      const {data} = await axios.get(queryString)
      setLoading(false)

      setRows(data.docs || []);
      setCount(data.pages || 0)

      if (!data.docs)
        handleClick('error', 'Error retrieving data')
    } catch (e) {
      setLoading(false)
      setRows([])
    }
  }

  const debouncedLoad = useCallback(debounce(load, 350), [])

  const handleFilter = (e, newValue, name) => {
    // console.log(name);
    setFilter({ ...filter, [name]: newValue, db: false});
  };

  const loadMax = async () => {
    try {
      const { data } = await axios.get('/expenses/max');

      let amount = Math.ceil(data.total / 1000) * 1000;

      let half = amount / 2;

      setMarks([
        {
          value: 0,
          label: `$0.00`,
        },
        {
          value: half,
          label: `${currencyFormat(half)}`,
        },
        {
          value: amount,
          label: `${currencyFormat(amount)}`,
        }
      ])

      setMinMax([0, amount])
      setFilter({...filter, slider: [0, amount], db: true})
      setLoad(false)
    } catch (error) {
      handleClick('error', 'Error retrieving max amount')
    }
  }

  const searchExpense = useCallback(debounce(async (val, setPage) => {
    const {rpp} = search
    let filtered = [];
    let filters = '';
      let keys = Object.keys(filter);
      if (keys.length) {
        keys.forEach(el => {
          if (Array.isArray(filter[el])) {
            filtered.push(`filter[${el}]=${filter[el][0]}&filter[${el}]=${filter[el][1]}`)
          } else {
            filtered.push(`filter[${el}]=${filter[el] ? 'true' : 'false'}`)
          }
        })
        filters = filtered.join('&')
      }
      let encodedVal = encodeURIComponent(val)
    const {data} = await axios.get(`/expenses?term=${encodedVal}&page=0&limit=${rpp}&${filters}`)
    setPage(0)
    setQuery(`/expenses?term=${encodedVal}`);
    setRows(data.docs || []);
    setCount(data.pages || 0)
  }, 250), [search, filter]);



  useEffect(() => {
    if (props.location.pathname !== '/expenses/') return
    loadMax();
    // load();
  }, [props.location.pathname])

  useEffect(() => {

    if (firstLoad) {
      return;
    }

    const {p, rpp, sp, so} = search;
    if (!filter.db) {
      debouncedLoad(p, rpp, sp, so, filter)
    }
  }, [filter])

  const headers = [
    { id: 'expenseType', label: 'Expense Type'},
    { id: 'qty', label: 'Qty' },
    { id: 'rate', currency: true, label: 'Rate' },
    { id: 'total', currency: true, label: 'Total' },
    { id: 'date', date: true, label: 'Date'},
    { id: 'notes', label: 'Notes'}
  ];

  const path = props.match.url;

 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeExpense' component={AddExpense} />
    <ProtectedRoute path={`${path}/edit`} guard='writeExpense' component={AddExpense} />
    {/* <ProtectedRoute path={`${path}/view/:id`} guard='readExpense' component={ViewExpense} /> */}
    <Route path="/expenses">
    <Table loading={loading} load={load} title="Expenses" headers={headers} rows={rows} link="expenses" count={count} view={false}  ord="desc" ordField="date"
      filterItems={
        <SliderContainer>
          <SliderLabel>Expense Amount:</SliderLabel>
          <div className="labels">
            <span className="ranges">Min: {currencyFormat(filter.slider[0])}</span>
            <span className="ranges">Max: {currencyFormat(filter.slider[1])}</span>
          </div>
          <div className="slider">
            <Slider
              getAriaLabel={() => 'Min Max range'}
              value={filter.slider}
              onChange={(e, ne) => handleFilter(e, ne, "slider")}
              min={0}
              max={minMax[1]}
              name="slider"
              step={500}
              marks={marks}
              valueLabelDisplay="off"
              getAriaValueText={valuetext}
              valueLabelFormat={(val) => `$${val}`}
            />
            <FormHelperText>Select range to target amount</FormHelperText>
          </div>
        </SliderContainer>
      }
      search={setPage => <TextField name="search" 
      label="Search Expense Type" 
      variant="outlined" size="small" fullWidth onChange={(e) => searchExpense(e.target.value, setPage)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}