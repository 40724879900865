import React, {useState, useEffect, useCallback} from 'react';
// import styled from 'styled-components';
import axios from '../../axios';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import Table from '../../utils/table';
import EditBrands from './AddBrands';
import ViewBrands from './ViewBrands';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import { debounce } from 'lodash'
 
export default (props) => {
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false)

  const load = async () => {
    setLoading(true)
    const {data} = await axios.get('/brands/')
    let info = Array.isArray(data) ? data : []
    setRows(info);
    setLoading(false)
  }

  const searchCust = useCallback(debounce(async val => {
    const {data} = await axios.get(`/brands?term=${encodeURIComponent(val)}`)
    let info = Array.isArray(data) ? data : []
    setRows(info);
  }, 250), [])

  useEffect(() => {
    if (props.location.pathname !== '/brands/') return
    load();
  }, [props.location.pathname])

  const headers = [
    { id: 'name', label: 'Brand Name' },
    { id: 'prefix', label: 'Brand Code' },
    { id: 'isDefault', render: row => row.isDefault === true ? "True" : "False", label: 'Default' },
    { id: 'companyInfo.name', label: 'Manufacturer Name' },
    { id: 'companyInfo.email', label: 'Contact Email' }
  ];

  const path = props.match.url;

 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeBrand' component={EditBrands} />
    <ProtectedRoute path={`${path}/edit`} guard='writeBrand' component={EditBrands} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readBrand' component={ViewBrands} />
    <Route path="/brands">
      <Table loading={loading} title="Brands" headers={headers} rows={rows} link="brands" 
      search={<TextField name="search" label="Search by Name"
      variant="outlined" size="small" fullWidth onChange={(e) => searchCust(e.target.value)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}