import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../../axios';
import ProtectedRoute from '../../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import useLoadList from '../../../hooks/useLoadList';

import { theme } from '../../../utils/theme';

import Table from '../../../utils/table-server';
import EditVendor from './AddVendor';
// import ViewCustomer from './ViewCustomer';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Switch as Switcher, FormControlLabel} from '@material-ui/core'
import Search from '@material-ui/icons/Search';

import Chip from '../../../utils/Chip';


const SwitchContainer= styled(FormControlLabel)`
  width: 300px;
  margin-left: 30px;
`
 
const VendorList = (props) => {
  const {rows, loading, load, searchList, count, switcher, setSwitch} = useLoadList('/vendors');
  

  const headers = [
    { id: 'displayName', label: 'Name' },
    { id: 'companyName', label: 'Company' },
    { id: "vendorType", label: "Vendor Type" },
    { id: 'email', label: 'Email' },
    { id: 'balance', currency: true, label: 'Balance' },
    {id: 'active', render: (row) => <Chip padx="10px" bgcolor={row.isActive ? theme.colors.success : theme.colors.error} size="small" label={row.isActive ? "Active" : "Not Active"}  />, label: "Status" }
  ];

  const path = props.match.url;

  const handleSwitch = (e) => { 
    setSwitch(e.target.checked);
  };

 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeVendor' component={EditVendor} />
    <ProtectedRoute path={`${path}/edit`} guard='writeVendor' component={EditVendor} />
    {/* <ProtectedRoute path={`${path}/view/:id`} guard='readCustomer' component={ViewCustomer} /> */}
    <Route path="/vendors">
      <Table loading={loading} load={load} title="Vendors" headers={headers} rows={rows} link="vendors" count={count} ord="asc" ordField="name"
      switchField={<SwitchContainer
        control={
          <Switcher
            checked={switcher}
            onChange={handleSwitch}
            name="inactive"
            color="primary"
          />
        }
        label="Include Inactive"
        />}
      search={setPage => <TextField name="search" label="Search by Name"
      variant="outlined" size="small" fullWidth onChange={(e) => searchList(e.target.value, setPage)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}

export default VendorList;