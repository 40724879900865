import React from 'react';
// import styled from 'styled-components';

import { BasicFlex } from '../../utils/common';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import SwitchField from '@material-ui/core/Switch';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const FilterFields = ({filter, handleFilter, applyFilter, enableBalance = true }) => {

  const { balance, startDate, endDate, useFromDate } = filter;
  return (
    <div>
      {enableBalance ? <BasicFlex>
        <FormControlLabel
          control={<Checkbox checked={balance} 
          onChange={(e) => handleFilter(e, "balance", "check")} name="balance" color="secondary" />}
          label="Open Balance"
        />
      </BasicFlex> : null }
      <BasicFlex my="2rem">
        <FormControlLabel
          control={
          <SwitchField
            checked={useFromDate}
            onChange={(e) => handleFilter(e, "useFromDate", "check")}
            name="closed"
            color="secondary"
            size='small'
          />
        }
        label="Use From Date"
      />
        </BasicFlex>
      <BasicFlex  width="20rem">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            openTo="year" 
            disableToolbar 
            views={["year", "month", "date"]} 
            inputVariant="outlined"
            disabled={!useFromDate}
            value={startDate} 
            format="MM/dd/yyyy" 
            onChange={(e) => handleFilter(e, "startDate", "date")}  
            label="From Date" 
            name="startDate"
          />
        </MuiPickersUtilsProvider>
    </BasicFlex>
    <BasicFlex my="2rem" width="20rem">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          openTo="year" 
          disableToolbar 
          views={["year", "month", "date"]} 
          inputVariant="outlined"
          value={endDate} 
          format="MM/dd/yyyy" 
          onChange={(e) => handleFilter(e, "endDate", "date")}  
          label="To Date" 
          name="endDate"
        />
    </MuiPickersUtilsProvider>
  </BasicFlex>
  <BasicFlex my="1rem">
    <Button startIcon={<FilterListRoundedIcon />} variant="contained" size="small" color="secondary" onClick={applyFilter}>Apply Filters</Button>
  </BasicFlex>
    </div>
  )

}

export default FilterFields;