import React, { useState, useEffect, useRef } from 'react';
import axios from '../../../../axios';

import { StyledLink } from '../../../../utils/common';

import { Button, TextField, Grid, FormHelperText, CircularProgress, Card, CardContent, CardHeader, LinearProgress } from '@material-ui/core'

import { useSBContext } from '../../../../utils/snackbar';

import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

import styled from 'styled-components';

import PanelForm from '../../PanelForm';

import SimpleAutoComplete from '../../../../utils/simpleAutoComplete';

import { handleErrors, createNewOption } from '../../../../utils/util'

const Container = styled.div`

`

const BackButton = styled(Button)`

  &.MuiButton-root {
    min-width: auto;
    display: flex;
    justify-content: center;
    padding: .5rem .8rem;
    text-decoration: none !important;

  }

  svg {
    transform: rotate(180deg);
  }
`

const CardForm = styled(Card)`
  margin-top: 3rem;
`

const FormPanel = styled.form`
  width: 60%;
  min-width: 70rem;
  margin-top: 1rem;
`

const AddStyle = (props) => {

  const { id } =  props.match.params;

  const isEdit = () => id ? true : false;

  const { handleClick } = useSBContext()

  const [cats, setCat] = useState([])

  const [ info, setInfo ] = useState({})

  const [ loading, setLoading ] = useState(false)

  const [ loadingCats, setLoadingCats ] = useState(false)

  const [ submitting, setSubmitting ] = useState(false)

  let message = isEdit ? "Successfully updated Style" : "Successfully added Style"

  const loadStyle = async () => {
    setLoading(true)
     try {
      const { data } = await axios.get(`/styles/${id}`)

      console.log(data.tags, "tags")
      setInfo(data)
     } catch (e) {
      handleClick("error", "Failed to load Style")
     } finally {
      setLoading(false)
     }
  }

  const loadCats = async () => {
    setLoadingCats(true)
    try {
      const { data } = await axios.get("/tags");
      if (data.length) {
        const tags = data.map(el => ({name: el.name, display: el.name}))
        setCat(tags) 
      }
    } catch (e) {
      handleClick("error", "Failed to load Categories")
    } finally {
      setLoadingCats(false)
    }
  }
  
  useEffect(() => {
    loadCats()
    if (id) {
      loadStyle()
    }
  }, [])

  const [tags, setTags] = useState([])
  const style = useRef()

  const handleSelect = (val) => {
    setTags(val);
  }

  const onSubmit = async () => {
    console.log({tags, style: style.current?.value});
    setSubmitting(true)
    let newStyle = style.current?.value
    try {
      const { data } = isEdit() ? await axios.put(`/styles/${id}`, {tags, style: newStyle}) : await axios.post("/styles", {tags, style: newStyle});
      handleClick("success", message)
      props.history.push('/setup/styles')
    } catch (e) {
      handleClick("error", handleErrors({e}))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Container>
      <PanelForm title={isEdit() ? `Edit Style` : "Add Style"}>
        <StyledLink to="/setup/styles">
        <BackButton  color="secondary" startIcon={<ForwardRoundedIcon />}>Back to List</BackButton>
        </StyledLink>
        {loading ? <LinearProgress /> : <CardForm variant="outlined">
          <CardHeader title={isEdit() ? `Edit Style` : "Add New Style"} />
          <CardContent>
            <FormPanel>
              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <TextField defaultValue={info.style} fullWidth variant="outlined" name="style" inputRef={style} placeholder='Color' label="Color" />
                </Grid>
                <Grid item xs={7}>
                  <SimpleAutoComplete 
                    list={cats} name="tags" label="Add Categories (Optional)"
                    multiple
                    disableCloseOnSelect
                    freeSolo
                    clearOnBlur
                    getOptionLabel={(opt) => opt.name || ''}
                    getOptionSelected={(style, val) => style.name === val.name}
                    renderOption={(opt) => <span >{opt.display}</span>}
                    handleChange={() => {}}
                    createNew={createNewOption}
                    createText="Category" // value to display as in "Create New Category"
                    handleSelect={handleSelect}
                    value={info.tags}
                    autoHighlight
                    // autoSelect
                    />
                    <FormHelperText>Select or create multiple tags</FormHelperText>
                </Grid>

                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color="primary"
                    onClick={onSubmit}
                    startIcon={<SaveRoundedIcon />}
                  >
                  {submitting ? <CircularProgress size={25} color="inherit" /> : "Save Style"} 
                  </Button>
                </Grid>

              </Grid>
            </FormPanel>
          </CardContent>
        </CardForm> }
      </PanelForm>
    </Container>
  )
}

export default AddStyle;