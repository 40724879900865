import React from "react";

function DollarCheck({color = "#000"}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 402 432" className="dollar-check MuiSvgIcon-root">
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="40"
        d="M281 113.14L118.75 113.14 118.95 215.23 281 215.23 281 318.06 117 318.06"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="40"
        d="M202.28 94L202.28 112.91"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="40"
        d="M202.55 320.4L202.28 338"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="50"
        d="M29 25h348l-2 382H25l4-382z"
      ></path>
    </svg>
  );
}

export default DollarCheck;