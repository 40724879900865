import React, {useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import Delete from '@material-ui/icons/Delete';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import displayPdf from '../../utils/displayPdf';
import {useSBContext} from '../../utils/snackbar';


import FormDialog from '../../utils/dialog';

import ApplyPayments from './ApplyPayments';

import FullDialog from '../../utils/FullDialog';



import { currencyFormat, formatCalendar, formatGenericDate } from '../../utils/util';

import { Flex } from '../../utils/common';

// import Accordion from './AccordionWigs';
import SimpleTable from '../../utils/simpleTable';
import { Button, CircularProgress } from '@material-ui/core';

const Info = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .amount {
    font-weight: 600;
    font-size: 1.7rem;
  }
`

const DialogHeader = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${props => props.theme.colors.secondaryMain};
  margin: 1.5rem 0;
  text-transform: uppercase;
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const AccordionContainer = styled.div`
  margin-top: 2rem;
`

const ApplyButton = styled(Button)`
  margin-top: .5rem;
  margin-right: 1.5rem;

  .icon {
    margin-right: 1rem;
  }
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const Balance = styled.h3`
  /* font-family: 'Roboto'; */
  color: ${props => props.negative ? 'salmon' : 'inherit'};
`

const Wallet = styled.div`
  height: 8rem;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 2rem;

  h3 {
    font-size: 4.5rem;
    font-weight: 600;
    text-align: center;
    margin: .5rem 0;
    
  }

  .sub {
    display: block;
    text-align: center;
  }
`
const Due = styled.div`
  height: 4rem;
  text-align: center;
  margin-bottom: 5rem;

  h3 {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    margin: .5rem 0;
  }

  .sub {
    display: block;
    text-align: center;
  }
`

const TooltipContainer = styled(Flex)`
`
 
export default (props) => {

  const [loading, setLoading] = useState(false)

  const [info, setInfo] = useState({});
  const [available, setAvailable] = useState(0)

  const [applied, setApplied] = useState([]);

  const [calculated, calculate] = useState({
    set: false,
    available,
    applied: 0,
    totalWigs: 0,
    totalInvoices: 0
  })

  const [selected, setSelected] = useState({

  })

  const [applyDate, setApplyDate] = useState(new Date)

  const [email, setEmail] = useState('');

  const [orders, setOrders] = useState([])

  const [emailErr, setEmailErr] = useState(false);

  const [eLoading, setELoading] = useState(false)

  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const [applyLoading, setApplyLoading] = useState(false)

  const [loadingOrders, setLoadingOrders] = useState(false)

  const [trashLoading, setTrashLoading] = useState(false)

  const [batchLoading, setBatchLoading] = useState(false)

  const {id} = props.match.params;

  const handleEmailChange = (e) => {
    setEmailErr(false)
    setEmail(e.target.value)
  }

  // console.log(id);
 
  const crumbs = [{path: '/payments/', text: 'Payments'}, 
  {path: `/payments/view/${id}`, text: 'View'}, {path: `/payments/view/${id}`, text: `${info?.paymentNum || ""}`}];

  const refreshStats = (data) => {
    let avail = data.payment.paymentAmount;

    let total = data.attachedPayments.reduce((acc, cur) => acc + cur.paymentAmount, 0)

    avail = avail - total;

    setApplied(data.attachedPayments)

    setAvailable(avail)
  }

  const loadUnpaidOrders = async (cust) => {
    try {
      setLoadingOrders(true)
      const { data } = await axios.get(`/orders/unpaid?custId=${cust?.id?._id}`)
      setOrders(data)
      calculate(x => {
        if (!x.set) {
        return {
          set: true,
          available,
          applied: 0
        }
      }
      })
      console.log({data});
      setLoadingOrders(false)
    } catch (e) {
      setLoadingOrders(false)
      console.log(e);
    }
  }

  const applyPayments = useCallback(async () => {
    setApplyLoading(true)
    let payments = []
    Object.entries(selected).forEach(([key, value]) => {
      Object.entries(value).forEach(([item, amount]) => {
        if (item !== "order" && amount > 0) {
          payments.push({
            remainingBalance: item === "remain" ? true : false,
            paymentAmount: amount,
            paymentType: info.paymentType,
            paymentInfo: "",
            date: applyDate,
            entryType: 'applied',
            paymentId: info._id,
            orderId: key,
            wigId: item !== "remain" ? item : "",
            customer: {
              name: info.customer?.name,
              id: info.customer?.id?._id
            },
            paymentNotes: "",
          })
        }
      })
    })

    console.log(payments, "paymemememem", applyDate);

    try {
      const { data } = await axios.post(`/payments/${info._id}/apply`, {payments})
      console.log(data, "ehyyyyyyy");
      setInfo(data.payment)

      refreshStats(data)

    } catch (e) {
      console.log(e);
    } finally {
      setApplyLoading(false)
    }
    return true;
  }, [selected, info, applyDate])

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/payments/${id}?populated=customer.id`);

    const custInfo = data.payment.customer?.id;
    // console.log(data, "check it out...");
    setInfo(data.payment, "info");
    setEmail(custInfo?.email || '')

    refreshStats(data)

    setLoading(false)
  }

  const paymentHeaders = [
    { id: 'customer.name', label: 'Customer'},
    { id: 'orderId', label: 'Sale ID' },
    { id: 'paymentAmount', currency: true, label: 'Payment Amount'},
    { id: 'paymentInfo', label: 'Info'},
    { id: 'wigId', label: "Wig ID"},
    { id: 'paymentNotes', truncate: true, label: 'Notes'},
    { id: 'date', date: true, label: 'Date'}
  ]

  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/payment');
  }, [])

  const { handleClick } = useSBContext()

  const sendEmail = async () => {
    if (!email) return setEmailErr(true);
    setELoading(true)
    try {
      await axios.post(`/payments/${id}/receipt?email=${email}`)
      handleClick('success', 'Email successfully sent')
      setInvoiceLoading(false)
    } catch (e) {
      handleClick('error', 'Error sending email')
      setELoading(false)
    }
    return 'sent'
  }

  const printInvoice = async () => {
    setInvoiceLoading(true)
    const {data} = await axios({
      method: "POST",
      url: `/payments/${id}/receipt?pdf=true`,
      responseType: 'blob',
      timeout: 60000,
      headers: {"Content-Type": "application/pdf"}
    });
    setInvoiceLoading(false)
    displayPdf(data, `Payment Receipt`)
  }

  const deletePayments = async (newPayments, loadFunc, reset) => {
    const updatedInfo = { custId: info.customer?.id?._id, payments: newPayments }
    console.log(updatedInfo, info.customer, "updatedInfo");
    loadFunc(true)

    try {
      const { data } = await axios.put(`/payments/${id}/remove`, {...updatedInfo});

      setInfo(data.payment)
      refreshStats(data)


      if (reset) {
        // reset selected
        reset({})
      }

      handleClick('success', 'Payment successfully removed')
    } catch (e) {
      console.log(e?.message)
      handleClick('error', '')
    } finally {
      loadFunc(false)
    }
    return "save"
  }

  const trashFunc = (index) => {
    let newPayments = applied.filter((el, ind) => ind === index)
    return deletePayments(newPayments, setTrashLoading)
  }

  const batchTrashFunc = (listObj, reset) => {
    let newPayments = applied.filter((el) => listObj[el._id])
    console.log(newPayments, "Chhcchchchchc");

    return deletePayments(newPayments, setBatchLoading, reset)
  }

  const batchActions = [
    {
      icon: <Delete color="primary" />,
      title: "Delete?",
      cta: "Confirm Delete",
      func: (listObj, reset) => batchTrashFunc(listObj, reset),
      warningText: "Deleting will permanently remove payments from the system.",
      loading: batchLoading
    }
  ]

 return (
  <ViewInfo title="View Payment Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Grid container spacing={3}>

      <Grid item xs={4}>

        <Card variant="outlined">
          <CardHeader title={`Payment Info - ${info?.paymentNum}`}
          action={
            <IconLink to={`/payments/edit/${info?._id}`}><EditIcon /></IconLink>
          } subheader={formatGenericDate(info?.date)} />
          <CardContent>
            <Info><InfoLabel>Customer Name:</InfoLabel>{info?.customer?.name}</Info>
            <Info><InfoLabel>Payment Amount:</InfoLabel><span className="amount">{currencyFormat(info?.paymentAmount)}</span></Info>
            <Info><InfoLabel>Payment Method:</InfoLabel>{info?.paymentType}</Info>
            <Info><InfoLabel>Payment Info:</InfoLabel>{info?.paymentInfo}</Info>
            
            <Info><InfoLabel>Notes:</InfoLabel>{info?.paymentNotes}</Info>

            <Divider style={{margin: '10px 0'}} />

            <Info><InfoLabel>Payment Entered By:</InfoLabel> {info?.addedBy?.name}</Info>
            <Info><InfoLabel>Payment Entered on:</InfoLabel> {formatCalendar(info?.createdAt)}</Info>
            <Info><InfoLabel>Last Updated:</InfoLabel> 
            {info?.updatedBy && `${formatCalendar(info?.updatedAt)} By ${info?.updatedBy?.name}`}
            </Info>
          </CardContent>
        </Card>

      </Grid>
     
      <Grid item xs={4}>
        
        <Card variant="outlined">
        <CardHeader title="Payment Availability" action={
            <TooltipContainer dir="row" pad="0" justify="space-between">
            <Tooltip title="Print Receipt">
            <IconButton onClick={printInvoice}>
              {invoiceLoading ? <CircularProgress size={25} /> :
              <PrintIcon color="primary" /> }
            </IconButton>
            </Tooltip>
            
            <FormDialog toggle={
                <Tooltip title="Email Receipt">
                <IconButton>
                  <EmailIcon color="primary" />
                </IconButton>
                </Tooltip>
              } 
              title="Add Email Address"
              onSave={sendEmail}
              save="Send Email"
              size="sm"
              >
              <Grid container justify-content="center">
                <Grid item xs={8}>
                  {eLoading ? <LinearProgress /> : 
                  <TextField error={emailErr} variant="outlined" label="Email" name="email" type="email" fullWidth defaultValue={email} onChange={handleEmailChange}
                  helperText={emailErr ? 'Email Required' : ''} />
                }
                </Grid>
              </Grid>
              </FormDialog>
              </TooltipContainer>
          }
           />
          {/* <CardContent> */}

            <Wallet>
            <h3>{currencyFormat(available)}</h3>
            <span className='sub'>Current Amount Available</span>
            </Wallet>

            <Divider style={{margin: '30px 0'}} />

            <Due>
              <Balance>{currencyFormat(info?.paymentAmount - available)}</Balance>
              <span className='sub'>Payment Amount Used</span>
            </Due>

        </Card>

      </Grid>
{/* 
      <Grid item xs={4}>
        <Card variant='outlined'>
          <CardHeader title="Apply Payment"></CardHeader>
        </Card>
      </Grid> */}
      
      <Grid item xs={12}>

      <Card variant="outlined"> 
      <CardHeader title={applied.length ? `Applied Payments - ${applied.length} Payments` : "No Applied Payments"} action={
       ( <FullDialog toggle={
          <Tooltip title="Cart">
           <ApplyButton color="secondary" variant='contained' onClick={() => loadUnpaidOrders(info?.customer)}><TouchAppIcon className='icon' />Apply Payment</ApplyButton>
          </Tooltip>
        } 
        title={<>Apply Payments</>}
        onSave={applyPayments}
        save={applyLoading ? <CircularProgress size={25} color="inherit" /> : "Save Payments"}
        cancel={false}
        >
        {loadingOrders ? <LinearProgress color="primary" /> : <>
          {orders.length ? <DialogHeader>Found {orders.length} Unpaid Invoices for {info.customer?.name} - {orders.reduce((acc, cur) => acc + cur.wigs?.length, 0)} Wigs</DialogHeader> : ""}
          <ApplyPayments orders={orders} calculated={calculated} calculate={calculate} original={available} selected={selected} setSelected={setSelected} date={{applyDate, setApplyDate}} />
        </>}
      </FullDialog>)
      } />

        

      <SimpleTable link="orders" headers={paymentHeaders} rows={applied} idReplace="orderId" write={false} view={false} trash={true} trashLoading={trashLoading} trashFunc={trashFunc} check={true} batchActions={batchActions} />

      </Card>
      </Grid>
      

    </Grid>
    }
  </ViewInfo>
 )
}