import React, {useState} from 'react';
import styled from 'styled-components'

import { get } from 'lodash'

import { CommonFlex } from '../../utils/common';

import { formatDisplay, currencyFormat } from '../../utils/util'
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import Search from '@material-ui/icons/Search'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { IconButton, LinearProgress } from '@material-ui/core';

import Dialog from '../../utils/dialog'

import Invoice from '../orders/Invoice';

import axios from '../../axios';

const Container = styled.div`
  /* margin: 1.5rem 0; */
`

const Header = styled(CommonFlex)`
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.headerBg};

  span {
    flex-grow: 1;
    flex-basis: 100%;

    &.flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 20rem;

      span.text {
        width: max-content;
        margin-right: 1.5rem;
      }
    }
  }
  
`
const Main = styled(CommonFlex)`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 3px;
  margin: 3px 0;

  div {
    flex-grow: 1;
    flex-basis: 100%;


    .number {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
`
const Info = styled(CommonFlex)`
  display: flex;
  /* min-width: 20rem; */
  flex-direction: row;
  align-items: center;

  span {
    /* width: 50%; */
  }
`
const Detail = styled.div`
  padding: 1.5rem;
  padding-top: .3rem;
  display: ${props => props.open ? "block" : "none"};
  background-color: ${props => props.theme.colors.headerBg};
`

const DetailHeader = styled.h3`
  font-size: 1.1rem;
  text-transform: uppercase;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.lightText};
`

const DetailRow = styled(CommonFlex)`
  span {
    flex-grow: 1;
    flex-basis: 100%;
  }
`

const DetailRowHeader = styled(DetailRow)`
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding-bottom: .5rem;
  margin-bottom: 1.5rem;
`

const Footer = styled(CommonFlex)`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.headerBg};
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;

  span {
    width: calc(100% / (${props => props.headers.length}))
  }
`

const CollapsibleTable = ({headers, rows = [], detailHeaders, noView = false}) => {

  const [expandRow, setExpandRow] = useState({})

  const [invoice, setInvoice] = useState({})
  const [loading, setLoading] = useState(false)

  const loadInvoice = async (order) => {
    console.log(order, "order")
    if (order.orderId) {
      return;
    }
    setLoading(true)
    try {
      const { data } = await axios.get(`/orders/${order.saleId}`)
      console.log(data, "invoice", order.saleId)
      setInvoice(data)
    } catch (e) {
      
    } finally {
      setLoading(false)
    }
  }

  const toggleExpand = (id, bracket) => {
    setExpandRow(x => {
      let expands = {...x}
      if (x[id]?.open) {
        // if this row is already expanded check if bracket is different
        // else close row
        if (x[id].bracket === bracket) {
          expands[id] = {
            open: false,
            bracket: ''
          }
        } else {
          expands[id].bracket = bracket
        }
      } else {
        // if this row is not expanded yet, expand.
        expands[id] = {
          open: true,
          bracket
        }
      }

      return expands
    })
  }

  

  return (
  <Container>

    <Header pad="1.5rem" headers={headers}>
      {headers.map(el => el.labelComponent ? el.labelComponent : <span key={el.label}>{el.label}</span>)}
    </Header>


    {rows.map(row => {
    let field = headers.find(el => el?.expandLabel === expandRow[row._id]?.bracket)

    return (
      <>

        <Main pad="1.5rem" padY="2rem" key={row._id} headers={headers}>
          {headers.map((cur, i) => 
            <Info key={cur.id} justify="flex-start" expand={cur.expand}>
              {get(row, cur.id) && cur.expand ? <span><IconButton onClick={() => toggleExpand(row._id, cur.expandLabel)} size='small'>
                {expandRow[row._id]?.open && expandRow[row._id]?.bracket === cur.expandLabel ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
                </IconButton></span> : null}
              <span className={cur.currency ? "number" : ""}>{formatDisplay(cur, row)}</span>
            </Info>
          )}
        </Main>

        <Detail open={expandRow[row._id]?.open}> 
          <DetailHeader>{field?.subLabel ? field.subLabel : `${field?.label} Due`} </DetailHeader>
            <DetailRowHeader pad="2rem" padY="1rem" headers={detailHeaders}>
            {detailHeaders.map(el => <span key={el.label}>{el.label}</span>)}
           {!noView ? <span>View</span> : null }

            </DetailRowHeader>
            {/* {console.log(row, expandRow[row._id]?.bracket, "orders")} */}
            {row[expandRow[row._id]?.bracket]?.docs.map(elem => (
              <DetailRow pad="2rem" padY="1rem" headers={detailHeaders}>
                {detailHeaders.map((cur, i) =>
                  <span>{formatDisplay(cur, elem)}</span>
                )}
               {!noView ? <span>
                  <Dialog
                    toggle={<IconButton onClick={() => loadInvoice(elem)}><Search color="secondary" /></IconButton>}
                    title={`Invoice #${elem.orderId ? elem.orderId : invoice.orderId}`}
                    cancel="Close"
                    size='lg'
                    >
                      {loading ? <LinearProgress /> : 
                      <Invoice order={elem.orderId ? elem : invoice} />
                      }
                    </Dialog>
                </span> : null }
              </DetailRow>
            ))}
        </Detail>

      </>
    )})}
                    

    <Footer pad="1.5rem" padY="2rem" headers={headers}>
      {headers.map((cur, i) => {
        rows.reduce((acc, curr) => {
         return  acc + (curr[cur.id]?.total || curr.total)
        } ,0);
        return (
          <span key={cur.id}>
            {cur.currency ? currencyFormat(rows.reduce((acc, curr) => acc + get(curr, cur.id) ,0)) : i === 0 ? "Grand Total" : ""}
            {/* {cur.currency && cur.total && <span>{c}</span>} */}
          </span>
        )}
      )} 
    </Footer>

  </Container>
  )
}

export default CollapsibleTable;