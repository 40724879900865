import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'

import axios from '../../../axios';

import { Button, Grid, Checkbox, FormControlLabel, CircularProgress, TextField, Input, InputAdornment } from '@material-ui/core';

import Add from '@material-ui/icons/Add';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import { Flex, SectionHeader, SectionHeaderRow, FullDivider } from '../../../utils/common';

import { Chip, getChipInfo } from './common';

import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import { set, capitalize, debounce } from 'lodash';

import { Header } from '../../../utils/common';

const WigPickerSelect = styled.div`
  margin-left: 1.8rem;
`

const WigPicker = styled.div`
  min-height: 6rem;
  max-height: 35rem;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #0000001f;
  /* padding: 3.5rem 0; */
  border-radius: 5px;
  margin-top: 1.5rem;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ccc;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #777;
  }

  .circular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
  }
`
const WigLine = styled(Flex)`
  border-bottom: 1px solid #0000001f;
  /* border-top: ${props => !props.first ? "none": "1px solid #0000001f"}; */
  border-top: none;
  display: flex;
  justify-content: flex-start;
  background-color: ${props => props.checked ? "#edf7ed" : "transparent"};
  /* padding: .3rem; */
`

const WigInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: .5rem; */
  font-weight: 500;
  min-width: 12rem;
`;

const WigInfoItemHeader = styled(Header)`
  font-size: 1rem;
`;

const WigInfo = styled.div`
  /* width: ${props => props.width ? props.width : "auto"}; */
`

const NoWigs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  color: ${p => p.theme.colors.lightText};
`

const WigPickerComponent = ({ transferWigs, setTransferWigs, id, transferType }) => {
  let searchBy;

  switch (transferType) {
    case "sthq":
      searchBy = "salon"
      break;
    case "rtr":
    case "rthq":
      searchBy = "vendor"
      break;
    default:
      searchBy = ""
      break;
  }

  const [loading, setLoading] = useState(false)

  const [transferWigObj, setTransferWigObj] = useState({})

  const [wigs, setWigs] = useState([
    // {wigId: "MK2765", status: "sold", sku: "7/1B-18", currentlyAt: "Libby's Salon"}, {wigId: "MK2764", status: "consignment", sku: "7/1B-18", currentlyAt: "Libby's Salon"}, {wigId: "MK2768", status: "stock", sku: "7/1B-18", currentlyAt: "Libby's Salon"}
  ]);

  const setWigPicker = (data) => {
    let wigs = data.map(el => {
      return { ...el, base: el.baseMaterial, currentlyAt: el.currentLocation?.entity === "hq" ? "HQ" : el.currentLocation?.name || el.currentlyAt?.name || ""}
    })
    setWigs(wigs)
  }

  
  const searchWigs = useCallback(debounce(async (val, e) => {
    if (e) {
      const { data } = await axios.get(`/inventory/location/${id}?term=${encodeURIComponent(val)}&location=${searchBy}&brand=all`);
      setWigPicker(data);
    }
  }, 500), [id, searchBy])

  const loadWigs = async () => {
    if (!searchBy) return;
    setLoading(true);
    console.log(id, "id")
    try {
      const { data } = await axios.get(`/inventory/location/${id}?brand=all&location=${searchBy}`);
      setLoading(false);
      setWigPicker(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const addWig = (wig) => {
    console.log(wig, "wig")
    let finalWigs = transferWigs.length ? transferWigs.concat(wig) : [wig]
    setTransferWigs(finalWigs)
  }

  const handleCheckedTransferWigs = () => {
    let wigsObj = {}
    transferWigs.forEach(el => {
      wigsObj[el.wigId] = el
    })
    setTransferWigObj(wigsObj)
  }
 
  useEffect(() => {
    handleCheckedTransferWigs()
  }, [transferWigs])

  useEffect(() => {
    setWigs([])
  }, [transferType])

  useEffect(() => {
    console.log(id, searchBy, "id and searchBy")
    if (id && searchBy) {
      console.log("loading wigs")
      loadWigs()
    } else if (searchBy && !id) {
      setWigs([])
    }
  }, [id])

  return (
    <>
    <Grid xs={6} item>
      <TextField variant='outlined' label="Search by Wig ID" size="small" fullWidth InputProps={{endAdornment: <InputAdornment><SearchRoundedIcon color="primary" /></InputAdornment>}} onChange={(e) => searchWigs(e.target.value, e)} />
    </Grid>
    <Grid xs={12} item className="order-total">
    
      <WigPicker>

        {loading ? 
        <div className='circular'>
          <CircularProgress />
        </div> :
        <>

        { wigs.map((el, i) => 
        <WigLine key={el.wigId} first={i === 0} dir="row" justify="space-between" checked={transferWigObj[el.wigId]} align="center" padY="1rem">
        <WigInfo>
          <FormControlLabel
            control={<Checkbox 
            onChange={(e) => {addWig(el)}} 
            checked={transferWigObj[el.wigId] ? true : false}
            disabled={transferWigObj[el.wigId] ? true : false}
            name={`check`} size="small" color="primary"
            checkedIcon={<DoneAllRoundedIcon style={{color: "#4caf50"}} />}
             />
          }
          />
        </WigInfo>
        <WigInfoItem>
          <WigInfoItemHeader>wig id</WigInfoItemHeader>
          <WigInfo>{el.wigId}</WigInfo>
        </WigInfoItem>
        <WigInfoItem>
          <WigInfoItemHeader>sku</WigInfoItemHeader>
          <WigInfo>{el.sku}</WigInfo>
        </WigInfoItem>
        <WigInfoItem>
          <WigInfoItemHeader>status</WigInfoItemHeader>
          <span><Chip fontSize='small' level={getChipInfo(el.status)}>{capitalize(el.status)}</Chip></span>
        </WigInfoItem>
        <WigInfoItem>
          <WigInfoItemHeader>Location</WigInfoItemHeader>
          <WigInfo>{el.currentlyAt || "HQ"}</WigInfo>
        </WigInfoItem>
      </WigLine>
        )}

        {!wigs.length && <NoWigs>No wigs found</NoWigs>}

      </> }
        
      </WigPicker>

  </Grid>
 
  </> 
  )
}

export default WigPickerComponent;