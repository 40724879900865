import React from 'react'

import FormDialog from '../../utils/dialog';

import { TextField, CheckboxWithLabel, RadioGroup } from 'formik-material-ui';
import { Field } from 'formik';


import Radio from '@material-ui/core/Radio';
import Add from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '../../utils/autoComplete';
import { Grid, Button, FormLabel, FormHelperText } from '@material-ui/core'


const Charges = ({openDialog, toggleDialog, chargeSave, values, bag, chargeType = "surcharge"}) => {
  return (
  <FormDialog forceOpen={openDialog} forceClose={toggleDialog} toggle={
    <Button
    variant="outlined"
    color="secondary"
    startIcon={<Add />}
  >
    Add {chargeType === "surcharge" ? "Charges" : "Discounts"} 
  </Button>
  } 
  title={chargeType === "surcharge" ? "Add Charge" : "Add Discount"}
  onSave={() => chargeSave(values, bag.setFieldTouched, bag.setErrors, chargeType)}
  save="Save"
  >
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={5}>
      <Field
        component={TextField}
        type="text"
        label="Description"
        name="description"
        variant="outlined"
        fullWidth
      />
      </Grid>

      <Grid item xs={3}>
      <Field
        component={TextField}
        type="number"
        label="Amount"
        name="amount"
        variant="outlined"
        fullWidth
      />
      </Grid>

      {/* <Grid item xs={1}>

      </Grid> */}

      {/* <Grid item xs={3}> */}
      {/* <Field component={RadioGroup} name="chargeType">
        <FormControlLabel
          value="surcharge"
          control={<Radio />}
          label="Surcharge"
        />
        <FormControlLabel
          value="discount"
          control={<Radio />}
          label="Discount"
        />
      </Field> */}
      {/* </Grid> */}

      {/* <Grid item xs={4}>
        {chargeType === "surcharge" ? <Field
          component={CheckboxWithLabel}
          type="checkbox"
          name="pw"
          Label={{ label: 'Divide Charge Per Wig' }}
        /> : null }
      </Grid> */}

        <Grid item xs={2}>
        <FormLabel component="legend" style={{paddingBottom: ".5rem"}}>Rate Type</FormLabel>
        <Field component={RadioGroup} name="rateType">
        <FormControlLabel
          value="fixed"
          control={<Radio />}
          label="Fixed"
        />
        <FormControlLabel
          value="percentage"
          control={<Radio />}
          label="Percentage"
        />
        </Field>

        </Grid>

        <Grid item xs={2}></Grid>

        <Grid item xs={6}>
        <Autocomplete
        list={values.wigs} name="wigId" field="wigId" label="Select Wig ID (Optional)"
        getOptionLabel={({wigId}) => wigId || ""} 
        getOptionSelected={({wigId}, val) => wigId === val.wigId}
        renderOption={(wig) => <span>{wig.wigId}</span>}
        setter={bag.setFieldValue}
          />
        <FormHelperText>Attaching Charge to Wig will increase Wig Total</FormHelperText>
        </Grid>

        <Grid item xs={6}>
          {chargeType === "discount" ? <><Field
          component={CheckboxWithLabel}
          type="checkbox"
          name="pw"
          Label={{ label: 'Apply To Each Item' }}
        />
          <FormHelperText>Only if Rate-Type of Fixed is Selected</FormHelperText>
        </> : null }
        </Grid>

      </Grid>
    </FormDialog>
  )
}

export default Charges;