import React, {useState, useEffect} from 'react'
import {Router, Route, Switch, Redirect} from "react-router-dom";
import history from './history';

import {useAuthContext} from './auth';
import axios from './axios';
import { getReturnUrl, getRedirectUrl } from './utils/util';

import { ThemeProvider as MuiThemeProvider, 
  CssBaseline, LinearProgress} from "@material-ui/core";

import { ThemeProvider } from "styled-components";
import { theme } from "./utils/theme";

import ProtectedRoute from './utils/ProtectedRoute';
import Snackbar from './utils/snackbar';

import Login from './components/login/Login';
import ChangePass from './components/users/ChangePass';
import Layout from './components/layout/Layout';
import PageNotFound from './utils/PageNotFound';
import Users from './components/users/User';
import Roles from './components/roles/Role';
import Customers from './components/customers/Customers';
import Vendors from './components/vendors/vendors/Vendors';
import Brands from './components/brands/Brands';
import Inventory from './components/inventory/Inventory';
import Shipments from './components/shipments/Shipment';
import Consignments from './components/consignments/Consignment';
import Orders from './components/orders/Order';
import Transfers from './components/repairs/transfers/Transfers';
import Repairs from './components/repairs/repairs/Repairs';
import Services from './components/repairs/services/Services';
import Payments from './components/payments/Payment';
import Track from './components/track/Tracking';
import Account from './components/accounting/Account';
import Expenses from './components/expenses/Expense';
import GeneralReports from './components/generalReports/GeneralReports';
import Dashboard from './components/dashboard/dashboard';
import Setup from './components/setup/SystemSetup';

function App() {

  const { login, isLoggedIn, hasPermission } = useAuthContext()
  const [loading, setLoading] = useState(true)

  const getLoggedInUser = async () => {
    try {
      const { data } = await axios.get('/users/loggedin')
      login(data)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getLoggedInUser()
  }, [])

  const LoggedOutRoutes = () => (
    <Switch>
      <Route path="/p/user/:id/setup/:slug" component={ChangePass} />
      <Route path="/login">
        <Login />
      </Route>
      <Redirect 
        to={{
          pathname: '/login',
          search: getReturnUrl()
        }}
      />
    </Switch>
  )

  const LoggedInRoutes = () => (
    <Layout>
      <Switch>
        <ProtectedRoute path="/users" guard='readUser' component={Users} />
        <ProtectedRoute path="/roles" guard='readRole' component={Roles} />
        
        <ProtectedRoute path="/customers" guard='readCustomer' component={Customers} />
        <ProtectedRoute path="/vendors" guard='readVendor' component={Vendors} />
        <ProtectedRoute path="/brands" guard='readBrand' component={Brands} />
        <ProtectedRoute path="/inventory" guard='readInventory' component={Inventory} />
        <ProtectedRoute path="/shipments" guard='readShipment' component={Shipments} />
        <ProtectedRoute path="/consignments" guard='readConsignment' component={Consignments} />
        <ProtectedRoute path="/orders" guard='readOrder' component={Orders} />
        <ProtectedRoute path="/payments" guard='readPayment' component={Payments} />
        <ProtectedRoute path="/repair_transfers" guard='readTransfer' component={Transfers} />
        <ProtectedRoute path="/repairs" guard='readRepair' component={Repairs} />
        <ProtectedRoute path="/services" guard='readService' component={Services} />
        <ProtectedRoute path="/expenses" guard='writeAccounting' component={Expenses} />
        <ProtectedRoute path="/track" guard='readInventory' component={Track} />
        <ProtectedRoute path="/accounting" guard='readAccounting' component={Account} />
        <ProtectedRoute path="/reports" guard='readCustomer' component={GeneralReports} />
        <ProtectedRoute path="/dashboard" guard='readDashboard' component={Dashboard} />
        <ProtectedRoute path="/setup" guard='readSettings' component={Setup} />
        
        <Redirect from="/login" to={getRedirectUrl()} />
        {hasPermission("readDashboard") ? 
        <Redirect exact from="/" to="/dashboard" /> 
        : <Redirect exact from="/" to="/track" /> }
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Layout>
  )

  return (
    <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Snackbar>
      {loading ? 
        <LinearProgress /> :
        <Router history={history}>
          {!isLoggedIn ? <LoggedOutRoutes /> : <LoggedInRoutes />}
        </Router>
      }
      </Snackbar>
    </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
