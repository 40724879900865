import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import ViewInfo from '../../../utils/viewInfo';
import axios from '../../../axios';
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import { DateTime } from 'luxon';

import { useSBContext } from '../../../utils/snackbar';


// timeline
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import { currencyFormat, formatPhone, formatCalendar, capitalizeWords } from '../../../utils/util';

import { FullDivider, SectionHeader } from '../../../utils/common';

import { theme } from '../../../utils/theme';

import SimpleTable from '../../../utils/simpleTable';

import { Switch, FormControlLabel, FormHelperText } from '@material-ui/core';

import getChip from './Common';

import StatCard from '../../../utils/StatCard';

const Info = styled.span`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  align-items: center;

  .email {
    font-style: italic;
  }
`

const StatusInfo = styled.div`
  margin-top: 1rem;
`;

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primaryMain};
  font-weight: 500;
`

const RepairName = styled.div`
  /* color: ${props => props.color || "inherit"}; */
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: .6rem;
`;

const ColoredDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: ${props => props.color || "inherit"};
`;

const NotesLabel = styled.div`
  font-weight: 500;
  margin-top: 1.8rem;
  /* color: ${props => props.theme.palette.grey[600]}; */
  /* font-style: italic; */
  font-size: 1.2rem;
`;

const Notes = styled.div`
  font-style: italic;
  background-color: ${props => props.theme.palette.grey[100]};
  padding: .5rem 1rem;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-radius: 3px;
  min-height: 80px;
  margin-top: .8rem;
`;

const SwitchContainer = styled.div`
  margin-top: 2rem;
`;

const MainTimeline = styled(Timeline)`
  padding: 0;
  margin: 0;
  padding-left: .5rem;
`;

const TimelineListItem = styled(TimelineItem)`
  &::before {
    display: none;
  }
  /* margin-bottom: 1rem; */
`;

const Connector = styled(TimelineConnector)`
  height: 5rem;
  background-color: ${props => props.theme.colors.primaryLight};
`;

const TimelineBox = styled(TimelineDot)`
  border-radius: 8px;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
`;

const Log = styled.span`
 display: block;
 font-size: 1.4rem;
 /* margin: .2rem 0; */
 font-weight: 500;
`;

const TimeLog = styled.span`
  display: block;
  font-size: 1.2rem;
  font-style: italic;
`;

 const AgentLog = styled.span`
  display: block;
  font-size: 1.2rem;
  font-style: italic;
`;
 
const ViewRepair = (props) => {

  const [loading, setLoading] = useState(false)

  const [info, setInfo] = useState({});

  const {id} = props.match.params;

  // console.log(id);
 
  const crumbs = [{path: '/repairs/', text: 'Repairs'}, 
  {path: '/repairs/view', text: 'View'}];

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/repairs/${id}?populated=repair.id`);
    setInfo(data);
    setLoading(false)
  }

  const { handleClick } = useSBContext();

  const changeStatus = async (status) => {
    try {
      const {data} = await axios.put(`/repairs/${id}/status`, {status});
      setInfo(data);
      handleClick('success', 'Status updated successfully');
    } catch (e) {
      handleClick('error', 'Error updating status');
    }
  }


  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/repairs');
  }, [])

 return (
  <ViewInfo title="View Repair Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Grid container spacing={3}>

      <Grid item xs={5}>

        <Card variant="outlined">
          <CardHeader title="General Details"
          />
          <CardContent>
            <Info><InfoLabel>Ticket #:</InfoLabel>{info.repairId}</Info>
            <Info><InfoLabel>Wig Id:</InfoLabel>{info.wig?.wigId}</Info>
            <Info><InfoLabel>Assigned To:</InfoLabel>{info.current_location?.name}</Info>
            {info.transferId && <Info><InfoLabel>Repair Transfer #:</InfoLabel><StyledLink to={`/repair_transfers/${info.transferId._id}`} target="_blank">{info.transferId?.transferId}</StyledLink></Info>}
            <Info><InfoLabel>Service Type:</InfoLabel><RepairName><ColoredDot color={info?.repair?.id?.color}></ColoredDot>{capitalizeWords(info.repair?.name)}</RepairName></Info>
            <Info><InfoLabel>Price:</InfoLabel>{currencyFormat(info.repair?.price)}</Info>
            <Info><InfoLabel>Date Assigned:</InfoLabel>{formatCalendar(info.transferId?.date)}</Info>
            <Info><InfoLabel>Status Updated:</InfoLabel>{formatCalendar(info.status_updated)}</Info>
           
            <FullDivider y="1.5rem" />
            {/* <Info><InfoLabel>Notes:</InfoLabel>{info.notes}</Info> */}
            <NotesLabel>Notes</NotesLabel>
            <Notes>{info.notes}</Notes>
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={3}>

        <Card variant='outlined'>
          <CardHeader title="Repair Details" />

          <CardContent>
            <StatCard title="Assigned To" icon={<AssignmentIndRoundedIcon />} stat={info.current_location?.name} bg={theme.colors.iconBg4} color={theme.colors.icon4L} />
            <FullDivider y="2rem" />
            <SectionHeader>Repair Status</SectionHeader>
            <StatusInfo>
              {getChip(info.repair?.status, "large")}
              <SwitchContainer>
                <FormControlLabel
                  control={
                    <Switch
                      checked={info.repair?.status === 'complete' ? true : false}
                      onChange={(e) => changeStatus(e.target.checked ? 'complete' : 'in-progress')}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  // label={info.repair?.status !== 'complete' ? "Mark Repair Complete" : "Mark Repair In Progress"}
                  label="Repair Complete"
                />
                <FormHelperText>update the status of this repair</FormHelperText>
              </SwitchContainer>
            </StatusInfo>
            
          </CardContent>

        </Card>

      </Grid>

      <Grid item xs={4}>

        <Card variant='outlined'>
          <CardHeader title="Repair History" />

          <CardContent>

          <MainTimeline>
            <TimelineListItem>
              <TimelineSeparator>
                <TimelineBox>
                  <AssignmentRoundedIcon htmlColor={theme.colors.primaryMain} fontSize='large' />
                </TimelineBox>
               {info.repair?.status !== 'in-progress' && <Connector />}
              </TimelineSeparator>
              <TimelineContent>
                <TimeLog>{DateTime.fromISO(info.date).toFormat('cccc, MMMM dd, yyyy, h:mm a')}</TimeLog>
                <Log>Assigned To {info.current_location?.name}</Log>
                <AgentLog>By {info.createdBy?.name}</AgentLog>
              </TimelineContent>
            </TimelineListItem>
            {info.repair?.status !== 'in-progress' && <TimelineListItem>
              <TimelineSeparator>
              <TimelineBox>
                <AssignmentRoundedIcon htmlColor={theme.colors.primaryMain} fontSize='large' />
              </TimelineBox>
              </TimelineSeparator>
              <TimelineContent> 
                <TimeLog>{DateTime.fromISO(info.status_updated).toFormat('cccc, MMMM dd, yyyy, h:mm a')}</TimeLog>
                <Log>Status marked Complete</Log>
                <AgentLog>By {info.updatedBy?.name}</AgentLog>
              </TimelineContent>
            </TimelineListItem>}
          </MainTimeline>
           
          </CardContent>
            
        </Card>

      </Grid>
      
    </Grid>
    }
  </ViewInfo>
 )
}

export default ViewRepair;