import React, {useState, useEffect} from 'react';

import {Formik, Form, Field} from 'formik';
import { TextField } from 'formik-material-ui'

import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';
import * as Yup from 'yup'

import PrintIcon from '@material-ui/icons/Print';
import displayPdf from '../../utils/displayPdf';
import { theme } from '../../utils/theme';

// icons
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';

import { useSBContext } from '../../utils/snackbar';

import SimpleTable from '../../utils/simpleTable';

import { DateTime } from 'luxon';

import { handleErrors, currencyFormat } from '../../utils/util';

import { Flex } from '../../utils/common';

// import Accordion from './AccordionWigs';
// import SimpleTable from '../../utils/simpleTable';

const Info = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .email {
    font-style: italic;
  }
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const StatBox = styled(Flex)`

`

const InfoHeader = styled.h3`
  font-size: 1.6rem;
  color: ${props => props.theme.colors.lightText};
  font-weight: 500;
  margin-bottom: 1rem;
`

const IconBox = styled(Flex)`
  /* border: 1px solid #ccc; */
  border-radius: 3px;
  background-color: ${props => props.bg};
`

const StatLine = styled(Flex)`
  font-size: 1.4rem;
`

const StatHeader = styled.span`
  display: block;
  /* font-size: 1.3rem; */
  margin-bottom: .3rem;
  color: ${props => props.theme.colors.lightText};
`

const Stat = styled.span`
  display: block;
  font-weight: 500;
`

const Liner = styled.div`
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 500;
  /* color: ${props => props.theme.colors.lightText}; */
  /* margin: 1rem 0; */
  border: 1px solid #e9e9e9;
  border-left: 3px solid ${props => props.theme.colors.iconBg4};
  padding: .8rem 1.5rem;
  margin: 1.3rem 0;
  border-radius: 3px;

 .num {
  padding-left: .5rem;
  color: black;
  font-size: 1.3rem;
 }
`

const inventoryHeaders = [
  { id: 'wigId', label: 'ID' },
  { id: 'line', label: 'Line' },
  { id: 'sku', label: 'SKU' },
  { id: 'brand', label: 'Brand' },
  { id: 'status', label: 'Status' },
  { id: 'baseMaterial', label: 'Material' },
  { id: 'currentlyAt.name', additional: true, label: 'Currently At' },
  { id: 'cost.subtotal', currency: true, additional: true, label: 'Cost Subtotal' },
  { id: 'cost.total', currency: true, additional: true, label: 'Cost Total' },
  // { id: 'sale.subtotal', currency: true, additional: true, label: 'Price'}
];

 
export default (props) => {

  const [loading, setLoading] = useState(false);

  const [info, setInfo] = useState({});

  const [detailed, setDetailed] = useState(false)
  
  const [summary, setSummary] = useState({})

  const [printLoad, setPrintLoad] = useState(false)

  const {id} = props.match.params;

  // console.log(id);
 
  const crumbs = [{path: '/shipments/', text: 'Shipments'}, 
  {path: `/shipments/view/${id}`, text: 'View'}, {path: `/shipments/view/${id}`, text: `${info?.shipmentNum || ""}`}];

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/shipments/${id}`);
    setInfo(data.shipment);
    console.log(data.summary, "DFJLSJFDLKSJLDJLK");
    setSummary(data.summary)
    setLoading(false)
  }


  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/shipments');
  }, []);

  const EditSchema = Yup.object().shape({
    styles: Yup.array()
    .of(
      Yup.object().shape({
        color: Yup.string()
        .required('Style is required'),
        qty: Yup.number(),
        price: Yup.number()
      })
    )
    });

  const EditSchemaSizes = Yup.object().shape({
    sizes: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.number()
        .required('Size is required'),
        cost: Yup.number(),
        price: Yup.number()
      })
    )
    });


  const {handleClick} = useSBContext();

  const onSubmitStyles = async (userInfo={}, { setSubmitting, setErrors, errors}) => {

    userInfo = {...info, items: [...userInfo.styles]}

    // console.log(userInfo);
    try {
      await axios.put(`/shipments/${id}`, {...userInfo})
      setSubmitting(false)
      handleClick('success', 'Styles successfully updated')
      loadData()
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

  const onSubmitSizes = async (userInfo={}, { setSubmitting, setErrors, errors}) => {

    userInfo = {...info, sizes: [...userInfo.sizes]}

    // console.log(userInfo);
    // try {
    //   await axios.put(`/shipments/${id}`, {...userInfo})
    //   setSubmitting(false)
    //   handleClick('success', 'Sizes successfully updated')
    //   loadData()
    // } catch (e) {
    //   setSubmitting(false);
    //   const displayMessage = handleErrors({e, setErrors})
    //   if (displayMessage)
    //     handleClick('error', displayMessage)
    // }
  }

  const printBarcodes = async () => {
    setPrintLoad(true)
    let includeAll = detailed ? `includeAll=true` : `includeAll=`
    const {data} = await axios({
      method: "POST",
      url: `/shipments/barcodes/${id}?${includeAll}`,
      responseType: 'blob',
      timeout: 60000,
      headers: {"Content-Type": "application/pdf"}
    });
    setPrintLoad(false)
    displayPdf(data, `Barcodes`)
  }
  
  let stock = summary?.summary?.find(el => el._id === "stock")
  let consignment = summary?.summary?.find(el => el._id === "consignment")
  let sold = summary?.summary?.find(el => el._id === "sold")

  let daysToMonths = (sold?.avgDays / 30).toFixed(1)

 return (
  <ViewInfo title="View Shipment Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Grid container spacing={3}>

      <Grid item xs={4}>

        <Card variant="outlined">
          <CardHeader title="Shipment Summary" subheader={DateTime.fromISO(info.date).toLocaleString()}
           />
          <CardContent>
            <Info><InfoLabel>Shipment Number:</InfoLabel>{info.shipmentNum}</Info>
            <Info><InfoLabel>Brand:</InfoLabel>{info.brand}</Info>
            <Info><InfoLabel>Number of Wigs:</InfoLabel>{info.totalQty}</Info>
            <Info><InfoLabel>Starting Wig ID:</InfoLabel>{info.startNum}</Info>
            <Info><InfoLabel>Last Wig ID:</InfoLabel>{info.endNum}</Info>
            <Info><InfoLabel>Date Entered:</InfoLabel>{DateTime.fromISO(info.dateUploaded).toLocaleString()}</Info>
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={4}>

        <Card variant="outlined">
          <CardHeader title="Additional Details" />
          <CardContent>
            <Info><InfoLabel>Subtotal:</InfoLabel>{currencyFormat(info.cost?.subtotal)}</Info>
            <Info><InfoLabel>Shipping Cost:</InfoLabel>{currencyFormat(info.cost?.shipping)}</Info>
            <Info><InfoLabel>Shipping Cost Per Item:</InfoLabel>{currencyFormat(info.cost?.spi)}</Info>
            <Info><InfoLabel>Total:</InfoLabel>{currencyFormat(info.cost?.total)}</Info>
            <Divider style={{margin: '25px 0 10px 0'}} />
            <Info><InfoLabel>Notes:</InfoLabel>{info.notes}</Info>
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={4}>
        <Card variant='outlined'>
          <CardHeader title="Download Barcodes" action={
            <Tooltip title="Print">
            <IconButton onClick={printBarcodes}>
            {printLoad ? 
              <CircularProgress size={30} /> :
              <PrintIcon color="primary" /> 
           }
            </IconButton>
            </Tooltip>
          } />
          <CardContent>
          <FormControlLabel
              control={<Checkbox 
              onChange={(e) => setDetailed(e.target.checked)} color="primary" 
              checked={detailed}
              />
            }
              label="Include Style, Length and Cap Size"
            /> <br /><br />
            Download a PDF with barcodes for all the wigs on this 
            Shipment.
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={10}>

        <Card variant='outlined'>
          <CardHeader title="Inventory & Sales" />
          <CardContent>

         <InfoHeader>Inventory</InfoHeader>
         { stock ? <StatBox dir="row" justify="flex-start" pad="0">
              <IconBox width="4.5rem" height="4.5rem" bg={theme.colors.iconBg2}><HomeRoundedIcon htmlColor={theme.colors.icon2} /></IconBox>
              <StatLine align="flex-start">
                <StatHeader>Number of Wigs in Stock</StatHeader>
                <Stat>{stock?.count} - {currencyFormat(stock?.cost)}</Stat>
              </StatLine>
            </StatBox> : null }

            { consignment ? <StatBox dir="row" justify="flex-start" pad="0">
              <IconBox width="4.5rem" height="4.5rem" bg={theme.colors.iconBg1}><CardGiftcardRoundedIcon htmlColor={theme.colors.icon1} /></IconBox>
              <StatLine align="flex-start">
                <StatHeader>Number of Wigs in Consignment</StatHeader>
                <Stat>{consignment?.count} - {currencyFormat(consignment?.cost)}</Stat>
              </StatLine>
            </StatBox> : null }

            {sold ? <><InfoHeader>Sales & Profit</InfoHeader>

            <StatBox dir="row" justify="flex-start" pad="0">
              <IconBox width="4.5rem" height="4.5rem" bg={theme.colors.iconBg4}><AttachMoneyRoundedIcon htmlColor={theme.colors.icon4} /></IconBox>
              <StatLine align="flex-start">
                <StatHeader>Number of Wigs Sold</StatHeader>
                <Stat>{sold?.count} - {currencyFormat(sold?.cost)}</Stat>
              </StatLine>
            </StatBox>

            <Liner>Price of Wigs Sold: <span className='num'>{currencyFormat(sold?.price)}</span></Liner>
            <Liner>Total Profit: <span className='num'>{currencyFormat(sold?.profit)}</span></Liner>
            <Liner>Avg Days to Sale: <span className='num'>{Math.ceil(sold?.avgDays)} Days {daysToMonths > 0 && `(${daysToMonths} Months)`}</span></Liner>
            
            </> : null}

          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={6}>
        <Card variant="outlined">
          <CardHeader title={`List of Styles - ${info.items?.length}`} />
          <CardContent>
          <Formik
            enableReinitialize
            initialValues={{
            styles: info.items || []
            }}
            onSubmit={onSubmitStyles}
            validationSchema={EditSchema}
          >
            {({ isSubmitting}) => (
              <Form>
                <Grid container spacing={3}>
                {info.items?.length ? info.items?.map(({color, qty}, i) => (
                  <Grid item container xs={12} key={color} spacing={3}>
                  <Grid item xs={8}>
                    <Field component={TextField} variant="outlined" label="Style" name={`styles.${i}.color`} fullWidth />
                  </Grid>

                  <Grid item xs={4}>
                    <Field component={TextField} variant="outlined" label="Qty" name={`styles.${i}.qty`} fullWidth disabled />
                  </Grid>

                  {/* <Grid item xs={3}>
                    <Field component={TextField} variant="outlined" label="Price" name={`styles.${i}.price`} fullWidth disabled />
                  </Grid> */}
                  </Grid>
                   )) : null
                  }

                  <Grid item xs={9}>

                  </Grid>

                <Grid xs={3} item >
                  
                  {isSubmitting ? <CircularProgress /> : 
                  <Button 
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  >Update</Button>
                  }

                </Grid>
                </Grid>
                
              </Form>
          )}

        </Formik>

          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card variant="outlined">
          <CardHeader title={`List of Sizes - ${info.sizes?.length}`} />
          <CardContent>
          <Formik
            enableReinitialize
            initialValues={{
            sizes: info.sizes || []
            }}
            onSubmit={onSubmitSizes}
            validationSchema={EditSchemaSizes}
          >
            {({ isSubmitting}) => (
              <Form>
                <Grid container spacing={3}>
                {info.sizes?.length ? info.sizes?.map(({size, cost, price}, i) => (
                  <Grid item container xs={12} key={size} spacing={3}>
                  <Grid item xs={6}>
                    <Field component={TextField} variant="outlined" label="Size" name={`sizes.${i}.size`} fullWidth disabled />
                  </Grid>

                  <Grid item xs={3}>
                    <Field component={TextField} variant="outlined" label="Cost" name={`sizes.${i}.cost`} fullWidth disabled />
                  </Grid>

                  <Grid item xs={3}>
                    <Field component={TextField} variant="outlined" label="Price" name={`sizes.${i}.price`} fullWidth />
                  </Grid>
                  </Grid>
                   )) : null
                  }

                  <Grid item xs={9}>

                  </Grid>

                <Grid xs={3} item >
                  
                  {isSubmitting ? <CircularProgress /> : 
                  <Button 
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  >Update</Button>
                  }

                </Grid>
                </Grid>
                
              </Form>
          )}

        </Formik>

          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title={`Wigs on Shipment ${info.shipmentNum}`}></CardHeader>
          <CardContent>
            <SimpleTable headers={inventoryHeaders} title="Inventory" rows={summary?.inventory} link="inventory" />
          </CardContent>
        </Card>
        
      </Grid>
      
    </Grid>
    }
  </ViewInfo>
 )
}