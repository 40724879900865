import React from 'react';
import styled, { css } from 'styled-components';


const levels =  {
    "info": {
      border: '#69c0ff',
      bg: '#e6f7ff',
      color: '#1890ff'
    },
    "success": {
      bg: "#d2e8ca",
      border: "#538e3e",
      color: "#2a471f"  
    },
    "warning": {
      border: '#69c0ff',
      bg: '#e6f7ff',
      color: '#1890ff'
    },
    "error": {
      border: '#d8a6a6',
      bg: '#e8caca',
      color: '#8e3e3e'
    },
    "icon2": {
      bg: "#cae8e1",
      border: "#3e8e7b",
      color: "#17352e"
    },
    "icon3": {
      bg: "#e7d9ca",
      border: "#8d663f",
      color: "#352618"
    },
    "icon4": {
      bg: "#d2e8ca",
      border: "#538e3e",
      color: "#1f3517"
    },
  }

 
const Container = styled.div`
 display: block;
 border: 1px solid ${props => levels[props.level].border};
 background-color: ${props => levels[props.level].bg};
 color: ${props => levels[props.level].color};
 padding: 0 .6rem;
 border-radius: 3px;
 font-size: ${props => props.fontSize === "large" ? "1.6rem" : "1.3rem"};

 ${props => props.size === "medium" ? css`
    padding: .3rem 1rem;
    font-size: 1.3rem;
 ` : ""  
  }

 ${props => props.size === "large" ? css`
    padding: .5rem 1.8rem;
    font-size: 1.3rem;
    text-transform: uppercase;
 ` : ""}
`
export default ({children, level="info", fontSize = "large", size = "small", colors, className}) => {
 
 return (
 <Container level={level} fontSize={fontSize} colors={colors} className={className} size={size}>
  {children}
 </Container>
 )
}