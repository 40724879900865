import React from "react";
import styled, { css } from "styled-components";

import { BasicFlex, Header } from "./common";

import { currencyFormat } from "./util";

const Card = styled.div`
  ${props => props.mx ? css`
    margin-right: ${props.mx};
  ` : css`
    margin-right: 3rem;
  `}

  ${props => props.my ? css`
    margin-bottom: ${props.my};
  ` : ""}
`;

const IconBox = styled.div`
  border-radius: 6px;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.size === "large" ? css`
    margin-right: 2rem;
    min-width: 5.5rem;
    min-height: 5.5rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  ` : css`
    min-width: 4rem;
    min-height: 4rem;
    margin-right: 1rem;

    svg {
      min-width: 2rem;
      min-height: 2rem;
    }
  `}
`;

const StatInfo = styled(BasicFlex)`
  & > span {
    display: block;
  }
`;

const Title = styled(Header)`  
`;

const Stat = styled.span`
  display: block;
  font-weight: 500;
`;

const StatCard = ({ title, stat, icon, currency = false, size = "small", bg, color, mx }) => {
  return (
    <Card mx={mx}>
      <BasicFlex>
        <IconBox bg={bg} color={color} size={size}>
          {icon}
        </IconBox>
        <StatInfo dir="column">
          <Title>{title}</Title>
          <Stat>{currency ? currencyFormat(stat) : stat}</Stat>
        </StatInfo>
      </BasicFlex>
    </Card>
  );
};

export default StatCard;