import React from 'react'
import styled from 'styled-components';
// import { TextField } from 'formik-material-ui';
import {Field} from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import { get } from 'lodash';

const Container = styled.div`
  .MuiAutocomplete-endAdornment {
    display: none;
  }
`
 
export default ({list, handleChange = () => {}, 
  setter, field, name, value, inputRef, addFunc,
label, variant="outlined", index, arrList, updates, err, blur, addRandom = false, ...props}) => {

  console.log(err, "err");

 return (
 <Container>
  <Autocomplete
    {...props}
    options={list}
    onInputChange={(_, val) => {handleChange(val, _)}}
    onChange={(e, obj, event) => {
      console.log({obj, event});

      const setValue = val => { setter(name, val)}
      if (event === 'select-option' || (event === 'blur' && blur)) {
        console.log({obj, field});

        if (updates && arrList) {
          let modObj = {[field]: obj[field]}
          updates.forEach(el => {
            modObj[el.name] = get(obj, el.field)
          });
          if (addRandom) {
            modObj.random = Math.random()
          }
          setter(`${arrList}.${index}`, modObj)
        } else {
          console.log("heyeyeyeyey");
          setValue(obj[field])
        }

        if (addFunc) { // any additional functions
          // console.log("heyeyeyey");
          addFunc(obj, setter)
        }
      }
      if (event === 'clear')
        setValue('')
    }}
    defaultValue={value || null}
    renderInput={(params) => 
    <TextField
      // component={TextField} 
      {...params} 
      error={err ? true : false}
      label={label} 
      variant={variant}
      inputRef={inputRef}
      // name={name}
      fullWidth
      helperText={err ? err : ''}
      />
  }
  />
 </Container>
 )
}