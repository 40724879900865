import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../../axios';
import {Grid, Paper, Button, InputAdornment, LinearProgress,
  CircularProgress, MenuItem, InputLabel, FormControl, FormHelperText, FormControlLabel} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, createNewOption } from '../../../utils/util';
import {useSBContext} from '../../../utils/snackbar';

import { debounce } from 'lodash'

import systemColors from '../../../utils/colors';

import EditForm from '../../../utils/editForm';

import AutoComplete from '../../../utils/simpleAutoComplete';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`

const ColorPicker = styled.input`
  width: 100%;
  height: 4rem;
`;
 
const AddService = (props) => {

  const {id} =  props.match.params;
 
  const [info, SetInfo] = useState({});

  const [loading, setLoading] = useState(false)

  const [categories, setCategories] = useState([])


  const loadData = async () => {
    try {
      setLoading(true)
      const {data} = await axios.get(`/services/${id}`);
      SetInfo(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

 

  useEffect(() => {
    if (id) 
      loadData()
  }, [])

  // console.log(info);

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Service successfully updated" : "Service successfully added";

  let crumbs = [{path: '/services/', text: 'Services'}];

  if (action === "edit")
    crumbs.push({path: `/services/view/${id}`, text: 'View'})

  crumbs.push({path: '/services/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
    name: Yup
      .string()
      .required('Expense Type is required')
  });

  const {handleClick} = useSBContext();

  const handleColor = (e, setFieldValue) => {
    setFieldValue("color", e.target.value)
  }

  const handleCategory = (e, setFieldValue) => {
    setFieldValue("category", e.target.value)
  }

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    console.log("getting here", userInfo);

    try {
      let query = !id ? axios.post('/services/', {...userInfo}) : axios.put(`/services/${id}`, {...userInfo});

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/services/`)
      // props.history.push(`/payments/view/${id || data._id}`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

  let newDate;

  useEffect(() => {
    newDate = new Date();
  })
 
 return (
 <EditForm title={action === "edit" ? "Edit Service" : "New Service"} header="Service" crumbs={crumbs}>
   {loading ? <LinearProgress /> :
   <Formik
    enableReinitialize
    initialValues={
      info.name ? {...info} : 
      {
        name: "",
        category: "",
        price: 0,
        duration: 1, // duration in days
        color: "#26a9e1",
        notes: ''
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, setFieldValue, setErrors, errors, touched, values}) => (
        <Form>
          {/* {console.log(errors, values, "checkem")} */}
          <Grid container spacing={4}>

            <Row item xs={12} md={6}>
              <Field
                component={TextField}
                type="text"
                label="Service Name"
                name="name"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12} md={6}>
              <AutoComplete
                list={categories} 
                label="Select Category (Optional)"
                handleSelect={(val) => { setFieldValue("category", val)}}
                autoHighlight
                blur
                freeSolo
                value={values.category}
              />
            </Row>

            <Row item xs={12} lg={6}>
              <Field
                component={TextField}
                type="number"
                label="Price"
                name="price"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12} lg={6}>
              <Field
                component={TextField}
                type="number"
                label="Estimated Time (In Days)"
                name="duration"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={3}>
              <ColorPicker type="color" value={values.color} name="color" list="presetColors" onChange={(e) => handleColor(e, setFieldValue)} />
              <datalist id="presetColors">
                {systemColors.map((color, index) => <option key={index} value={color} />)}
              </datalist>
              <FormHelperText>Select Color for Service</FormHelperText>
            </Row>


            <Row item xs={12}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              name="notes"
              label="Notes"
              />
            </Row>

            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
  }
 </EditForm>
 )
}

export default AddService;