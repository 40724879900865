import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../../axios';
import ProtectedRoute from '../../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import useLoadList from '../../../hooks/useLoadList';

import { theme } from '../../../utils/theme';

import Table from '../../../utils/table-server';
// import EditTransfer from './AddTransfer';
import ViewRepair from './ViewRepair';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Switch as Switcher, FormControlLabel} from '@material-ui/core'
import Search from '@material-ui/icons/Search';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';


import Filters from './Filters';

import Chip from '../../../utils/Chip';
import CustomChip from '../../../utils/CustomChip';

import { BasicFlex } from '../../../utils/common';

import getChip from './Common';

const SwitchContainer= styled(FormControlLabel)`
  width: 300px;
  margin-left: 30px;
`

const ChipContainer = styled.div`
  width: max-content;
`

const ChipFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
`;

const RepairName = styled.div`
  /* color: ${props => props.color || "inherit"}; */
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: .8rem;
`;

const ColoredDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background-color: ${props => props.color || "inherit"};
`;

const ChipBlock = ({level, fontSize, text, icon}) => {
  return (
    <ChipContainer>
      <CustomChip level={level} fontSize={fontSize} size="medium">
        <ChipFlex>
        {icon} <span>{text}</span>
        </ChipFlex>
      </CustomChip>
    </ChipContainer>
  )
}
 
const Repairs = (props) => {
  const {rows, loading, load, searchList, count, switcher, setSwitch, tabFilter, setTabFilter, query, handleQuery, reload} = useLoadList('/repairs');

  const [options, setOptions] = useState({repairTypes: []});
  const [loadingOptions, setLoadingOptions] = useState(false);

  const loadOptions = async () => {
    setLoadingOptions(true)
    try {
      const {data} = await axios.get('/repairs/options');
      setOptions(data);
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingOptions(false)
    }
  }

  useEffect(() => {
    loadOptions();
  }, [])
  

  const applyFilter = () => {
    reload();
  }

  const headers = [
    { id: 'repairId', label: 'Ticket' },
    { id: 'wig.wigId', label: 'Wig ID' },
    { id: "current_location.name", label: "Location" },
    { id: 'repair.name', render: row => {
      return <RepairName color={row.repair.id?.color}><ColoredDot color={row.repair.id?.color}></ColoredDot>{row.repair.name}</RepairName>
    }, label: 'Service Type' },
    { id: 'total', currency: true, label: 'Total'},
    { id: "repair.status", render: (row) => getChip(row.repair?.status), label: "Status"},
    // { id: "status_updated", date: true, label: "Status Updated"},
    { id: 'date', date: true, label: 'Date' }
  ];

  const path = props.match.url;

  const handleSwitch = (e) => { 
    setSwitch(e.target.checked);
  };

  const handleTabs = (filter) => {
    setTabFilter(filter)
  }

  const tabs = [
    {
      label: "All",
      title: "",
      active: tabFilter === "" ? true : false
    },
    {
      label: "In Progress",
      default: true,
      title: "in-progress",
      active: tabFilter === "in-progress" ? true : false
    },
    {
      label: "Complete",
      title: "complete",
      active: tabFilter === "complete" ? true : false
    },
    {
      label: "Cancelled",
      title: "cancelled",
      active: tabFilter === "cancelled" ? true : false
    }
  ]

 return (
  <Switch>
    {/* <ProtectedRoute path={`${path}/edit/:id`} guard='writeTransfer' component={EditTransfer} />
    <ProtectedRoute path={`${path}/edit`} guard='writeTransfer' component={EditTransfer} /> */}
    <ProtectedRoute path={`${path}/view/:id`} guard='readRepair' component={ViewRepair} />
    <Route path="/repairs">
      <Table loading={loading} tabs={tabs} tabHandler={handleTabs} load={load} title="Repairs" headers={headers} rows={rows} link="repairs" count={count} ord="desc" ordField="date" write={false} add={false}
      filterItems={<Filters query={query} handleQuery={handleQuery} options={options} applyFilter={applyFilter} />}
      search={setPage => <TextField name="search" label="Search by Ticket, Wig ID"
      variant="outlined" size="small" fullWidth onChange={(e) => searchList(e.target.value, setPage)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}

export default Repairs;