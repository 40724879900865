import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from '../../axios';


import { Card, CardContent, CardHeader, IconButton, MenuItem, Tooltip,  TextField, Button } from '@material-ui/core'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import RedeemRoundedIcon from '@material-ui/icons/RedeemRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';

import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';

import OutlinedSelect from '../../utils/OutlinedSelect';

import { Style, StyleSingle, Trend, SideBarCard } from './dashComponents';

import { theme } from '../../utils/theme';

import { CommonFlex, FullDivider, BasicFlex, StyledLink } from "../../utils/common";

import StatCard from "../../utils/StatCard";

import { currencyFormat, getPercent, fxn, formatDate, formatDateTime2 } from '../../utils/util'

import { Line } from 'react-chartjs-2'

// Lottie animation for loading
import Lottie from 'react-lottie'

import animationData from '../../images/lotties/loading-boxes-colored.json'
import { capitalize } from "lodash";


const Container = styled.div`
  /* width: 95%; */
  margin: 0 auto;
`

const TopBar = styled.div`
  width: 95%;
  margin: 3rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BrandSelect = styled.div`
  min-width: 20rem;
`

const Title = styled.h4`
  /* width: 95%;
  margin: 3rem auto; */
  margin: 0;
  font-weight: 500;
  font-size: 2.4rem;
`

const Tiles = styled.section`
  width: 95%;
  margin: 3rem auto;
  padding-bottom: 2rem;

  display: grid;
  grid-gap: 2rem;
  grid-template-areas: "top top top top top top"
                        "inventory inventory inventory inventory sidebar sidebar"
                        "inventory inventory inventory inventory sidebar sidebar";
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 20rem calc(75vh - 20rem) auto;

  @media ${props => props.theme.breakpoints.down('xl')} {
    grid-template-columns: repeat(6, 1fr);
  }
`

const Stats = styled.div`
  grid-area: top;
`

const Sales = styled.div`
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  border-radius: 6px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SalesInfo = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: .6rem 2rem;
  padding-right: 0;
`

const SalesHeader = styled.h4`
  color: ${props => props.theme.colors.lightText};
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.6rem;
  margin: 1rem 0;
  margin-bottom: 2.5rem;
`

const SalesAmount = styled.span`
  display: block;
  font-size: 3.8rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`

const SalesChips = styled.div`
  display: flex;
  /* justify-content: center; */
  cursor: pointer;
`

const SalesChip = styled.div`
  background-color: #ededed;
  color: #8f8f8f;
  padding: .4rem 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 1rem;

  svg {
    height: 2rem;
  }
`

const StockInfo = styled.div`
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  border-radius: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 24%;
  padding: 1rem;
`

const GraphContainer = styled.div`
  /* width: 60%; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  height: 100%;
  margin-right: 1rem;
  margin-bottom: 2rem;
`

const LineGraph = styled.div`
  width: 100%;
  height: 14.5rem;

  canvas {
    width: 100% !important;
    height: 14.5rem !important;
  }
`

const GraphSelect = styled.div`
  /* height: 2rem; */
  margin-top: 2rem;
  margin-right: 1rem;
  width: 14rem;
`

const StockGraphs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;

  .amount {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 500;
    color: ${props => props.trend === "up" ? props.theme.colors.icon1 : props.trend === "down" ? props.theme.colors.iconRed : props.theme.colors.icon3};

    .number {
      font-size: 2.6rem;
    }
    .subheader {
      font-size: 1rem;
      text-transform: uppercase;
      opacity: .75;
      font-weight: 600;
    }
  }
`

const StockLabel = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.lightText};
`

const StockAmount = styled.span`
  display: block;
  font-size: 3.2rem;
  font-weight: 500;
  margin-top: .3rem;
`

const StockIconLabel = styled.div`
  display: flex;
  align-items: center;
  /* text-transform: uppercase; */
  margin-top: 1.5rem;
  line-height: 1.1;
`

const StockIcon = styled.div`
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 6px;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`

const StockFlex = styled.div`
  display: flex;
  flex-direction: column;
`

const InventoryBox = styled.div`
  grid-area: inventory;
  /* max-height: 68rem; */
`

const SelectContainer = styled.div`
  margin: 0 1rem;
  margin-left: 1.5rem;
  margin-bottom: .5rem;
  width: ${props => props.width ? props.width : "18rem"};
`

const LengthField = styled(TextField)`
  width: 14rem;
  margin-bottom: .5rem;
  margin-left: 1.5rem;
`

const SearchButton = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
  margin-left: 1.5rem;
  margin-bottom: 0.8rem;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryMain};
  }
`

const Inventory = styled(Card)`
  width: 100%;
  height: 100%;
`
const CC = styled(CardContent)`
  height: calc(100% - 8rem);
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
`

const SelectHeader = styled.h4`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${props => props.theme.colors.lightText};
  margin: 0 1.5rem;
  margin-bottom: .5rem;
  margin-top: ${props => props.bottom ? "1rem" : "0"};
`

const DividerWithShadow = styled(FullDivider)`
  box-shadow: 0 5px 10px rgba(0, 0, 0, .13);
  height: 2px;
`

const StyleContainer = styled.div`
  padding: 1.5rem;
  padding-top: 0;
`

const StatContainer = styled(BasicFlex)`
  /* height: 4rem; */
  margin: 0 1.5rem;
  margin-bottom: 2.5rem;
  padding: 1.5rem 1rem;
  width: auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .13);
  background-color: #f9f9f9;
  border-radius: 3px;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const FilterButton = styled.div`
  width: 20rem;
`

const StyleGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const Progress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  /* height: 30rem; */
`

const Sidebar = styled.div`
  grid-area: sidebar;
  /* background-color: #e6e6e6; */
`


const lightColors = [
  "#f7eded",
  "#edf7f5",
  "#f5edf7",
  "#f7edf5",
  "#f2f7ed",
  "#edf7f2",
  "#f7edf7",
  "#f7edf2",
  "#f7f0ed"
]

// animation settings
const defaultOptions = (data) => ({
  loop: true,
  autoplay: true,
  speed: 1,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    // preserveAspectRatio: "none slice"
  }
});

const Dashboard = () => {

  let stockLevel = 15;

  const [styles, setStyles] = useState([])

  const [color, setColor] = useState("")

  const [showSingle, setShowSingle] = useState(false)

  const [period, setPeriod] = useState("week")

  const [activity, setActivity] = useState({
    orders: [],
    consignments: [],
    payments: []
  })

  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState("all")

  const [showMore, setShowMore] = useState("")

  const [singleOptions, setSingleOptions] = useState({
    filter: "lengthNum",
    sortBy: "stock",
    order: "desc"
  })

  const [singleStyle, setSingleStyle] = useState({})

  const [stockInfo, setStockInfo] = useState({})
  const [soldInfo, setSoldInfo] = useState({
    soldDir: "",
    soldPer: 0,
    soldAmount: 0
  })

  const [invLoad, setInvLoad] = useState(false);
  const [singleLoad, setSingleLoad] = useState(false); // single

  const [sortBy, setSortBy] = useState("stock");
  const [dir, setDir] = useState("desc");

  const [materials, setMaterials] = useState([]);
  const [material, setMaterial] = useState("all");
  const [lengthNum, setLengthNum] = useState("");
  const [capSizes, setCapSizes] = useState([]);
  const [capSize, setCapSize] = useState("all");

  const showHandler = (show) => {
    setShowMore(x => show === x ? "" : show)
  }

  const handleSingle = (e, name) => {
    setSingleOptions(x => ({
      ...x,
      [name]: e.target.value
    }))
  }

  const handleSelect = (e, setFunc) => {
    setFunc(e.target.value);
  }

  const openSingle = (col) => {
    setShowSingle(true)
    setColor(col)
  }

  const closeSingle = () => {
    setShowSingle(false)
  }

  const loadMaterials = async () => {
    try {
      let { data } = await axios.get('/inventory/materials')
      setMaterials(data.bases)
      setCapSizes(data.capSizes)
    } catch (e) {
      console.log(e);
    }
  }

  const loadInventory = async () => {
    try {
      setInvLoad(true);
      let {data} = await axios.get(`/dashboard/report?sortBy=${sortBy}&dir=${dir}&brand=${brand ? brand : "all"}&material=${material}&lengthNum=${lengthNum}&capSize=${capSize}`)
      setStyles(data);
    } catch (e) {
      console.log(e);
    } finally {
      setInvLoad(false);
    }
  }

  const loadInventorySingle = async () => {

    const { order, sortBy, filter } = singleOptions
    try {
      setSingleLoad(true);
      let {data} = await axios.get(`/dashboard/report/color?color=${color}&dir=${order}&sortBy=${sortBy}&filterBy=${filter}&brand=${brand ? brand : "all"}`);
      setSingleStyle(data);
    } catch (e) {
      console.log(e);
    } finally {
      setSingleLoad(false);
    }
  }

  const loadActivity = async () => {
    try {
      let { data } = await axios.get(`/dashboard/activity?brand=${brand ? encodeURIComponent(brand) : "all"}`)

      setActivity({
        orders: data.orders,
        payments: data.payments,
        consignments: data.consignments
      })

      const { data: brandList } = await axios.get('/brands/names')
      const brandNames = brandList.map(el => el.name)
      setBrands(brandNames)
    } catch (e) {
      console.log(e);
    }
  }

  const loadStockInfo = async () => {
    try {
      let { data } = await axios.get(`/dashboard/stock?period=${period}&brand=${brand ? brand : "all"}`);
      let soldThis = data?.sold?.thisWeek || 0
      let soldLast = data?.sold?.lastWeek || 0
      let soldDir, soldPer = 0, soldAmount = 0, stockDir, consignDir;
      let percent = getPercent(soldThis, soldLast)
      console.log({pre: "pre", percent});
      if (percent !== 0) {
        percent = fxn(percent - 100, 1)
      }
      if (percent < 0) {
        soldDir = "down"
        soldPer = Math.abs(percent)
        soldAmount = soldLast - soldThis
      } else if (percent > 0) {
        soldDir = "up"
        soldPer = percent
        soldAmount = soldThis - soldLast
      } else {
        soldDir = "flat"
        soldPer = percent
        soldAmount = 0
      }
      console.log({percent});
      setSoldInfo({ soldDir, soldPer, soldAmount })

      stockDir = data?.stock?.thisWeek < data?.stock?.lastWeek ? "down" : data?.stock?.thisWeek > data?.stock?.lastWeek ? "up" : "flat";

      consignDir = data?.consign?.thisWeek < data?.consign?.lastWeek ? "down" : data?.consign?.thisWeek > data?.consign?.lastWeek ? "up" : "flat";

      setStockInfo({...data, stockDir, consignDir})
    } catch (e) {
      
    }
  }

  useEffect(() => {
    loadActivity()
  }, [brand])

  useEffect(() => {
    loadStockInfo()
  }, [period, brand])

  useEffect(() => {
    loadInventory()
  }, [sortBy, dir, brand])

  useEffect(() => {
    loadMaterials()
  }, [])

  useEffect(() => {
    if (color) {
      loadInventorySingle()
    }
  }, [color, singleOptions])

  let stockQty = 0, consignQty = 0, soldPastQty = 0, soldAllQty = 0, avgDaysQty = 0;

    styles.forEach(el => {
      stockQty += el.stock;
      consignQty += el.consign;
      soldPastQty += el.soldPast;
      soldAllQty += el.soldAll;
      avgDaysQty = el.avgDays !== "N/A" ? avgDaysQty + el.avgDays : avgDaysQty;
    })

  return (<Container>
    <TopBar>
      <Title>Dashboard</Title>
       {brands.length > 1 ? <BrandSelect>
          <OutlinedSelect size="small" 
            color={theme.colors.secondary75} 
            bgColor={theme.colors.secondary75}
            textColor={theme.colors.secondary25}
            button
            val={brand} 
            onChange={(e) => setBrand(e.target.value)}
          >
            <MenuItem value="all">All Brands</MenuItem>
            {brands.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
          </OutlinedSelect>
        </BrandSelect> : null }
    </TopBar>
    <Tiles>
    <Stats>
      <CommonFlex dir="row" height="100%">
          <Sales>

          <SalesInfo>
            <SalesHeader>Total Sales</SalesHeader>
            <SalesAmount>{currencyFormat(stockInfo?.sold?.thisWeek || 0)}</SalesAmount>

            <SalesChips>
              <Tooltip title={`${currencyFormat(stockInfo?.sold?.lastWeek)} ${capitalize(period)} Before`}><SalesChip>{soldInfo.soldDir === "up" ? <ArrowUpwardRoundedIcon /> : soldInfo.soldDir === "down" ? <ArrowDownwardRoundedIcon /> : ""} {soldInfo.soldPer}%</SalesChip></Tooltip>
              <SalesChip>{soldInfo.soldDir === "up" ? "+" : soldInfo.soldDir === "down" ? "-" : ""}{currencyFormat(soldInfo.soldAmount)}</SalesChip>
            </SalesChips>
          </SalesInfo>

          <GraphContainer>
          <GraphSelect>
            <OutlinedSelect size="small" fullWidth={false} color={theme.colors.primaryMain} val={period} onChange={(e) => handleSelect(e, setPeriod)}>
              <MenuItem value="week">Past Week</MenuItem>
              <MenuItem value="month">Past Month</MenuItem>
            </OutlinedSelect>
          </GraphSelect>

            <LineGraph>
            <Line data={{

                labels: stockInfo?.sold?.byDay.map(el => formatDate(el.date)) || [],
                datasets: [
                  {
                    // fill: true,
                    tension: 0.4,
                    label: 'Sales by day of week',
                    data: stockInfo?.sold?.byDay.map(el => el.count) || [],
                    borderColor: theme.colors.primaryMain,
                    backgroundColor: theme.colors.primaryLight
                  },
                ],
                }} options={{
                  maintainAspectRatio: false,
                  elements: {
                    point: {radius: 4, hoverRadius: 6}
                  },
                  scales: {
                    x: {
                      display: false,
                      // grid: {
                      //   display: false      
                      // }
                    },
                    y: {
                      display: false,
                      // grid: {
                      //   display: false      
                      // },
                      ticks: {
                        color: "transparent"
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                  }} />
            </LineGraph>
          </GraphContainer>
          </Sales>

          <StockInfo>
            <StockIconLabel>
              <StockIcon bg={theme.colors.iconBg2} color={theme.colors.icon2}><StoreRoundedIcon /></StockIcon> 
              <StockFlex>
                <StockLabel>Stock</StockLabel>
                <StockAmount>{stockInfo?.stock?.current || 0}</StockAmount>
              </StockFlex>
            </StockIconLabel>
            <StockGraphs trend={stockInfo?.stockDir}>
              <div className="amount"><div className="number">{stockInfo?.stock?.thisWeek || 0}</div> <div className="subheader">Added Past {capitalize(period)}</div></div>
              <Trend dir={stockInfo?.stockDir} lastWeek={stockInfo?.stock?.lastWeek} period={period}></Trend>
            </StockGraphs>
          </StockInfo>
          <StockInfo>
            <StockIconLabel>
              <StockIcon bg={theme.colors.iconBg2} color={theme.colors.icon2}><RedeemRoundedIcon /></StockIcon> 
              <StockFlex>
                <StockLabel>Consignment</StockLabel>
                <StockAmount>{stockInfo?.consign?.current || 0}</StockAmount>
              </StockFlex>
            </StockIconLabel>
            <StockGraphs trend={stockInfo?.consignDir}>
              <div className="amount"><div className="number">{stockInfo?.consign?.thisWeek || 0}</div> <div className="subheader"> Consigned Past {capitalize(period)}</div></div>
              <Trend dir={stockInfo?.consignDir} lastWeek={stockInfo?.consign?.lastWeek} period={period}></Trend>
            </StockGraphs>
          </StockInfo>
      </CommonFlex>
    </Stats>
    <InventoryBox>
      <Inventory elevation={3}>
        <CardHeader title="Inventory Report" action={<IconButton><MoreHorizIcon /></IconButton>}  />

        <SelectHeader>Sort By</SelectHeader>
        <CommonFlex dir="row" justify="flex-start">
        <SelectContainer width="16rem">
          <OutlinedSelect size="small"
            color={theme.colors.primaryVeryLight} 
            bgColor={theme.colors.primaryVeryLight}
            textColor={theme.colors.primaryMain} 
           val={sortBy} onChange={(e) => handleSelect(e, setSortBy)}>
            <MenuItem value="stock">Stock</MenuItem>
            <MenuItem value="consign">Consignment</MenuItem>
            <MenuItem value="soldPast">Sold Past Quarter</MenuItem>
            <MenuItem value="soldAll">Sold All Time</MenuItem>
            <MenuItem value="avgDays">Avg. Days To Sell</MenuItem>
          </OutlinedSelect>
          </SelectContainer>
        <SelectContainer width="16rem">
          <OutlinedSelect size="small"
           color={theme.colors.primaryVeryLight} 
           bgColor={theme.colors.primaryVeryLight}
           textColor={theme.colors.primaryMain} 
           val={dir} onChange={(e) => handleSelect(e, setDir)}>
            <MenuItem value="asc">Low To High</MenuItem>
            <MenuItem value="desc">High To Low</MenuItem>
          </OutlinedSelect>
          </SelectContainer>
        </CommonFlex>

        <SelectHeader bottom>Filter By</SelectHeader>

        <FilterRow>
        <BasicFlex dir="row" justify="flex-start" align="flex-end">

          <SelectContainer width="16rem">
            <OutlinedSelect 
            size="small" 
            color={theme.colors.secondary75} 
            bgColor={theme.colors.secondary75}
            textColor={theme.colors.secondary25}
            // button
            val={material} 
            onChange={(e) => handleSelect(e, setMaterial)}
            >
                <MenuItem value="all">All Materials</MenuItem>
                {materials.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
            </OutlinedSelect>
          </SelectContainer>

          <SelectContainer width="16rem">
            <OutlinedSelect 
            size="small" 
            color={theme.colors.secondary75} 
            bgColor={theme.colors.secondary75}
            textColor={theme.colors.secondary25}
            // button
            val={capSize} 
            onChange={(e) => handleSelect(e, setCapSize)}
            >
                <MenuItem value="all">All Cap Sizes</MenuItem>
                {capSizes.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
            </OutlinedSelect>
          </SelectContainer>

          <LengthField size="small" label="Enter Length" 
          type="number"
          onChange={(e) => setLengthNum(e.target.value)}
          value={lengthNum}
          variant="outlined" />

          {/* <SearchButton>Advanced Search</SearchButton> */}
        </BasicFlex>
       <FilterButton> <Button variant="outlined" color="secondary" onClick={loadInventory} startIcon={<FilterListRoundedIcon />} size="small">Apply Filters</Button> </FilterButton>
        </FilterRow>

        <DividerWithShadow y="2rem" />

        <CC>
        {!showSingle ? 
        <>
        {invLoad ? <Progress>
          
          <Lottie 
            options={defaultOptions(animationData)}
              // height={150}
              width={300}
            />
        </Progress> 
          :
        <StyleContainer>

          <StatContainer justify="flex-start">
            <StatCard title="Stock" stat={stockQty} icon={<StoreRoundedIcon />} currency={false}  bg={theme.colors.iconBg2} color={theme.colors.icon2} />
            <StatCard title="Consigned" stat={consignQty} icon={<RedeemRoundedIcon />} currency={false} bg={theme.colors.iconBg1} color={theme.colors.icon1} />
            <StatCard title="Sold Past Quarter" stat={soldPastQty} icon={<AttachMoneyRoundedIcon />} currency={false}  bg={theme.colors.iconBg4} color={theme.colors.icon4} />
            <StatCard title="Sold All Time" stat={soldAllQty} icon={<ReceiptRoundedIcon />} currency={false}  bg={theme.colors.iconBg3} color={theme.colors.icon3} />

          </StatContainer>

          <StyleGrid>
              {styles.map(({style, stock, consign, soldPast, soldAll, avgDays}, ind) => 
                // <Grid key={style} item xs={3}>
                  <Style onClick={openSingle} sortBy={sortBy} key={style} ind={ind % 9} color={style} stock={stock} consign={consign} soldPast={soldPast} soldAll={soldAll} avgDays={avgDays} stockLevel={stockLevel}></Style>
                // </Grid>
              )}
             
          </StyleGrid>
        </StyleContainer>
        }
        </> :

        <StyleSingle onClick={closeSingle} singleOptions={singleOptions} handleSingle={handleSingle} color={color} show={showSingle ? true : false} loading={singleLoad} stats={singleStyle}></StyleSingle> 

        }
        </CC>
      </Inventory>
    </InventoryBox>
    <Sidebar>

      <SideBarCard title="orders" label="Recent Sales" results={activity?.orders} show={showMore} handler={showHandler} first={true}>
       </SideBarCard>

      <SideBarCard title="payments" label="Recent Payments" results={activity?.payments} show={showMore} handler={showHandler}>
       
      </SideBarCard>

      <SideBarCard title="consignments" label="Recent Consignments" results={activity?.consignments} show={showMore} handler={showHandler}>
       
      </SideBarCard>

    </Sidebar>
    </Tiles>
  </Container>)
}

export default Dashboard;