import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../../../axios';

import ProtectedRoute from '../../../../utils/ProtectedRoute';
import { Switch, Route, Link } from 'react-router-dom'

//icons
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Add from '@material-ui/icons/Add';

import styled from 'styled-components';

import { debounce } from 'lodash'

import { useSBContext } from '../../../../utils/snackbar';

import PanelForm from '../../PanelForm';

import AddStyle from './AddStyle';

import SimpleTable from '../../../../utils/simpleTable';

import { OutlinedInput, Button } from '@material-ui/core'

const Container = styled.div`

`

const MainContainer = styled.div`
  width: 90%;
  margin-top: 6rem;
`

const Search = styled(OutlinedInput)`
  border-radius: 20px;
  flex-grow: 1;
`

const ButtonContainer = styled.div`
  width: 20rem;
  margin-left: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const CreateButton = styled(Button)`
  /* height: ; */
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6rem;
`

const Styles = (props) => {

  const [styles, setStyles] = useState([])
  const [loading, setLoading] = useState(false)
  const [trashLoading, setTrashLoading] = useState(false)

  const { handleClick } = useSBContext()

  const loadStyles = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get('/styles')
      setStyles(data)
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadStyles()
  }, [props?.location?.pathname])

  const searchStyles = useCallback(debounce(async (val) => {
    const {data} = await axios.get(`/styles?term=${encodeURIComponent(val)}`)
    setStyles(data)
  }, 250), [])

  const headers = [
    {id: "style", label: "Color"},
    {id: "tags", render: (row) => { return row.tags.map(el => el.name).join(", ")}, label: "Categories"}
  ]

  const trashFunc = async (index) => {
    const find = styles[index]
    if (!find) {
      handleClick('error', "Style not found")
      return "fail"
    }
    try {
      setTrashLoading(true)
      axios({
        url: `/styles/${find._id}`,
        method: "DELETE"
      })
      handleClick('success', "Successfully removed Style")
      loadStyles()
    } catch (e) {
      handleClick('error', e.message)
    } finally {
      setTrashLoading(false)
      return "done"
    }
  }

  const path = props?.match?.url;

  return (
    <Container>
        <Switch>
          <ProtectedRoute path={`${path}/edit/:id`} guard='writeSettings' component={AddStyle} />
          <ProtectedRoute path={`${path}/edit`} guard='writeSettings' component={AddStyle} />
          <Route path="/setup/styles">
          <PanelForm title="Style List">

            <MainContainer>
              <SearchBar>
               <Search 
                variant="outlined"
                fullWidth
                endAdornment={<SearchRoundedIcon color="primary" />}
                onChange={(e) => searchStyles(e.target.value)}
                placeholder="Search By Style, Category"
               />
               <ButtonContainer>
                <Link to={`${path}/edit/`}>
                <CreateButton variant="contained" color="primary" startIcon={<Add />} >
                  New Style
                </CreateButton>
               </Link>
               </ButtonContainer>
              </SearchBar>
              <SimpleTable headers={headers} title="Styles" rows={styles} link="setup/styles" view={false} write={true} trash={true} rpp={30} dense={true} trashFunc={trashFunc} />
            </MainContainer>
          </PanelForm>

          </Route>
        </Switch>
    </Container>
  )
}

export default Styles;