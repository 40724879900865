import React, { useState, useEffect } from 'react';

import axios from '../../../../axios';

import styled from 'styled-components';

import { Formik, Form, Field, FieldArray } from 'formik';

import { TextField, CheckboxWithLabel, RadioGroup, Select } from 'formik-material-ui';

import { useSBContext } from '../../../../utils/snackbar';

import { object, string, number } from 'yup';

import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import EditIcon from '@material-ui/icons/Edit';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ForwardRoundedIcon from '@material-ui/icons/ForwardRounded';

import { BasicFlex, CommonFlex, FullDivider, SectionHeader, SectionHeaderRow, StyledLink } from '../../../../utils/common';

import { Button, Grid, LinearProgress, FormHelperText, IconButton } from '@material-ui/core'

import PanelForm from '../../PanelForm';

import { handleErrors } from '../../../../utils/util'

import { LengthField, CapSize, BaseMaterial, HairStyle, BasicOptions } from './AdditionalSettings';

const Container = styled.div`

`

const BackButton = styled(Button)`

  &.MuiButton-root {
    min-width: auto;
    display: flex;
    justify-content: center;
    padding: .5rem .8rem;
    text-decoration: none !important;
  }

  svg {
    transform: rotate(180deg);
  }
  margin: 0;
`

const Row = styled(Grid)`
  /* margin-top: 1rem; */
`

const HeaderLabel = styled.span`
  /* min-width: 10rem; */
  display: block;
  padding: .3rem 0;
  background-color: ${props => props.theme.colors.primaryVeryLight};
  margin-right: 1px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  flex-grow: 1;
  /* width: min-content; */
  text-align: center;

  &:last-child {
    border-radius: 0 6px 6px 0;
  }
  &:first-child {
    border-radius: 6px 0 0 6px;
  }
`

const ChipContainer = styled.div`
  position: relative;
`

const HeaderChip = styled.div`
  background-color: ${props => props.theme.colors.primaryVeryLight};
  border-radius: 6px;
  padding: .5rem 2.5rem;
  width: max-content;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
`

const EditIconChip = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #eee; 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -3rem;

  /* margin-left: 1rem; */

  svg {
    width: 1.6rem;
    height: 1.6rem;
    color: ${props => props.theme.colors.primaryMain};
  }

  &:hover {
    background-color: ${props => props.theme.colors.primaryLight}; 
    svg {
      color: #fff;
    }
  
  }
`

const Line = styled.div`
  height: 1rem;
  width: 1px;
  border: 1px solid ${props => props.theme.colors.border};
  margin: .5rem 0;
`

const FormStyle = styled(Form)`
  margin-top: 1rem;
`

const TableHeaders = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 2rem;
  width: 100%;
  /* margin-bottom: 3rem; */
`

const AdditionalTag = styled.span`
  font-weight: 700;
  font-size: 105%;
  text-transform: uppercase;
`

const FieldWithChip = ({ chip, required = false, name, editable = false, editFunc = () => {} }) => {
  return (
    <CommonFlex dir="column">
      <ChipContainer>
      <HeaderChip>{chip}</HeaderChip>
      {editable && <EditIconChip onClick={() => editFunc(chip)}><EditIcon /></EditIconChip>}
      </ ChipContainer>
      <Line />
      <Field 
        component={TextField}
        fullWidth 
        variant="outlined" 
        name={name} 
        placeholder={chip}
        label={chip}
        size="small"
      />
    </CommonFlex>
  )
}



const AddShipmentSettings = (props) => {

  const { id } =  props.match.params;

  const isEdit = () => id ? true : false;

  const [info, setInfo] = useState({})

  const [loading, setLoading] = useState(isEdit() ? true : false)

  const [additional, setAdditional] = useState("")

  const { handleClick } = useSBContext()

  const message = isEdit() ? "Successfully Updated Profile" : "Successfully Added New Profile"

  const FieldWithChipEditable = ({ chip, required = false, name}) => {
    return (
      <FieldWithChip chip={chip} name={name} required={required} editable editFunc={setAdditional} />
    )
  }

  const loadProfile = async () => {
    try {
      let { data } = await axios.get(`/system_settings/profiles/${id}`)
      let headers = {}
      Object.entries(data.headers).forEach(([key, value]) => {
        headers[key] = value.name
      })    
      data.headers = headers 
      setInfo(data)
    } catch (e) {
      handleClick("error", "Error loading profile")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      loadProfile()
    }
  }, [])

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {
    console.log(userInfo, "heyeyeyeoooff");
    try {
      let { data } = isEdit() ? await axios.put(`/system_settings/profiles/${id}`, {...userInfo}) : await axios.post("/system_settings/profiles", {...userInfo})
      handleClick("success", message)
      props.history.push('/setup/shipments')
    } catch (e) {
      handleClick("error", handleErrors({e}))
    } finally {
      setSubmitting(false)
    }
  }

  const ProfileSchema = object().shape({
    field: string()
    .required("Profile Name is Required")
  })

 return (
  <Container>
    <PanelForm title={isEdit() ? `Edit Shipment Profile` : "Add Shipment Profile"}>
    <StyledLink to="/setup/shipments">
      <BackButton  color="secondary" startIcon={<ForwardRoundedIcon />}>Back to List</BackButton>
    </StyledLink>
    <Formik
    enableReinitialize
    initialValues={
     {
      field: info.field ||  "",
      description: info.description ? info.description : "",
      headers: {
      sku: "style",
      wigId: "serial",
      lengthField: "length",
      capSize: "cap",
      cost: "cost",
      retail: "price",
      base: "material",
      hairstyle: "hairstyle",
      highlights: "highlights",
      title: "title",
      description: "description",
      ...info.field ? info.headers : {}
     },
     settings: {
       requirements: {
        lengthField: "recommended",
        capSize: "recommended",
        base: "optional",
        hairstyle: "optional",
        highlights: "optional",
        title: "optional",
        description: "optional",
        ...info.field ? info.settings?.requirements : {}
      },
      defaults: {
        lengthField: "16",
        capSize: "M",
        base: "",
        hairstyle: "",
        highlights: "",
        ...info.field ? info.settings?.defaults : {}
      },
      min: {
        lengthField: "",
        ...info.field ? info.settings?.min : {}
      },
      max: {
        lengthField: "",
        ...info.field ? info.settings?.max : {}
      },
      allowedValues: {
        base: [],
        hairstyle: [],
        ...info.field ? info.settings?.allowedValues : {}
      },
      mappedValues: {
        base: [],
        hairstyle: [],
        ...info.field ? info.settings?.mappedValues : {}
      }
    }
   }
    }
    validationSchema={ProfileSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, values, setFieldValue, errors }) => (
        <>
        {loading ? <LinearProgress color="primary" /> : <FormStyle>
          {console.log({values, errors})}
      <Grid container spacing={4}>

      <Grid item xs={12}>
          <FullDivider y=".3rem" />
        </Grid>

        <Grid item xs={5}>
          <Field
          component={TextField}
          fullWidth 
          variant="outlined" 
          name="field" 
          placeholder="Profile Name"
          label="Profile Name"
          size="small"

          />
        </Grid>

        <Grid item xs={8}>
        <Field
          component={TextField}
          fullWidth 
          variant="outlined" 
          name="description" 
          placeholder="Profile Description"
          label="Profile Description"
          size="small"
          multiline
          minRows={3}
          />
        </Grid>

        <Grid item xs={4}>

        </Grid>

        <Grid item xs={12}>
          <FullDivider y="1rem" />
        </Grid>

        
        <SectionHeaderRow item xs={12}>
          <SectionHeader>Current Available Headers</SectionHeader>
        </SectionHeaderRow>


        <Grid item xs={12}>
          <TableHeaders>
          <HeaderLabel>*Freight</HeaderLabel>
          <HeaderLabel>*Date</HeaderLabel>
          <HeaderLabel>*Order</HeaderLabel>
          <HeaderLabel>*Brand</HeaderLabel>
          <HeaderLabel>*Serial</HeaderLabel>
          <HeaderLabel>*Style</HeaderLabel>
          <HeaderLabel>Length</HeaderLabel>
          <HeaderLabel>Cap</HeaderLabel>
          <HeaderLabel>Material</HeaderLabel>
          <HeaderLabel>Hairstyle</HeaderLabel>
          <HeaderLabel>Highlights</HeaderLabel>
          <HeaderLabel>Title</HeaderLabel>
          <HeaderLabel>Description</HeaderLabel>
          <HeaderLabel>*Cost</HeaderLabel>
          <HeaderLabel>*Price</HeaderLabel>
          </TableHeaders>
          <FormHelperText>* Required Fields</FormHelperText>
        </Grid>

        <Grid item xs={12}></Grid>

        <SectionHeaderRow item xs={12}>
          <SectionHeader>Map Custom Header Titles</SectionHeader>
        </SectionHeaderRow>

        <Grid item xs={3}>
          <FieldWithChip chip="Style" name="headers.sku" />
        </Grid>
        <Grid item xs={3}>
          <FieldWithChip chip="Serial" name="headers.wigId" />
        </Grid>
        <Grid item xs={3}>
          <FieldWithChipEditable chip="Length" name="headers.lengthField"/>
        </Grid>
        <Grid item xs={3}>
          <FieldWithChipEditable chip="Cap" name="headers.capSize"/>
        </Grid>
        <Grid item xs={3}>
          <FieldWithChipEditable chip="Material" name="headers.base"/>
        </Grid>
        <Grid item xs={3}>
          <FieldWithChipEditable chip="Hairstyle" name="headers.hairstyle"/>
        </Grid>
        <Grid item xs={3}>
          <FieldWithChipEditable chip="Highlights" name="headers.highlights"/>
        </Grid>
        <Grid item xs={3}>
          <FieldWithChipEditable chip="Title" name="headers.title"/>
        </Grid>
        <Grid item xs={3}>
          <FieldWithChipEditable chip="Description" name="headers.description"/>
        </Grid>
        <Grid item xs={3}>
          <FieldWithChip chip="Cost" name="headers.cost" />
        </Grid>
        <Grid item xs={3}>
          <FieldWithChip chip="Price" name="headers.retail" />
        </Grid>

        

        {additional ? <>
        <Grid item xs={12}>
          <FullDivider y="1rem" />
        </Grid>

        <SectionHeaderRow item xs={12}>
          <SectionHeader>
            <BasicFlex>
            <span>Additional Settings For <AdditionalTag>{additional}</AdditionalTag></ span> <span><IconButton onClick={() => setAdditional("")} size="small"><CloseRoundedIcon /></IconButton></span>
            </BasicFlex>
            </SectionHeader>
        </SectionHeaderRow>

        <Grid item xs={12}>
         {additional === "Length" ?  <LengthField /> : null }
         {additional === "Cap" ?  <CapSize /> : null }
         {additional === "Material" ?  <BaseMaterial updateFunc={setFieldValue} allowedValues={values?.settings?.allowedValues?.base || []} mappedValues={values?.settings?.mappedValues?.base || []} /> : null }
         {additional === "Hairstyle" ?  <HairStyle updateFunc={setFieldValue} allowedValues={values?.settings?.allowedValues?.hairstyle || []} mappedValues={values?.settings?.mappedValues?.hairstyle || []} /> : null }
         {additional === "Highlights" ?  <BasicOptions name="highlights" /> : null }
         {additional === "Title" ?  <BasicOptions name="title" /> : null }
         {additional === "Description" ?  <BasicOptions name="description" /> : null }
        </Grid>

        </> : null }

        <Grid item xs={12}>
          <FullDivider y=".3rem" />
        </Grid>

        <Row item xs={12}>
        <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            startIcon={<SaveRoundedIcon />}
            >Save Profile</Button>
        </Row>
        
      </Grid>
      </FormStyle> 
        }
        </>
      )}
      </Formik>
    </PanelForm>
  </Container>
 )
};

export default AddShipmentSettings;