import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ViewInfo from '../../utils/viewInfo';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from '../../axios';

import { truncate } from 'lodash';

import WigIcon from '../../utils/WigIcon';
import { IconBox } from '../../utils/common';

import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import StyleRoundedIcon from '@material-ui/icons/StyleRounded';
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@material-ui/icons/UnfoldLessRounded';

import CustomChip from '../../utils/CustomChip';

import { FlexLine, FullDivider, BasicFlex } from '../../utils/common'

import { theme } from '../../utils/theme';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import { formatGenericDate, currencyFormat, findFeaturesByQty } from '../../utils/util';

import SimpleTable from '../../utils/simpleTable';

import {useSBContext} from '../../utils/snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import displayPdf from '../../utils/displayPdf';

const InfoItem = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;


`
const StatusChip = styled(CustomChip)`
  display: inline-block;
  margin-left: 3.5rem;
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const LogLabel = styled.h4`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.lightText};
`

const LogLine = styled.span`
 display: inline-block;
 margin-left: 2.5rem;

 .bold {
  display: inline;
  font-weight: 500;
 }
`

const LogContainer = styled.div`
  display: ${props => props.open ? "block" : "none"};
`

// const StyleChip = styled(CustomChip)`
//   width: max-content;
//   margin-left: 1.5rem;
//   ${props => props.bold ? css`
//     font-weight: 600;
//     padding: .1rem 1rem;
//     /* margin-left: 1rem; */
//   ` : ""}
// `;

const ListQty = styled.span`
  display: block;
  flex-grow: 1;
  text-align: right;
  font-size: 1.6rem;
  font-weight: 500;
`;

const StyleChip = styled.div`
  width: max-content;
  /* margin-bottom: .2rem; */
  background-color: ${props => props.theme.colors.iconBg4};
  border-radius: 5px;
  padding: .1rem .5rem;
  font-size: 1.1rem;
  border: 1px solid ${props => props.theme.colors.icon4L};
  text-transform: uppercase;
  width: 100%;
  font-weight: 500;
  text-align: center;

  ${props => props.bold ? css`
    font-weight: 600;
    font-size: 1.6rem;
    padding: 1rem;
    /* width: 100%;
    text-align: center; */
    /* margin-left: .5rem; */
    border: none;
    background-color: ${props => props.theme.colors.iconBg2};
  ` : ""}
`;

const StyleCardList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2.5rem;
`;

const StyleItemCard = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 18rem; */
  width: 13.5rem;
  /* border: 1px solid ${props => props.theme.colors.border}; */
  border-radius: 5px;
  padding: .5rem .8rem;
  /* background-color: ${props => props.theme.colors.headerBg}; */
  box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
  overflow: hidden;

  .top, .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 1rem; */

    > span {
      display: block;
    }
  }

  .top {
    /* background-color: greenyellow; */
  }

  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.8rem;
    /* width: 50%; */

    svg {
      /* width: 1.6rem;
      height: 1.6rem; */
      /* margin-right: .5rem; */
    }


    .qty-title {
      display: block;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      color: ${props => props.theme.colors.lightText};
      /* margin-bottom: .5rem; */
    }

    .qty {
      /* display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center; */
      font-size: 2rem;
      font-weight: 600;
      /* margin-left: 1rem; */
      /* border: 1px solid ${props => props.theme.colors.border}; */
      /* padding: .2rem 1rem; */
      border-radius: 5px;
      color: ${props => props.theme.colors.primaryMain};
    }
  }

  .bottom {
    padding: 0 1rem;
    .length {
      font-style: italic;
      font-weight: 600;
      color: ${props => props.theme.colors.lightText};
      background-color: ${props => props.theme.colors.primary95};
      padding: .2rem .8rem;
      border-radius: 5px;
    }
    .len_qty {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
`;

const ViewLength = styled.span`
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: ${props => props.theme.colors.lightText};
  margin-right: .5rem;
  /* margin-bottom: .5rem; */
`;

const ExpandIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
`;

const Log = ({ el }) => {
  return (
  <FlexLine key={el.createdAt} dir="row" justify="flex-start" pad="1.5rem">
    {el.method === "POST" ? <PostAddRoundedIcon color="primary" /> : <BuildRoundedIcon color="secondary" />}
    <LogLine>
      <span className='bold'>{el.user}</span>
      {el.method === "POST" ? " created" : " modified"} the consignment{el.method === "PUT" ?  el.mod ? `: ${el.mod},` : "" : ` with ${el?.body?.wigs?.length} wig${el?.body?.wigs?.length > 1 ? "s" : ""}`} on {formatGenericDate(el.createdAt)}
    </LogLine>
    </FlexLine>
  )
}

 
export default (props) => {

  const [loading, setLoading] = useState(false)

  const [loadingLogs, setLoadingLogs] = useState(false)

  const [logs, setLogs] = useState([])

  const [openLogs, setOpenLogs] = useState(false)

  const [info, setInfo] = useState({})

  const [email, setEmail] = useState('');

  const [emailErr, setEmailErr] = useState(false);

  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const [expandLengths, setExpandLengths] = useState({

  });

  console.log(findFeaturesByQty(info.wigs), "check features");
  let featureList = findFeaturesByQty(info.wigs);

  const handleExpand = (key) => {
    setExpandLengths(x => ({...x, [key]: !x[key]}))
  }

  const handleExpandAll = (cmd = "expand") => {
    let obj = {};
    featureList.forEach(el => {
      if (cmd === "expand")
      {
        obj[el.key] = true;
      } else {
        obj[el.key] = false;
      }
    })
    setExpandLengths(obj)
  }


  const toggleLogOpen = () => setOpenLogs(x => !x)

  const handleChange = (e) => {
    setEmailErr(false)
    setEmail(e.target.value)
  };

  const {id} = props.match.params;


 
  const crumbs = [{path: '/consignments/', text: 'Consignments'}, 
  {path: `/consignments/view/${id}`, text: 'View'}, {path: `/consignments/view/${id}`, text: `${info?.consignmentId || ""}`}];

  const loadLogs = async (info) => {
    if (info) {
      setLoadingLogs(true)
      // console.log(info, "hdhdhdh");
      const {data: logs} = await axios.get(`/logs?model=consignments&createdAt=${info.createdAt}&id=${id}`);
      // console.log(logs, "cchek logssss");
      setLogs(logs)
      setLoadingLogs(false)
    }
  }

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/consignments/${id}`);
    setInfo(data);
    if (data.isCheckIn) {
      if (data.from?.id) {
        const {data: from} = await axios.get(`/customers/${data?.from.id}`);
        if (from.email) setEmail(from.email);
      }
    } else {
      const {data: to} = await axios.get(`/customers/${data?.to.id}`);
      if (to.email) setEmail(to.email);
    }
    setLoading(false);

    await loadLogs(data)
  }

  

  const wigItemHeaders = [
    {id: 'wigId', label: 'ID'},
    {id: 'brand', label: 'Brand'},
    {id: 'sku', label: 'SKU'},
    {id: 'baseMaterial', label: 'Material'},
    {id: 'price', currency: true, additional: true, label: 'Price'}
  ]

  useEffect(() => {
    if (id){
      loadData();
      loadLogs()
    }
    else
      props.history.push('/consignments');
  }, []);

  const {handleClick} = useSBContext();

  const sendEmail = async () => {
    if (!email) return setEmailErr(true);
    setInvoiceLoading(true)
    try {
      await axios.post(`/consignments/${id}/invoice?email=${email}`)
      handleClick('success', 'Email successfully sent')
      setInvoiceLoading(false)
    } catch (e) {
      handleClick('error', 'Error sending email')
      setInvoiceLoading(false)
    }
  }

  const printInvoice = async () => {
    setInvoiceLoading(true)
    const {data} = await axios({
      method: "POST",
      url: `/consignments/${id}/invoice?pdf=true`,
      responseType: 'blob',
      timeout: 60000,
      headers: {"Content-Type": "application/pdf"}
    });
    setInvoiceLoading(false)
    displayPdf(data, `packing-slip`)
  }

  

 return (
  <ViewInfo title="View Consignment Details" crumbs={crumbs}>
    {loading ? <Grid container> <Grid item xs={12}><LinearProgress /></Grid></Grid> : 
    <Grid container spacing={3}>

      <Grid item xs={7}>

        <Card variant="outlined">
          <CardHeader title={<>Consignment Details - {info.consignmentId} {info?.status === "draft" ? <StatusChip size="large">Draft</StatusChip> : null} {info?.isCheckIn ? <StatusChip size="large" level="icon3">Check-In</StatusChip> : null}</>} 
          action={
            <IconLink to={`/consignments/edit/${info._id}`}><EditIcon /></IconLink>
          }
          subheader={formatGenericDate(info.date)} />
          <CardContent>
            
          {info?.isCheckIn ? null : <InfoItem><InfoLabel>Customer:</InfoLabel> {info.to?.name}</InfoItem>}
          {info?.isCheckIn && info?.from?.name ? <InfoItem><InfoLabel>Returning Customer:</InfoLabel> {info.from?.name}</InfoItem> : null}
          <InfoItem><InfoLabel>Num of Wigs:</InfoLabel> {info.qty}</InfoItem>
          {info.delivery?.amount ? <InfoItem><InfoLabel>Delivery Charge:</InfoLabel> {currencyFormat(info.delivery?.amount)}</InfoItem> : null}
          <InfoItem><InfoLabel>Consignment Created By:</InfoLabel> 
          {info.addedBy?.name}
          </InfoItem>
          <InfoItem><InfoLabel>Notes:</InfoLabel> {info.notes}</InfoItem>
          {/* <FullDivider b="20px" /> */}

          {/* <InfoItem><InfoLabel>Last Updated At:</InfoLabel> 
          {formatGenericDate(info.updatedAt)}
          </InfoItem> */}

          <FullDivider t="20px" />

          <FlexLine dir="row" justify="space-between" pad="0"><LogLabel>History Logs</LogLabel>
          <IconButton onClick={toggleLogOpen}> {openLogs ? <ExpandLessRoundedIcon /> : <ExpandMoreIcon />} </IconButton></FlexLine>

          <LogContainer open={openLogs}>
            {logs.map(el => 
              <Log el={el} key={el.createdAt} />
              )}
          </LogContainer>
         
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={5}>

        <Card variant="outlined">
          <CardHeader title="Create Invoice" subheader="Print or Email"
          action={
            <>
            <Tooltip title="Print">
            <IconButton onClick={printInvoice}>
              <PrintIcon color="primary" />
            </IconButton>
            </Tooltip>
            <Tooltip title="Email">
            <IconButton onClick={sendEmail}>
              <EmailIcon color="primary" />
            </IconButton>
            </Tooltip>
            </>
          } />
          <CardContent>
            {invoiceLoading ? <CircularProgress /> : 
            <TextField error={emailErr} type="email" variant="outlined" 
            name="email" fullWidth onChange={handleChange}
            label="Email Address"
            defaultValue={email}
            helperText={emailErr ? 'Email address required' : ''} />
          }
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader title="Item List Summary" subheader={`${featureList.length} Items`} action={
            <ExpandIcons>
              <Tooltip title="Expand All"><IconButton onClick={() => handleExpandAll("expand")}><UnfoldMoreRoundedIcon /></IconButton></Tooltip>
              <Tooltip title="Collapse All">
              <IconButton onClick={() => handleExpandAll("collapse")}><UnfoldLessRoundedIcon /></IconButton>
              </Tooltip>
            </ExpandIcons>
          } />
          <CardContent>
            <StyleCardList>
          {featureList.map((el, i) => (
            <StyleItemCard key={i}>
              <div className="top">
                <BasicFlex justify="center" my=".5rem">
                  {/* <StyleRoundedIcon color="primary" /> */}
                  <StyleChip bold>
                    {el.style}
                  </StyleChip>
                </BasicFlex>
               { el.baseMaterial ? <StyleChip>
                   <Tooltip title={el.baseMaterial}><span>{truncate(el.baseMaterial, {length: 12})}</span></Tooltip> 
                </StyleChip> : null }
              </div>
              <div className="middle">
                {/* <span><IconBox bg={theme.colors.iconBg1} size="small"><WigIcon color={theme.colors.icon1} /></IconBox></span> */}
                <span className='qty-title'>Qty</span>
                <span className='qty'>
                  {el.qty}
                  </span>
              </div>
              {/* <FullDivider /> */}
              <BasicFlex justify="flex-start" align="center" my=".5rem">
                <ViewLength>View Lengths</ViewLength> <IconButton size="small" onClick={() => handleExpand(el.key)}>{ expandLengths[el.key] ? <KeyboardArrowDownRoundedIcon /> : <ChevronRightRoundedIcon /> }</IconButton> 
              </BasicFlex>
             { expandLengths[el.key] ? <div className="bottom">
                { Object.keys(el.lengths).map((len, ind) => (
                <BasicFlex key={ind} my=".4rem" justify="space-between">
                  <span className='length'>{len}</span>
                  <span className='len_qty'>{el.lengths[len]}</span>
                </BasicFlex>
                ))}
              </div> : null }
            </StyleItemCard>
          ))}
          </StyleCardList>
            {/* {featureList.map((el, i) => (
              <React.Fragment key={i}>
              <BasicFlex my="2rem" justify="flex-start" align="flex-end">
                <StyleRoundedIcon color="primary" />
                <StyleChip fontSize="small" bold>
                  {el.style}
                </StyleChip>
               {el.baseMaterial ? <StyleChip fontSize="small" level="icon2">
                  {truncate(el.baseMaterial)}
                </StyleChip> : null }
                <ListQty>{el.qty}</ListQty>
              </BasicFlex>
              </React.Fragment>
            ))} */}
          </CardContent>
        </Card>
      </Grid>


      <Grid item xs={8}>
        <Card variant="outlined">
          <CardHeader title="Consigned Wigs" subheader={`${info.wigs?.length} Items`} />
          <CardContent>
            <SimpleTable rows={info.wigs} headers={wigItemHeaders} title="Inventory" link="inventory" pagination={false} idReplace="wigId" />
          </CardContent>
        </Card>
      </Grid>

     


    </Grid>
    }
  </ViewInfo>
 )
}