import React, {useState, useEffect} from 'react';
import {get} from 'lodash';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import axios from '../axios';

import {useSBContext} from './snackbar';
import { useAuthContext } from '../auth';

import { handleErrors, formatDisplay } from './util';

import {Table, TableBody, TableCell, 
  TableContainer, TableHead, TablePagination, TableRow
,TableSortLabel, Toolbar, Paper, Checkbox, IconButton, 
Tooltip, FormControlLabel, Switch, Fab, Grid, Button} from '@material-ui/core';
import {Delete, FilterList, Add, Search, Edit} from '@material-ui/icons';

import Skeleton from '@material-ui/lab/Skeleton';
import AlertDialog from './dialog';

const DisplayPaper = styled(Paper)`
  background-color: rgb(250, 250, 251);

  .MuiTablePagination-root {
    width: 95%;
    border: 2px solid ${props => props.theme.colors.border};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    margin: 0 auto;
    background-color: rgb(245, 245, 245);
  }
`

const ButtonLink = styled(Button)`
  /* display: block; */
  float: right;
  margin-right: 2rem;
`

const TableHeadStyles = styled(TableHead)`
  background-color: rgb(245, 245, 245);
`

const TableHeader = styled.span`
 font-size: 1.6rem;
 display: block;
 font-size: 2.4rem;
 font-weight: 500;
 width: 95%;
 margin: 0 auto;
 padding: 2.5rem 0;
`

const GridBreak = styled.div`
  span {
    display: block;
    text-align: right;
    padding: 1rem 3.5rem 1rem 0;
    font-weight: 500;
    font-size: 105%;
  }
`

const ActionsContainer = styled.div`

  display: flex;
  justify-content: center;

  a {
  color: ${props => props.theme.palette.primary.main};
  }

  .edit-icon {
    margin-right: 1rem;
  }

  .hover-icon:hover {
    cursor: pointer;
  }
`

const MainTable = styled(Table)`
  width: 95%;
  margin: 0 auto;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  background-color: #fff;
`

const TableToolBarStyles = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 1.2rem 1rem .5rem 1rem;
  border: 1px solid rgb(224, 224, 224);
  border-bottom: none;
  background-color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  .MuiToolbar-gutters {
    padding: 0;
  }
`
const NoRows = styled.span`
  color: #777;
`

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, search} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHeadStyles>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : 'left'}
            style={{fontSize: '15px', fontWeight: '600'}}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
            {headCell.search ? search : null}
          </TableCell>
        ))}
        <TableCell
          align='center'
          style={{fontSize: '15px', fontWeight: '600'}}
          >
            Actions
          </TableCell>
      </TableRow>
    </TableHeadStyles>
  );
}
 

const EnhancedTableToolbar = (props) => {
  const { add, title, count = 0, numSelected, link, switchField, search } = props;

  return (
    <TableToolBarStyles>
    <Toolbar
      // style={{height: '8rem'}}
    >
      {numSelected > 0 && (
         <span style={{display: 'block', width: '120px'}}> {numSelected} selected</span>
      ) 
      }

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <Delete />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterList />
          </IconButton>
        </Tooltip>
      )}
     
      
      <Grid container spacing={3} alignItems="center" justifyContent="flex-start">
        <Grid item xs={3}>
         {switchField || null}
        </Grid>
        <Grid item xs={5}>
          {search || null}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          {/* <Link to={`/${link}/edit/`}> */}
            {/* <Fab size="small" color="primary">
              <Add />
            </Fab> */}
            {add ? <ButtonLink color='primary' variant='contained' size='small' href={`/${link}/edit/`} startIcon={<Add /> }>New {title.slice(0, -1)}</ButtonLink>  : null }
          {/* </Link> */}
        </Grid>
      </Grid>
    </Toolbar>
    <GridBreak>
      <span>{count} Results</span>
    </GridBreak>
    </TableToolBarStyles>
  );
};

const SkeletonContainer = styled(TableBody)`
/* 
  &.MuiTableBody-root {
    border: none;
  }
  .MuiTableRow-root {
    height: 40px;
    border: none;
  }
  .MuiTableCell-root {
    border: none;
  } */
`


export default function EnhancedTable({loading, title, headers, rows, link, view = true, write = true, trash = true, add = true, switchField, search, ord, ordField}) {

  const [liveRows, setRows] = useState([])

  useEffect(() => {
    setRows(rows);
  }, [rows])

  const { user } = useAuthContext()

  const drpp = user.settings?.general?.defaultRpp

  const [order, setOrder] = useState(ord ? ord : 'asc');
  const [orderBy, setOrderBy] = useState(ordField ? ordField : '');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(drpp ? drpp : 25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = liveRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {handleClick: sb} = useSBContext();

  const trashFunc = async (id) => {
    try {
      await axios.delete(`/${link}/${id}`);
      setRows(state => state.filter(el => el._id !== id))
      sb('success', 'Successfully deleted')
    } catch (e) {
      sb('error', handleErrors({e}))
    }
    return 'deleted';
  }

  const columns = headers.length + 2

  const Skeletons = () => ( 
    <SkeletonContainer>
      {[...Array(5)].map((el, i) => (
        <TableRow key={i}>
          {[...Array(columns)].map((cur, ind) => (
            <TableCell key={ind}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </SkeletonContainer>
    )
  
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, liveRows.length - page * rowsPerPage);


  // const formatDisplay = (obj1, obj2) => {
  //   const {render} = obj1
      
  //   let string = ''
  //   const val = render ? render(obj2) : get(obj2, obj1.id)

  //   if (obj1.currency && obj2.rateType !== 'percentage') string = currencyFormat(val)
  //   else if (obj1.currency && obj2.rateType === 'percentage') string = `%${val}`
  //   else if (obj1.date) string = formatGenericDate(val)
  //   else string = val

  //   return string || string === 0 ? string : '-';
  // }


  return (
    <div>
      <DisplayPaper elevation={0}>
        <TableHeader>
          {title} 
        </TableHeader>
        <EnhancedTableToolbar count={liveRows.length} add={add} title={title} numSelected={selected.length} link={link} switchField={switchField} search={search} />
        <TableContainer>
          <MainTable
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={liveRows.length}
              headCells={headers}
              search={search}
            />
            {loading ? <Skeletons /> :
            <TableBody>
               
              {stableSort(liveRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {headers.map(cur => 
                      <TableCell key={cur.id} align={cur.align ? cur.align : 'left'}>
                       {formatDisplay(cur, row)}
                      </TableCell>
                      )}
                      <TableCell align="center">
                        <ActionsContainer>
                         { write &&
                         <Link to={`/${link}/edit/${row._id}`} ><Edit className="edit-icon" /></Link>
                         }
                         { view &&
                          <Link to={`/${link}/view/${row._id}`} ><Search className="edit-icon" /></Link>
                         }
                          { trash && 
                            <AlertDialog toggle={
                              <Delete className="hover-icon" color="primary" />
                            } 
                            title="Delete?"
                            onSave={() => trashFunc(row._id)}
                            save="Comfirm Delete"
                            size="xs"
                            >
                              Deleting will permanently remove this information
                              from the system.
                            </AlertDialog>
                         }
                        </ActionsContainer>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan="100" />
                </TableRow>
              )}
            </TableBody>
            }
          </MainTable>
        </TableContainer>
        {!loading ? 
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={liveRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> : null}
      </DisplayPaper>
    </div>
  );
}