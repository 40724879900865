import React, {useState, useEffect} from 'react';
// import styled from 'styled-components';
import axios from '../../axios';
import {Route, Switch} from 'react-router-dom';
import ProtectedRoute from '../../utils/ProtectedRoute';

import Table from '../../utils/table';

import AddUser from './AddUser';
import ViewUser from './ViewUser';
 
export default (props) => {

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false)

  const load = async () => {
    setLoading(true)
    const {data} = await axios.get('/users/')
    setRows(data);
    setLoading(false)
  }

  useEffect(() => {
    if (props.location.pathname !== '/users/') return
    load();
  }, [props.location.pathname])

  const headers = [
    { id: 'displayName', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'username', label: 'Username' },
    { id: 'role.name', label: 'Role' }
  ]

  const path = props.match.url;
 
 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeUser' component={AddUser} />
    <ProtectedRoute path={`${path}/edit`} guard='writeUser' component={AddUser} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readUser' component={ViewUser} />
    <Route path="/users/">
      <Table loading={loading} title="Users" headers={headers} rows={rows} link="users" trash={false} />
    </Route>
  </Switch>
 )
}
