import React from 'react'
import styled, { css } from 'styled-components';

const Icon = styled.span`
  #Layer_2 {
    fill: ${props => props.color || "currentColor"};
    width: 1.1em;
    height: .9em;
    display: inline-block;
    font-size: 2.4rem;

    ${props => props.fontSize === "large" && css`
      font-size: 3.2rem;
    `}
  }
`
export default ( { color, fontSize = "medium" } ) => {
 
 return (
   <Icon color={color} fontSize={fontSize}>
      <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 894 965" style={{enableBackground: "new 0 0 894 965"}} xmlSpace="preserve">
      <path d="M372.5,42.5c0,0-90-5-163,101c-33,41-39,113-39,120s-5,62-32,75s-57,78-44,121s1,101-21,117s-49,45-41,92c4,24,17,25,11,44
      s-33,24-25,88c12,51,25,62,25,62s-4-57,8-61s55,79,36,87c-14,12,82-41,122-42s130-11,158-98c19-91,11-93,11-93s-114-93-112-152
      c0,0-5-39,4-79s8-61,8-61s51-65,54-82c22,27,12,74-4,84s30-3,46-44s-1-78-1-78s12-34,28-35c13,25,57,92,142,101c0,0,23,97,85,118
      c0,0,10,153-91,226c0,0-1,101,39,143s110,56,128,54c0,0-16,51-1,73s41,31,41,31s-9-50,13-68s37-16,37-16l26,30c0,0,19-9,36-60
      s4-78,4-78s-44-20-47-59c4-39,6-50,6-50s11-8,13-28s-26-34-29-42s-4-19,15-38s16-56,10-75s-37-75-61-96c0,0-1-99-4-117
      s-35-106-125-181c-48-32-106-58-106-58s-68-15-103-8S376.5,25.5,372.5,42.5z"/>
    </svg>
  </Icon>
 )
}