import React, { useState, useEffect, useCallback } from 'react';

import axios from '../axios';
import { debounce } from 'lodash';

import { useSBContext } from '../utils/snackbar';

const useLoadList = (url) => {
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false)

  const [switcher, setSwitch] = useState(false);

  const [tabFilter, setTabFilter] = useState("");

  const [count, setCount] = useState(0);

  const [search, setSearch] = useState({
    p: '', rpp: '', sp: '', so: ''
  });

  const [query, setQuery] = useState({

  })

  const { handleClick } = useSBContext();

  const getQueries = (queries) => {
    let queryStr = ''
    Object.keys(queries).forEach(key => {
      if (Array.isArray(queries[key])) {
        queryStr = queryStr + `&${key}=${queries[key].join(',')}`
      } else {
        queryStr = queryStr + `&${key}=${queries[key]}`
      }
    })
    if (tabFilter) {
      queryStr = queryStr + `&tab=${tabFilter}`
    }
    return queryStr;
  } 

  const load = async (page, rowsPerPage, sortProp, sortOrder) => {
    setSearch({p: page, rpp: rowsPerPage, sp: sortProp, so: sortOrder});
    setLoading(true)
    let queryString = `${url}?sort=${sortProp}&sortOrd=${sortOrder}&page=${page}&limit=${rowsPerPage}`;
    let additionalQueries = getQueries(query)
    queryString = queryString + additionalQueries

    console.log(queryString, "queryString", additionalQueries, "additionalQueries")
    try {
      const {data} = await axios.get(queryString)
      setLoading(false)

      setRows(data.docs || []);
      setCount(data.pages || 0)

      console.log(data.docs, "data.docs")

      if (!data.docs)
        handleClick('error', 'Error retrieving data')
    } catch (e) {
      setLoading(false)
      setRows([])
    }
  }

  const reload = async () => {
    const {p, rpp, sp, so} = search
    load(p, rpp, sp, so)
  }

  const handleQuery = (field, value) => {
    setQuery(x => ({
      ...x,
      [field]: value
    }))
  }
  
  const searchList = useCallback(debounce(async (val, setPage) => {
    let encodedVal = encodeURIComponent(val)

    const {rpp} = search
    let additionalQueries = getQueries(query)
    const {data} = await axios.get(`${url}?term=${encodedVal}&page=0&limit=${rpp}${additionalQueries}`)
    setPage(0)
    setQuery(x => ({
      ...x,
      term: encodedVal
    }));
    setRows(data.docs || []);
    setCount(data.pages || 0)
  }, 250), [search, switcher, tabFilter])

  useEffect(() => {
    const {p, rpp, sp, so} = search
    if (sp)
      load(0, rpp, sp, so, query)
  }, [switcher, tabFilter])

  return { load, loading, rows, count, searchList, setQuery, handleQuery, setSwitch, tabFilter, setTabFilter, query, reload }
}

export default useLoadList;
