import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, FastField } from 'formik';
import axios from '../../../axios';
import {Grid, Paper, Button, InputAdornment, 
  CircularProgress, FormHelperText, Switch, FormControl, FormControlLabel, MenuItem, InputLabel} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors } from '../../../utils/util';
import {useSBContext} from '../../../utils/snackbar';

import EditForm from '../../../utils/editForm';

import { FullDivider, SectionHeader, SectionHeaderRow } from '../../../utils/common';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`
 
const AddVendor = (props) => {

  const {id} =  props.match.params;
 
  const [info, SetInfo] = useState({})

  const handleSwitch = (e, setFieldValue) => {
    setFieldValue('active', e.target.checked)
  };


  const loadData = async () => {
    const {data} = await axios.get(`/vendors/${id}`)
    SetInfo(data);
  }

  useEffect(() => {
    if (id) 
      loadData();
  }, [])


  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Vendor successfully updated" : "Vendor successfully added";

  let crumbs = [{path: '/vendors/', text: 'Vendors'}];

  if (action === "edit")
    crumbs.push({path: `/vendors/view/${id}`, text: 'View'})

  crumbs.push({path: '/vendors/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
    displayName: Yup
      .string()
      .required('Name is required.'),
    email: Yup
      .string()
      .email(),
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {


    try {
      let query = !id ? axios.post('/vendors/', {...userInfo}) : axios.put(`/vendors/${id}`, {...userInfo});

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/vendors/view/${id || data._id}`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }
 
 return (
 <EditForm title={action === "edit" ? "Edit Vendor" : "New Vendor"} header="Vendor" crumbs={crumbs}>
   <Formik
    enableReinitialize
    initialValues={
      info.name ? {...info} : {
        displayName: '',
        companyName: '',
        vendorType: 'manufacturing',
        email: '',
        phone:'',
        altPhone: '',
        address: {
          name: '',
          street:'',
          city: '',
          state: '',
          zip: '',
          country: ''
        },
        notes: '',
        active: true
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, values, setFieldValue, errors }) => (
        <Form>
          <Grid container spacing={4}>
          <SectionHeaderRow item xs={12}>
            <SectionHeader>Company Info</SectionHeader>
          </SectionHeaderRow>

            <Row item xs={12} sm={6}>
            <FastField
            component={TextField}
            name="displayName"
            type="text"
            label="Name"
            variant="outlined"
            fullWidth
          />
          <FormHelperText>Internal, not visible on invoices</FormHelperText>
            </Row>
            <Row item xs={12} sm={6}>
              <FastField
                component={TextField}
                name="companyName"
                type="text"
                label="Company Name"
                variant="outlined"
                fullWidth
              />
             <FormHelperText>Internal, not visible on invoices</FormHelperText>
            </Row>

            <Row item xs={12} sm={6}>
            <FastField
            component={TextField}
            name="email"
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
          />
            </Row>

            <Row item xs={12} sm={6}>
            <FastField
            component={TextField}
            name="phone"
            type="phone"
            label="Phone"
            variant="outlined"
            fullWidth
          />
            </Row>

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Address</SectionHeader>
            </SectionHeaderRow>

            <Row item xs={12} lg={5}>
              <FastField
                component={TextField}
                name="address.name"
                type="text"
                label="Company Name"
                variant="outlined"
                fullWidth
              />
              <FormHelperText>Displayed on invoices</FormHelperText>
            </Row>

            <Row item xs={12} lg={7}>
              <FastField
                component={TextField}
                name="address.street"
                type="text"
                label="Address"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={6} sm={3}>
            <FastField
            component={TextField}
            name="address.city"
            type="text"
            label="City"
            variant="outlined"
            fullWidth
          />
            </Row>

            <Row item xs={6} sm={3}>
            <FastField
            component={TextField}
            name="address.state"
            type="text"
            label="State"
            variant="outlined"
            fullWidth
          />
            </Row>

            <Row item xs={6} sm={3}>
            <FastField
            component={TextField}
            name="address.zip"
            type="text"
            label="Zip"
            variant="outlined"
            fullWidth
          />
            </Row>

            <Row item xs={6} sm={3}>
            <FastField
            component={TextField}
            name="address.country"
            type="text"
            label="Country"
            variant="outlined"
            fullWidth
          />
            </Row>

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

          <Row item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" style={{width: '100%'}} >
              <InputLabel id="role-label">Vendor Type</InputLabel>
              <Field
                  component={Select}
                  name="vendorType"
                  id="vendorType"
                  label="Vendor Type"
                  fullWidth
                >
                  <MenuItem value="manufacturing">Manufacturing</MenuItem>
                  <MenuItem value="services">Services</MenuItem>
              </Field>
              </FormControl>
            </Row>
          <Row item xs={12} md={3}>
          <FormControlLabel
            control={
              <Switch
                checked={values.active}
                onChange={(e) => handleSwitch(e, setFieldValue)}
                name="isActive"
                color="primary"
              />
            }
            label="Active"
            />
          </Row>

          {/* {console.log(values, "chehdhchchc")} */}

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>More Info</SectionHeader>
            </SectionHeaderRow>
            
            <Row item xs={12} sm={6}>
            <Field
            component={TextField}
            name="notes"
            type="text"
            label="Notes"
            multiline
            minRows={4}
            variant="outlined"
            fullWidth
          />
            </Row>
            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
 </EditForm>
 )
}

export default AddVendor;