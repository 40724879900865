import styled, { css } from "styled-components";

import CustomChip from "../../../utils/CustomChip";
import { Header } from "../../../utils/common";

import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';


const Chip = styled(CustomChip)`
  width: fit-content;
  font-size: 1.1rem;
  padding: 0 .3rem;
  margin-top: .2rem;
`;

const ItemAccordion = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 10px;
`;


const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 10px;
  position: relative;
  /* width: 97%; */
  border: 1px solid ${props => props.theme.colors.border};
  border-left: 3px solid ${props => props.err ? props.theme.colors.error : props.theme.colors.secondaryMain};
  border-radius: 5px;
`

const WigInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  span {
    display: block;
  }
`

const WigInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap ? props.gap : ".5rem"};
  font-weight: 500;
  min-width: 12rem;
`;

const WigInfoItemHeader = styled(Header)`
  font-size: 1.1rem;
`;

const WigActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

const ItemRepairListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemRepairList = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
  margin: 0 .2rem;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  margin-top: -.1rem;

  ${props => props.expanded && css`
    grid-template-rows: 1fr;
    border-color: ${props.theme.colors.border};
    margin-top: 0;
  `}
`;

const Repairs = styled.div`
  /* padding: .5rem 0; */
  overflow: hidden;
`;

const ItemRepairListHandleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 3rem;
`;

const ItemRepairListHandle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  width: 10rem;
  font-size: 1.1rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  padding: .2rem;
  color: ${props => props.theme.colors.secondary25};
  background-color: ${props => props.theme.colors.secondary90};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.secondary75};
  }

  span {
    display: inline-block;
  }
`;

const ChipContainer = styled.div`
  width: max-content;
`

const ChipFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
`;

const ChipBlock = ({level, fontSize, text, icon}) => {
  return (
    <ChipContainer>
      <CustomChip level={level} fontSize={fontSize}>
        <ChipFlex>
        <ArrowRightAltRoundedIcon /> <span>{text}</span>
        </ChipFlex>
      </CustomChip>
    </ChipContainer>
  )
}

const getChipInfo = (status) => {
  let level = "info";
  if (status === "sold") {
    level = "success";
  } else if (status === "consignment") {
    level = "icon3";
  }
  return level;
}

const getRepairStatus = (status) => { 
  switch(status) {
    case 'in-progress':
      return "info"
    case 'complete':
      return "success"
    default:
      return "info"
  }
}

const getTransferType = (to) => {
  switch (to) {
    case 'hq':
      return <ChipBlock level="info" fontSize="small" text="HQ" />
    case 'repair':
      return <ChipBlock level="icon3" fontSize="small" text="Repair" />
    case 'salon':
      return <ChipBlock level="icon4" fontSize="small" text="Salon" />
    default:
      return <ChipBlock level="info" fontSize="small" text="HQ" />
  }
}

export {
  Chip,
  getChipInfo,
  getRepairStatus,
  getTransferType,
  WigInfo,
  WigInfoItem,
  WigInfoItemHeader,
  WigActions,
  ItemContainer,
  ItemAccordion,
  Repairs,
  ItemRepairList,
  ItemRepairListContainer,
  ItemRepairListHandle,
  ItemRepairListHandleContainer
}