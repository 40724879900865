import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../../axios';
import ProtectedRoute from '../../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import useLoadList from '../../../hooks/useLoadList';

import { theme } from '../../../utils/theme';

import Table from '../../../utils/table-server';
import EditService from './AddService';
// import ViewCustomer from './ViewCustomer';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Switch as Switcher, FormControlLabel} from '@material-ui/core'
import Search from '@material-ui/icons/Search';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';

import Chip from '../../../utils/Chip';
import CustomChip from '../../../utils/CustomChip';

import { BasicFlex } from '../../../utils/common';

const SwitchContainer= styled(FormControlLabel)`
  width: 300px;
  margin-left: 30px;
`

const ChipContainer = styled.div`
  width: max-content;
`

const ChipFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
`;

const ColorDisplay = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${props => props.color || "#0ff"};
  border-radius: 4px;
  border: 1px solid #999;
`;

const ChipBlock = ({level, fontSize, text, icon}) => {
  return (
    <ChipContainer>
      <CustomChip level={level} fontSize={fontSize}>
        <ChipFlex>
        <ArrowRightAltRoundedIcon /> <span>{text}</span>
        </ChipFlex>
      </CustomChip>
    </ChipContainer>
  )
}
 
const Services = (props) => {
  const {rows, loading, load, searchList, count, switcher, setSwitch, tabFilter, setTabFilter} = useLoadList('/services');
  

  const headers = [
    { id: 'name', label: 'Name' },
    { id: 'category', label: 'Category' },
    { id: "price", currency: true, label: "Price" },
    { id: 'duration', render: (row) => `${row.duration} Day${row.duration > 1 ? 's' : ''}`, label: 'Estimated Time' },
    { id: "color", render: row => <ColorDisplay color={row.color} />, label: "Color"},
    { id: 'date', label: 'Notes' }
  ];

  const path = props.match.url;

  const handleSwitch = (e) => { 
    setSwitch(e.target.checked);
  };


 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeService' component={EditService} />
    <ProtectedRoute path={`${path}/edit`} guard='writeService' component={EditService} />
    {/* <ProtectedRoute path={`${path}/view/:id`} guard='readCustomer' component={ViewCustomer} /> */}
    <Route path="/services">
      <Table loading={loading} load={load} title="Services" headers={headers} rows={rows} link="services" count={count} ord="asc" ordField="name"
      
      search={setPage => <TextField name="search" label="Search by Service Name"
      variant="outlined" size="small" fullWidth onChange={(e) => searchList(e.target.value, setPage)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}

export default Services;