import React, {useState, useEffect, useCallback} from 'react';
// import styled from 'styled-components';
import axios from '../../axios';
import {Switch, Route} from 'react-router-dom';
import ProtectedRoute from '../../utils/ProtectedRoute';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import { debounce } from 'lodash'

import Table from '../../utils/table';

import AddShipment from './AddShipment';
import ViewShipment from './ViewShipment';
 
export default (props) => {
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false)

  const load = async () => {
    setLoading(true)
    const {data} = await axios.get('/shipments/')
    setRows(data);
    setLoading(false)
  }

  const searchShipment = useCallback(debounce(async val => {
    const {data} = await axios.get(`/shipments?term=${encodeURIComponent(val)}`)
    setRows(data);
  }, 250), [])

  useEffect(() => {
    if (props.location.pathname !== '/shipments/') return
    load();
  }, [props.location.pathname])

  const headers = [
    { id: 'shipmentNum', label: 'Num' },
    { id: 'brand', label: 'Brand' },
    { id: 'totalQty', label: 'Qty'},
    { id: 'cost.total', currency: true, label: 'Total'},
    { id: 'date', date: true, label: 'Date' }
  ];

  const path = props.match.url;

 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeShipment' component={AddShipment} />
    <ProtectedRoute path={`${path}/edit`} guard='writeShipment' component={AddShipment} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readShipment' component={ViewShipment} />
    <Route path="/shipments">
    <Table loading={loading} title="Shipments" headers={headers} rows={rows} link="shipments" write={false} ord="desc" ordField="date"
    search={<TextField name="search" label="Search by Shipment Num"
    variant="outlined" size="small" fullWidth onChange={(e) => searchShipment(e.target.value)}
    InputProps={{
      endAdornment:
        <InputAdornment position="end">
          <Search color="primary" />
        </InputAdornment>
    }}
    />} 
    />
    </Route>
  </Switch>
 )
}