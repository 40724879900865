import React from 'react'
import styled from 'styled-components'
import history from '../history'
import moment from 'moment';
import { DateTime } from 'luxon';
import { sumBy, get, truncate } from 'lodash'
import { ErrorMessage } from 'formik';
import axios from '../axios';
import { Tooltip } from '@material-ui/core';


const ErrorRed = styled(ErrorMessage)`
  color: rgb(244, 67, 54);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 1.2rem;
`

const CustomErrStyles = styled.div`
  color: rgb(244, 67, 54);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 1.2rem;
`

const TooltipText = styled.span`
  font-size: 1.2rem;
`

export const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

export const ErrorMessageRed = props => <ErrorRed {...props} component="div" />

export const CustomErrMsg = props => {
  return (
  <>
  {props.errors[props.name] && props.touched[props.name] ?
  <CustomErrStyles {...props}>
     {props.errors[props.name]}
  </CustomErrStyles>
  : null}
  </>
  )
}

const ivd = (date) => { // local version of isValidDate
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const capitalizeWords = (name = "") => {
  let splitted = name.split(/\s+/)
  let names = splitted.map(el => {
      const slash = el.split('/')
      let mod = [], combined;
      if (slash.length > 1){
          slash.forEach(cur => {
              mod.push(capitalize(cur))
          })
          combined = mod.join('/');
          return combined;
      } else {
          return capitalize(el);
      }
  })
  let final = names.join(' ');
  return final;
}

export const getReturnUrl = () => {
  const returnUrl = history.location.pathname.slice(1)
  const queryParams = returnUrl  ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''
  return queryParams
}

export const autoCompleteRender = (wig) => {
  let status = wig.status === "consignment" ? "consigned" : wig.status
  return (
  <span>{wig.wigId} &nbsp; - &nbsp; {status} {wig.status !== "stock" ? ` to ${wig.currentlyAt?.name}` : ""} {wig.isAssigned ? ` / Assigned To ${wig.assignedTo?.name}` : ""} &nbsp; - &nbsp; {wig.skuDisplay || wig.sku}</span>
  )
}

export const getRedirectUrl = () => {
  const redirectUrl = new URLSearchParams(history.location.search).get('returnUrl')

  if (redirectUrl)
    return `/${redirectUrl}`
  else
    return `/`
}

export const formatDateTime = val => DateTime.fromISO(val).toFormat('cccc, MMMM dd, yyyy, h:mm a') 

export const formatDateTime2 = val => DateTime.fromISO(val).toFormat('ccc, LLL dd, h:mm a')  // no year

export const formatDate = val => moment(val).format('dddd, MMMM Do YYYY') 

export const formatCalendar = val => moment(val).calendar()

export const formatNiceDate = val => {
  if (!val) return val;
  let newDate = new Date(val)
  if (!ivd(newDate)) {
    return val
  }

  return DateTime.fromJSDate(newDate).toFormat('MMMM dd, yyyy')
}

const formatDateStr = date => {
  return new Date(date).toLocaleDateString()
}

const formatGenericDate = date => {
  let newDate = DateTime.fromISO(date)

  if (newDate.invalid)
    newDate = DateTime.fromJSDate(date)

  // console.log(date, newDate, newDate.invalid, "NEW DATRATATATTA");
  return newDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
};

export const getDaysDiff = date => {
  return DateTime.local().diff(DateTime.fromISO(date), 'days').toObject().days
}

export const dateEnd = (date, returnType) => {
  if (!date || !ivd(new Date(date))) {
    return date
  }
  let dateStr = DateTime.fromISO(new Date(date).toISOString()).endOf('day').toUTC().toISO()
  if (!returnType || returnType === 'string')
    return dateStr
  else
    return new Date(dateStr)
}

const dotToArrayPath = path => {
  const re = /\.(\d+)/g;
  return path.replace(re, (match, p1) => `[${p1}]`)
}

export const fxn = (num, dec) => +num.toFixed(dec)

export const getPercent = (val, divisor, format) => {
  let percent = 0;
  if (!val || !divisor || typeof(+val) !== 'number' || typeof(+divisor) !== 'number') return percent;
  
  percent = +(+val / +divisor * 100).toFixed(1)

  if (format === "str") percent = `${percent}%`

  return percent;
} 

export const handleErrors = ({e, setErrors, time=2500}) => {
  let displayMessage = ''

  const data = e?.response?.data
  // console.log(data, "ERORORORORO");
  if (data) {
    const {errors, message} = data
    // console.log(errors, data);
    if (typeof errors === 'object' && Object.keys(errors).length) {
      const parsedErrors = []
      console.error(errors)
      for (const key in errors) {
        parsedErrors.push({name: dotToArrayPath(key), message: errors[key]})
        displayMessage += errors[key]
      }
      if (setErrors){
        return setErrors(errors)
      }
    }

    displayMessage = displayMessage || data.displayMessage || message
  }

  const message = displayMessage || 'An error has occured. Please try again.'
  // toast.error(message, {autoClose: time})
  return message;
}

const currencyFormat = (number) => typeof number === 'number' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number) : `$0.00`


export const rateCalc = (amount, rateType, total) => {
  if (rateType === 'fixed')
    return amount
  
    return (amount / 100) * total 
}

export const calculateTotal = (wigs, charges) => {
    let total = 0;
    let subtotal = 0;
    let subtotalCharges = 0;
    let wigObj = {};

    if (wigs.length) {

      console.log(wigs, "WIGS")
      
      for (let wig of wigs) {
        wigObj[wig.wigId] = wig.price;
      }

      subtotal = sumBy(wigs, 'price') || 0;
      subtotalCharges = sumBy(wigs, 'price') || 0;
      
      total = subtotal;
    }

    let chargesTotal = 0;

    if (charges.length) {

      charges.forEach(el => {
        if (el.wigId) {
          let amount = rateCalc(el.amount, el.rateType, wigObj[el.wigId])
          // if (el.chargeType === "surcharge") {
          //   subtotalCharges += amount
          //   chargesTotal += amount
          // } else {
          //   subtotalCharges -= amount
          //   chargesTotal -= amount
          // }
          chargesTotal = el.chargeType === "surcharge" ? chargesTotal + amount : chargesTotal - amount;
        } else {
          /* NEW STUFF! pw discount for each item. */
          if (el.chargeType === "discount" && el.rateType === "fixed" && el.pw) {
            chargesTotal -= el.amount * (wigs.length || 1)
          } else {
            chargesTotal = el.chargeType === "surcharge" ? chargesTotal + rateCalc(el.amount, el.rateType, subtotal) : chargesTotal - rateCalc(el.amount, el.rateType, subtotal);
          }
        }
      });
    }

    total = subtotal + chargesTotal;

    return {total, subtotal};
}


export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    // console.log("heheheheeh", data, parentKey);
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export const calculatePayIndicator = (total, paid) => {
  let status, text;
  if (paid >= total) {
    status = "success"; text = "Paid"
  } else {
    if (paid > 0) {
      status = "partial"; text = "Partially Paid"
    } else {
      status = "fail"; text = "Not Paid"
    }
  }

  return {status, text}
}

export const formatPhone = (num) => {
  if (!num) return num;

  num = num.replace(/\D+/g, '');

  // console.log(num, "ccckckc");
  if (num.length === 7) {
    num = `${num.slice(0, 3)}-${num.slice(3)}`
  } else if (num.length === 10) {
    num = `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`
  } else if (num.length === 11) {
    num = `+${num[0]} (${num.slice(1, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`
  } else {
    num = num;
  }
  return num;
}

export const downloadFile = async ({url, method="GET", contentType = "text/csv; charset=utf-8"}) => {

  const data = await axios({
    method: method,
    url: url,
    responseType: 'blob',
    timeout: 60000,
    headers: {
      "Content-Type": contentType,
      "Content-disposition": "attachment;"
    }
  })
  console.log(data, "heeeeyy");

  let filename = data.headers["content-disposition"].split("filename=")[1]

  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(data.data);
  elem.download = filename;        
  document.body.appendChild(elem);
  elem.click();        
  document.body.removeChild(elem);
}

export const getIndicatorStatus = (balance, total) => {
  let text, status;
  if (balance <= 0) {
    status = "success"; text = "Paid"
  } else if (balance < total) {
    status = "partial"; text = "Partially Paid"
  } else if (balance >= total) {
    status = "fail"; text = "Not Paid"
  }
  return {status, text}
} 

export const formatDisplay = (obj1, obj2) => {
  const {render} = obj1
    
  let string = ''
  let val = render ? render(obj2) : get(obj2, obj1.id)

  val = val || val === 0 ? val : '-';

  if (obj1.currency && obj2.rateType !== 'percentage') {
    string = obj1.noZero && val == 0 ? "-" : currencyFormat(val);
  }
  else if (obj1.currency && obj2.rateType === 'percentage') string = `%${val}`
  else if (obj1.date) string = formatGenericDate(val)
  else string = val

  if (!render && obj1.truncate) {
    let len = typeof obj1.truncate === 'number' ? {length: obj1.truncate} : {};
    console.log(len)
    let newString = truncate(string, {...len})
    string = newString && newString !== "-" ? <Tooltip title={<TooltipText>{string}</TooltipText>} arrow><span>{newString}</span></Tooltip> : string;
  }
  
  return string || string === 0 ? string : '-';
}

export const splitArr = (arr = [], testFunc) => {
  let orig = [], filtered = [];
  if (arr.length) {
    arr.forEach(el => {
      let result = testFunc(el)
      if (result) {
        filtered.push(el)
      } else {
        orig.push(el)
      }
    })
  }

  return {orig, filtered}
}

const splitSku = (sku) => {
  if (!sku) return sku;
  let split = sku.split('-')
  if (!split.length > 1) return sku;
  let skuObj = {
    sku: sku,
    style: split[0],
    length: split[1],
    capSize: split[2],
    hairstyle: split[3],
  }
  return skuObj;
}

const findFeaturesByQty = (wigs = []) => {
  let list = {};
  if (wigs.length) {
    wigs.forEach(el => {
     let skuObj = splitSku(el.sku)
     let base = el.baseMaterial ? `-${el.baseMaterial}` : '';
     let key = `${skuObj.style}${base}`
      if (list[key]) {
        list[key].qty += 1;
        if (list[key].lengths[skuObj.length]) {
          list[key].lengths[skuObj.length] += 1;
        } else {
          list[key].lengths[skuObj.length] = 1;
        }
      } else {
        list[key] = {key, qty: 1, style: skuObj.style, lengths: {[skuObj.length]: 1}, baseMaterial: el.baseMaterial}
      }
     
    })
  }
  return Object.values(list).sort((a, b) => a.style.localeCompare(b.style));
}

export const createFilterQuery = (filter) => {
  let filtered = [];
  let filters = '';
    let keys = Object.keys(filter);
    if (keys.length) {
      keys.forEach(el => {
        filtered.push(`filter[${el}]=${filter[el]}`)
      })
      filters = filtered.join('&')
    }
  return filters;
}


export const createNewOption = (list, newValue, text = "Value") => {
  if (newValue !== '') {
    list.push(
      {
        name: newValue,
        display: `Create ${text} "${newValue}"`
      }
    );
  }
  return list;
}

const paymentMethods = [
  {name: 'cash', display: 'Cash'},
  {name: 'Check', display: 'Check'},
  {name: 'Credit Card', display: 'Credit Card'},
  {name: 'Quick Pay', display: "Quick Pay"},
  {name: 'Bank Account', display: "Bank Account"},
  {name: 'Other', display: 'Other'},
]


export { currencyFormat, formatGenericDate, capitalize, splitSku, findFeaturesByQty, formatDateStr, paymentMethods };


