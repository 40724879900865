import React from 'react';
import styled from 'styled-components';

import {Grid, Select, MenuItem, FormControl, InputLabel, FormHelperText, Checkbox, ListItemText, Button} from '@material-ui/core';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';

import Chip from '../../../utils/Chip';

import { Flex } from '../../../utils/common';

import { theme } from '../../../utils/theme';

import OutlinedSelect from '../../../utils/OutlinedSelect';


const FilterContainer = styled.div`
  width: 300px;
`;

const ChipContainer = styled(Flex)`
  gap: 1rem;
`

const FilterHeader = styled.span`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.lightText};
  display: block;
  margin-bottom: 1rem;
`;

const EmptyValue = styled(MenuItem)`
  color: ${props => props.theme.colors.lightText};
`


const FilterComponent = ({ handleQuery, query, options, applyFilter }) => {

  const { repairTypes = [], vendors = [] } = options;
  const { repairType = [], vendor = '', status = '' } = query;

  console.log(options, "options")

  return (
    <FilterContainer>
    <Grid container spacing={3}>
      <Grid item xs={12}>
      <FormControl variant="outlined" fullWidth>
        <FilterHeader>Filter Service Types</FilterHeader>
        {/* <InputLabel id="service-label">Filter Service Types</InputLabel> */}
        <Select
          labelId="service-label"
          id="service-search"
          multiple
          value={repairType}
          onChange={(e) => handleQuery("repairType", e.target.value)}
          // label="Filter Service Types"
          fullWidth
          renderValue={(selected) => (
            // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            <ChipContainer dir="row" justify="flex-start" pad="0">
              {selected.map((value) => (
                  <Chip key={value} bgcolor={theme.colors.success} label={value} />
              ))}
            </ChipContainer>
            // </Box>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            // transformOrigin: {
            //   vertical: "top",
            //   horizontal: "left"
            // },
            getContentAnchorEl: null
          }}
        >
        {repairTypes.map(({name, _id}) => 
            <MenuItem value={name} key={name}>
              <Checkbox 
                checked={repairType.indexOf(name) > -1} 
              />
              <ListItemText primary={name} />
              </MenuItem>
          )}
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12}>
      <FormControl variant="outlined" fullWidth>
        <FilterHeader>Filter By Vendor</FilterHeader>
        <OutlinedSelect color={theme.colors.primaryLight} val={vendor} onChange={(e) => handleQuery("vendor", e.target.value)}
          >
            <EmptyValue value="">View All</EmptyValue>
          {vendors.map(({name, _id}) =>
            <MenuItem value={name} key={name}>{name}</MenuItem>
          )}
        </OutlinedSelect>
      </FormControl>
      </Grid>
      {/* <Grid item xs={12}>
      <FormControl variant="outlined" fullWidth>
        <FilterHeader>Filter By Status</FilterHeader>
        <OutlinedSelect color={theme.colors.primaryLight} val={status} onChange={(e) => handleQuery("status", e.target.value)}>
          <MenuItem value="in-progress">In Progress</MenuItem>
          <MenuItem value="complete">Complete</MenuItem>
        </OutlinedSelect>
      </FormControl>
      </Grid> */}
      <Grid item xs={12}>
        <Button startIcon={<FilterListRoundedIcon />} variant="contained" size="small" color="secondary" onClick={applyFilter}>Apply Filters</Button>
      </Grid>
    </Grid>
    </FilterContainer>
  )
}

export default FilterComponent;