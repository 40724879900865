import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {Grid, Button, 
  CircularProgress} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import FormLabel from '@material-ui/core/FormLabel';
// import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import EditForm from '../../utils/editForm';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`
 
export default (props) => {

  const {id} =  props.match.params;

  const [checks, setChecked] = useState({
    all: false,
    readUser: false,
    writeUser: false,
    readRole: false,
    writeRole: false,
    readCustomer: true,
    writeCustomer: true,
    readConsignment: true,
    writeConsignment: true,
    readOrder: true,
    writeOrder: true,
    readInventory: true,
    writeInventory: true,
    readShipment: true,
    writeShipment: true,
    readPayment: true,
    writePayment: true,
    readBrand: false,
    writeBrand: false,
    readAccounting: false,
    readSettings: false,
    writeSetting: false
  });

  const handleChange = (event) => {
    setChecked({ ...checks, [event.target.name]: event.target.checked });
  };

 
  const [info, SetInfo] = useState({})

  const loadData = async () => {
    const {data} = await axios.get(`/roles/${id}`)
    SetInfo({name: data.name});
    setChecked(data.permissions)
  }

  useEffect(() => {
    if (id) 
      loadData();
  }, [])

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Role successfully updated" : "Role successfully added";

  let crumbs = [{path: '/roles/', text: 'Roles'},{path: '/roles/edit', text: action === "edit" ? 'Edit' : 'Add'}];

  const EditSchema = Yup.object().shape({
    name: Yup
      .string()
      .required('Name is required.')
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {
    const role = {
      name: userInfo.name,
      permissions: {...checks}
    }

    try {
      let query = !id ? axios.post('/roles/', role) : axios.put(`/roles/${id}`, role);

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/roles/`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }
 
 return (
 <EditForm title={action === "edit" ? "Edit Role" : "New Role"} header="Role" crumbs={crumbs}>
   <Formik
    enableReinitialize
    initialValues={{
      name: info.name || ''
    }}
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting}) => (
        <Form>
          <Grid container spacing={4}>
            <Row item xs={6} sm={6}>
              <Field
              component={TextField}
              name="name"
              type="text"
              label="Name"
              variant="outlined"
              fullWidth
              />
            </Row>

            <Row item xs={12}>
              <FormLabel component="legend">Assign Admin Role</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.all} onChange={handleChange} name="all" />}
                  label="All Permissions"
                />
                </FormGroup>
                <FormHelperText>Be careful</FormHelperText>
            </Row>

            <Row container item xs={12}>

              <Grid item xs={3}>
              <FormLabel component="legend"> User Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readUser} onChange={handleChange} name="readUser" />}
                  label="View User"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeUser} onChange={handleChange} name="writeUser" />}
                  label="Write User"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend"> Role Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readRole} onChange={handleChange} name="readRole" />}
                  label="View Role"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeRole} onChange={handleChange} name="writeRole" />}
                  label="Write Role"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend"> Customer Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readCustomer} onChange={handleChange} name="readCustomer" />}
                  label="View Customer"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeCustomer} onChange={handleChange} name="writeCustomer" />}
                  label="Write Customer"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend"> Consignment Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readConsignment} onChange={handleChange} name="readConsignment" />}
                  label="View Consignment"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeConsignment} onChange={handleChange} name="writeConsignment" />}
                  label="Write Consignment"
                />
                </FormGroup>
              </Grid>
              

            </Row>

            <Row container item xs={12}>

              <Grid item xs={3}>
              <FormLabel component="legend"> Sale Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readOrder} onChange={handleChange} name="readOrder" />}
                  label="View Sale"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeOrder} onChange={handleChange} name="writeOrder" />}
                  label="Write Sale"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend"> Inventory Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readInventory} onChange={handleChange} name="readInventory" />}
                  label="View Inventory"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeInventory} onChange={handleChange} name="writeInventory" />}
                  label="Write Inventory"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend"> Shipment Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readShipment} onChange={handleChange} name="readShipment" />} 
                  label="View Shipment"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeShipment} onChange={handleChange} name="writeShipment" />}
                  label="Write Shipment"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend"> Payment Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readPayment} onChange={handleChange} name="readPayment" />}
                  label="View Payment"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writePayment} onChange={handleChange} name="writePayment" />}
                  label="Write Payment"
                />
                </FormGroup>
              </Grid>
              

            </Row>

            <Row container item xs={12}>

              <Grid item xs={3}>
              <FormLabel component="legend"> Brands Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readBrand} onChange={handleChange} name="readBrand" />}
                  label="View Brand"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeBrand} onChange={handleChange} name="writeBrand" />}
                  label="Write Brand"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
            <FormLabel component="legend"> Settings Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readSettings} onChange={handleChange} name="readSettings" />}
                  label="View Settings"
                />
                <FormControlLabel
                  control={<Checkbox checked={checks.writeSettings} onChange={handleChange} name="writeSettings" />}
                  label="Write Settings"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend"> Accounting Roles</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readAccounting} onChange={handleChange} name="readAccounting" />}
                  label="View Accounting"
                />
                </FormGroup>
              </Grid>

              <Grid item xs={3}>
              <FormLabel component="legend">Dashboard Permissions</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readDashboard} onChange={handleChange} name="readDashboard" />}
                  label="View Dashboard"
                />
                </FormGroup>
              </Grid>

            </Row>

            <Row container item xs={12}>

              <Grid item xs={3}>
              <FormLabel component="legend">Report Permissions</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checks.readReports} onChange={handleChange} name="readReports" />}
                  label="View Reports"
                />
                </FormGroup>
              </Grid>

            </Row>
           
            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
 </EditForm>
 )
}