import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Container = styled.div`
  
`

const DialogButtons = styled(DialogActions)`
  width: 96%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  /* min-height: 6rem; */

  button { 
    margin-right: 2rem;
  }
`

const Title = styled.h3`
  font-size: 1.8rem;
  font-weight: 500;
  margin: .5rem 0;
`

const Content = styled(DialogContent)`

background-color: #f6f6f6; 
box-shadow: inset 0 -3px 10px rgba(0, 0, 0, .14); 
overflow-x: hidden;
  
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
}

`


const FullDialog = ({toggle, title, text, children, onSave, save, forceOpen=false, forceClose=() => {}, cancel = true, ...props}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!open) forceClose()
    setOpen(false);
  };

  const handleSave = async (type) => {
    const saved = await onSave(type);
    if (!saved) return;
    if (!open) forceClose()
    setOpen(false);
  };

  return (
    <Container>
      <div onClick={handleClickOpen}>{toggle}</div>
      <Dialog  fullScreen open={open || forceOpen} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
      <AppBar position="static">
          <Toolbar variant='dense'>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <DialogTitle id="form-dialog-title"><Title>{title}</Title></DialogTitle>
            {/* <Typography variant="h6">
              Sound
            </Typography> */}
            {/* <Grid container>
              <Grid item xs={12}></Grid>
            </Grid> */}
            {/* <SaveButton>
              <Button autoFocus color="inherit" onClick={handleSave}>
                {save}
              </Button>
            </SaveButton> */}
          </Toolbar>
        </AppBar>
        {/* <DialogTitle id="form-dialog-title">{title}</DialogTitle> */}
        <Content>
         {children}
        </Content>
        <DialogButtons>
          {cancel ? <Button type="button" onClick={() => handleSave("consignment")} variant="contained" color="secondary">
            Create Consignment
          </Button> : null }
          <Button type="button" onClick={() => handleSave("order")} variant="contained" color="primary">
            {save}
          </Button>
        </DialogButtons>
      </Dialog>
    </Container>
  );
}

export default FullDialog;