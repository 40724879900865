import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  margin-top: 40%;
  font-size: 2rem;
  color: salmon;
`

const Unauthorized = props => {
 return (
   <Container>You are not authorized to view this page</Container>
 );
}

export default Unauthorized;