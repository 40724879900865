import React, {useState, useEffect, useCallback, useRef} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'
import {FormControlLabel, FormControl, FormLabel, Switch as Switcher, TextField, InputAdornment, Checkbox, FormGroup, IconButton, Grid, LinearProgress, Tooltip} from '@material-ui/core'
import Search from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import StoreMallDirectoryRoundedIcon from '@material-ui/icons/StoreMallDirectoryRounded';

import Chip from '../../utils/Chip';
import { theme } from '../../utils/theme';
import { getDaysDiff } from '../../utils/util'

import { useSBContext } from '../../utils/snackbar';
import { useCartContext } from '../cart/cartContext';
import { useAuthContext } from '../../auth';

import Table from '../../utils/table-server';
import ViewWig from './ViewWig';

import FormDialog from '../../utils/dialog';

import { debounce } from 'lodash'


const SwitchContainer= styled(FormControlLabel)`
  width: 300px;
  margin-left: 30px;
`

const FormGroupHeader = styled(FormLabel)`
  margin-top: 2rem;
`

const UpdatePriceHeader = styled.span`
  display: block;
  margin-bottom: 2rem;
`

const returnToStock = async (selected, hc, setInfo) => {
  try {
    const {data} = await axios.post('/consignments/stock', {wigIds: selected})
    setInfo(x => ({...x, rows: x.rows.filter(el => !selected.includes(el._id))}))
    hc("success", `${selected.length} Items checked in to inventory`)
    return true;
  } catch (e) {
    hc("error", `Something went wrong, please try again`)
    return false;
  }
}
 
const Inventory = (props) => {

  const [switcher, setSwitch] = useState(false);

  const [loading, setLoading] = useState(false)

  const [brandLoading, setBrandLoading] = useState(false)

  const [dialogLoad, setDialogLoad] = useState(false)

  const [prices, setPrices] = useState([])

  const [priceUpdate, setPriceUpdate] = useState([])

  const priceRef = useRef([])

  // const [rows, setRows] = useState([]);

  // const [count, setCount] = useState(0)

  const [brands, setBrands] = useState([])

  const [info, setInfo] = useState({
    rows: [],
    count: 0,
    brands: []
  })

  const [search, setSearch] = useState({
    p: '', rpp: '', sp: '', so: ''
  })

  const [query, setQuery] = useState('/inventory')

  const [filter, setFilter] = useState()

  const [filterOptions, setFilterOptions] = useState({
    all: true
  })

  const {handleClick} = useSBContext();
  const { user } = useAuthContext()
  const inv = user.system?.settings?.inventory

  const batchActions = [ // batch actions when items are checked
    {
      icon: <StoreMallDirectoryRoundedIcon color='secondary' />,
      title: "Check In to Stock",
      action: (selected) => returnToStock(selected, handleClick, setInfo)
    }
  ]

  const handlePriceUpdate = (e, i) => {
    let size = +e.target.name.split("-")[1]
    if (e.target.checked) {
      // console.log(priceRef.current[i].name);
      setPriceUpdate(x => x.concat({size, price: priceRef.current[i].value}))
    } else {
      setPriceUpdate(x => x.filter(el => el.size !== size))
    }
  }

  const updatePrices = async () => {
    let updates = priceUpdate.map(el => {
      let price = priceRef.current.find(cur => cur.name === `price-${el.size}`).value
      // console.log(price);
      return {...el, price: +price}
    })
    // console.log(updates);

    try {
      const {data} = await axios.put("/inventory/prices", {
        prices: [...updates]
      })
      handleClick('success', "Successfully updated prices")
      // console.log(data);

      return "done"
    } catch (e) {
      // console.log(e);
      handleClick('error', "Something went wrong, please try again.")
    }
    
  }

  const brandjs = {}

  const handleFilterOptions = (e) => {
    setFilterOptions({ ...filter, [e.target.name]: e.target.checked });
    if (e.target.checked) {
      setFilter({
        ...brandjs,
        status: {
        stock: false,
        consignment: false
        }
      })
      filterjs = {
        ...brandjs,
        status: {
        stock: false,
        consignment: false
        }
      }
    }
    const {p, rpp, sp, so} = search;
    load(p, rpp, sp, so)
  }

  let filterjs = {...filter}

  const handleFilter = (e) => {
    const name = e.target.name.split(".")
    setFilter({ ...filter, [name[0]]: {...filter[name[0]], [name[1]]: e.target.checked} });
    filterjs = { ...filter, [name[0]]: {...filter[name[0]], [name[1]]: e.target.checked} }

    if (e.target.checked) {
      setFilterOptions({all: false})
    } else {
      let filters = false
      for (let item in filterjs.brand) {
        if (filterjs.brand[item]) filters = true;
      }
      for (let item in filterjs.status) {
        if (filterjs.status[item]) filters = true;
      }
      if (!filters) {
        setFilterOptions({all: true})
      }
    }
    const {p, rpp, sp, so} = search;
    load(p, rpp, sp, so)
  };



  const load = async (page, rowsPerPage, sortProp, sortOrder, queryStr = query) => {

    let filtered = [];
    let filters = '';
      let keys = Object.keys(filterjs);
      if (keys.length) {
        keys.forEach(el => {
          for (let item in filterjs[el]) {
            if (filterjs[el][item]) {
              filtered.push(`filter[${el}]=${item}`)
            }
          }
        })
        filters = filtered.join('&')
      }

    setSearch({p: page, rpp: rowsPerPage, sp: sortProp, so: sortOrder});
    let sort = sortOrder === 'asc' ? `${sortProp}` : `-${sortProp}`
    setLoading(true)
    let queryString = queryStr === '/inventory' ? `/inventory?sort=${sort}&page=${page}&limit=${rowsPerPage}&${filters}` : `${queryStr}&sort=${sort}&page=${page}&limit=${rowsPerPage}&${filters}`;
    const {data} = await axios.get(queryString)

    setInfo(x => ({rows: data.docs, count: data.pages}))

    setLoading(false)


    // setRows(data.docs);
    // setCount(data.pages)
  }

  const handleSwitch = (e) => { 
    const {p, rpp, sp, so} = search;
    if (!switcher) {
      setQuery(`/inventory?status=sold`)
      load(p, rpp, sp, so, '/inventory?status=sold');
    } else {
      setQuery(`/inventory`)
      load(p, rpp, sp, so, `/inventory`);
    }
    setSwitch(e.target.checked);
  };

  const searchWigs = useCallback(debounce(async (val, setPage) => {
    const {rpp} = search

    let filtered = [];
    let filters = '';
      let keys = Object.keys(filterjs);
      if (keys.length) {
        keys.forEach(el => {
          for (let item in filterjs[el]) {
            if (filterjs[el][item]) {
              filtered.push(`filter[${el}]=${item}`)
            }
          }
        })
        filters = filtered.join('&')
      }

    let status = 'all'
    if (switcher) status = 'sold';
    let encodedVal = encodeURIComponent(val)
    const {data} = await axios.get(`/inventory?term=${encodedVal}&status=${status}&page=0&limit=${rpp}&${filters}`)
    setPage(0)
    setQuery(`/inventory?term=${encodedVal}&status=${status}`);
    setInfo(x => ({...x, rows: data.docs, count: data.pages}))

    // setRows(data.docs);
    // setCount(data.pages)
  }, 250), [search])

  

  const headers = [
    { id: 'wigId', label: 'ID' },
    { id: 'brand', label: 'Brand' },
    { id: 'line', label: 'Line' },
    { id: 'sku', label: 'SKU' },
    {id: 'length', label: "Length"},
    { id: 'baseMaterial', label: 'Material' },
    { id: 'status', label: 'Status' },
    {id: 'lastLogDate', render: row => {
      let date = row.history[row.history.length - 1].date
      let cInterval = inv?.cNote ? +inv?.cNote : 45
      let sInterval = inv?.sNote ? +inv?.sNote : 45
      let diff = Math.floor(getDaysDiff(date))

      if (row.status !== 'sold') {

        if (row.status === 'consignment') {
          return <Chip padx="3px" bgcolor={diff > cInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        } else {
          return <Chip padx="3px" bgcolor={diff > sInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        }

      } else {
        return "-"
      }

    }, label: 'Duration'},
    { id: 'currentlyAt.name', label: 'Currently At' },
    { id: 'shipmentNum', label: 'Order' },
  ]

  

  const path = props.match.url;

  const loadBrands = async () => {
    setBrandLoading(true)
    try {
      const { data } = await axios.get('/brands/names')
      const brandNames = data.map(el => el.name)
      // console.log(brandNames, "brands");
      setBrands(brandNames)
      brandNames.forEach(el => {
        brandjs[el] = false
      })
      // console.log(brandjs);
      setFilter({
        ...brandjs,
        status: {
        stock: false,
        consignment: false
        }
      })
      setBrandLoading(false)
    } catch (e) {
      setBrandLoading(false)
    }
  }

  useEffect(() => {
    if (!brands.length)
      loadBrands()
  }, [])

  const loadPrices = async () => {
    try {
      setDialogLoad(true)
      const {data} = await axios.get("/inventory/prices")

      setPrices(data)
      setDialogLoad(false)

    } catch (e) {
      setDialogLoad(false)
    }
  }

  const { addItem } = useCartContext()

  const actions = [
    {
      icon: <AddShoppingCartRoundedIcon color="primary" />,
      toolTip: 'Add to Cart',
      id: "cart",
      action: (row) => addItem(row)
    }
]
 
 return (
   <Switch>
    {/* <ProtectedRoute path={`${path}/edit/:id`} guard='writeInventory' component={EditInventorys} /> */}
    {/* <ProtectedRoute path={`${path}/edit`} guard='writeInventory' component={EditInventorys} /> */}
    <ProtectedRoute path={`${path}/view/:id`} guard='readInventory' component={ViewWig} />
     <Route path="/inventory">
      <Table loading={loading} title="Inventory" headers={headers} rows={info.rows} count={info.count} link="inventory" load={load} ord="desc" ordField="wigId"
      batchActions={batchActions}
      write={false} add={false} actions={actions}
      
      settings={<FormDialog toggle={
        <Tooltip title="Update Inventory Prices"><IconButton onClick={loadPrices}><SettingsIcon color="secondary" /></IconButton></Tooltip>
      } 
      title="Update Inventory Prices"
      // forceOpen={true}
      onSave={() => updatePrices()}
      save="Update Prices"
      size="xs"
      >
        {dialogLoad ? <LinearProgress /> : 
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <UpdatePriceHeader>Please Check Lengths you want to update</UpdatePriceHeader>
          </Grid>
          
          {prices.map(({_id, avgPrice}, i) => 
            <>
              <Grid item xs={1}> <FormControlLabel
              control={<Checkbox 
              onChange={(e) => handlePriceUpdate(e, i)} name={`check-${_id}`} color="primary" />}
              label=""
            /></Grid>

              <Grid item xs={6}>
                <TextField name={`size-${_id}`} label={`Length ${_id}`}
            variant="outlined" disabled defaultValue={_id} fullWidth /></Grid>

              <Grid item xs={5}>
                <TextField name={`price-${_id}`} label="Avg Price"
            variant="outlined" defaultValue={avgPrice} inputRef={el => priceRef.current[i] = el} type="number" fullWidth /></Grid>
            </>
            )}
          
        </Grid>
        }
      </FormDialog>} 

      switchField={<SwitchContainer
        control={
          <Switcher
            checked={switcher}
            onChange={handleSwitch}
            name="stock"
            color="primary"
          />
        }
        label="Include Sold"
        />}
        filterItems={
          <FormControl component="fieldset" variant="standard">
            <FormGroupHeader component="legend">Default</FormGroupHeader>
            <FormControlLabel
              control={<Checkbox checked={filterOptions.all} 
              onChange={handleFilterOptions} name="all" color="primary" />}
              label="All"
            />
            {brands?.length > 1 && <>
            <FormGroupHeader component="legend">Brand</FormGroupHeader>
              <FormGroup>
                {brands?.map(el => 
                  <FormControlLabel
                    key={el}
                    control={<Checkbox checked={filter && filter.brand && filter.brand[el]} 
                    onChange={handleFilter} name={`brand.${el}`} color="primary" />}
                    label={`${el}`}
                  />
                )}
              
              {/* <FormControlLabel
                control={<Checkbox checked={filter?.brand?.Utopia} 
                onChange={handleFilter} name="brand.Utopia" color="primary" />}
                label="Utopia"
              /> */}
              </FormGroup>
            </>
            }
            <FormGroup>
              <FormGroupHeader component="legend">Status</FormGroupHeader>
              <FormControlLabel
                control={<Checkbox checked={filter?.status?.stock} 
                onChange={handleFilter} name="status.stock" color="primary" />}
                label="Stock"
              />
              <FormControlLabel
                control={<Checkbox checked={filter?.status?.consignment} 
                onChange={handleFilter} name="status.consignment" color="primary" />}
                label="Consignment"
              />
              {/* <FormControlLabel
                control={<Checkbox checked={filter?.status?.assigned} 
                onChange={handleFilter} name="status.assigned" color="primary" />}
                label="Assignment"
              /> */}
            </FormGroup>
          </FormControl>
        } 
        search={setPage => <TextField name="search" label="Search by ID"
        variant="outlined" size="small" fullWidth onChange={(e) => searchWigs(e.target.value, setPage)}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <Search color="primary" />
            </InputAdornment>
        }}
        />} />
     </Route>
  </Switch>
 )
}

export default Inventory;