import React, {useState, useEffect, useCallback} from 'react';
// import styled from 'styled-components';
import axios from '../../axios';

import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import Indicator from '../../utils/Indicator';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import ViewQuiltRoundedIcon from '@material-ui/icons/ViewQuiltRounded';

import { createFilterQuery } from '../../utils/util';

import { debounce } from 'lodash'

import Table from '../../utils/table-server';

import AddOrder from './AddOrder';
import ViewOrder from './ViewOrder';

import { useSBContext } from '../../utils/snackbar';
import { IconButton, Tooltip } from '@material-ui/core';

import useFirstLoad from '../../hooks/useFirstLoad';

import FilterFields from '../../utils/common/filters';

const ViewSetting = ({view, toggleView}) => {
  console.log(view, "Changeddddd");
  let isSale = () => view === "sale";
  return <Tooltip title={`Select ${isSale() ? 'Wig' : 'Sale'} View`}><IconButton onClick={toggleView}><ViewQuiltRoundedIcon color={isSale() ? "secondary" : "primary"} /></IconButton></Tooltip>
}

const headers = [
  { id: 'orderId', label: 'Sale ID' },
  { id: 'qty', label: 'Qty' },
  { id: 'to.name', label: 'Customer' },
  { id: 'total', currency: true, label: 'Total' },
  { id: 'balance', currency: true, label: 'Balance' },
  {id: 'balance2', render: (row) => {
    let status, text;
    if (row.balance === 0) {
      status = 'success'
      text = "Paid"
    } else {
    if (row.balance === row.total) {
      status = 'fail'
      text = "Not Paid"
    }
    if (row.totalPaid > 0 && row.totalPaid < row.total) {
      status = "partial"
      text = "Partially Paid"
    }
  }
    return <Indicator status={status} text={text} />
  }, label: 'Status'},
  { id: 'date', date: true, label: 'Date'}
];

const wigHeaders = [
  { id: 'wigId', label: 'Wig ID' },
  { id: 'sku', label: 'SKU' },
  { id: 'currentlyAt.name', label: 'Customer' },
  { id: 'sale.total', currency: true, label: 'Total' },
  { id: 'balance', currency: true, render: (row) => row.sale.total - row.sale.paidAmount, label: 'Balance' },
  {id: 'balance2', render: (row) => {
    let status, text;
    if (row.sale.paid) {
      status = 'success'
      text = "Paid"
    } else {
    if (row.sale.paidAmount <= 0) {
      status = 'fail'
      text = "Not Paid"
    } else {
      status = "partial"
      text = "Partially Paid"
    }
  }
    return <Indicator status={status} text={text} />
  }, label: 'Status'},
  { id: 'soldDate', date: true, label: 'Date'}
];


const TableSelect = ({curRows, curHeaders, ordField, viewTitle, loading, load, count, filter, handleFilter, applyFilter, searchCust, view, toggleView}) => {
  return (<Table loading={loading} load={load} title="Sales" headers={curHeaders} rows={curRows} link="orders" count={count} ord="desc" ordField={ordField} trash={view === "sale" ? true : false} altId={view !== "sale" && "saleId"}
  filterItems={
    <FilterFields filter={filter} handleFilter={handleFilter} applyFilter={applyFilter} />
  }
  search={setPage => <TextField name="search" 
  label="Search by Order ID, Customer Name, Wig ID" 
  variant="outlined" size="small" fullWidth onChange={(e) => searchCust(e.target.value, setPage)}
  InputProps={{
    endAdornment:
      <InputAdornment position="end">
        <Search color="primary" />
      </InputAdornment>
  }}
  />} 
  views={<ViewSetting view={view} toggleView={toggleView} />}
  viewTitle={viewTitle}
  />)
}
 
const Order =  (props) => {
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(0);

  const [view, setView] = useState("sale")

  const [firstLoad, setFirstLoad] = useFirstLoad()

  const newDate = new Date()

  const toggleView = () => {
    setLoading(true)
    setView(x => {
      if (x === "sale") {
        return "wig"
      } else {
        return "sale"
      }
    })
  }

  const [search, setSearch] = useState({
    p: '', rpp: '', sp: '', so: ''
  });

  const [query, setQuery] = useState('/orders')

  const { handleClick } = useSBContext();

  const [filter, setFilter] = useState({
    balance: false,
    startDate: newDate,
    endDate: newDate,
    useFromDate: false
  })


  const handleFilter = (e, field, input) => {
    console.log(e)
    let val = input === "check" ? e.target.checked : input === "date" ? e : e.target.value;
    setFilter({ ...filter, [field]: val });
  };

  const applyFilter = () => {
    const {p, rpp, sp, so} = search;
    load(p, rpp, sp, so)
  }
  // useEffect(() => {
  //   if (firstLoad) {
  //     setFirstLoad(false)
  //     return;
  //   }
  //     const {p, rpp, sp, so} = search;
  //     load(p, rpp, sp, so)
  // }, [filter])

  const load = async (page, rowsPerPage, sortProp, sortOrder, queryStr = query) => {
    let filters = createFilterQuery(filter)

    let url = view === "sale" ? '/orders' : '/orders/wigs'

    setSearch({p: page, rpp: rowsPerPage, sp: sortProp, so: sortOrder});
    let sort = sortOrder === 'asc' ? `${sortProp}` : `-${sortProp}`
    setLoading(true)
    let isTerm = queryStr.split("?") // check if the querystring has a query on it already
    let queryString = !isTerm[1] ? `${url}?sort=${sort}&page=${page}&limit=${rowsPerPage}&${filters}` : `${queryStr}&sort=${sort}&page=${page}&limit=${rowsPerPage}&${filters}`;
    try {
      const {data} = await axios.get(queryString)

      console.log({data: data.docs});

      setRows(data.docs || []);
      setCount(data.pages || 0)

      setLoading(false)

      if (!data.docs)
        handleClick('error', 'Error retrieving data')
    } catch (e) {
      setLoading(false)
      setRows([])
    }
  }

  const searchCust = useCallback(debounce(async (val, setPage) => {
    const {rpp} = search
    let filters = createFilterQuery(filter)
    let url = view === "sale" ? '/orders' : '/orders/wigs'
    let encodedVal = encodeURIComponent(val)
    const {data} = await axios.get(`${url}?term=${encodedVal}&page=0&limit=${rpp}&${filters}`)
    setPage(0)
    setQuery(`${url}?term=${encodedVal}`);
    setRows(data.docs || []);
    setCount(data.pages || 0)
  }, 250), [search, filter, view])

  const path = props.match.url;

  const propBag = {
    loading, load, count, filter, 
    handleFilter, searchCust, applyFilter,
    view, toggleView
  }

 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeOrder' component={AddOrder} />
    <ProtectedRoute path={`${path}/edit`} guard='writeOrder' component={AddOrder} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readOrder' component={ViewOrder} />
    <Route path="/orders">
      {view === "sale" ? 
      <TableSelect key="sale" curHeaders={headers} curRows={rows} ordField="date" viewTitle="Sales" {...propBag} /> :
       <TableSelect key="wig" curHeaders={wigHeaders} curRows={rows} ordField="paymentDueDate" viewTitle="Wigs" {...propBag} /> }
       {/* change this sold date to paymentDueDate */}
    </Route>
  </Switch>
 )
}

export default Order;