import React, { useState, useEffect } from "react";
import styled from 'styled-components';

import { FormHelperText, Paper, Grid, Select, MenuItem, FormControl, InputLabel, FormLabel, Button, TextField, LinearProgress } from "@material-ui/core";

import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';

import { useSBContext } from "../../utils/snackbar";

import { AgingTopBar, AgingReport } from "./reports/AgingReport";

import { SBCTopBar, SBCReport } from "./reports/SalesByCustomer";

import { PBCReport, PBCTopBar } from "./reports/PaymentsByCustomer";

import { IBCReport, IBCTopBar } from "./reports/InventoryByCustomer";

import { FullDivider } from "../../utils/common";

import axios from '../../axios'

import { colors } from "./reports/common";

const Container = styled.div`
  width: 90%;
  height: auto;
  margin: 1rem auto;

  @media ${props => props.theme.breakpoints.down('xl')} {
    width: 90%;
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
  }
`

const ReportType = styled(FormControl)`
  margin-top: 1rem;
`

const Header = styled.div`
  height: 6rem;
 `

const Title = styled.h2`
  font-size: 2rem;
  padding-top: 1rem;
  font-weight: 500;
  /* text-align: center; */
`

const ReportBar = styled(Paper)`
 padding: 2.5rem;
 overflow-x: hidden;
`

const ResultContainer = styled.div`
  margin: 2rem 0;
`

const CustomerReports = () => {

  const [brands, setBrands] = useState([])

  const [customers, setCustomers] = useState({})

 
  const [balance, setBalance] = useState("")

  const [pageLoading, setPageLoading] = useState(false)

  const [reportLoading, setReportLoading] = useState(false)

  const [reportType, setReportType] = useState("")

  const [agingResults, setAgingResults] = useState([])
  const [SBCResults, setSBCResults] = useState({
    results: [],
    topCustomersByTotal: [],
    topCustomerByQty: [],
    topCustomerByItr: [],
    sumTotal: 0,
    sumQty: 0,
    chartData: {}
  })

  const [PBCResults, setPBCResults] = useState({
    results: null,
  });

  const [IBCResults, setIBCResults] = useState({
    results: null,
    wigCount: 0,
    custNum: 0,
  });

  const [agingOptions, setAgingOptions] = useState({
    brand: '', 
    balance: ''
  })

  const runAgingReport = async (rt) => {

    const { brand, balance } = agingOptions
    try {
      const { data } = await axios.get(`/accounting/reports/${rt}?brand=${encodeURIComponent(brand)}&balance=${balance}`)
      setAgingResults(data)
    } catch (e) {
      
    }
  }

  // Sales By Customer Options
  const [SBCOptions, setSBCOptions] = useState({
    brand: 'all',
    startDate: '',
    endDate: '',
    sortBy: 'total',
    range: "pm",
    includeZeroSales: false
  })

  // Payments By Customer Options
  const [PBCOptions, setPBCOptions] = useState({
    startDate: '',
    endDate: '',
    paymentType: [],
    min: '',
    max: '',
    sortBy: 'total',
    range: "td"
  });

  // Inventory By Customer Options
  const [IBCOptions, setIBCOptions] = useState({
    date: '',
    brand: 'all',
    sortBy: 'qty',
    duration: '',
  });

  const runSBCReport = async (rt) => {
    const { brand, startDate, endDate, sortBy, includeZeroSales } = SBCOptions
    try {
      const { data } = await axios.get(`/reports/sales-by-customer?brand=${encodeURIComponent(brand)}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}&includeZeroSales=${includeZeroSales}`)
      const top20 = data.filter(el => el.qty > 0).slice(0, 20)
      let labels = top20.map(el => el._id)
      let dataPoints = top20.map(el => el.qty)
      let chartData = {
        labels,
        datasets: [
          {
            label: 'Sales Qty By Customers',
            data: dataPoints,
            backgroundColor: colors,
            hoverOffset: 6,
            radius: "90%",
            cutout: "50%"
            // circumference: 180,
            // rotation: 270
          },
        ],
      }
      let topCustomersByTotal = data.filter(el => el.total > 0).sort((a, b) => b.total - a.total).slice(0, 5)
      let topCustomerByQty = data.filter(el => el.qty > 0).sort((a, b) => b.qty - a.qty).slice(0, 5)
      let topCustomerByItr = data.filter(el => el.itr !== "N/A").sort((a, b) => b.itr - a.itr).slice(0, 5)
      let sumTotal = data.reduce((acc, cur) => acc + cur.total, 0)
      let sumQty = data.reduce((acc, cur) => acc + cur.qty, 0)
      
      console.log(data, "check me out")
      setSBCResults({
        results: data, 
        topCustomersByTotal,
        topCustomerByQty,
        topCustomerByItr,
        sumTotal,
        sumQty,
        chartData
      })
    } catch (e) {
      console.log(e)
    }
  }

  const runPBCReport = async (rt) => {
   const { startDate, endDate, paymentType, min, max, sortBy, range } = PBCOptions
    try {
      const { data } = await axios.get(`/reports/payments-by-customer?startDate=${startDate}&endDate=${endDate}&paymentType=${paymentType}&min=${min}&max=${max}&sortBy=${sortBy}`)
      console.log(data, "PBC")
      setPBCResults(data)
    } catch (e) {
      console.log(e)
    }
  };

  const runIBCReport = async (rt) => {
    const { date, brand, sortBy, duration} = IBCOptions
    try {
      const { data } = await axios.post(`/reports/inventory-by-customer?date=${date}&brand=${encodeURIComponent(brand)}&sortBy=${sortBy}&duration=${duration}`, { customers: Object.values(customers) })
      console.log(data, "IBC")
      setIBCResults(data)
    } catch (e) {
      console.log(e)
    }
  }

  const { handleClick } = useSBContext()

  const loadInfo = async () => {
    
    try {
      const { data } = await axios.get('/brands/names')
      const brandNames = data.map(el => el.name)
      setBrands(data)
      setPageLoading(false)
    } catch (e) {
      setPageLoading(false)
      handleClick('error', 'Please reload page')
    }
  }

  useEffect(() => {
    loadInfo()
  }, [])

  const runReport = async (rt) => {
    if (!rt) {
      return;
    }
    setReportLoading(true)
    try {
      if (rt === 'aging') {
        await runAgingReport(rt)
      }
      if (rt === 'sbc') {
        await runSBCReport()
      }
      if (rt === 'pbc') {
        await runPBCReport()
      }
      if (rt === 'ibc') {
        await runIBCReport()
      }
    } catch (e) {
      
    } finally {
      setReportLoading(false)
    }
    
  }

  return <Container>
    <Header>
      <Title>
        General Reports
      </Title>
    </Header>

    <ReportBar variant="outlined" square>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={4} lg={4}>
        <FormLabel component="legend">Report Type</FormLabel>
          <ReportType variant="outlined" fullWidth>
            <InputLabel id="brand-sel-label">Select Report Type</InputLabel> 
            <Select
              labelId="brand-sel-label"
              id="cust-search-brand"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              label="Select Report Type"
              fullWidth
              variant="outlined"
            >
              <MenuItem value="aging">Customer Accounts Aging Report</MenuItem>
              <MenuItem value="sbc">Sales By Customer</MenuItem>
              <MenuItem value="pbc">Payments By Customer</MenuItem>
              <MenuItem value="ibc">Inventory Aging Report</MenuItem>
              {/* <MenuItem value="sbi">Sales By Item</MenuItem> */}
            </Select>
            {/* <FormHelperText>{brandError ? 'Please select brand' : null}</FormHelperText> */}
          </ReportType>
        </Grid>

        {/* <Grid item xs={9}></Grid> */}
        <Grid item xs={12}>
          <FormLabel component="legend">Search Details</FormLabel> 
        </Grid>
       
        {reportType === 'aging' && <AgingTopBar brands={brands} agingOptions={agingOptions} setAgingOptions={setAgingOptions} />}

        {reportType === 'sbc' && <SBCTopBar brands={brands} SBCOptions={SBCOptions} setSBCOptions={setSBCOptions} />}

        {reportType === 'pbc' && <PBCTopBar PBCOptions={PBCOptions} 
          setPBCOptions={setPBCOptions}
        />}

        {reportType === 'ibc' && <IBCTopBar IBCOptions={IBCOptions} customerOptions={{customers, setCustomers}} brands={brands} setIBCOptions={setIBCOptions} />}

        <Grid item xs={12}>
          <FullDivider y=".5rem" />
        </Grid>

        <Grid item xs={12}>
          <Button startIcon={<BarChartRoundedIcon />} onClick={() => runReport(reportType)} variant="contained" color="primary">Run Report</Button>
        </Grid>
      </Grid>
    </ReportBar>

    <ResultContainer>

     {reportType === "aging" ? <AgingReport agingResults={agingResults} loading={reportLoading} /> : null }
      {reportType === "sbc" ? <SBCReport SBCResults={SBCResults} loading={reportLoading} options={SBCOptions} /> : null }
      {reportType === "pbc" ? <PBCReport PBCResults={PBCResults} snack={handleClick} loading={reportLoading} options={PBCOptions} /> : null }
      {reportType === "ibc" ? <IBCReport IBCResults={IBCResults} snack={handleClick} loading={reportLoading} options={IBCOptions} customers={customers} /> : null }

    </ResultContainer>

  </Container>
}

export default CustomerReports;