import React from "react";

function WigIcon({htmlColor}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 894 965"
      fill={htmlColor}
      className="wigIcon"
    >
      <path d="M372.5 42.5s-90-5-163 101c-33 41-39 113-39 120s-5 62-32 75-57 78-44 121 1 101-21 117-49 45-41 92c4 24 17 25 11 44s-33 24-25 88c12 51 25 62 25 62s-4-57 8-61 55 79 36 87c-14 12 82-41 122-42s130-11 158-98c19-91 11-93 11-93s-114-93-112-152c0 0-5-39 4-79s8-61 8-61 51-65 54-82c22 27 12 74-4 84s30-3 46-44-1-78-1-78 12-34 28-35c13 25 57 92 142 101 0 0 23 97 85 118 0 0 10 153-91 226 0 0-1 101 39 143s110 56 128 54c0 0-16 51-1 73s41 31 41 31-9-50 13-68 37-16 37-16l26 30s19-9 36-60 4-78 4-78-44-20-47-59c4-39 6-50 6-50s11-8 13-28-26-34-29-42-4-19 15-38 16-56 10-75-37-75-61-96c0 0-1-99-4-117s-35-106-125-181c-48-32-106-58-106-58s-68-15-103-8-53 15-57 32z"></path>
    </svg>
  );
}

export default WigIcon;