import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {useAuthContext} from '../../auth';
import {Grid, Paper, Button, InputAdornment, 
  CircularProgress, LinearProgress} from '@material-ui/core'
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, regex } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';


import LogoImg from '../../images/logo-wig.png'

import SalonGraphic from '../../images/login.png'




const Container = styled.div`

  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`

const LoginContainer = styled.div`
  width: 47%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const LoginCard = styled(Paper)`
  position: relative;
  width: 75%;
  padding: 4rem 5rem;
  background-color: transparent;


  input:-ms-input-placeholder {font-size: 1rem;}
  input::-webkit-input-placeholder {font-size: 1rem;}

  svg {
    font-size: 18px;
    color: ${props => props.theme.palette.primary.main};
  }
`

const Logo = styled.div`

margin: 8rem 0;
  
  svg {
    width: 10rem
  }

  img {
    height: 7rem;
  }
`

const GraphicContainer = styled.div`
  width: 53%;
  background-image: linear-gradient(to left, #4771b8dd, #324f81dd) ; //#e0ecf933, #c4d9f177
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    height: 73%;
    width: 90%;
    margin: 0 auto;
    /* margin-bottom: auto; */
    /* margin-right: auto; */
    object-fit: contain;
    filter: drop-shadow(1rem 1rem 2rem #0005) saturate(115%);
  }
`

const Welcome = styled.h1`
  font-weight: 400;
  /* margin-bottom: 3.5rem; */
  font-size: 2.4rem;
  color: #444e6f;
  /* text-align: left; */
  text-transform: uppercase;
  letter-spacing: 1px;
`

const Legal = styled.span`
  display: block;
  color: #9B9B9F;
`

const CtaText = styled.div`
  color: #fff;
  max-width: 85%;
  margin: 0 auto;
  margin-bottom: 15rem;
  letter-spacing: .5px;
  line-height: 2.2rem;

  .details {
    font-size: 1.2rem;
    display: block;
    margin: 0 auto;
    margin-top: 1.8rem;
    /* width: 50%; */
    line-height: 1.8rem;
    /* letter-spacing: 0; */

    * {
      color: inherit;
    }
  }
`

 
export default (props) => {

  const getQuery = (search) => {
    let queryObj = {}
    let query = search.replace(/\?/, "")
    query = query.split("&")
    query.forEach(el => {
      let param = el.split('=');
      queryObj[param[0]] = param[1]
    })
    return queryObj;
  }

  const [loading, setLoading] = useState(true);

  const [setup, setSetup] = useState("pass")

  // console.log(getQuery(props.location.search));

  const onLoad = () => setLoading(false)

  const {id, slug} = props.match.params;

  useEffect(() => {
    if (props.location?.search) {
      setSetup(getQuery(props.location.search).setupType)
    }
  }, [])

  const additional = getQuery(props.location.search).setupType !== "pass" ? {
    username: Yup
        .string()
        .required('username is required.'),
    displayName: Yup
        .string()
        .required('Display name is required.'),
  } : {}

  const PassSchema = Yup.object().shape({
    ...additional,
    password: Yup
      .string()
      .required('Password is required.')
      .matches(regex, 'Password must be eight characters with at least one uppercase letter and a number'),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {
    let route = setup === "pass" ? "changePassword" : "setup";

    let msg = setup === "pass" ? 'Password successfully changed' : 'User successfully created'
    
    try {
      const {data} = await axios.put(`/users/${route}/${id}/slug/${slug}`, {...userInfo})
      setSubmitting(false)
      handleClick('success', 'Password successfully changed')
      props.history.push('/login')
    } catch(e) {
      setSubmitting(false)
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }
 
 return (
 <Container>
  <LoginContainer>
  <LoginCard elevation={0}>
    
  <Formik
      initialValues={{
        username: "",
        displayName: '',
        password: '',
        confirmPassword: '',
      }}
      validationSchema={PassSchema}
      onSubmit={onSubmit}
    >
  {({ submitForm, isSubmitting, errors}) => (
    <Form>
      <Welcome>{setup === "pass" ? "Change Password" : "Account Setup"}</Welcome>
      <Logo>
        <img src={LogoImg} />
      </Logo>
      <Grid container spacing={3}>
          
        {setup !== "pass" ? <>
          <Grid item xs={12} md={6}>
          <Field
              component={TextField}
              name="username"
              type="text"
              label="Username"
              variant="outlined"
              fullWidth
             
            />
          </Grid>

          <Grid item xs={12} md={6}>
          <Field
              component={TextField}
              name="displayName"
              type="text"
              label="Display Name"
              variant="outlined"
              fullWidth
             
            />
          </Grid>

          </> : <Grid item xs={12}></Grid> }

          <Grid item xs={12}>
          <Field
              component={TextField}
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
             
            />
          </Grid>

          <Grid item xs={12}>
          <Field
              component={TextField}
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              variant="outlined"
              fullWidth
             
            />
          </Grid>
          <Grid item xs={12}>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            disabled={isSubmitting}
            color="primary"
            variant='contained'
            fullWidth
            size='large'
            // onClick={submitForm}
            >{setup === 'pass' ? 'Change Password' : 'Setup Account'}</Button>
            }
          </Grid>
          <Grid item xs={12}>
            <Legal>
            &copy; Novosoft 2023 | All Rights Reserved
            </Legal>
          </Grid>
      </Grid>
       </Form>
       )}
    </Formik>
    
  </LoginCard>
  </LoginContainer>

  <GraphicContainer>
    <img src={SalonGraphic} alt="graphic of inventory" />
    <CtaText>Novo Tracker is the ultimate inventory tracking app for your consignment business model. You will get real-time inventory tracking information and you can monitor your accounting data with ease. With the help of this app you will be in control of your business. <br />
    <span className='details'>For customer support, please reach out to us at <a href="mailto:novosoftsolutions@gmail.com">novosoftsolutions@gmail.com</a></span>
    </CtaText>
  </GraphicContainer>

 </Container>
 )
}