import React from 'react';
import styled from 'styled-components';
import Chip from "@material-ui/core/Chip";

const ChipComponentStyles = styled(Chip)`
  background-color: ${props => props.bgcolor ? props.bgcolor : "auto"};
  padding-left: ${props => props.padx ? props.padx : "auto"};
  padding-right: ${props => props.padx ? props.padx : "auto"};
  padding-top: ${props => props.pady ? props.pady : "auto"};
  padding-bottom: ${props => props.pady ? props.pady : "auto"};
`


const ChipComponent = (props) => {
  return <ChipComponentStyles {...props} />
}

export default ChipComponent;