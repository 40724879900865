import React, { useEffect } from "react";
import styled from 'styled-components';

import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, 
  Title as ChartTitle,
  Filler,
} from 'chart.js';
import { Doughnut, Line, Bar } from 'react-chartjs-2';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';

import { DateTime } from "luxon";

import { MenuItem } from "@material-ui/core";

import { theme } from "../../../utils/theme";

import OutlinedSelect from "../../../utils/OutlinedSelect";

ChartJS.register(
  ArcElement, 
  ChartTooltip, 
  Legend, 
  );

const colors = [
  '#b95f5f',
  '#4e98b1',
  '#b1b14e',
  '#4eb14e',
  '#804eb1',
  "#b9765f",
  "#5fb9b9",
  "#a3b95f",
  "#5fb95f",
  "#b95fb9",
  "#b95f8c",
  "#5f76b9",
  "#5fb9a3",
  "#a3b95f",
  "#b95fb9"
]

const DateRangePicker = ({ options, setOptions }) => {

  const { range = "tm" } = options;

  console.log(range, "range")

  useEffect(() => {

    switch(range) {
      case "td": 
        setOptions(x => ({...x, 
          startDate: DateTime.local().startOf("day"), endDate: DateTime.local().endOf("day")
        }))
        break;
      case "tw":
        setOptions(x => ({...x, 
          startDate: DateTime.local().startOf("week"), endDate: DateTime.local().endOf("week")
        }))
        break;
      case "tm":
        setOptions(x => ({...x, 
          startDate: DateTime.local().startOf("month"), endDate: DateTime.local().endOf("month")}))
        break;
      case "tq":
        setOptions(x => ({...x, 
          startDate: DateTime.local().startOf("quarter"), endDate: DateTime.local().endOf("quarter")
        }))
        break;
      case "py":
        setOptions(x => ({...x, 
          startDate: DateTime.local().minus({years: 1}).startOf("year"), endDate: DateTime.local().minus({years: 1}).endOf("year")
        }))
        break;
      case "ytd":
        setOptions(x => ({...x, 
          startDate: DateTime.local().startOf("year"), 
          endDate: DateTime.local().endOf('day')}))
        break;
      default:
        setOptions(x => ({...x, startDate: DateTime.local().startOf("day"), endDate: DateTime.local().endOf("day")}))
    }
  }, [range])

  const handleSelect = (e) => {
    setOptions(x => ({...x, range: e.target.value}));
  }

 return (
  <OutlinedSelect
    label="Select Range"
    color={theme.colors.primaryLight} 
    bgColor={theme.colors.primary95}
    textColor={theme.colors.primary35}
    // defaultValue={range}
    val={range}
    onChange={handleSelect}
  >
    <MenuItem value="td">Today</MenuItem>
    <MenuItem value="tw">This Week</MenuItem>
    <MenuItem value="tm">This Month</MenuItem>
    <MenuItem value="tq">This Quarter</MenuItem>
    <MenuItem value="py">Past Year</MenuItem>
    <MenuItem value="ytd">Year To Date</MenuItem>
    <MenuItem value="custom">Custom</MenuItem>
  </OutlinedSelect>
 )
}

const DateField = ({ options, setOptions, label = "Start Date", field }) => {

  const handleDateChange = (date) => {
    setOptions(x => ({...x, [field]: date}));
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
       variant="inline"
       openTo="year" 
       disableToolbar 
       views={["year", "month", "date"]} 
       inputVariant="outlined"
       value={options[field]} 
       format="MM/dd/yyyy" 
       onChange={handleDateChange}  
       label={label} 
       name="date"
      />
    </MuiPickersUtilsProvider>
  )
}


const DoughnutChart = ({ data, title }) => {

  return (
    <Doughnut data={data} options={{
      plugins: {legend: {
        labels: {
          // boxHeight: 20,
          padding: 15
        }
      }}
    }} />
  )
}

export { DateRangePicker, DateField, colors, DoughnutChart };