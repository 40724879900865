import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import axios from '../../axios';

import {Formik, Form, Field} from 'formik';
import { TextField, Select, Switch } from 'formik-material-ui'

import { useAuthContext } from '../../auth';
import { useSBContext } from '../../utils/snackbar';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { FormControl, InputLabel, MenuItem, Grid } from "@material-ui/core"
 
import { SettingsRounded } from '@material-ui/icons'

import TabContainer from '../../utils/tabContainer';

import Dialog from '../../utils/dialog';

import { Flex, FlexLine, CommonFlex } from '../../utils/common'

const TabHeaders = styled.div`
  display: flex;

  .MuiSvgIcon-root {
    margin-right: 1rem;
  }
`

const Label = styled.span`
  display: block;
  min-width: 50%;
`

const SubTitle = styled.h5`
  font-weight: normal;
  color: ${props => props.theme.colors.lightText};
  margin-top: 2rem;
  margin-bottom: .3rem;
`

const TabContentStyles = styled.div`
  padding-top: 1.5rem;
  border-top: 1px solid #ccc;
`

const SettingsDialog = styled(Dialog)`
  /* height: 60rem; */

  .MuiDialog-paperFullWidth {
    height: 75vh;
    /* background-color: green; */
  }
`

const SettingsFlex = ({children, ...props}) => {
  return <CommonFlex pad="0" height="4rem" {...props}>
    {children}
  </CommonFlex>
}
 
const Settings =  ({ permissions }) => {

  const [value, setValue] = useState(0); // for the tabs

  // first tab option: General, Details, Profile, Personal

  const tabNames = [
    <TabHeaders>General</TabHeaders>,
    <TabHeaders>Invoices</TabHeaders>,
    <TabHeaders>Scanning</TabHeaders>,
    <TabHeaders>Shipments</TabHeaders>,
    <TabHeaders>Inventory</TabHeaders>,
  ]

  const handleChange = (event, newValue) => {  // for the tabs
    setValue(newValue);
  };

  const isPerm = () => permissions.all || permissions.writeSettings // check if user has system settings permissions

  const TabContent = ({value, panel, children}) => {
    if (value !== panel) return <div></div>
  
    return <TabContentStyles>{children}</TabContentStyles>;
  }

 return (
  <>
  <TabContainer elevation={0} tabNames={tabNames} value={value} handleChange={handleChange} indColor="secondary" textColor='secondary' />
  <TabContent panel={0} value={value}>

    <Grid container spacing={3}>
      <Grid item xs={6}>
          <SubTitle>Tables</SubTitle>
          <FlexLine dir="row" justify="space-between" pad="0">
            <Label>Default Rows Per Page</Label>
            <FormControl variant="outlined" size="small" fullWidth>
            {/* <InputLabel id="brand-label">Default Rows Per Page</InputLabel> */}
              <Field
                  component={Select}
                  name="rowsPerPage"
                  fullWidth
                >
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Field>
            </FormControl>
          </FlexLine>
      </Grid>

      <Grid item xs={6}></Grid>
    </Grid>


      
    
  </TabContent>

  {isPerm && <TabContent panel={1} value={value}>

    <Grid container spacing={3}>
      <Grid item xs={6}>
          <SubTitle>Orders</SubTitle>
          <SettingsFlex>
            <Label>Use Product Title instead of Wig ID</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="orderTitle"
                >
              </Field>
          </SettingsFlex>
          <SettingsFlex>
            <Label>Use Description instead of Style Colors</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="orderDesc"
                >
              </Field>
          </SettingsFlex>
          <SubTitle>Consignments</SubTitle>
          <SettingsFlex>
            <Label>Use Product Title in place of Wig ID</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="consignTitle"
                >
              </Field>
          </SettingsFlex>
          <SettingsFlex>
            <Label>Use Description instead of Style Colors</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="consignDesc"
                >
              </Field>
          </SettingsFlex>
          <SettingsFlex>
            <Label>Show Duration of consignment on Track invoices</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="duration"
                >
              </Field>
          </SettingsFlex>
      </Grid>

      <Grid item xs={6}></Grid>
    </Grid>


      
    
  </TabContent> }

  {isPerm && <TabContent panel={2} value={value}>

    <Grid container spacing={3}>
      <Grid item xs={6}>
          <SubTitle>General</SubTitle>
          <SettingsFlex>
            <Label>Require Scanning for Sales</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="orderScanning"
                >
              </Field>
          </SettingsFlex>
          <SettingsFlex>
            <Label>Require Scanning for Consignments</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="consignmentScanning"
                >
              </Field>
          </SettingsFlex>
      </Grid>

      <Grid item xs={6}></Grid>
    </Grid>
    
  </TabContent>
}
  {isPerm && <TabContent panel={3} value={value}>

    <Grid container spacing={3}>
      <Grid item xs={6}>
          <SubTitle>Defaults</SubTitle>
          <SettingsFlex>
            <Label>Wigs are rooted By Default</Label>
              <Field
                component={Switch}
                type="checkbox"
                name="rooting"
                >
              </Field>
          </SettingsFlex>
      </Grid>

      <Grid item xs={6}></Grid>
    </Grid>
    
  </TabContent>
}

{isPerm && <TabContent panel={4} value={value}>

    <Grid container spacing={3}>
      <Grid item xs={6}>
          <SubTitle>Notifications</SubTitle>
          <SettingsFlex padY="1.5rem">
            <Label>Notify me when item is consigned for more than </Label>
            <FormControl variant="outlined" size="small" >
              <Field
                  component={Select}
                  name="consignedNotification"
                  // fullWidth
                >
                <MenuItem value="30">30 Days</MenuItem>
                <MenuItem value="45">45 Days</MenuItem>
                <MenuItem value="60">60 Days</MenuItem>
                <MenuItem value="75">75 Days</MenuItem>
                <MenuItem value="90">90 Days</MenuItem>
              </Field>
            </FormControl>
          </SettingsFlex>
          <SettingsFlex padY="1.5rem">
            <Label>Notify me when item is in stock for more than </Label>
            <FormControl variant="outlined" size="small" >
              <Field
                  component={Select}
                  name="stockNotification"
                  // fullWidth
                >
                <MenuItem value="30">30 Days</MenuItem>
                <MenuItem value="45">45 Days</MenuItem>
                <MenuItem value="60">60 Days</MenuItem>
                <MenuItem value="75">75 Days</MenuItem>
                <MenuItem value="90">90 Days</MenuItem>
              </Field>
            </FormControl>
          </SettingsFlex>
      </Grid>

      <Grid item xs={6}></Grid>
    </Grid>

  </TabContent>
  }
  </>
 )
}

const SettingsContainer = () => {
  const { user, setUser } = useAuthContext()

  const { general } = user.settings || {}

  const { inventory, shipment, invoices, scanning } = user.system?.settings || {}

  const {handleClick} = useSBContext();

  const saveFunc = async val => {
    const settings = {
      general: {
        defaultRpp: val.rowsPerPage
      }
    }
    const systemSettings = {
      inventory: {
        cNote: val.consignedNotification,
        sNote: val.stockNotification
      },
      invoices: {
        order: {
          title: val.orderTitle,
          description: val.orderDesc
        },
        consignment: {
          title: val.consignTitle,
          description: val.consignDesc
        },
        duration: val.duration
      },
      shipment: {
        rooting: val.rooting
      },
      scanning: {
        order: val.orderScanning,
        consignment: val.consignmentScanning
      }
    }
    try {
      const {data} = await axios.put(`/users/settings`, {
        settings,
        systemSettings
      })
      setUser(data)
      handleClick('success', "Successfully updated settings")
    } catch (e) {
      handleClick('error', "Something went wrong, please try again.")
    }
    return "done"
  }

  return (
    <Formik
      enableReinitialize 
      initialValues={{
        rowsPerPage: general?.defaultRpp ? general?.defaultRpp : 25,
        rooting: shipment?.rooting ? shipment?.rooting : false,
        orderTitle: invoices?.order?.title ? invoices?.order?.title : false,
        consignTitle: invoices?.consignment?.title ? invoices?.consignment?.title : false,
        orderDesc: invoices?.order?.description ? invoices?.order?.description : false,
        consignDesc: invoices?.consignment?.description ? invoices?.consignment?.description : false,
        duration: invoices?.duration ? invoices?.duration : false,
        consignedNotification: inventory?.cNote ? inventory?.cNote : "45",
        stockNotification: inventory?.sNote ? inventory?.sNote : "45",
        orderScanning: scanning?.order ? scanning?.order : false,
        consignmentScanning: scanning?.consignment ? scanning?.consignment : false
      }}
    >
      {({ values }) => (
      <SettingsDialog
        toggle={<Flex dir="row" justify="space-between" pad="0">
          <ListItemIcon>
            <SettingsRounded color="primary" />
          </ListItemIcon>
          <ListItemText primary="Settings"/>
        </Flex>}
        title="User Settings"
        onSave={() => saveFunc(values)}
        save="Save"
        // forceOpen={true}
        size='xl'
        // fullScreen
        >
          <Form>
            <Settings permissions={user.role?.id?.permissions || {}} />
          </Form>
      </SettingsDialog>
      )}
    </Formik>
  )
}



export default SettingsContainer;