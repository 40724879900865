import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {AppBar, Toolbar, IconButton, Grid, Tooltip} from '@material-ui/core';
import { Menu, PowerSettingsNew } from '@material-ui/icons';

import { useAuthContext } from '../../auth';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '../../utils/popover';

import Settings from '../users/Settings';

import Cart from '../cart/Cart';


const CartContainer = styled.div`
  margin-right: 2rem;
`


const Bar = styled(AppBar)`
  height: 7rem;
  /* background-color: #ffe6f9; */
  background-color: #dae3f1;
  width: 100%;
  /* position: absolute; */
  /* box-shadow: 
      0px 2px 4px -1px rgba(0,0,0,0.2), 
      0px 4px 5px 0px rgba(0,0,0,0.14), 
      0px 1px 10px 0px rgba(0,0,0,0.12); */
  

  .logout {
    margin-right: 25px;
  }

  .logout:hover {
    cursor: pointer;
  }
`

const colors = [{
  color: '#fff',
  bgColor: '#0BA39F'
},
{
  color: '#fff',
  bgColor: '#970274'
}
]

const Name = styled(Avatar)`
 color: ${props => props.letter.charCodeAt(0) > 78 ? colors[1].color : colors[0].color};
 background-color: ${props => props.letter.charCodeAt(0) > 78 ? colors[1].bgColor : colors[0].bgColor};
`
 
export default ({toggle}) => {

  const firstL = val => val ? val.charAt(0).toUpperCase() : '';

  const { logout, user } = useAuthContext();

  const [first, setFirst] = useState('')

  useEffect(() => {
    setFirst(firstL(user.displayName))
  }, [])
 
 return (
 <>
  <Bar position="sticky">
    <Toolbar>
      <IconButton
        color="secondary"
        aria-label="open drawer"
        edge="start"
        onClick={toggle}
      >
        <Menu />
      </IconButton>
      <Grid justify-content="center" container>
        <Grid item xs={10}>
          {/* <Logo>
            <img src={UtopiaLogo} />
          </Logo> */}
        </Grid>
        <Grid item xs={2}>
        </Grid>
      </Grid>
      <CartContainer>
        <Cart />
      </CartContainer>

      <div className="logout">
        <Popover marginThreshold={15}
        toggle={
          <Name letter={first} >{first}</Name>
        }>
          <List>
            <ListItem button >
              <Settings />
            </ListItem>
            <ListItem button  onClick={logout} >
              <ListItemIcon>
                <PowerSettingsNew color="primary" />
              </ListItemIcon>
              <ListItemText primary="Logout"/>
            </ListItem>
          </List>
        </Popover>
      </div>
    </Toolbar>
  </Bar>
 </>
 )
}