import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Field, FieldArray } from 'formik';

import { TextField, CheckboxWithLabel, Select } from 'formik-material-ui';

import { MenuItem, FormControl, InputLabel, FormHelperText, Button, IconButton, Tooltip } from '@material-ui/core';
import LibraryAddRoundedIcon from '@material-ui/icons/LibraryAddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { CommonFlex, BasicFlex, SectionHeader } from '../../../../utils/common';

import SimpleAutoComplete from '../../../../utils/simpleAutoComplete';

import { createNewOption } from '../../../../utils/util';


const Container = styled.div`
  width: 70%;
  max-width: 60rem;
`

const FieldContainer = styled.div`
  width: 20rem;
  margin-right: 2rem;
`

const HeaderLabel = styled.span`
  display: block;
  min-width: 20rem;
  margin-right: .5rem;
`

const FieldBlock = styled.div`
  margin: 2.5rem 0;
  display: flex;
  align-items: baseline;
  font-weight: 500;
`

const Connector = styled.div`
  width: 4rem;
  height: 2px;
  background-color: ${props => props.theme.colors.border};
  margin: 0 1rem;
`
const AddButton = styled(Button)`
  margin-top: 1.5rem;
`



const DefaultField = ({ name }) => {
  return (
    <FieldBlock>
      <HeaderLabel>Default Value:</HeaderLabel>
      <FormControl variant="outlined" style={{width: '100%'}}>
        <Field
          component={TextField}
          name={`settings.defaults.${name}`}
          variant="outlined"
          margin="dense"
          fullWidth
        />
        <FormHelperText>If Value is Missing This will be the default value</FormHelperText>
      </FormControl>
    </FieldBlock>
  )
}

// const autoCompleteCreate = (val, name, func) => {
//   let newValue = {name: val.name, display: val.name}
//   func(`${name}`, newValue)
// }

const LengthField = ()  => {
  return (
    <Container>
      {/* <Labels>
        <HeaderLabel>Default Length (Number)</HeaderLabel>

        </Labels> */}
      <FieldBlock>
        <HeaderLabel>Field Requirement:</HeaderLabel>

        <FormControl variant="outlined" style={{width: '100%'}} >
          <Field
              component={Select}
              name="settings.requirements.lengthField"
              margin="dense"
              fullWidth
            >
              <MenuItem value="recommended">Recommended</MenuItem>
              <MenuItem value="required">Required</MenuItem>
          </Field>
          <FormHelperText>Set Field Requirement for this field</FormHelperText>
        </FormControl>
      </FieldBlock>
      <DefaultField name="lengthField" />
      <FieldBlock>
        <HeaderLabel>Range of Values Allowed:</HeaderLabel>
        <CommonFlex justify="flex-start">
          <FieldContainer>
            <Field
              component={TextField}
              name="settings.min.lengthField"
              label="Min Length (Number)"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </FieldContainer>
          <FieldContainer>
            <Field
              component={TextField}
              name="settings.max.lengthField"
              label="Max Length (Number)"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </FieldContainer>
        </CommonFlex>
      </FieldBlock>
    </Container>
  )
}

const CapSize = ()  => {
  return (
    <Container>
    
      <FieldBlock>
        <HeaderLabel>Field Requirement:</HeaderLabel>

        <FormControl variant="outlined" style={{width: '100%'}} >
          <Field
              component={Select}
              name="settings.requirements.capSize"
              margin="dense"
              fullWidth
            >
              <MenuItem value="optional">Optional</MenuItem>
              <MenuItem value="recommended">Recommended</MenuItem>
              <MenuItem value="required">Required</MenuItem>
          </Field>
          <FormHelperText>Set Field Requirement for this field</FormHelperText>
        </FormControl>
      </FieldBlock>
      <DefaultField name="capSize" />
      <FieldBlock>
       
      </FieldBlock>
    </Container>
  )
}

const BaseMaterial = ({ updateFunc, allowedValues, mappedValues})  => {

  console.log(mappedValues, "mappedValues", allowedValues, "allowedValues");
  return (
    <Container>
      
      <FieldBlock>
        <HeaderLabel>Field Requirement:</HeaderLabel>

        <FormControl variant="outlined" style={{width: '100%'}} >
          <Field
              component={Select}
              name="settings.requirements.base"
              margin="dense"
              fullWidth
            >
              <MenuItem value="optional">Optional</MenuItem>
              <MenuItem value="recommended">Recommended</MenuItem>
              <MenuItem value="required">Required</MenuItem>
          </Field>
          <FormHelperText>Set Field Requirement for this field</FormHelperText>
        </FormControl>
      </FieldBlock>
      <DefaultField name="base" />
      <FieldBlock>
       <HeaderLabel>Allowed Values:</HeaderLabel>
       <BasicFlex dir="column">
        <SimpleAutoComplete 
          list={[]} 
          name="settings.allowedValues.base"
          multiple
          disableCloseOnSelect
          freeSolo
          clearOnBlur
          getOptionLabel={(opt) => opt.name || ''}
          getOptionSelected={(style, val) => style.name === val.name}
          renderOption={(opt) => <span >{opt.display}</span>}
          handleChange={() => {}}
          createNew={createNewOption}
          handleSelect={(val) => updateFunc("settings.allowedValues.base", val)}
          value={allowedValues}
          autoHighlight
          // autoSelect
        />
          <FormHelperText>Select or create multiple tags</FormHelperText>
        </ BasicFlex>
      </FieldBlock>

      <SectionHeader>Map Sheet Values</SectionHeader>
      <FieldArray
        name="settings.mappedValues.base"
        render={arrayHelpers => (
          <>
          {!!mappedValues.length && mappedValues.map((val, index) => {
            // let rand = Math.random()
           return (
            <FieldBlock key={val.id}>
              <BasicFlex>
              <Field
              component={TextField}
              name={`settings.mappedValues.base.${index}.value`}
              label="Value on Sheet"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <Connector />
              {allowedValues.length ?
              <FormControl variant="outlined" style={{width: '100%'}} >
                <Field
                    component={Select}
                    name={`settings.mappedValues.base.${index}.mapped`}
                    margin="dense"
                    fullWidth
                  >
                  {allowedValues.map(el => <MenuItem value={el.name}>{el.name}</MenuItem>)}
                </Field>
              </FormControl> :
               <Field
               component={TextField}
               name={`settings.mappedValues.base.${index}.mapped`}
               label="Value to be stored"
               variant="outlined"
               margin="dense"
               fullWidth
               /> 
            }
            <Tooltip title="Delete Item" className="fab-buttons">
              <IconButton aria-label="delete" onClick={() => {
                  arrayHelpers.remove(index)
                }}>
                <DeleteForeverRoundedIcon color="secondary" />
              </IconButton>
            </Tooltip>
            </ BasicFlex>
          </FieldBlock>
        )
      })}
        <AddButton 
          startIcon={<LibraryAddRoundedIcon />} 
          color="secondary" 
          variant='outlined'
          onClick={() => arrayHelpers.push({id: Math.random(), value: "", mapped: ""})}
        >
          Add New Value Pair
        </AddButton>
        </>
        )}   
      > 

      </FieldArray>
    </Container>
  )
}
const HairStyle = ({ updateFunc, allowedValues, mappedValues})  => {

  console.log(mappedValues, "mappedValues", allowedValues, "allowedValues")
  return (
    <Container>
      
      <FieldBlock>
        <HeaderLabel>Field Requirement:</HeaderLabel>

        <FormControl variant="outlined" style={{width: '100%'}} >
          <Field
              component={Select}
              name="settings.requirements.hairstyle"
              margin="dense"
              fullWidth
            >
              <MenuItem value="optional">Optional</MenuItem>
              <MenuItem value="recommended">Recommended</MenuItem>
              <MenuItem value="required">Required</MenuItem>
          </Field>
          <FormHelperText>Set Field Requirement for this field</FormHelperText>
        </FormControl>
      </FieldBlock>
      <DefaultField name="hairstyle" />
      <FieldBlock>
       <HeaderLabel>Allowed Values:</HeaderLabel>
       <BasicFlex dir="column">
        <SimpleAutoComplete 
          list={[]} 
          name="settings.allowedValues.hairstyle"
          multiple
          disableCloseOnSelect
          freeSolo
          clearOnBlur
          getOptionLabel={(opt) => opt.name || ''}
          getOptionSelected={(style, val) => style.name === val.name}
          renderOption={(opt) => <span >{opt.display}</span>}
          handleChange={() => {}}
          createNew={createNewOption}
          handleSelect={(val) => updateFunc("settings.allowedValues.hairstyle", val)}
          value={allowedValues}
          autoHighlight
          // autoSelect
        />
          <FormHelperText>Select or create multiple tags</FormHelperText>
        </ BasicFlex>
      </FieldBlock>

      <SectionHeader>Map Sheet Values</SectionHeader>
      <FieldArray
        name="settings.mappedValues.hairstyle"
        render={arrayHelpers => (
          <>
          {!!mappedValues.length && mappedValues.map((val, index) => {
            // let rand = Math.random()
           return (
            <FieldBlock key={val.id}>
              <BasicFlex>
              <Field
              component={TextField}
              name={`settings.mappedValues.hairstyle.${index}.value`}
              label="Value on Sheet"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <Connector />
              {allowedValues.length ?
              <FormControl variant="outlined" style={{width: '100%'}} >
                <Field
                    component={Select}
                    name={`settings.mappedValues.hairstyle.${index}.mapped`}
                    margin="dense"
                    fullWidth
                  >
                  {allowedValues.map(el => <MenuItem value={el.name}>{el.name}</MenuItem>)}
                </Field>
              </FormControl> :
               <Field
               component={TextField}
               name={`settings.mappedValues.hairstyle.${index}.mapped`}
               label="Value to be stored"
               variant="outlined"
               margin="dense"
               fullWidth
               /> 
            }
            <Tooltip title="Delete Item" className="fab-buttons">
              <IconButton aria-label="delete" onClick={() => {
                  arrayHelpers.remove(index)
                }}>
                <DeleteForeverRoundedIcon color="secondary" />
              </IconButton>
            </Tooltip>
            </ BasicFlex>
          </FieldBlock>
        )
      })}
        <AddButton 
          startIcon={<LibraryAddRoundedIcon />} 
          color="secondary" 
          variant='outlined'
          onClick={() => arrayHelpers.push({id: Math.random(), value: "", mapped: ""})}
        >
          Add New Value Pair
        </AddButton>
        </>
        )}   
      > 

      </FieldArray>
    </Container>
  )
}

const BasicOptions = ({ name })  => {
  return (
    <Container>
    
      <FieldBlock>
        <HeaderLabel>Field Requirement:</HeaderLabel>

        <FormControl variant="outlined" style={{width: '100%'}} >
          <Field
              component={Select}
              name={`settings.requirements.${name}`}
              margin="dense"
              fullWidth
            >
              <MenuItem value="optional">Optional</MenuItem>
              <MenuItem value="recommended">Recommended</MenuItem>
              <MenuItem value="required">Required</MenuItem>
          </Field>
          <FormHelperText>Set Field Requirement for this field</FormHelperText>
        </FormControl>
      </FieldBlock>
    </Container>
  )
}


export { LengthField, CapSize, BaseMaterial, HairStyle, BasicOptions }