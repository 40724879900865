import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import axios from "../../../axios";

import { Grid, Select, MenuItem, FormControl, InputLabel, LinearProgress, Card, CardHeader, CardContent, Checkbox, FormControlLabel, Tooltip, Icon, IconButton, CircularProgress, ListItemText } from "@material-ui/core";

import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';

import MuiField from "../../../utils/MuiField";

import displayPDF from "../../../utils/displayPdf";

import Chip from '../../../utils/Chip';

import { theme } from "../../../utils/theme";

import { DateRangePicker, DateField, DoughnutChart } from "./common";

import FormDialog from "../../../utils/dialog";

import { Flex, CommonFlex, BasicFlex, FullDivider, SectionHeader, SectionHeaderRow } from "../../../utils/common";

import { currencyFormat, formatNiceDate } from "../../../utils/util";

import CollapsibleTable from "../CollapsibleTable";

import OutlinedSelect from "../../../utils/OutlinedSelect";

const Container = styled.div`
  
`

const Summary = styled(Card)`
  background-color: ${props => props.theme.colors.secondary95};
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.5rem;
  height: 4.5rem;
  background-color: ${props => props.bg};
  border-radius: 5px;
  margin-right: 1.5rem;
`

const StatHeader = styled.h4`
  margin: 0;
  margin-bottom: 1.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => props.theme.colors.lightText};

  &.icon-header {
    margin-bottom: .5rem;
    font-weight: 600;
  }
  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StatLabel = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.lightText};
`

const StatInfo = styled.span`
  font-weight: 500;
`

const ChipContainer = styled(Flex)`
  gap: 1rem;
  flex-wrap: wrap;
`


const headers = [
  { id: '_id', label: 'Customer Name', expand: true, expandLabel: "list", subLabel: "List of payments" },
  { id: 'count', noZero: false, label: 'Number of Payments' },
  { id: 'total', currency: true, total: true, label: 'Total Payments' },
];

const detailHeaders = [
  {id: 'paymentNum', label: "Payment Num"},
  {id: 'name', label: "Customer"},
  {id: 'paymentType', label: "Method"},
  {id: 'paymentAmount', currency: true, label: "Amount"},
  {id: 'paymentInfo', truncate: true, label: "Info"},
  {id: 'paymentNotes', truncate: true, label: "Notes"},
  {id: 'date', date: true, label: "Payment Date"},
]

const PBCTopBar = ({ brands, PBCOptions, setPBCOptions }) => {

  const paymentMethods = [
    {label: "Cash", value: "cash"},
    {label: "Quick Pay", value: "Quick Pay"},
    {label: "Credit Card", value: "Credit Card"},
    {label: "Check", value: "Check"},
    {label: "Bank Account", value: "Bank Account"},
    {label: "Other", value: "Other"},
  ]


  const { startDate, endDate, paymentType, min, max, sortBy, range } = PBCOptions;

  const handleSelect = (e, field) => {
    setPBCOptions(x => ({...x, [field]: e.target.value}));
  }

  return (
      <Grid item xs={12}>
        <Grid container spacing={3}>
     

        <Grid item xs={3}>
          <DateRangePicker options={PBCOptions} setOptions={setPBCOptions} />
        </Grid>

        

        <Grid item xs={2} md={2}>
          <FormControl variant="outlined" fullWidth 
          >
            <InputLabel>Sort By</InputLabel>
            <Select
              defaultValue={sortBy}
              onChange={(e) => handleSelect(e, 'sortBy')}
              label="Sort By"
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
            <MenuItem value="total">Total</MenuItem>
            <MenuItem value="qty">Qty</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {range === "custom" ? <Grid item xs={12}>
          <Grid container spacing={3}>

            <Grid item xs={3}>
              <DateField options={PBCOptions} setOptions={setPBCOptions}
              label="Start Date" field="startDate" />
            </Grid>
            <Grid item xs={3}>
              <DateField options={PBCOptions} setOptions={setPBCOptions}
              label="End Date" field="EndDate" />
            </Grid>

          </Grid>
        </Grid> : null }

        <Grid item xs={12} md={12}>
          <FullDivider />
        </Grid>

        <SectionHeaderRow item xs={12}>
          <SectionHeader>Advanced Filters</SectionHeader>
        </SectionHeaderRow>

        <Grid item xs={2} md={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="base-label">Select Payment Method</InputLabel>
          <Select
            labelId="base-label"
            id="base-search"
            multiple
            value={paymentType}
            onChange={(e) => handleSelect(e, "paymentType")}
            label="Select Payment Method"
            fullWidth
            renderValue={(selected) => (
              // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <ChipContainer dir="row" justify="flex-start" pad="0">
                {selected.map((value) => (
                    <Chip key={value} bgcolor={theme.colors.success} label={value} />
                ))}
              </ChipContainer>
              // </Box>
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
          {paymentMethods?.map(({ label, value }) => 
              <MenuItem value={value} key={value}>
                <Checkbox 
                  checked={paymentType.indexOf(value) > -1} 
                />
                <ListItemText primary={label} />
                </MenuItem>
            )}
          </Select>
        </FormControl>
        </Grid>

        </Grid>
      </Grid>
  )
}

const PBCReport = ({ PBCResults, loading, snack, options }) => {

  let { startDate, endDate, paymentType, min, max, sortBy } = options;
  startDate = startDate ? formatNiceDate(startDate) : null;
  endDate = endDate ? formatNiceDate(endDate) : null;

  const [invoiceLoading, setInvoiceLoading] = useState({
    email: false,
    print: false
  });

  const [missingEmail, setMissingEmail] = useState(false);

  const [email, setEmail] = useState('');

  const [detailed, setDetailed] = useState(false)

  const handleInvoiceLoading = (type, bool) => {
    setInvoiceLoading(x => ({...x, [type]: bool}));
  }

  
  const printInvoice = async () => {
    handleInvoiceLoading('print', true);
    try {
      const {data} = await axios({
        method: "GET",
        url: `/reports/payments-by-customer?startDate=${startDate}&endDate=${endDate}&paymentType=${paymentType}&min=${min}&max=${max}&sortBy=${sortBy}&pdf=true&detailed=${detailed}`,
        responseType: 'blob',
        timeout: 60000,
        headers: {"Content-Type": "application/pdf"}
      });
      displayPDF(data, `customer-payments`)
    } catch (e) {
      
    } finally {
      handleInvoiceLoading('print', false);
    }
  }

  const sendEmail = async () => {
    handleInvoiceLoading('email', true);
    if (!email) {
      setMissingEmail(true);
      return handleInvoiceLoading('email', false);
    }
    try {
      await axios.get(`/reports/payments-by-customer?startDate=${startDate}&endDate=${endDate}&paymentType=${paymentType}&min=${min}&max=${max}&sortBy=${sortBy}&email=${email}&detailed=${detailed}`);
      snack('Email successfully sent', 'success');
    } catch (e) {
      snack('Error sending email', 'error');
    } finally {
      handleInvoiceLoading('email', false);
      return "done"
    }
  }

  
  return (
    <Container>
      { loading ? <LinearProgress /> : 
      PBCResults.results ? (
        <Grid container spacing={3}>

        <Grid item xs={12}>  
          <Summary elevation={4}>
            <CardHeader title="Summary" subheader={
              <span>{startDate} - {endDate}</span>
            } 
            action={
              <BasicFlex>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={detailed}
                      onChange={(e) => setDetailed(e.target.checked)}
                      name="detailed"
                      color="primary"
                    />
                  }
                  label="Detailed Report"
                />
              <Tooltip title="Print">
              <IconButton onClick={printInvoice}>
               {invoiceLoading.print ? <CircularProgress size={20} /> : <PrintIcon color="primary" /> }
              </IconButton>
              </Tooltip>
              <FormDialog toggle={
                <Tooltip title="Email">
                <IconButton>
                  <EmailIcon color="primary" />
                </IconButton>
                </Tooltip>
              } 
              title="Add Email Address"
              onSave={sendEmail}
              save={invoiceLoading.email ? <CircularProgress size={20} /> : "Send Email"}
              size="xs"
              >
          
              <MuiField 
                label="Email Address" 
                defaultValue={email} 
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                type="email"
                err={missingEmail}
                errMsg="Email Address is required"
                />
               
              </FormDialog>
              </BasicFlex>
            }
            />
            <CardContent>
              <Grid container spacing={8}>

                {/* <Grid item xs={12}>
                  <CommonFlex justify="flex-start">

                  <CommonFlex width="max-content" >
                    <IconBox bg={theme.colors.iconBg2}><AttachMoneyRoundedIcon htmlColor={theme.colors.icon2} /></IconBox>
                    <CommonFlex dir="column" align="flex-start" justify="center">
                      <StatHeader className="icon-header">Total Payments</StatHeader>
                      <StatInfo>{currencyFormat(PBCResults.sumTotal)}</StatInfo>
                    </CommonFlex>
                  </CommonFlex>

                  <CommonFlex width="max-content"  pad="6.5rem" padY=".3rem">
                    <IconBox bg={theme.colors.iconBg1}><StyleRoundedIcon htmlColor={theme.colors.icon1} /></IconBox>
                    <CommonFlex dir="column" align="flex-start" justify="center">
                      <StatHeader className="icon-header">Total Qty</StatHeader>
                      <StatInfo>{PBCResults.sumQty}</StatInfo>
                    </CommonFlex>
                  </CommonFlex>

                  </CommonFlex>
                </Grid> */}

                {/* <Grid item xs={4}>
                  <StatHeader>Top 5 Customer By Total</StatHeader>
                  {PBCResults.topCustomersByTotal.map(el => 
                    <CommonFlex height="max-content" padY=".4rem" key={el._id}>
                      <StatLabel>{el._id}</StatLabel>
                      <StatInfo>{currencyFormat(el.total)}</StatInfo>
                    </CommonFlex>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <StatHeader>Top 5 Customer By Qty</StatHeader>
                  {PBCResults.topCustomerByQty.map(el => 
                    <CommonFlex height="max-content" padY=".4rem" key={el._id}>
                      <StatLabel>{el._id}</StatLabel>
                      <StatInfo>{el.qty}</StatInfo>
                    </CommonFlex>
                  )}
                </Grid> */}

              </Grid>
            </CardContent>
          </Summary>
        </Grid>

        <Grid item xs={12}
        // md={8} xl={9}
        >
          <CollapsibleTable headers={headers} rows={PBCResults.results} detailHeaders={detailHeaders} noView={true} /> 
        </Grid>

        {/* <Grid item xs={12} md={4} xl={3}>
            <Card elevation={4}>
              <CardHeader title="Sales Qty By Top 20 Customers" />
              <CardContent>
                <DoughnutChart data={PBCResults.chartData} title="Sales By Customer" />
              </CardContent>
            </Card>
        </Grid> */}

        </ Grid> 
      )
      : null }
    </Container>
  )
}

export {  PBCTopBar, PBCReport };