import React, {useState, useCallback, useEffect, useRef } from "react";
import styled from 'styled-components'

import {Flex} from './common'

import { Grid, Button, LinearProgress, Tooltip } from '@material-ui/core'

import { useSBContext } from "./snackbar";

import axios from "../axios"

import Lottie from 'react-lottie'

import animationData from '../images/lotties/barcode.json'

import PagesRoundedIcon from '@material-ui/icons/PagesRounded';

const AnimationBox = styled.div`
  height: 7.5rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3.5rem;
`

const ButtonContainer = styled.div`
  .helper-text {
    display: block;
    margin-top: .5rem;
  }
`

const ScanButton = styled(Button)`
  padding: .5rem .8rem;
  /* width: 3rem !important; */
  min-width: max-content;
  margin: ${props => !props.required ? "0 2rem" : "0"};

  .barcode-text {
    padding-left: 1rem;
  }

  /* .MuiButton-label {
    max-width: 1rem;
    width: auto;
  } */
`

const ScanProgress = styled.div`
  /* width: 90%; */
`

let barcode = '', interval;



const BarcodeComponent = ({wigs, setFieldValue, required}) => {

  const toggleButton = useRef()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    speed: 1,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
      // preserveAspectRatio: "none slice"
    }
  };

  const { handleClick } = useSBContext()

  const [wigItem, setWigItem] = useState("")

  const [scan, setScan] = useState(false)

  // const callScan = (e) => scanCode(e, setWigItem)

  const loadWig = async (wigId) => {
    let select = "wigId brand sku length status sale line shipmentNum history currentlyAt title description baseMaterial"
    try {
      console.log({wigId, wigs});
      if (wigs.find(el => el.wigId === wigId)) {
        handleClick("warning", `Wig ${wigId} is already selected.`)
      } else {
        const {data} = await axios.get(`/inventory/${wigId}?selected=${select}`)
  
        if (data.status === "sold") {
          handleClick("warning", `Wig ${wigId} is sold.`)
        } else {
          setFieldValue("wigs", wigs.concat({...data, currentlyAt: data.currentlyAt?.name, price: data.sale.subtotal}))
        }
      }
    } catch (e) {
      handleClick("error", `Wig ${wigId} not found, please scan again.`)
    }
    setWigItem("")
  }

  useEffect(() => {
    if (wigItem) {
      const wigId = wigItem.split(/\s+/)[0]
      if (wigId.charAt(0) !== "#") {
        loadWig(wigId?.toUpperCase())
      }
    }
  }, [wigItem])

  useEffect(() => {
    const scanCode = (e) => {
      if (interval)
          clearInterval(interval)
    
        if (e.key !== "Shift" && e.key !== "Enter")
          barcode += e.key
    
        interval = setInterval(() => {
          if (barcode && barcode.length > 4) {
            
            setWigItem(x => {
             return barcode
            })
          }
          barcode = ''
        }, 40)
    }

    if (scan) {
      document.addEventListener("keyup", scanCode)
    } else {
      document.removeEventListener("keyup", scanCode)
    }

    return () => {
      document.removeEventListener("keyup", scanCode)
    }
  }, [scan])
  
  const toggleScan = (e) => {
    toggleButton.current.blur()
   setScan(x => !x)
  }


  return <Grid item xs={4}>
  <Flex dir="row" justify="space-between" pad="0" align="center">
    <ButtonContainer>
      <Tooltip title={scan ? 'Stop Scanning' : `Scan Barcodes`}>
        <ScanButton onClick={toggleScan} required={required} ref={toggleButton} color="secondary" size="medium" variant="contained"><PagesRoundedIcon /> 
         {required ? <span className="barcode-text">Scan Barcodes</span> : "" }
        </ScanButton>
      </Tooltip>
      {/* <span className="helper-text">To add Wigs manually change Settings &gt; Scanning</span> */}
    </ButtonContainer>
    {scan ? 
    <ScanProgress>
      <AnimationBox>
        <Lottie 
        options={defaultOptions}
          height={56}
          width={75}
        />
      </AnimationBox>
    </ScanProgress> : null }
  </Flex>
</Grid>
}

export default BarcodeComponent;