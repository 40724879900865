import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../axios';

import {debounce} from 'lodash';
import {theme} from '../../utils/theme';

import CustomChip from '../../utils/CustomChip';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button';
import SimpleAutoComplete from '../../utils/simpleAutoComplete';

// bs items stats icons
import WigIcon from '../../images/wig';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

// end

import FormDialog from '../../utils/dialog';

import CardTravelIcon from '@material-ui/icons/CardTravel';


import { FlexLine, SectionHeaderRow, BasicFlex } from '../../utils/common'


import { currencyFormat,  formatGenericDate, getPercent } from '../../utils/util';

import { ResultContainer, Ribbon } from './Tracking'


const Top3Header = styled.span`
  color: ${props => props.theme.colors.lightText};
`

const SectionTitle = styled.h4`
  font-weight: 400;
  font-size: 1.8rem;
`

const ViewMoreStyles = styled.span`
 color: #3a354199;
 cursor: pointer;
 font-weight: normal;
`

const Light = styled.span`
  color: ${props => props.theme.colors.lightText};
  font-weight: normal;
`

const DetailContainer = styled.div`
  padding-right: 1.5rem;
  border-right: ${p => p.last ? "" : "1px solid #3a35411f"};
`

const StatBoxStyles = styled.div`
  display: flex;

  .icon-box {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 5px;
    box-shadow: rgb(58 53 65 / 42%) 0px 4px 8px -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    background-color: ${props => props.color || '#fff'};
      
    .wigIcon {
      width: 3rem;
    }
  }

  .stat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .stat-header {
    color: ${props => props.theme.colors.lightText};
  }

  .stat {
    color: ${props => props.theme.colors.darkText};
    font-weight: 500;
    font-size: 1.5rem;
  }

`

const StyleItemContainer = styled.div`
  padding: 0 1rem;
`

const StyleItem = styled.span`
  background-color: ${p => p.color || "#f7eded"};
  letter-spacing: 1px;
  padding: .5rem 1rem;
  border-radius: 5px;
  font-weight: 500;
`

const StyleQty = styled.span`
  color: ${p => p.theme.palette.primary.main};
  font-weight: 500;
`

const ShipQty = styled.span`
  font-weight: 500;
`

const CustomFlexStyles = styled(FlexLine)`
  font-weight: 500;

  .profit-span {
    padding-right: .7rem;
  }
`

const DialogHeader = styled.span`
  display: block;
  font-size: 1.8rem;
  color: ${props => props.theme.colors.lightText};
  margin-bottom: 2.5rem;
`

const CustomFlex = ({children}) => (
  <CustomFlexStyles dir="row" pad="0" padY=".6rem" justify="space-between">
    {children}
  </CustomFlexStyles>
)

const StatBox = ({color, icon, header, stat, total}) => {
  return (
    <StatBoxStyles color={color}>
      <div className='icon-box'>
        {icon}
      </div>
      <div className='stat-container'>
        <div className='stat-header'>{header}</div>
        <div className='stat'>{stat}</div>
        <div className='stat'>{total}</div>
      </div>
    </StatBoxStyles>
  )
}


const lightColors = [
  "#f7eded",
  "#edf7f5",
  "#f5edf7",
  "#f7edf5",
  "#f2f7ed",
  "#edf7f2",
  "#f7edf7",
  "#f7edf2",
  "#f7f0ed"
]


const TrackOrder = ({loading, panel, orderOptions, setOrderOptions, idSearch, symbol}) => {

   /* DIALOG STATE */

  const [open, setOpen] = useState(false) // open Dialog

  const [dialogContent, setDialogContent] = useState()

  const [dialogTitle, setDialogTitle] = useState()

  /* END DIALOG */

  const {styles, status, summary, shipments, bases, lengths, shipmentNum} = orderOptions

  let sold = status.find(el => el._id === 'sold') || {}

  let daysToMonths = (sold.avgDays / 30).toFixed(1)

  let consignment = status.find(el => el._id === 'consignment') || {}

  let stock = status.find(el => el._id === 'stock') || {}

  let combined = status.filter(el => el._id !== 'sold').reduce((acc, cur) => ({count: acc?.count + cur?.count, cost: acc?.cost + cur?.cost}), {count: 0, cost: 0}) || {}


  // const [link, setLink] = useState({});
  const handleChangeShipment = useCallback(debounce(async val => {
    const {data} = await axios.get(`/shipments/nums`)
    setOrderOptions(x => {return {...x, shipments: data}});
  }, 250), [])

  const handleShipmentSelect = (val) => {
    // console.log(val, "cccccc");
    setOrderOptions(x => {return {...x, shipmentNum: val._id}});
  }

  const ViewMore = ({title, content, children}) => {
    return (
      <ViewMoreStyles onClick={() => {
        setDialogTitle(title);
        setDialogContent(content);
        setOpen(true);
      }}>{children}</ViewMoreStyles>
    )
  }

  const AllShipments = summary.map(el => 
    <FlexLine key={el._id} dir="row" pad="0" padY=".6rem" justify="space-between">
      <ShipQty>{el.qty} Wigs / {currencyFormat(el.totalCost)}</ShipQty>
      <span>{formatGenericDate(el.date)}</span>
    </FlexLine>
    )

  const allLengths = <>
  <FlexLine dir="row" pad="0" padY=".6rem" justify="space-between">
    <span>Length</span> <span>Count</span>
  </FlexLine>
  {lengths?.map(({_id, count}) => 
    <CustomFlex key={_id}>
      <span>{_id}{symbol}</span> <span>{count}</span>
    </CustomFlex>
  )}
  </>

  const allBases = <>
  <FlexLine dir="row" pad="0" padY=".6rem" justify="space-between">
    <span>Base Material</span> <span>Count</span>
  </FlexLine>
  {bases?.map(({_id, count}) => 
    <CustomFlex key={_id}>
      <span>{_id}</span> <span>{count}</span>
    </CustomFlex>
  )}
  </>

  if (panel !== 3) return <div></div>;

 return (
      <>

      <FormDialog
        title="View More"
        forceOpen={open}
        onSave={() => {setOpen(false); return "close"}}
        save="Close"
        cancel={false}
        size="xs"
      >
        <DialogHeader>{dialogTitle}</DialogHeader>
        {dialogContent}
      </FormDialog>

       <Ribbon variant='outlined'>
          <Grid container spacing={4} alignItems="center">
      <Grid item md={8}>
      <SimpleAutoComplete 
          list={shipments} name="search-wigID" label="Order No."
          getOptionLabel={(opt) => opt._id || ''}
          getOptionSelected={(id, val) => id === val._id}
          renderOption={(opt) => <span>{opt._id} &nbsp; - &nbsp; {opt.totalQty} Wigs </span>}
          autoHighlight
          autoSelect
          handleChange={handleChangeShipment}
          handleSelect={handleShipmentSelect}
          />
      </Grid>

      <Grid item xs={3}>
        <Button 
          color="primary"
          variant="contained"
          onClick={idSearch}
          >Search</Button>
      </Grid>
      </Grid>
      </Ribbon>

  {loading ? <Grid container><Grid item xs={12}><LinearProgress /></Grid></Grid> : <ResultContainer>

    <Grid container spacing={3}>
      {summary.length ? <>
      <Grid item xs={12}>
        <Card elevation={4}>
          <CardHeader title={`Summary of Order #${shipmentNum}`} subheader={`Stats on Order #${shipmentNum}`}>

          </CardHeader>

          <CardContent>

          <Grid container spacing={3}>
         
          <Grid item xs={3}>
            <StatBox color={theme.colors.iconBg1} icon={<LocalShippingIcon fontSize='large' htmlColor={theme.colors.icon1} />} header="Number of Shipments" stat={summary.length} total={currencyFormat(summary.reduce((acc, cur) => acc + cur?.totalCost, 0))} />
          </Grid>
          <Grid item xs={3}>
            <StatBox color={theme.colors.iconBg2} icon={<WigIcon fontSize='large' htmlColor={theme.colors.icon2} />}  header="Qty of Wigs" stat={`${summary.reduce((acc, cur) => acc + cur?.qty, 0)}`} total={currencyFormat(summary.reduce((acc, cur) => acc + cur?.sub, 0))} />
          </Grid>
          <Grid item xs={3}>
            <StatBox color={theme.colors.iconBg3} icon={<AttachMoneyIcon fontSize='large' htmlColor={theme.colors.icon3} />} header="Wigs Sold"  stat={`${sold.count || 0}`} total={currencyFormat(sold.cost)} />
          </Grid>
          <Grid item xs={3}>
            <StatBox color={theme.colors.iconBg4} icon={<CardTravelIcon fontSize='large' htmlColor={theme.colors.icon4} />} header="Wigs in Stock" stat={`${combined.count || 0}`} total={currencyFormat(combined.cost)} />
          </Grid>

          <Grid item xs={12}>

          </Grid>

          <SectionHeaderRow item xs={12}>
            <Top3Header>Detailed Overview</Top3Header>
          </SectionHeaderRow>

          <Grid item xs={4}>
            <DetailContainer>
              <FlexLine dir="row" pad="0" justify="space-between">
                <SectionTitle>Shipments</SectionTitle>
               {summary.length > 6 ? <ViewMore content={AllShipments} title={`${summary.length} Shipments`}>View More</ViewMore> : null }
              </FlexLine>
              {summary.slice(0, 6).map(el => 
                <FlexLine key={el._id} dir="row" pad="0" padY=".6rem" justify="space-between">
                  <ShipQty>{el.qty} Wigs / {currencyFormat(el.totalCost)}</ShipQty>
                  <span>{formatGenericDate(el.date)}</span>
                </FlexLine>
                )}
            </DetailContainer>
          </Grid>

          <Grid item xs={4}>
          <DetailContainer>
              <FlexLine dir="row" pad="0" justify="space-between">
                <SectionTitle>Wig Details</SectionTitle>
                {/* <ViewMore>View More</ViewMore> */}
              </FlexLine>
              <CustomFlex>
                {styles.length} Styles
              </CustomFlex>
              {bases.length > 1 || bases[0]?._id !== null ? <CustomFlex>
                {bases.length} Base Material <ViewMore content={allBases} title={`${bases?.length} Base Materials`}>View More</ViewMore>
              </CustomFlex> : null}
              <CustomFlex>
                {lengths?.length} Lengths <ViewMore content={allLengths} title={`${lengths?.length} Lengths`}>View More</ViewMore>
              </CustomFlex>
              {consignment.count && 
                <CustomFlex>
                  {consignment.count} Wigs / {currencyFormat(consignment.cost)} <Light>In Consignment</Light>
                </CustomFlex>
              }
              {stock.count && 
                <CustomFlex>
                  {stock.count} Wigs / {currencyFormat(stock.cost)} <Light>In Stock</Light>
                </CustomFlex>
              }
            </DetailContainer>
          </Grid>

          <Grid item xs={4}>
          <DetailContainer last>
              <FlexLine dir="row" pad="0" justify="space-between">
                <SectionTitle>Sale & Profit</SectionTitle>
              </FlexLine>
              <CustomFlex>Number of Wigs Sold <span>{sold.count || 0}</span></CustomFlex>
              <CustomFlex>Cost of Wigs Sold <span>{currencyFormat(sold.cost)}</span></CustomFlex>
              <CustomFlex>Price of Wigs Sold <span>{currencyFormat(sold.price)}</span></CustomFlex>
              <CustomFlex>Total Profit <FlexLine justify="flex-end" pad="0" dir="row" width="25rem">
                <span className='profit-span'>{currencyFormat(sold.profit)} / </span>
                <CustomChip size="small" fontSize='small' level="success">
                  {getPercent(sold.profit, sold.cost, "str")}
                </CustomChip>
                </FlexLine>
              </CustomFlex>
              <CustomFlex>Avg days to sale {sold.avgDays ? <span>{Math.ceil(sold.avgDays)} Days {daysToMonths > 0 && `(${daysToMonths} Months)`}</span> : <span>N/A</span>}</CustomFlex>
            </DetailContainer>
          </Grid>
          {/* <Grid item xs={3}>
          <DetailContainer last>
              <FlexLine dir="row" pad="0" justify="space-between">
                <SectionTitle>In Stock</SectionTitle>
                <ViewMore>View More</ViewMore>
              </FlexLine>
            </DetailContainer>
          </Grid> */}

              </Grid>
            
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card elevation={4}>
            <CardHeader title="Style List"></CardHeader>
            <CardContent>
              <Grid container spacing={5}>

                {[...Array(6)].map((el, i) => 
                  <Grid item xs={2} key={i}>
                    <StyleItemContainer>
                    {[...Array(5)].map((el, j) => 
                      <FlexLine key={j} dir="row" justify="space-between" pad="0" padY="1rem">
                        {styles[i * 5 + j] ? <StyleItem color={lightColors[i]}>
                        {styles[i * 5 + j]?._id}</StyleItem> : null}
                        <StyleQty>{styles[i * 5 + j]?.count || null}</StyleQty></FlexLine>
                    )}
                    </StyleItemContainer>
                  </Grid>
                )}
          
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        </> : null}
      </Grid>

      </ResultContainer> }
       </>
 )
}

export default TrackOrder;