import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form, Field, FastField } from 'formik';
import axios from '../../../axios';
import {Grid, Paper, Button, InputAdornment, TextField as MuiTextField,
  CircularProgress, LinearProgress, FormHelperText, Switch, FormControl, FormControlLabel, MenuItem, Select, InputLabel, IconButton, Tooltip} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors } from '../../../utils/util';
import {useSBContext} from '../../../utils/snackbar';

// icons
import AddLocationRoundedIcon from '@material-ui/icons/AddLocationRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import ColorizeRoundedIcon from '@material-ui/icons/ColorizeRounded';
import BrushRoundedIcon from '@material-ui/icons/BrushRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import { theme } from '../../../utils/theme';

import EditForm from '../../../utils/editForm';

import { debounce, capitalize, set } from 'lodash';

import DatePicker from '../../../utils/DatePicker';

import ToggleButtons from '../../../utils/ToggleButtons';

import CustomChip from '../../../utils/CustomChip';

import { FullDivider, SectionHeader, SectionHeaderRow, IconBox, Header } from '../../../utils/common';

import { getChipInfo, WigInfo, WigActions, WigInfoItemHeader, WigInfoItem, ItemContainer } from './common';

import Autocomplete from '../../../utils/autoComplete';

import SimpleAutocomplete from '../../../utils/simpleAutoComplete';

import WigPickerComponent from './WigSelect';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`

const TransferTypeHeader = styled.h4`
  margin: 0;
  font-weight: 600;
  /* color: ${props => props.theme.colors.lightText}; */
`

const FieldHeader = styled.span`
  display: flex;
  align-items: center;
  gap: .6rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.source === "from" ? props.theme.colors.primaryMain : props.theme.colors.secondaryMain};
  margin-bottom: 1.2rem;
  padding-left: .2rem;
  background-color: ${props => props.source === "from" ? props.theme.colors.primaryVeryLight : props.theme.colors.secondary90};
  border-radius: 5px;
  padding: .6rem 1.4rem;
  width: fit-content;
`;

const ItemAccordion = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 10px;
`;

// const ItemContainer = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1.2rem 10px;
//   position: relative;
//   /* width: 97%; */
//   border: 1px solid ${props => props.theme.colors.border};
//   border-left: 3px solid ${props => props.err ? props.theme.colors.error : props.theme.colors.secondaryMain};
//   border-radius: 5px;
// `

const ItemRepairListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemRepairList = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
  margin: 0 .2rem;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  margin-top: -.1rem;

  ${props => props.expanded && css`
    grid-template-rows: 1fr;
    border-color: ${props.theme.colors.border};
    margin-top: 0;
  `}
`;

const Repairs = styled.div`
  /* padding: .5rem 0; */
  overflow: hidden;
`;

const ItemRepairListHandleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 3rem;
`;

const ItemRepairListHandle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  width: 10rem;
  font-size: 1.1rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  padding: .2rem;
  color: ${props => props.theme.colors.secondary25};
  background-color: ${props => props.theme.colors.secondary90};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.secondary75};
  }

  span {
    display: inline-block;
  }
`;

const ServiceInput = styled(SimpleAutocomplete)`
  background-color: #fff;
  width: 20rem;
`;

// const WigInfo = styled.div`
//   display: flex;
//   flex-direction: row;
//   gap: 2rem;

//   span {
//     display: block;
//   }
// `

// const WigInfoItem = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: .5rem;
//   font-weight: 500;
//   min-width: 12rem;
// `;

const Chip = styled(CustomChip)`
  width: fit-content;
`;

// const WigInfoItemHeader = styled(Header)`
//   font-size: 1.1rem;
// `;

// const WigActions = styled.div`
//   display: flex;
//   flex-direction: row;
//   gap: 1.5rem;
// `;

const NewRepairLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.2rem .8rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.secondary95};

  &:last-child {
    border-bottom: none;
  }

  input {
    background-color: #fff;
  }
`;

const RepairFields = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  flex-grow: 1;
`;

const NotesField = styled.div`
  flex-grow: 1;
  padding-right: 5rem;
`;

const ActionFields = styled.div`
  display: flex;
`;

let newDate;

const RepairLine = ({name, price, duration, notes, handleChangeRepair, removeRepair, id, isEdit = false, setExistingRepairs, setNewRepairs}) => {
  const [services, setServices] = useState([]);

  let selectFunc = isEdit ? setExistingRepairs : setNewRepairs;

  const searchServices = useCallback(debounce(async (val) => {
    if (val) {
      const { data } = await axios.get(`/services?term=${encodeURIComponent(val)}`)
      setServices(data.docs);
    }
  }, 250), [])

  const getService = (serviceObj) => {
    let {name, price, duration, _id: id} = serviceObj;
    let value = {name, price: price?.toString(), duration: duration?.toString(), id};
    return value;
  }

  const addRepair = (val) => {
   let foundService = getService(val);
    handleChangeRepair(id, "name", foundService, selectFunc);
  }
  return (
    <NewRepairLine key={id}>
      <RepairFields>
     { !isEdit ? <div>
        <ServiceInput
          size="small"
          list={services}
          label="Service Name"
          getOptionLabel={(opt) => opt.name || ''}
          getOptionSelected={(service, val) => service.name === val.name}
          renderOption={(opt) => <span >{opt.name}</span>}
          handleChange={(val, e) => searchServices(val, e)}
          handleSelect={(val) => {addRepair(val)}}
          autoHighlight
          // blur
          freeSolo
          value={name || ""}
          fullWidth
          disabled={isEdit}
        />
      </div>
    : <div>
      <MuiTextField label="Service Name" variant="outlined" disabled={isEdit} size="small" value={name} onChange={(e) => handleChangeRepair(id, "name", e.target.value, selectFunc)} />
      </div>  
    }
      <div><MuiTextField label="Price" variant="outlined" disabled={isEdit} size="small" value={price} onChange={(e) => handleChangeRepair(id, "price", e.target.value, selectFunc)} /></div>
      <div><MuiTextField label="Estimated Time" variant="outlined" disabled={isEdit} size="small" value={duration} onChange={(e) => handleChangeRepair(id, "duration", e.target.value, selectFunc)} /></div>
      <NotesField><MuiTextField fullWidth label="Notes" variant="outlined" disabled={isEdit} size="small" value={notes} onChange={(e) => handleChangeRepair(id, "notes", e.target.value, selectFunc)} /></NotesField>
      </RepairFields>
      <ActionFields>
        <IconButton onClick={() => removeRepair(id, selectFunc, isEdit)} size="small"><DeleteForeverRoundedIcon htmlColor={theme.colors.iconRedLight} /></IconButton>
      </ActionFields>
    </NewRepairLine>
  )
}
 
const AddTransfer = (props) => {

  const {id} =  props.match.params;

  const [loading, setLoading] = useState(false)

  // console.log(id, "Check id");
 
  const [info, SetInfo] = useState({})
  const [fromSources, setFromSources] = useState([]);
  const [toSources, setToSources] = useState([]);
  const [wigs, setWigs] = useState([]);

  const [newRepairs, setNewRepairs] = useState([]); // {wigId: "MK2765", name: "remove lace", price: 100, duration: 1, notes: "Some notes"}
  const [existingRepairs, setExistingRepairs] = useState([]);

  const [ttOverwrite, setTtOverwrite] = useState();

  const [transferWigs, setTransferWigs] = useState([
    // {wigId: "MK2765", status: "sold", sku: "7/1B-18", currentlyAt: "Libby's Salon"}, 
    // {wigId: "MK2764", status: "consignment", sku: "7/1B-18", currentlyAt: "Libby's Salon"}, 
    // {wigId: "MK2766", status: "stock", sku: "7/1B-18", currentlyAt: "Libby's Salon"}
  ]);

  const [editedRepairs, setEditedRepairs] = useState(false);

  const [itemExpanded, setItemExpanded] = useState({

  });

  const handleExpand = (wigId) => {
    setItemExpanded(expanded => ({...expanded, [wigId]: !expanded[wigId]}));
  }

  const isFromHq = (tt) => ["hqtr", "hqts"].includes(tt);

  const {handleClick} = useSBContext();

  const fromRef = useRef();
  const toRef = useRef();

  const handleChangeOptions = useCallback(debounce(async (val, tt, source) => {
    let base = ["rtr", "rthq", "hqtr"].includes(tt) ? "vendors" : "customers";
    // console.log(tt, base, "Check base")
    let url = `/${base}?term=${encodeURIComponent(val)}${base === "vendors" ? "&vendorType=services" : ""}`
    const {data} = await axios.get(url)
    let options = data
    if (data.docs) {
      options = data.docs;
    }
    // console.log(options, "Check data");
    if (source === "from") {
      setFromSources(options);
    } else {
      setToSources(options);
    }
    return val;
  }, 250), [])

  
  const handleSwitch = (e, setFieldValue) => {
    setFieldValue('active', e.target.checked)
  };

  const handleToggle = (val, setFieldValue) => {
    if (id) { // check if editing
      return handleClick('error', 'Cannot change transfer type when editing existing transfer');
    }
    if (transferWigs.length) {
      return handleClick('error', 'Please remove all wigs before changing transfer type');
    }
    setFieldValue('transferType', val);
    setFieldValue('from', "");
    setFieldValue('to', "");
  }

  const toggleAllowed = (val) => {
    if (transferWigs.length || id) {
      return false;
    }
    return true;
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const {data} = await axios.get(`/repair_transfers/${id}`)
      console.log(data, "Check data")
      const { transfer, repairs } = data;
      console.log(repairs, "Check repairs")
      SetInfo(transfer);
      setExistingRepairs(repairs);
      setTransferWigs(transfer.wigs);
      setTtOverwrite(transfer.transferType);
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false)
    }
  }

  const getChipInfo = (status) => {
    let level = "info";
    if (status === "sold") {
      level = "success";
    } else if (status === "consignment") {
      level = "icon3";
    }
    return level;
  }

  newDate = new Date().toLocaleDateString();

  useEffect(() => {
    if (id) 
      loadData();
  }, [])

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Transfer successfully updated" : "Transfer successfully added";

  let crumbs = [{path: '/repair_transfers/', text: 'Transfers'}];

  if (action === "edit")
    crumbs.push({path: `/repair_transfers/view/${id}`, text: 'View'})

  crumbs.push({path: '/repair_transfers/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
   
  });

  const onRepair = (tt) => ["hqtr", "rtr"].includes(tt);

  const removeWig = (index) => {
    let findWigId = transferWigs[index].wigId;
    setTransferWigs(oldWigs => oldWigs.filter((_, i) => i !== index));
    setNewRepairs(existingRepairs => existingRepairs.filter((repair) => repair.wigId !== findWigId));
    setExistingRepairs(oldRepairs => oldRepairs.filter((repair) => repair?.wig?.wigId !== findWigId));
  }

  const addRepair = (wigId) => {
    const rand = Math.random();
    setNewRepairs(existingRepairs => [...existingRepairs, {key: rand, wigId, name: "", price: "0", duration: "1", notes: ""}]);
    if (!itemExpanded[wigId]) {
      handleExpand(wigId);
    }
  }
 
  const handleChangeRepair = (key, field, value, func) => {
    func(existingRepairs => {
      const index = existingRepairs.findIndex((repair) => repair.key === key);
      if (index === -1) {
        return existingRepairs;
      }
      if (field === "name") {
        return [...existingRepairs.slice(0, index), {...existingRepairs[index], ...value}, ...existingRepairs.slice(index + 1)];
      }
      return [...existingRepairs.slice(0, index), {...existingRepairs[index], [field]: value}, ...existingRepairs.slice(index + 1)];
    });
  }

  const removeRepair = (key, func, isEdit) => {
    func(existingRepairs => existingRepairs.filter((repair) => repair.key !== key));
    if (isEdit) {
      setEditedRepairs(true);
    }
  }

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    console.log(userInfo, {id: userInfo?.from, name: fromRef?.current?.value})

    let transferObj = {...userInfo};
    transferObj.from= {id: userInfo?.from, name: fromRef?.current?.value};
    transferObj.to = {id: userInfo?.to, name: toRef?.current?.value};
    transferObj.wigs = transferWigs.map(el => ({wigId: el.wigId, status: el.status, id: el._id}));
    let missingRepairName = false;
    newRepairs.forEach(repair => {
      if (!repair.name) {
        missingRepairName = repair.wigId;
      }
    })
    if (missingRepairName) {
      setSubmitting(false);
      return handleClick('error', `Repair on wig ${missingRepairName} is missing a name`);
    }
    transferObj.newRepairs = newRepairs;
    transferObj.existingRepairs = existingRepairs;
    transferObj.isEdited = editedRepairs;

    console.log(existingRepairs, "Check existingRepairs")

    try {
      let query = !id ? axios.post('/repair_transfers/', {...transferObj}) : axios.put(`/repair_transfers/${id}`, {...transferObj});

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/repair_transfers/`)
      // props.history.push(`/repair_transfers/view/${id || data._id}`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }


  const toggleButtonOptions = [
    { value: "sthq", component: "Salon to HQ" },
    { value: "hqtr", component: "HQ to Repair" },
    { value: "rtr", component: "Repair to Repair" },
    { value: "rthq", component: "Repair to HQ" },
    { value: "hqts", component: "HQ to Salon" },
  ]
 
 return (
 <EditForm title={action === "edit" ? "Edit Transfer" : "New Transfer"} header="Transfer" crumbs={crumbs}>
  {loading ? <LinearProgress />:
   <Formik
    enableReinitialize
    initialValues={
       {
        date: id ? info?.date : newDate,
        transferType: id ? info?.transferType : "sthq",
        from: id ? info?.from?.id?._id: "",
        to: id ? info?.to?.id?._id : ""
        // wigs: [
        //   // {wigId: "MK2765", status: "sold", sku: "7/1B-18", currentlyAt: "Libby's Salon"}, {wigId: "MK2764", status: "consignment", sku: "7/1B-18", currentlyAt: "Libby's Salon"}, {wigId: "MK2766", status: "stock", sku: "7/1B-18", currentlyAt: "Libby's Salon"}
        // ],
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, values, setFieldValue, errors, touched }) => (
        <Form>
          <Grid container spacing={4}>

            {console.log(values, "Check values")}

          <Row item xs={12}>
            <TransferTypeHeader>Select Transfer Type</TransferTypeHeader>
            <ToggleButtons buttons={toggleButtonOptions} val={values.transferType} setFunc={(val) => handleToggle(val, setFieldValue)} toggleAllowed={toggleAllowed} overwriteVal={ttOverwrite} />
          </Row>
            
          <SectionHeaderRow item xs={12}>
            <SectionHeader>General Info</SectionHeader>
          </SectionHeaderRow>

            <Row item xs={12} sm={6} md={4}>
              <DatePicker
                label="Transfer Date"
                name="date"
                dtb
                fullWidth
              />
            </Row>

            {/* Filler Row */}
            <Row item xs={false} sm={6} md={8}>
            </Row>

           {["hqtr", "hqts"].includes(values.transferType) ? null : <Row item xs={8}>
            <FieldHeader source="from"><AddLocationRoundedIcon fontSize='small' /> Select Source</FieldHeader>
            <Autocomplete
               list={fromSources} name="from" 
               field={'_id'} 
               label={values.transferType === "sthq" ? "Salon" : "Vendor"}
               key={values.transferType === 'sthq' ? 'salon' : 'vendor'}
               getOptionLabel={({name}) => name || ""} 
               getOptionSelected={({name}, val) => name === val.name}
               renderOption={(opt) => <span>{opt.name}</span>}
               handleChange={(val, _) => handleChangeOptions(val, values.transferType, "from")}
               autoHighlight
               autoSelect
               inputRef={fromRef}
               blur
               setter={setFieldValue}
               value={{name: info?.from?.name}}
               err={errors.from && touched.from ? errors.from : ''}
              />
            </Row> }

          {["sthq", "rthq"].includes(values.transferType) ? null : <Row item xs={8}>
          <FieldHeader><AddLocationRoundedIcon fontSize='small' /> Select Destination</FieldHeader>
            <Autocomplete
               list={toSources} name="to" 
               field={'_id'} 
               label={values.transferType === "hqts" ? "Salon" : "Vendor"}
               getOptionLabel={({name}) => name || ""} 
               getOptionSelected={({name}, val) => name === val.name}
               renderOption={(opt) => <span>{opt.name}</span>}
               handleChange={(val, _) => handleChangeOptions(val, values.transferType, "to")}
               autoHighlight
               autoSelect
               inputRef={toRef}
               blur
               setter={setFieldValue}
               value={{name: info?.to?.name}}
               err={errors.to && touched.to ? errors.to : ''}
              />
            </Row> }

            
          <Grid item xs={12}>
            <FullDivider />
          </Grid>

          <SectionHeaderRow item xs={12}>
            <SectionHeader>Select wigs from {isFromHq(values.transferType) ? "HQ" : fromRef?.current?.value}</SectionHeader>
          </SectionHeaderRow>

          <Row item xs={12}>
            <WigPickerComponent transferWigs={transferWigs} setTransferWigs={setTransferWigs} transferType={values.transferType} id={values?.from} />
          </Row>


          <Grid item xs={12}>
            <FullDivider />
          </Grid>

          <SectionHeaderRow item xs={12}>
            <SectionHeader>Transfer Items</SectionHeader>
          </SectionHeaderRow>

          <Row item xs={12}>
          {transferWigs.map((wig, index) => (
            <ItemAccordion key={wig.wigId}>
              <ItemContainer err={wig.err}>
                <WigInfo>
                  <WigInfoItem className='wigId'>
                    <WigInfoItemHeader>Wig ID</WigInfoItemHeader>
                    <span>{wig.wigId}</span>
                  </WigInfoItem> 
                  <WigInfoItem className='sku'>
                    <WigInfoItemHeader>Style</WigInfoItemHeader>
                    <span>{wig.sku}</span>
                  </WigInfoItem>
                  <WigInfoItem className='status'>
                    <WigInfoItemHeader>Status</WigInfoItemHeader>
                    <span><Chip fontSize='small' level={getChipInfo(wig.status)}>{capitalize(wig.status)}</Chip></span>
                  </WigInfoItem>
                  <WigInfoItem className='location'>
                    <WigInfoItemHeader>Location</WigInfoItemHeader>
                    <span>{wig.currentlyAt || "HQ"}</span>
                  </WigInfoItem>
                </WigInfo>
                <WigActions>
                {onRepair(values.transferType) &&  <Tooltip title="Add Repair" onClick={() => addRepair(wig.wigId)}>
                  <div>
                    <IconBox size="small" button color={theme.colors.icon2} bg={theme.colors.iconBg2}>
                      <BrushRoundedIcon fontSize="small" />
                    </IconBox>
                  </div>
                  </Tooltip>}
                <Tooltip title="Remove"> 
                    <div>
                    <IconBox size="small" onClick={() => removeWig(index)} button color={theme.colors.iconRed} bg={theme.colors.iconBgRed}>
                      <DeleteRoundedIcon fontSize="small" />
                    </IconBox>
                    </div>
                </Tooltip>
                </WigActions>
              </ItemContainer>

              {console.log(existingRepairs, "Check old repairs")}

             {onRepair(values.transferType) && <ItemRepairListContainer expanded={itemExpanded[wig.wigId]}>
                <ItemRepairList expanded={itemExpanded[wig.wigId]}>
                 <Repairs>
                  {existingRepairs.filter(item => item.wigId === wig.wigId).map(({repair: {name, price, duration, notes, key}}) =>
                  <RepairLine id={key} name={name} duration={duration} notes={notes} price={price}  handleChangeRepair={handleChangeRepair} removeRepair={removeRepair} setExistingRepairs={setExistingRepairs} setNewRepairs={setNewRepairs} isEdit={true} />
                  )}
                  {newRepairs.filter(item => item.wigId === wig.wigId).map(({name, price, duration, notes, key}) =>
                 <RepairLine id={key} name={name} duration={duration} notes={notes} price={price}  handleChangeRepair={handleChangeRepair} removeRepair={removeRepair} setExistingRepairs={setExistingRepairs} setNewRepairs={setNewRepairs} />
                  )}
                  </Repairs> 
                </ItemRepairList>
                <ItemRepairListHandleContainer>
                  <ItemRepairListHandle onClick={() => handleExpand(wig.wigId)}>
                  <span>Repairs</span> {itemExpanded[wig.wigId] ? <KeyboardArrowDownRoundedIcon fontSize='small' /> : <KeyboardArrowRightRoundedIcon fontSize='small' /> }
                  </ItemRepairListHandle>
                </ItemRepairListHandleContainer>
              </ItemRepairListContainer>}

            </ItemAccordion>
          ))}
          </Row>


          <Grid item xs={12}>
            <FullDivider />
          </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>More Info</SectionHeader>
            </SectionHeaderRow>
            
            <Row item xs={12} sm={6}>
            <Field
            component={TextField}
            name="notes"
            type="text"
            label="Notes"
            multiline
            minRows={4}
            variant="outlined"
            fullWidth
          />
            </Row>
            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> :  
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
  }
 </EditForm>
 )
}

export default AddTransfer;