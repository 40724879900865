import React from "react";

import { TextField } from "@material-ui/core";

const MuiField = ({ variant = "outlined", err = false, errMsg, ...props}) => {
  return (
    <TextField
      id="standard-basic"
      label="Standard"
      variant={variant}
      error={err}
      helperText={err && errMsg}
      {...props}
    />
  );
}

export default MuiField;