import React from 'react'
import axios from './axios'

const AuthContext = React.createContext()

export const useAuthContext = () => 
  React.useContext(AuthContext)

export default function AuthProvider({children}) {
  const [user, setUser] = React.useState(null)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)


  const logout = async () => {
    try {
      await axios({
        method: 'UNLINK',
        url: '/users/logout'
      });
    } catch (e) {
    } finally {
      setIsLoggedIn(false)
      setUser(null)

      //test mode
      // localStorage.removeItem('user', '')
      // localStorage.removeItem('expiry', '')
    }
  }

  const setAutoLogout = milliseconds => {
    setTimeout(logout, milliseconds)
  }

  const login = ({expiry, user}) => {
    //set user
    setUser(user)
    setIsLoggedIn(true)

    //auto logout user when token expires
    const currTime = Date.now()

    const remainingMilliseconds =
      expiry - currTime
    setAutoLogout(remainingMilliseconds)
  }

  const hasPermission = permission =>
    isLoggedIn && (user?.role?.id?.permissions[permission] || user?.role?.id?.permissions.all)

  const auth = {
    isLoggedIn,
    user,
    setUser,
    login,
    logout,
    hasPermission
  }

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}