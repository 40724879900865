import React from 'react';
import styled from 'styled-components';

import { tableHeaders, basicHeaders, sampleValues } from './headers';

const Container = styled.div`
  height: 40rem;
  border: 2px solid #ccc;
  padding: .6rem;
  border-radius: 3px;
`
const HeaderRow = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1px;
  margin-top: 1px;
`
const HeaderCell = styled.div`
  background-color: ${props => props.theme.colors.primaryVeryLight};
  width: calc(100% / ${props => props.len});
  text-align: center;
  font-weight: 500;
  padding: .5rem 0;
`

const Cell = styled.div`
  text-align: center;
  width: ${props => `calc(100% / ${props.len} )`};
  border: 1px solid ${props => props.theme.colors.border};
  padding: .5rem 0;
`

const SampleSheet = ( { headers } ) => {

  console.log(headers, "headers")

  let sampleHeaders = headers ? {...basicHeaders, ...headers} : basicHeaders;
  let sampleHeaderKeys = Object.keys(sampleHeaders);

 return (
  <Container>
    <Row>
      {Object.entries(sampleHeaders).map(([key, value], i) => (
        <HeaderCell key={value} len={sampleHeaderKeys.length}>{value}</HeaderCell>
      ))}
    </Row>
    {[...Array(10)].map((el, i) => (
      <Row key={i}>
        {console.log(sampleHeaderKeys, "sampleHeaderKeys", i)}
        {sampleHeaderKeys.map((el, ind) => (
          <Cell key={el} len={sampleHeaderKeys.length}>{sampleValues[el]?.[i]}</Cell>
        ))}
      </Row>
    ))}
  </Container>
 )
}

export default SampleSheet;