import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'

import axios from '../../axios';

import { Button, Grid, Checkbox, FormControlLabel, CircularProgress, TextField, InputAdornment } from '@material-ui/core';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import Add from '@material-ui/icons/Add';

import { Flex, SectionHeader, SectionHeaderRow, FullDivider } from '../../utils/common';

import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';

const WigPickerSelect = styled.div`
  margin-left: 1.8rem;
`

const WigPicker = styled.div`
  min-height: 6rem;
  max-height: 26.5rem;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #0000001f;
  /* padding: 3.5rem 0; */
  border-radius: 5px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ccc;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #777;
  }

  .circular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
  }
`
const WigLine = styled.div`
  border-bottom: 1px solid #0000001f;
  /* border-top: ${props => !props.first ? "none": "1px solid #0000001f"}; */
  border-top: none;
  display: flex;
  gap: 4rem;
  align-items: center;
  padding: .5rem 2rem;
`

const WigInfo = styled.div`
  /* width: ${props => props.width ? props.width : "auto"}; */
  margin: ${props => props.mx ? `0 ${props.mx}` : "0"};
`

const NoWigs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  color: ${p => p.theme.colors.lightText};
`

const ConsignButton = styled(Button)`
  margin-top: 1.5rem;
  float: right;
`

const TopBar = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const SearchBarField = styled.div`
  flex-grow: 1;
  max-width: 60%;
`

const ConsignedWigPicker = ({ values, setFieldValue, custId, custName, setConsignedWigs, consignedWigs }) => {

  const [loading, setLoading] = useState(false)

  const [filteredWigs, setFilteredWigs] = useState([])

  const searchRef = useRef()

  const handleChange = (e, wigId) => {
    let check = e.target.checked ? true : false
    setConsignedWigs(x => x.map(el => el.wigId === wigId ? {...el, check } : el))
    searchRef.current.value = ""
  }

  const filterWigs = (e) => {
    e.stopPropagation()
    let val = e.target.value
    val = val.toUpperCase()

    // check if enter was pressed
    if (e.key === "Enter") {
      let findWig = consignedWigs.find(el => el.wigId.toUpperCase() === val)

      if (findWig) {
        if (!findWig.check) {
          handleChange({ target: { checked: true } }, findWig.wigId)
        } else {
          searchRef.current.value = ""
        }
      }
      // setFilteredWigs(filtered)
    }

    if (val === "") {
      setFilteredWigs(consignedWigs)
    } else {
      let filtered = consignedWigs.filter(el => el.wigId.toUpperCase().includes(val))
      setFilteredWigs(filtered)
    }
  }

  const loadConsigned = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`/track/client/${custId}?brand=all&onlyConsign=true`);
      setLoading(false)
      let wigs = data.wigs.map(el => {
        console.log(el, "WIGGGGG");
        return { ...el, base: el.baseMaterial, check: false, consignedByCust: true, price: el.sale.subtotal, currentlyAt: el.currentlyAt?.name || ""}
      })
      setConsignedWigs(wigs)
    } catch (e) {
      setLoading(false)
    }
  }

  const selectAllToggle = (e, arr) => {
    setConsignedWigs(x => x.map(el => ({...el, check: e.target.checked ? true : false})))
  }

  useEffect(() => {
    if (custId) {
      loadConsigned()
    } 
  }, [custId])

  useEffect(() => {
    setFilteredWigs(consignedWigs)
  }, [consignedWigs])

  return (
    custId ?
    <>
    <SectionHeaderRow item xs={12}>
      <SectionHeader>Wigs on Consignment by {custName}</SectionHeader>
    </SectionHeaderRow>

    <Grid xs={12} item className="order-total">
    <TopBar justify="space-between" align="center">
        <SearchBarField>
        <TextField variant='outlined' size="small" fullWidth InputProps={{endAdornment: <InputAdornment><SearchRoundedIcon color="primary" /></InputAdornment>}} inputRef={searchRef} placeholder="Search List..." onKeyUp={filterWigs} />
        </SearchBarField>
        {consignedWigs.length ? <WigPickerSelect>
          <FormControlLabel
            control={<Checkbox 
            onChange={(e) => selectAllToggle(e)} 
            name={`check`} size="small" color="primary" />}
            label="Select All"
          />
        </WigPickerSelect> : null }
    </TopBar>

      <WigPicker>

        {loading ? 
        <div className='circular'>
          <CircularProgress />
        </div> :
        <>

        { filteredWigs?.map((el, i) => 
        <WigLine key={el.wigId} first={i === 0}>
        <WigInfo>
        <FormControlLabel key={el.wigId}
          control={<Checkbox checked={el.check ? true : false} onChange={(e) => handleChange(e, el.wigId)} name={el.wigId}/>}
          // label={el}
        />
          {/* <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name={`checkWig.${i}.check`}
            // Label={{ label: 'Per Item' }}
          /> */}
        </WigInfo>
        <WigInfo>{el.wigId}</WigInfo>
        <WigInfo mx="10rem">{el.skuDisplay || el.sku} / {el.brand}</WigInfo>
      </WigLine>
        )}

        {!filteredWigs.length && <NoWigs>No wigs on Consignment</NoWigs>}

      </> }
        
      </WigPicker>

        {consignedWigs.length ? 
      <ConsignButton 
        variant="outlined"
        color="primary"
        startIcon={<Add />}
        onClick={() => {
          let addedWigs = consignedWigs.filter(el => el.check && !values.wigs.find(cur => cur.wigId === el.wigId))
          let wigs = values.wigs
          let finalWigs = addedWigs.length ? wigs.concat(addedWigs) : wigs
          setFieldValue("wigs", finalWigs)
        }}
      >
        Add to Order
      </ConsignButton> : null }
      {/* </CardContent>
    </Card> */}
  </Grid>

  <Grid item xs={12}>
      <FullDivider />
    </Grid>
  </> : null )
}

export default ConsignedWigPicker;