import React, { useEffect, useState } from 'react';

const useFirstLoad = () => {
 const [firstLoad, setFirstLoad] = useState(true)

//  useEffect(() => {
//   setFirstLoad(false)
//  }, [])
 
 return [firstLoad, setFirstLoad];
}

export default useFirstLoad;