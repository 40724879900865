import React from 'react';
import styled, { css } from 'styled-components';

const StatusStyles = styled.span`
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .5px;

  ${props => props.size === "large" ? css`
    padding: .8rem 2.5rem;
    font-size: 1.4rem;
    margin-right: 2rem;
  ` : props.size === "medium" ? css`
    padding: .5rem 2rem;
    font-size: 1.3rem;
  ` : css`
    padding: .4rem 1.3rem;
    font-size: 1.2rem;
  `
}

`

const Status = ({ color, bgColor, statusText, size = "medium", className }) => {
  return (
    <StatusStyles className={className} color={color} bgColor={bgColor} size={size}>
      {statusText}
    </StatusStyles>
  )
}

export default Status;