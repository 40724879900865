import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


export default ({tabNames, value, handleChange, elevation = 3, variant="standard", indColor = "primary", textColor = "primary"}) => {
  // const [value, setValue] = useState(0);
// console.log(value);

  tabNames = tabNames || ['One', 'Two', 'Three']

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <Paper style={{borderTop: 'none', position: "relative", zIndex: 5}} elevation={elevation}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor={indColor}
        textColor={textColor}
        // centered
        variant={variant}
      >
        {tabNames.map((cur, i) => (
          <Tab key={i} label={cur} />
        ))}
        
      </Tabs>
    </Paper>
  );
}
