import React, {useState, useContext, createContext} from 'react';
import styled from 'styled-components';
import {Snackbar, Slide} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Toast = styled(Snackbar)`
  top: 50px;
  right: 20px;

  &.MuiSnackbar-root {
    width: 300px;
    margin-left: 80%;
  }
`

const SBContext = createContext();

export const useSBContext = () => useContext(SBContext);
 
export default ({children}) => {

  const vertical = "top";
  const horizontal = "right"

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const handleClick = (severity, message) => {
    setSeverity(severity)
    setMessage(message)
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const actions = {
    handleClick,
    handleClose,
    severity,
    message
  }
 
 return (
  <SBContext.Provider value={actions}>
    <Toast anchorOrigin={{vertical, horizontal}} 
    open={open} autoHideDuration={4000} 
    onClose={handleClose}
    // TransitionComponent={Slide}
    >
      <Alert onClose={handleClose} severity={actions.severity}>
        {actions.message}
      </Alert>
    </Toast>
    {children}
  </SBContext.Provider>
 )
}