import React, { useState, useRef, useEffect } from "react";
import axios from '../../../../axios';

import styled from "styled-components";

import { useSBContext } from "../../../../utils/snackbar";

import { Chip, IconButton, TextField, Button, LinearProgress, CircularProgress } from "@material-ui/core";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import OutlinedSelect from "../../../../utils/OutlinedSelect";

// import theme from "../../../../utils/theme";

import Dialog from "../../../../utils/dialog";

import PanelForm from "../../PanelForm";

import { FullDivider } from "../../../../utils/common";

const Container = styled.div`
`;

const Sections = styled.div`

`

const Section = styled.section`
  margin-bottom: 6rem;
`

const SectionHeader = styled.h5`
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
`;

const ChipArray = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem 0;
  margin-bottom: 3rem;
`

const InventorySettings = (props) => {

  const field = useRef("");

  const [open, setOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [values, setValues] = useState({
    materials: [],
    lengths: [],
    capSizes: [],
    hairstyles: [],
  });

  const [modify, setModify] = useState({
    isNew: true,
    index: null,
    field: "",
    name: "",
    arr: ""
  });

  const handleEdit = (index, name, arr, field) => {
    setModify({
      isNew: false,
      index,
      field,
      name,
      arr
    });
    setOpen(true);
  }

  const handleCreate = (name, arr, field) => {
    setModify({
      isNew: true,
      field,
      name,
      arr
    });
    setOpen(true);
  }

  const { handleClick } = useSBContext();

  const handleSave = () => {
    console.log(field.current.value, "field")
    let newValue = modify.arr === "lengths" ? +field?.current?.value : field.current?.value.toUpperCase();
    let findValue = values[modify.arr].find(value => typeof newValue === "string" ? value[modify.field]?.toLowerCase() === newValue?.toLowerCase() : value[modify.field] === newValue);
    if (findValue) {
      handleClick("warning", "Value already exists");
      return "close";
    }
    if (modify.isNew) {
      let newValues = values[modify.arr].concat({
        [modify.field]: newValue,
        brand: "all"
      });
      setValues(x => ({ ...x, [modify.arr]: newValues }));
    }
    else {
      setValues({
        ...values,
        [modify.arr]: [
          ...values[modify.arr].slice(0, modify.index),
          {
            ...values[modify.arr][modify.index],
            [modify.field]: newValue
          },
          ...values[modify.arr].slice(modify.index + 1)
        ]
      });
    }
    setOpen(false);
    return "done";
  }

  const handleDelete = (index, arr) => {
    let newValues = values[arr].filter((value, i) => i !== index);
    setValues(x => ({
      ...x,
      [arr]: newValues
    }));
  }

  const loadData = async () => {
    setLoading(true);
    try {
     // fetch brands
    const { data: brandList } = await axios.get('/brands/names')
    // fetch values
    const { data: systemValues } = await axios.get('/system_settings/system_values')
    console.log(systemValues, "systemValues")
    const brandNames = brandList.map(el => el.name)
    setBrands(brandNames)
    setBrand(brandList.find(el => el.isDefault).name)

    setValues(systemValues.settings)
    } catch (e) {
      
    } finally {
      setLoading(false);
    }
    
  }

  useEffect(() => {
    loadData();
  }, [])

  const submitHandler = async () => {
    setSubmitLoading(true);
    try {
      const { data } = await axios.put('/system_settings/system_values', {settings: values})
      handleClick("success", "Changes saved successfully");
    } catch (e) {
      handleClick("error", "Error saving changes");
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Container>
      <Dialog
        title={`Add ${modify.name} Value`}
        forceOpen={open}
        forceClose={() => setOpen(false)}
        onSave={() => handleSave()}
        save="Save"
        size="xs"
      >
        <TextField 
          inputRef={field} 
          label={`Enter ${modify.name} Value`} 
          defaultValue={modify.isNew ? "" : values[modify.arr][modify.index][modify.field]}
          variant="outlined"
          fullWidth
        />
      </Dialog>
      <PanelForm title="Inventory Settings">
      {loading ? <LinearProgress /> :
        <Sections>
          <Section>
            <SectionHeader>
              Base Materials
            </SectionHeader>
            <ChipArray>
              {values.materials.map((value, index) => {
                return (
                  <Chip key={index} color="secondary" icon={<IconButton size="small" onClick={() => handleEdit(index, "Material", "materials", "value")}><EditRoundedIcon fontSize="small"  /></IconButton>} label={`${value.value}`} onDelete={() => handleDelete(index, "materials")} />
                )
              })}
            </ChipArray>
            <Button onClick={() => handleCreate("Material", "materials", "value")} variant="outlined" color="secondary" size="small" startIcon={<AddBoxRoundedIcon />}>Add New Material</Button>
          </Section>
          <Section>
            <SectionHeader>
              Lengths
            </SectionHeader>
            <ChipArray>
              {values.lengths.map((value, index) => {
                return (
                  <Chip key={index} color="secondary" label={`${value.value}`} icon={<IconButton size="small" onClick={() => handleEdit(index, "Length", "lengths", "value")}><EditRoundedIcon fontSize="small"  /></IconButton>} onDelete={() => handleDelete(index, "lengths")} />
                )
              })}
            </ChipArray>
            <Button onClick={() => handleCreate("Length", "lengths", "value")} variant="outlined" color="secondary" size="small" startIcon={<AddBoxRoundedIcon />}>Add New Length</Button>
          </Section>
          <Section>
            <SectionHeader>
              Cap Sizes
            </SectionHeader>
            <ChipArray>
              {values.capSizes.map((value, index) => {
                return (
                  <Chip key={index} color="secondary" icon={<IconButton size="small" onClick={() => handleEdit(index, "Cap Size", "capSizes", "value")}><EditRoundedIcon fontSize="small"  /></IconButton>} label={`${value.value}`} onDelete={() => handleDelete(index, "capSizes")} />
                )
              })}
            </ChipArray>
            <Button onClick={() => handleCreate("Cap Size", "capSizes", "value")} variant="outlined" color="secondary" size="small" startIcon={<AddBoxRoundedIcon />}>Add New Cap Size</Button>
          </Section>
          <Section>
            <SectionHeader>
              Hairstyles
            </SectionHeader>
            <ChipArray>
              {values.hairstyles.map((value, index) => {
                return (
                  <Chip key={index} color="secondary" icon={<IconButton size="small" onClick={() => handleEdit(index, "Hairstyle", "hairstyles", "value")}><EditRoundedIcon fontSize="small"  /></IconButton>} label={`${value.value}`} onDelete={() => handleDelete(index, "hairstyles")} />
                )
              })}
            </ChipArray>
            <Button onClick={() => handleCreate("Hairstyle", "hairstyles", "value")} variant="outlined" color="secondary" size="small" startIcon={<AddBoxRoundedIcon />}>Add New Hairstyle</Button>
          </Section>
        </Sections>
        }

        <FullDivider y="1.5rem" />

        <Button variant="contained" color="primary" onClick={submitHandler} startIcon={<SaveOutlinedIcon />}>{submitLoading? <CircularProgress size={24} color="white" /> : <>Save Changes</>}</Button>
      </PanelForm> 
    </Container>
  )
}

export default InventorySettings;