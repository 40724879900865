import React, {useEffect} from 'react'
import styled, { css } from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from '../../axios';

import { sumBy, get, capitalize } from 'lodash';

import { theme } from '../../utils/theme';

import SimpleTable from '../../utils/simpleTable';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Chip from '../../utils/Chip';

import OutlinedSelect from '../../utils/OutlinedSelect';

import CustomChip from '../../utils/CustomChip';

import SearchIcon from '@material-ui/icons/Search';

import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CardTravelIcon from '@material-ui/icons/CardTravel';

import FormLabel from '@material-ui/core/FormLabel';
// import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import SimpleAutoComplete from '../../utils/simpleAutoComplete';

import { FullDivider, Flex } from '../../utils/common';

import { useCartContext } from '../cart/cartContext';


import { ResultContainer, Ribbon } from './Tracking'


const InfoItem = styled.span`
  display: block;
  /* margin-bottom: 1rem; */
  font-size: 1.6rem;
  margin: .8rem 0;

  &.clickable {
    cursor: pointer;
  }

  &.customer {
    color: ${props => props.theme.colors.darkText};
    font-weight: 600;
  }

  &.customer:hover {
    transform: scale(1.01);
  }

  &.header {
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
`
const InfoLabel = styled.span`
  font-weight: 500;
  font-size: 1.5rem;
  padding-right: 1rem;
`

const HeaderLabel = styled(CustomChip)`
  width: max-content;
  font-weight: 500;
  padding: .3rem 1rem;
  /* font-weight: 500;
  padding-right: 1rem;
  font-size: 1.4rem;
  background-color: #efefef;
  border-radius: 5px;
  padding: .5rem; */
`

const StyleLine = styled.div`
  ${props => props.type === "category" ? css`
    display: block;
    width: 100%;
    /* background-color: #efefef; */
    font-weight: 500;
  ` : ""}
`

const SliderLabel = styled(FormLabel)`
  margin-bottom: 2rem;
` 

const CapLabel = styled(FormLabel)`
  margin-bottom: 1rem;
`

const BaseLabel = styled(FormLabel)`
  margin-bottom: 1.7rem;
`

const Spacer = styled.div`
  width: 20rem;
`

const ChipContainer = styled(Flex)`
  gap: 1rem;
`

const Group = styled(FormGroup)`
  /* .MuiCheckbox-root {
    padding-bottom: 4px;
  } */
` 

const SliderContainer = styled.div`
  margin-top: 1rem;
`
 
export default ({loading, panel, options, styleHeaders, setStyleOptions, search, brands}) => {

  const { addItem } = useCartContext()

  const {styles, slider, marks, sku, brand, uniqueStyles, capSizes, capSizeValues, lengths, filteredStyles, panelLoading, minMax} = options

  // console.log(capSizeValues, "cccccc");

  const filter = (field, val, all) => { // filterStyles
    const {styles} = options
    let filtered;
    if (!all) {
      filtered = styles.wigs.filter(el => get(el, field) === val)
      setStyleOptions(x => {return {...x, filteredStyles: filtered}});
    } else {
      setStyleOptions(x => {return {...x, filteredStyles: styles.wigs}});

    }
  }

  const handleFilter = (e, newValue, name) => {
    if (newValue[1] < newValue[0]) return;
    // console.log(name);
    setStyleOptions(x => {return {...x, [name]: newValue}});
  };

  const loadStyles = async () => {
    setStyleOptions(x => {return {...x, panelLoading: true}});
    try {
      const {data} = await axios.get('/inventory/styles');
      console.log(data, "ceeekeke");
      setStyleOptions(x => {return {...x, uniqueStyles: data.modList, capSizes: data.capSizes, lengths: data.lengths, bases: data.bases}});
    } catch (e) {
      setStyleOptions(x => {return {...x, uniqueStyles: [], capSizes: [], lengths: []}});
    } finally {
      setStyleOptions(x => {return {...x, panelLoading: false}});
    }
  }

  const handleChange = (e) => {
    setStyleOptions(x => {return {...x, capSizeValues: {...x.capSizeValues, [e.target.name]: e.target.checked} }});;
  }

  const handleSelect = (event, field) => {
    setStyleOptions(x => {return {...x, [field]: event.target.value}});;
  };

  const handleSelectBase = (val) => {
    setStyleOptions(x => {return {...x, base: val}});
  }


  const handleSku = (val) => setStyleOptions(x => {return {...x, sku: val}});

  useEffect(() => {
    if (panel !== 1) return;

    loadStyles();
  }, [panel])

  if (panel !== 1) return <div></div>;

  let stock = styles?.summary[0].stock.length ? styles.summary[0].stock[0].count : 0;

  let consigned = styles?.summary[0].consigned.length ? sumBy(styles.summary[0].consigned, 'count') : 0;

  const links = [
    {
      icon: <ShoppingCartIcon color="primary" />,
      link: '/orders/edit/',
      state: true,
      toolTip: 'Add to Sale',
      id: false
    },
    {
      icon: <CardTravelIcon color="primary" />,
      link: '/consignments/edit/',
      state: true,
      toolTip: 'Add to Consignment',
      id: false
    },
]

const actions = [
  {
    icon: <AddShoppingCartRoundedIcon color="primary" />,
    toolTip: 'Add to Cart',
    id: "cart",
    action: (row) => addItem(row),
    condition: (row) => row.status !== "sold" 
  }
]
 
 return (
 <>
  <>
      {panelLoading ? <LinearProgress /> :
        <Ribbon variant='outlined'>
          <Grid container spacing={4} alignItems="center"> 

            <Grid item xs={brands?.length > 1 ? 5 : 8} md={brands?.length > 1 ? 5 : 8}>
            <SimpleAutoComplete 
                list={uniqueStyles || []} name="sku" label="Color"
                getOptionLabel={(opt) => opt.combined || ''}
                getOptionSelected={({style}, val) => style === val.style}
                renderOption={(opt) => <StyleLine type={opt.type}>{opt.style}</StyleLine>}
                groupBy={(option) => `By ${capitalize(option.type)}`}
                handleChange={() => {}}
                handleSelect={handleSku}
                autoHighlight
                autoSelect
                />
            </Grid>

            {brands?.length > 1 ?  <Grid item xs={5} md={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="brand-sel-label">Select Brand</InputLabel>
              <Select
                labelId="brand-sel-label"
                id="cust-search-brand"
                value={brand}
                onChange={(e) => handleSelect(e, "brand")}
                label="Select Brand"
                fullWidth
              >
               {brands?.map(el => 
                  <MenuItem value={el.name} selected={el.isDefault} key={el.name}>{el.name}</MenuItem>
                )}
                <MenuItem value="all">All</MenuItem>
              </Select>
            </FormControl>
            </Grid> : null }

           

            <Grid item xs={2} md={2}>
              <Button 
                color="primary"
                variant="contained"
                onClick={search}
                startIcon={<SearchIcon />}
                >Search</Button>
            </Grid>

            <Grid item xs={1} />

            {/* <Grid item xs={12}>
              Filter By:
            </Grid> */}

            <Grid item container xs={12} spacing={2} alignItems="flex-start">
              <Grid item xs={5} md={4} lg={3}>
                <CapLabel component="legend">Cap Size</CapLabel>

                <Group row>
                  { capSizes && capSizes.map(el => 
                  <FormControlLabel key={el}
                    control={<Checkbox checked={capSizeValues[el] || false} onChange={handleChange} name={el}/>}
                    label={el}
                  />
                )}
                </Group>
              </Grid>

              <Grid item xs={5} md={5} lg={4}>
                <SliderLabel component="legend">Wig Length</SliderLabel>
                  <Flex dir="row" justify="space-between" pad="0">
                    <TextField 
                      variant="outlined" 
                      value={slider[0]}
                      type="number"
                      onChange={(e) => handleFilter(e, [+e.target.value, +e.target.value], 'slider')}
                      name="min" 
                      label="Min"
                      // fullWidth
                      size='small'
                    />
                    <Spacer />
                    <TextField 
                      variant="outlined" 
                      value={slider[1]}
                      type="number"
                      onChange={(e) => handleFilter(e, [slider[0], +e.target.value], 'slider')}
                      name="max" 
                      label="Max"
                      // fullWidth
                      size='small'
                    />
                  </Flex>
                  <SliderContainer>
                    <Slider
                      getAriaLabel={() => 'Min Max range'}
                      value={slider}
                      onChange={(e, ne) => handleFilter(e, ne, "slider")}
                      min={minMax[0]}
                      max={minMax[1]}
                      name="slider"
                      step={2}
                      marks={marks}
                      valueLabelDisplay="off"
                      getAriaValueText={(value) => `$${value}`}
                      valueLabelFormat={(val) => `$${val}`}
                    />
                  </SliderContainer>
              </Grid>

              <Grid item xs={1}>

              </Grid>

              <Grid item xs={5} md={4} lg={4}>
                <BaseLabel component="legend">Base Material</BaseLabel>

                <FormControl variant="outlined" fullWidth>
                  {/* <InputLabel id="base-label">Select Base Material</InputLabel> */}
                  {/* <Select
                    labelId="base-label"
                    id="base-search"
                    multiple
                    value={options.base}
                    onChange={(e) => handleSelect(e, "base")}
                    label="Select Base Material"
                    fullWidth
                    renderValue={(selected) => (
                      // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      <ChipContainer dir="row" justify="flex-start" pad="0">
                        {selected.map((value) => (
                            <Chip key={value} bgcolor={theme.colors.success} label={value} />
                        ))}
                      </ChipContainer>
                      // </Box>
                    )}
                  >
                  {options.bases?.map(el => 
                      <MenuItem value={el} key={el}>
                        <Checkbox 
                          checked={options.base.indexOf(el) > -1} 
                        />
                        <ListItemText primary={el} />
                        </MenuItem>
                    )}
                  </Select> */}
                  <SimpleAutoComplete 
                    list={options.bases} name="tags" 
                    label="Select Base Material"
                    multiple
                    disableCloseOnSelect
                    freeSolo
                    clearOnBlur
                    getOptionLabel={(opt) => opt || ''}
                    getOptionSelected={(style, val) => style === val}
                    renderOption={(opt) => <span >{opt}</span>}
                    handleChange={() => {}}
                    handleSelect={handleSelectBase}
                    value={options.base}
                    autoHighlight
                    // autoSelect
                    />
                </FormControl>
              </Grid>

              {/* <Grid item lg={2}>
              <BaseLabel component="legend">Wig Line</BaseLabel>
                <OutlinedSelect>

                </OutlinedSelect>
              </Grid> */}

            </Grid>

          </Grid>

        </Ribbon>
        }
      </>
      <ResultContainer>
 {loading ? <LinearProgress /> : 
      <Grid container spacing={3}>
        {styles && 
        <>
        <Grid item xs={9}>
          <SimpleTable headers={styleHeaders} rows={filteredStyles} title="Inventory" link="inventory" links={links} actionLinks={actions} />
        </Grid>
        <Grid item xs={3}>
          <Card variant="outlined">
            <CardHeader title={<span style={{cursor: "pointer"}} onClick={() => filter(null, null, 'all')}> Summary <span style={{fontSize: "1.4rem"}}>
              ({consigned + stock} {consigned + stock > 1 || consigned + stock === 0 ? 'Wigs' : 'Wig'}
            )</span></span>}  />
        <CardContent>
        <InfoItem className='clickable header' onClick={() => filter('status', 'stock')}><HeaderLabel level="icon4" size="large">{stock} {stock > 1 || stock === 0 ? 'Wigs' : 'Wig'} in Stock</HeaderLabel></InfoItem>
        <Divider style={{margin: '20px -20px 20px -20px'}} />
          
        <InfoItem className='header'><InfoLabel>{consigned} {consigned > 1 || consigned === 0? 'Wigs' : 'Wig'} in Consignment</InfoLabel></InfoItem>
        {/* <Divider style={{margin: '20px 0'}} /> */}
          {styles.summary[0].consigned.length ? styles.summary[0].consigned.map(({_id, count}, i) => (
          <InfoItem className='clickable customer' onClick={() => filter('currentlyAt.name', _id)} key={i}><InfoLabel>{_id}:</InfoLabel >{count}</InfoItem>
          )) : null}
        </CardContent>
          </Card>
        </Grid>
        </>
        }
      </Grid>
    }
  </ResultContainer>
 </>
 )
}