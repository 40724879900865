import React, {useState, useEffect} from 'react';
// import styled from 'styled-components';
import axios from '../../axios';
import {Route, Switch} from 'react-router-dom';
import ProtectedRoute from '../../utils/ProtectedRoute';



import Table from '../../utils/table';

import AddRole from './AddRole';
 
export default (props) => {

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false)

  const load = async () => {
    setLoading(true)
    const {data} = await axios.get('/roles/')
    setRows(data);
    setLoading(false)
  }

  useEffect(() => {
    if (props.location.pathname !== '/roles/') return
    load();
  }, [props.location.pathname])

  const headers = [
    { id: 'name', label: 'Name' }
  ]

  const path = props.match.url;
 
 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeRole' component={AddRole} />
    <ProtectedRoute path={`${path}/edit`} guard='writeRole' component={AddRole} />
    <ProtectedRoute path={`${path}/view/:id`} guard='writeRole' component={AddRole} />
    <Route path="/roles/">
      <Table loading={loading} title="Roles" headers={headers} rows={rows} link="roles" />
    </Route>
  </Switch>
 )
}