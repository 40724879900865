import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';

import { theme } from '../../utils/theme';
import { updatePayments } from './Common';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';

import {useSBContext} from '../../utils/snackbar';

import displayPdf from '../../utils/displayPdf';

import FormDialog from '../../utils/dialog';

import { CommonFlex, FlexLine, FullDivider } from '../../utils/common'

import Invoice from './Invoice';

import { truncate } from 'lodash';

import Status from '../../utils/Status';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import Delete from '@material-ui/icons/Delete';

import Payments from './Payments';
import Refunds from './Refunds';

import { currencyFormat, formatCalendar, formatGenericDate, handleErrors, capitalize } from '../../utils/util';

import { useAuthContext } from '../../auth';

import SimpleTable from '../../utils/simpleTable';
import { Formik, Form } from 'formik';

const InfoItem = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .calendar-icon {
    margin-right: .5rem;
  }
`

const StatusRow = styled(CommonFlex)`
  font-size: 1.6rem;
`

// const Status = styled.span`
//   background-color: ${props => props.bgColor};
//   color: ${props => props.color};
//   padding: .5rem 2rem;
//   border-radius: 5px;
//   font-weight: 600;
//   text-transform: uppercase;
//   font-size: 1.3rem;
//   letter-spacing: .5px;
// `

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const Balance = styled.span`
  color: ${props => props.color ? 'salmon': 'inherit'};
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const TooltipContainer = styled.div`
  display: flex;
`

const LogLabel = styled.h4`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.lightText};
`

const LogLine = styled.span`
 display: inline-block;
 margin-left: 2.5rem;

 .bold {
  display: inline;
  font-weight: 500;
 }
`

const LogContainer = styled.div`
  display: ${props => props.open ? "block" : "none"};
`

const FlexInline = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: .6rem;
`

const ReceiptOptionContainer = styled.div`
  background-color: ${props => props.theme.palette.grey[100]};
  padding: .8rem 1rem;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-radius: 3px;
  margin-bottom: 1.5rem;
  margin-top: -20px;
`;

const ReceiptOptionHeader = styled.span`
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${props => props.theme.palette.grey[700]};
`;

const Log = ({ el }) => {
  return (
  <FlexLine key={el.createdAt} dir="row" justify="flex-start" pad="1.5rem">
    {el.method === "POST" ? <PostAddRoundedIcon color="primary" /> : <BuildRoundedIcon color="secondary" />}
    <LogLine>
      <span className='bold'>{el.user}</span>
      {el.method === "POST" ? " created" : " modified"} the order{el.method === "PUT" ?  el.mod ? `: ${el.mod},` : "" : ` with ${el.body.wigs.length} wig${el.body.wigs.length > 1 ? "s" : ""}`} on {formatGenericDate(el.createdAt)}
    </LogLine>
    </FlexLine>
  )
}

 
export default (props) => {

  const [loading, setLoading] = useState(false)

  const [loadingLogs, setLoadingLogs] = useState(false)

  const [addLoading, setAddLoading] = useState(false)

  const [trashLoading, setTrashLoading] = useState(false);

  const [batchLoading, setBatchLoading] = useState(false)

  const [logs, setLogs] = useState([])

  const [openLogs, setOpenLogs] = useState(false)

  const {handleClick} = useSBContext();

  const [info, setInfo] = useState({})

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [email, setEmail] = useState('');

  const [emailErr, setEmailErr] = useState(false);

  const [eLoading, setELoading] = useState(false)

  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const [paymentErrors, setPaymentErrors] = useState([]);

  const [payments, setPayments] = useState({
    allPayments: [],
    newPayments: []
  })

  const [paymentObj, setPaymentObj] = useState({
  })

  const [invoiceType, setInvoiceType] = useState('')


  const {user} = useAuthContext();

  console.log(user?.system?.settings?.invoices)

  let defaultInvoiceType = user?.system?.settings?.invoices?.order?.invoiceType || 'invoice';

  useEffect(() => {
    setInvoiceType(defaultInvoiceType)
  }, [user.system?.settings])

  const handleRadioChange = (e) => {
    setInvoiceType(e.target.value)
  }

  const toggleLogOpen = () => setOpenLogs(x => !x)

  const handleEmailChange = (e) => {
    setEmailErr(false)
    setEmail(e.target.value)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const {id} = props.match.params;

 
  const crumbs = [{path: '/orders/', text: 'Sales'}, 
  {path: `/orders/view/${id}`, text: 'View'}, {path: `/orders/view/${id}`, text: `${info?.orderId || ""}`}];

  const loadLogs = async (info) => {
    if (info) {
      setLoadingLogs(true)
      // console.log(info, "hdhdhdh");
      const {data: logs} = await axios.get(`/logs?model=orders&createdAt=${info.createdAt}&id=${id}`);
      // console.log(logs, "cchek logssss");
      setLogs(logs)
      setLoadingLogs(false)
    }
  }

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/orders/${id}?populated=to.id`);
    setInfo(data);

    setPayments({
      allPayments: data.payments || [],
      newPayments: []
    })

    if (data.to?.id?.email) setEmail(data.to.id.email);

    setLoading(false)

    await loadLogs(data)
  }

  const refundHeaders = [
    { id: 'wigId', label: 'ID' },
    { id: 'amount', currency: true, label: 'Wig Price' },
    { id: 'refundedAmount', currency: true, label: 'Amount Refunded' },
    { id: 'amountPaid', currency: true, label: 'Amount Paid for wig' },
    { id: 'location', label: 'Currently' },
    { id: 'notes', label: 'Notes' },
    { id: 'date', date: true, label: 'Date' }
  ]

  const wigItemHeaders = [
    {id: 'wigId', label: 'ID'},
    {id: 'brand', label: 'Brand'},
    {id: 'sku', label: 'SKU'},
    {id: 'baseMaterial', label: 'Material'},
    {id: 'price', currency: true, additional: true,  label: 'Price'}
  ]

  const chargeHeaders = [
    {id: 'description', label: 'Description'},
    {id: 'chargeType', label: 'Charge Type'},
    {id: 'rateType', label: 'Rate Type'},
    // {id: 'pw', label: 'Per Item'},
    {id: 'wigId', label: 'Wig ID'},
    {id: 'amount', currency: true, label: 'Amount'},
    // {id: 'calculated', label: 'Calculated', render: row => currencyFormat(row.calculated)}
  ];

  const paymentHeaders = [
    {id: 'paymentType', label: 'Payment Type'},
    {id: 'paymentAmount', currency: true, label: 'Amount'},
    {id: 'paymentInfo', truncate: true, label: 'Payment Info'},
    {id: 'paymentNotes', truncate: true, label: 'Notes'},
    {id: 'paymentWigId', label: 'Wig ID'},
    {id: 'date', date: true, label: 'Date'},
  ];

  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/orders');
  }, []);


  const paymentSave = async (values, setFieldValue, wal, lineItems) => {

    setPaymentErrors([]);

    setAddLoading(true)

    let customer = info.to?.id

    const { paymentType, paymentInfo, paymentNotes } = values;

    let paymentTotal = 0

    let paymentsList = Object.entries(paymentObj).filter(([key, value]) => value > 0).map(([key, value]) => {
      let payItem = {paymentType, paymentAmount: value, paymentInfo, date: selectedDate.toISOString(), paymentNotes }
      if (key === "remain") {
        payItem.remainingBalance = true
        payItem.paymentWigId = ""
      } else {
        payItem.paymentWigId = key
      }
      paymentTotal += value
      return payItem
    })

    if (paymentTotal <= 0) {
      setPaymentErrors({paymentAmount: 'Amount is required'})
      return;
    }

    if (wal <  paymentTotal) {
      setPaymentErrors({paymentAmount: 'Customer does not have enough money in account'}); 
      return;
    }

    let allPayments = payments.allPayments.concat(paymentsList);

    setPayments(state => {
      return {
        allPayments: state.allPayments.concat(paymentsList),
        newPayments: state.newPayments.concat(paymentsList)
      }
     });

     console.log(allPayments, paymentsList, "HOH OHOHOHOHOH OHO H");

    const updatedInfo = { ...info, customer: info.to.id._id, payments: allPayments }

    values.paymentType = 'Other';
    values.paymentInfo = '';
    values.paymentNotes = "";

    setPaymentObj({})

    try {
      await axios.put(`/orders/${id}`, {...updatedInfo});

      loadData()

      setAddLoading(false)

      handleClick('success', 'Payment added successfully')
    } catch (e) {
      handleClick('error', handleErrors({e}))
    }

   setFieldValue("lineItems", values.lineItems.map(el => ({...el, check: false})))

   return 'save';
  }

  const sendEmail = async () => {
    if (!email) return setEmailErr(true);
    setELoading(true)
    try {
      await axios.post(`/orders/${id}/invoice?email=${email}&invoiceType=${invoiceType}`)
      handleClick('success', 'Email successfully sent')
      setInvoiceLoading(false)
    } catch (e) {
      handleClick('error', 'Error sending email')
      setELoading(false)
    }
    return 'sent'
  }

  const printInvoice = async () => {
    setInvoiceLoading(true)
    const {data} = await axios({
      method: "POST",
      url: `/orders/${id}/invoice?pdf=true&invoiceType=${invoiceType}`,
      responseType: 'blob',
      timeout: 60000,
      headers: {"Content-Type": "application/pdf"}
    });
    setInvoiceLoading(false)
    displayPdf(data, `order-invoice`)
  }

  const refundWig = async (values) => {

    // const {wigId, amount, refundedAmount, paymentType, notes, date, amountPaid} = values

    // let wigs = info.wigs.filter(el => el.wigId !== wigId)
    // let charges = info.charges.filter(el => el.wigId !== wigId)
    let payments = info.payments;

    let refunds = info.refunds.concat(values)
    let refund = {...values}

    // if (refundedAmount < amountPaid) {
    //   charges.push({
    //     description: `For refunded wig ${wigId}`,
    //     chargeType: 'surcharge',
    //     rateType: 'fixed',
    //     pw: false,
    //     wigId: '',
    //     amount: amountPaid - refundedAmount
    //   })
    // }

    // if (refundedAmount > 0)
    //   payments = info.payments.concat({
    //     paymentType,
    //     paymentAmount: -refundedAmount,
    //     paymentNotes: `Refund for wig ${wigId}`,
    //     date: Date.now()
    //   })

    const updatedInfo = {...info, customer: info.to.id._id, refunds}

    try {
      await axios.put(`/orders/${id}`, {...updatedInfo, refund});

      loadData()

      handleClick('success', 'Refunded successfully')
    } catch (e) {
      handleClick('error', handleErrors({e}))
    }
   return 'refund';
  }

  const deletePayments = async (newPayments, loadFunc, reset) => {
    const updatedInfo = { ...info, customer: info.to.id._id, payments: newPayments }
    loadFunc(true)

    try {
    await axios.put(`/orders/${id}`, {...updatedInfo});


      loadData()

      loadFunc(false)

      if (reset) {
        // reset selected
        reset({})
      }

      handleClick('success', 'Payment successfully removed')
    } catch (e) {
      handleClick('error', handleErrors({e}))
    }
  }

  const trashFunc = (index) => {
    let newPayments = payments.allPayments.filter((el, ind) => ind !== index)
    deletePayments(newPayments, setTrashLoading)
  }

  const batchTrashFunc = (listObj, reset) => {
    let newPayments = payments.allPayments.filter((el) => !listObj[el._id])

    deletePayments(newPayments, setBatchLoading, reset)
  }

  const batchActions = [
    {
      icon: <Delete color="primary" />,
      title: "Delete?",
      cta: "Confirm Delete",
      func: (listObj, reset) => batchTrashFunc(listObj, reset),
      warningText: "Deleting will permanently remove payments from the system.",
      loading: batchLoading
    }
  ]

  let color, bgColor, statusText;

  if (info.balance <= 0) {
    color = theme.colors.icon2; bgColor = theme.colors.iconBg2; statusText = "Paid"
  } else {
    if (info.totalPaid > 0) {
      color = theme.colors.iconCaution; bgColor = theme.colors.iconBgCaution; statusText = "Partially Paid"
    } else {
      color = theme.colors.iconRed; bgColor = theme.colors.iconBgRed; statusText = "Not Paid"
    }
  }


 return (
  <ViewInfo title="View Sale Details" crumbs={crumbs}>
    {loading ? <Grid container><Grid item xs={12}><LinearProgress /></Grid></Grid> : 
    <Grid container spacing={3}>

      <Grid item xs={6}>

        <Card variant="outlined">
          <CardHeader title={`Sale Summary - ${info.orderId}`} subheader={formatGenericDate(info?.date)} action={
            <IconLink to={`/orders/edit/${info._id}`}><EditIcon color="primary" /></IconLink>
          } />
          <CardContent>
          <InfoItem><InfoLabel>Customer:</InfoLabel> {info.to?.name}</InfoItem>
          <InfoItem><InfoLabel>Num of Wigs:</InfoLabel> {info.qty}</InfoItem>
          <InfoItem><InfoLabel>Date of Sale:</InfoLabel><FlexInline><TodayRoundedIcon className='calendar-icon' color="primary" />  {formatGenericDate(info.date)}</FlexInline></InfoItem>
          <InfoItem><InfoLabel>Date Due:</InfoLabel><FlexInline className=''><TodayRoundedIcon className='calendar-icon' color="primary" /> {formatGenericDate(info.dueDate)}</FlexInline></InfoItem>
          <InfoItem><InfoLabel>Notes:</InfoLabel> {info.notes}</InfoItem>
          <FullDivider y="20px" />
          <InfoItem><InfoLabel>Subtotal:</InfoLabel> {currencyFormat(info.subtotal)}</InfoItem>
          <InfoItem><InfoLabel>Charges & Discounts:</InfoLabel> {currencyFormat(info.total - info.subtotal)}</InfoItem>
          <InfoItem><InfoLabel>Total:</InfoLabel> {currencyFormat(info.total)}</InfoItem>
          <FullDivider y="20px" />
          <InfoItem><InfoLabel>Sale Added By:</InfoLabel> {info.addedBy?.name}</InfoItem>
          <InfoItem><InfoLabel>Last Updated:</InfoLabel> 
          {info.updatedBy && `${formatCalendar(info.updatedAt)} By ${info.updatedBy?.name}`}
          </InfoItem>

          <FullDivider y="10px" />

          <FlexLine dir="row" justify="space-between" pad="0"><LogLabel>History Logs</LogLabel>
          <IconButton onClick={toggleLogOpen}> {openLogs ? <ExpandLessRoundedIcon /> : <ExpandMoreIcon />} </IconButton></FlexLine>

          <LogContainer open={openLogs}>
            {logs.map(el => 
              <Log el={el} key={el.createdAt} />
              )}
          </LogContainer>

            
          </CardContent>
        </Card>

      </Grid>

      

      {/* <Grid item xs={3}>

        <Card variant="outlined">
          <CardHeader title="Balance Details"  />

          <CardContent>
            {invoiceLoading ? <CircularProgress /> : 
            <>
            <InfoItem><InfoLabel>Total:</InfoLabel> {currencyFormat(info.total)}</InfoItem>
            <InfoItem><InfoLabel>Total Paid:</InfoLabel> {currencyFormat(info.totalPaid)}</InfoItem>
            <InfoItem>
              <InfoLabel>Balance:</InfoLabel> 
              <Balance color={info.balance > 0 ? 'color' : ''}>
                {currencyFormat(info.balance)}
              </Balance> 
            </InfoItem>
            
            </>
            }
          </CardContent>
        </Card>

      </Grid> */}

      <Grid item xs={5}>

        <Card variant="outlined">
          <CardHeader title="Balance Details" action={
            <TooltipContainer>
            <Tooltip title="Print">
            <IconButton onClick={printInvoice}>
              {invoiceLoading ? <CircularProgress size="2.5rem" color="primary" /> : 
              <PrintIcon color="primary" /> }
            </IconButton>
            </Tooltip>
            
            <FormDialog toggle={
                <Tooltip title="Email">
                <IconButton>
                  <EmailIcon color="primary" />
                </IconButton>
                </Tooltip>
              } 
              title="Add Email Address"
              onSave={sendEmail}
              save="Send Email"
              size="sm"
              >
              <Grid container justify-content="center">
                <Grid item xs={8}>
                  {eLoading ? <LinearProgress /> : 
                  <TextField error={emailErr} variant="outlined" label="Email" name="email" type="email" fullWidth defaultValue={email} onChange={handleEmailChange}
                  helperText={emailErr ? 'Email Required' : ''} />
                }
                </Grid>
              </Grid>
              </FormDialog>
              </TooltipContainer>
          } />
          <CardContent>

          <ReceiptOptionContainer>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <ReceiptOptionHeader>Invoice Options</ReceiptOptionHeader>
            </FormLabel>
            <RadioGroup aria-label="search-by" name="search-by" value={invoiceType} onChange={handleRadioChange} row>
              <FormControlLabel value="invoice" control={<Radio />} label="Invoice" />
              <FormControlLabel value="receipt" control={<Radio />} label="Sales Receipt" />
            </RadioGroup>
          </FormControl>
          </ReceiptOptionContainer>

          <InfoItem><InfoLabel>Total:</InfoLabel> {currencyFormat(info.total)}</InfoItem>
            <InfoItem><InfoLabel>Total Paid:</InfoLabel> {currencyFormat(info.totalPaid)}</InfoItem>
            

            <InfoItem><InfoLabel>Number of Payments:</InfoLabel> {info.payments?.length}</InfoItem>
            <InfoItem>
              <InfoLabel>Balance:</InfoLabel> 
              <Balance color={info.balance > 0 ? 'color' : ''}>
                {currencyFormat(info.balance)}
              </Balance> 
            </InfoItem>
            {/* <InfoItem><InfoLabel>Total Paid:</InfoLabel> {currencyFormat(info.totalPaid)}</InfoItem> */}

            <StatusRow justify="flex-start">
              <InfoLabel>Status:</InfoLabel>
              <Status color={color} bgColor={bgColor} statusText={statusText} size="medium"></Status>
            </StatusRow>

            <FullDivider y="20px" />

            <Formik
            enableReinitialize
            initialValues={{
              paymentType: 'Other',
              paymentInfo: '',
              paymentNotes: '',
              lineItems: []
            }}
            >
              {
            ({submitForm, isSubmitting, values, setFieldTouched, setErrors, setFieldValue, touched, handleChange}) => (
              <Form>
                <Payments saveFunc={(wal) => paymentSave(values, setFieldValue, wal, values.lineItems)} buttonVariant="outlined"
               dateValue={selectedDate} handleDateChange={handleDateChange} wigs={info.wigs || []} charges={info.charges || []} setter={setFieldValue} errors={paymentErrors} resetErr={setPaymentErrors} cust={info.to?.id} payments={payments.newPayments} allPayments={payments.allPayments} lineItems={values.lineItems} loading={addLoading} paymentState={{paymentObj, setPaymentObj}} />
              </Form>
            )
          }
            </Formik>
          </CardContent>
        </Card>

      </Grid>

      <Grid item xs={12}>
        <Invoice order={info} />
      </Grid>

      {/* <Grid item xs={12} xl={6}>
        <Card variant="outlined">
          <CardHeader title="Sale Items" action={
            <IconLink to={`/orders/edit/${info._id}`}><EditIcon /></IconLink>
          } />
          <CardContent>
            <SimpleTable rows={info.wigs} headers={wigItemHeaders} title="Inventory" link="inventory" pagination={false}  idReplace="wigId" />
          </CardContent>
        </Card>
      </Grid>

      {info.charges?.length ? 
      <Grid item xs={12} xl={6}>
        <Card variant="outlined">
          <CardHeader title="Charges" action={
            <IconLink to={`/orders/edit/${info._id}`}><EditIcon /></IconLink>
          } />
          <CardContent>
            <SimpleTable rows={info.charges} headers={chargeHeaders} actions={false} pagination={false} />
          </CardContent>
        </Card>
      </Grid> : null
    } */}

      {info.payments?.length ? 
      <Grid item xs={12} xl={8}>
        <Card variant="outlined">
          <CardHeader title="Payments" 
          // action={
          //   <IconLink to={`/orders/edit/${info._id}`}><EditIcon /></IconLink>
          // } 
          />
          <CardContent>
            <SimpleTable rows={info.payments} headers={paymentHeaders} write={false} view={false} trash={true} trashLoading={trashLoading} trashFunc={trashFunc} check={true} batchActions={batchActions} />
          </CardContent>
        </Card>
      </Grid> : null
    }

    <Grid item xs={12} xl={8}>
      <Card variant="outlined">
        <CardHeader title="Refunded Items" />
        <CardContent>
          <Refunds saveFunc={refundWig} payments={info.payments} wigs={info.wigs} charges={info.charges} />
          <Divider style={{margin: '20px 0'}} />
          {info.refunds?.length ? 
          <SimpleTable rows={info.refunds} headers={refundHeaders} actions={false} 
          pagination={false} />
          : null }
        </CardContent>
        </Card>
      </Grid>
      
    </Grid>
    }
  </ViewInfo>
 )
}