import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import StyleIcon from '@material-ui/icons/Style';
import PersonIcon from '@material-ui/icons/Person';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import Chip from '../../utils/Chip';
import { theme } from '../../utils/theme';

import { useAuthContext } from '../../auth';
import { useSBContext } from '../../utils/snackbar';

import axios from '../../axios';

import { getDaysDiff, handleErrors } from '../../utils/util'

import TabContainer from '../../utils/tabContainer';


import TrackCustomer from './TrackCustomer';
import TrackStyle from './TrackStyle';
import TrackId from './TrackId';
import TrackOrder from './TrackOrder';


const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`

export const Ribbon = styled(Paper)`
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  padding: 4rem 2rem;
  overflow: hidden;
`;

const Title = styled.h4`
  width: 95%;
  margin: 3rem auto;
  font-weight: 500;
  font-size: 2.4rem;
`

const TabContent = styled.div`
  width: 100%;
`

export const ResultContainer = styled.div`
  width: 100%;
  margin: 40px auto;
`

const TabHeaders = styled.div`
  display: flex;

  .MuiSvgIcon-root {
    margin-right: 1.5rem;
  }
`

 
const TrackingPage = () => {

  const [loading, setLoading] = useState(false)

  const [brands, setBrands] = useState([])

  const { user, hasPermission } = useAuthContext()
  const inv = user.system?.settings?.inventory
  const measurement = user.system?.settings?.shipment?.measurement || "in"

  let symbol = measurement && measurement === 'in' ? '″' : ''

  const tabNames = [
  <TabHeaders><PersonIcon />Customer</TabHeaders>,
  <TabHeaders><StyleIcon />Style</TabHeaders>,
  <TabHeaders><LocalOfferIcon />Wig ID</TabHeaders>
]

if (hasPermission('readAccounting')) {
  tabNames.push(<TabHeaders><LocalShippingIcon />Order No.</TabHeaders>)
}

  const [value, setValue] = useState(0); // for the tabs

  const { handleClick } = useSBContext()

  /* for customer search */

  const [custResults, setCustResults] = useState();  // for customer search

  const [custOptions, setCustOptions] = useState({
    cust: [],
    custValue: "",
    brand: "",
    range: "current",
    days: inv?.cNote,
    includeStatus: "all",
    filterDuration: false,
    sort: "",
    selectedStartDate: new Date(),
    selectedEndDate: new Date(),
  })

  const getCustResults = async () => {
    const {range, sort, custValue, brand, selectedStartDate, selectedEndDate, includeStatus, filterDuration, days} = custOptions

    let start = selectedStartDate.toISOString()
    let end = selectedEndDate.toISOString()
    let queryString = `&includeStatus=${includeStatus}`;
    if (range !== 'current')
      queryString += `&status=${sort}&start=${start}&end=${end}`

    if (filterDuration)
      queryString += `&duration=${days}`

      try {
        setLoading(true)
  
        const {data} = await axios.get(`/track/client/${custValue._id}?brand=${encodeURIComponent(brand)}&range=${range}${queryString}`);
        setCustResults(data);
      } catch (e) {
          handleClick('error', handleErrors({e}))
      } finally {
        setLoading(false)
      }
  }

  /* for style search */

  let marks = []
  let start = measurement && measurement === 'in' ? 10 : 5
  let end = measurement && measurement === 'in' ? 32 : 80
  let defaultSlider = measurement && measurement === 'in' ?16 : 40

  for (let i = start; i <= end; measurement && measurement === 'in' ? i+=2 : i+=5) {
    marks.push({value: i, label: `${i}${symbol}`})
  }
  const [styleOptions, setStyleOptions] = useState({
    styles: null,
    filteredStyles: null,
    brand: "",
    sku: "",
    panelLoading: false,
    slider: [defaultSlider, defaultSlider],
    bases: [],
    capSizeValues: {

    },
    marks,
    minMax: [start, end],
    uniqueStyles: null,
    capSizes: null,
    lengths: null,
    base: []
  })

  const styleResult = async () => {
    const {sku, brand, slider, capSizeValues, base} = styleOptions;
    const cs = Object.keys(capSizeValues).filter(el => capSizeValues[el]).join(",");
    // console.log(cs, "ccccccjjjuu");
    setLoading(true);
    try {
      const {data} = await axios.get(`/track/stock?sku=${sku?.style}&type=${sku?.type}&brand=${encodeURIComponent(brand)}&length[min]=${slider[0]}&length[max]=${slider[1]}&capSizes=${cs}&bases=${base}`)
      setStyleOptions(x => {return {...x, styles: data, filteredStyles: data.wigs}});

      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  /* for wigId search */

  const [idOptions, setIdOptions] = useState({
    info: {},
    link: {},
    wigs: [],
    edits: {},
    wigsValues: ""
  })

  const wigIdSearch = async () => {
    const { wigsValues } = idOptions
    // console.log(wigsValues);
    setLoading(true);
    const {data} = await axios.get(`/inventory/${wigsValues._id}?details=true`)
    // const {data: backLink} = await axios.get(`/inventory/link/${wigsValues._id}`)
    let wigInfo = data.inventory;
    setIdOptions(x => {return {...x, 
      // link: backLink?.link,
      info: data.inventory,
      repairs: data?.repairs,
      transfers: data?.transfers, 
      edits: {
      style: wigInfo.style,
      capSize: wigInfo.capSize,
      lengthNum: wigInfo.lengthNum,
      description: wigInfo.description,
      hairstyle: wigInfo.hairstyle,
      price: wigInfo.sale?.subtotal
    }}});
    setLoading(false)
  }


  /* for track order */

  const [orderOptions, setOrderOptions] = useState({
    summary: [],
    styles: [],
    lenghts: [],
    bases: [],
    status: [],
    shipmentNum: '',
    shipments: []
  })

  const orderIdSearch = async () => {
    const { shipmentNum } = orderOptions

    if (!shipmentNum)
      return;

    try {
      setLoading(true)
      const {data} = await axios.get(`/track/order/${shipmentNum}`)

      // console.log(data, 'DATATATATA');
      setOrderOptions(x => ({
        ...x, summary: data.summary,
        styles: data.wigDetails?.styles,
        status: data.wigDetails?.status,
        bases: data.wigDetails?.bases,
        lengths: data.wigDetails?.lengths,
      }))
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }



  const handleChange = (event, newValue) => {  // for the tabs
    setValue(newValue);
  };

  const custHeaders = [
    { id: 'wigId', label: 'ID' },
    { id: 'brand', label: 'Brand' },
    { id: "line", label: "Line" },
    { id: 'sku', label: 'SKU' },
    {id: 'baseMaterial', label: "Material"},
    { id: 'status', label: 'Status' },
    {id: 'duration', render: row => {
      let date = row?.history[row?.history?.length - 1]?.date
      let cInterval = inv?.cNote ? +inv?.cNote : 45
      let sInterval = inv?.sNote ? +inv?.sNote : 45
      let diff = Math.floor(getDaysDiff(date))
      if (row.status !== 'sold') {

        if (row.status === 'consignment') {
          return <Chip padx="3px" bgcolor={diff > cInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        } else {
          return <Chip padx="3px" bgcolor={diff > sInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        }

      } else {
        return "-"
      }
    }, label: 'Duration'},
  ];

  const styleHeaders = [
    ...custHeaders, 
    {id: 'currentlyAt.name', label: 'Currently At'}
  ]

  const loadBrands = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get('/brands/names')

      console.log(data, "ahoy");
      
      const brandNames = data.map(el => {return {name: el.name, isDefault: el.isDefault}})
      let defaultBrand = brandNames.find(el => el.isDefault).name
      // console.log(brandNames, "brands");
      setCustOptions(x => {return {...x, brand: defaultBrand}})
      setStyleOptions(x => {return {...x, brand: defaultBrand}})
      setBrands(brandNames)
      setLoading(false)
    } catch (e) {
      console.log(e, "trouble");
      setLoading(false)
    }
  }

  useEffect(() => {
    loadBrands()
  }, [])

 return (
   <>
    <Title>Track Wigs</Title>

    <Container>

    <TabContainer variant='fullWidth' tabNames={tabNames} value={value} handleChange={handleChange}/>

    <TabContent>

      <TrackCustomer loading={loading} panel={value} results={custResults} custHeaders={custHeaders} custOptions={custOptions} setCustOptions={setCustOptions} getCustResults={getCustResults} brands={brands} />

      <TrackStyle panel={value} loading={loading} 
      options={styleOptions} setStyleOptions={setStyleOptions} styleHeaders={styleHeaders} search={styleResult} brands={brands}
        />

      <TrackId panel={value} loading={loading} idOptions={idOptions} setIdOptions={setIdOptions} idSearch={wigIdSearch} />

      {hasPermission('readAccounting') ? 
        <TrackOrder panel={value} symbol={symbol} loading={loading} orderOptions={orderOptions} setOrderOptions={setOrderOptions} idSearch={orderIdSearch} />
      : null }
     
    </TabContent>

    </Container>
    </>
 )
}

export default TrackingPage;