import React from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom';

import { DateTime } from 'luxon';

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

const StyledLink = styled(Link)`
  display: block;
  padding: 0 !important;
  margin: 0 !important;
  color: inherit;
  min-height: auto;
  height: auto;

  svg {
    display: block;
  }
`;

const TimelineListItem = styled(TimelineItem)`
  &::before {
    display: none;
  }
  /* margin-bottom: 1rem; */
`;

const TimeLineBox = styled(TimelineDot)`
  border-radius: 8px;
  padding: .8rem;
`;

const Content = styled(TimelineContent)`
  padding: .6rem 0 .6rem 1.5rem;
`;

const Connector = styled(TimelineConnector)`
  min-height: 3.5rem;
`;

const Message = styled.div`

  .date {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: .8rem;
  }

  h4 {
    font-size: 1.4rem;
    /* text-transform: uppercase; */
    margin: .2rem 0;
    font-weight: 500;
    /* font-style: italic; */

    /* color: ${props => props.theme.colors.lightText}; */
  }

  span {
    font-size: 1.2rem;
    font-style: italic;
  }
`

const TimelineDisplay = ({linkId, date, msg, by, icon, collection, len, id}) => {

  return (
    <TimelineListItem>
      <StyledLink to={`/${collection}/view/${linkId}`} target="_blank">
        <TimelineSeparator>
            <TimeLineBox color={id % 2 === 0 ? "primary" : "secondary"}>
              {icon}
            </TimeLineBox>
            {/* <Connector /> */}
          {len - 1 !== id ? <Connector /> : null}
        </TimelineSeparator>
      </StyledLink>
      <Content>
          <Message>
          <span className='date'>{DateTime.fromISO(date).toFormat('cccc, MMMM dd, yyyy, h:mm a')}</span>
            <h4>
                {msg}
            </h4>
            <span>
              By {by?.name}
            </span>
          </Message>
      </Content>
    </TimelineListItem>
  )
}

export default TimelineDisplay

