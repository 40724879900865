import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import { theme } from '../../utils/theme';

import Table from '../../utils/table-server';
import EditCustomers from './AddCustomer';
import ViewCustomer from './ViewCustomer';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Switch as Switcher, FormControlLabel} from '@material-ui/core'
import Search from '@material-ui/icons/Search';
import { formatPhone, currencyFormat } from '../../utils/util';

import { useSBContext } from '../../utils/snackbar';


import { debounce } from 'lodash'

import Chip from '../../utils/Chip';


const SwitchContainer= styled(FormControlLabel)`
  width: 300px;
  margin-left: 30px;
`

 
export default (props) => {
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false)

  const [switcher, setSwitch] = useState(false);

  const [count, setCount] = useState(0);

  const [search, setSearch] = useState({
    p: '', rpp: '', sp: '', so: ''
  });

  const [query, setQuery] = useState('/customers/details')

  const { handleClick } = useSBContext();

  // const load = async (include) => {
  //   let query = include ? `?includeInactive=true` : ""
  //   setLoading(true)
  //   const {data} = await axios.get(`/customers/${query}`)
  //   console.log(data, "hhhdhdhdh");
  //   let info = Array.isArray(data) ? data : []
  //   setRows(info.filter(el => el.name !== 'Stock'));
  //   setLoading(false)
  // }

  const load = async (page, rowsPerPage, sortProp, sortOrder, queryStr = query) => {
    let active = switcher ? `&includeInactive=true` : ""
    // console.log(page, rowsPerPage,sortProp, sortOrder,);
    setSearch({p: page, rpp: rowsPerPage, sp: sortProp, so: sortOrder});
    // let sort = sortOrder === 'asc' ? `${sortProp}` : `-${sortProp}`
    setLoading(true)
    let queryString = queryStr === '/customers/details' ? `/customers/details?sort=${sortProp}&sortOrd=${sortOrder}&page=${page}&limit=${rowsPerPage}` : `${queryStr}&sort=${sortProp}&sortOrd=${sortOrder}&page=${page}&limit=${rowsPerPage}`;
  queryString = queryString + active
    try {
      const {data} = await axios.get(queryString)
      setLoading(false)

      setRows(data.docs?.filter(el => el.name !== 'Stock') || []);
      setCount(data.pages || 0)

      if (!data.docs)
        handleClick('error', 'Error retrieving data')
    } catch (e) {
      setLoading(false)
      setRows([])
    }
  }

  // const searchCust = useCallback(debounce(async val => {
  //   let query = switcher ? `&includeInactive=true` : ""
  //   const {data} = await axios.get(`/customers?term=${val}${query}`)
  //   let info = Array.isArray(data) ? data : []
  //   setRows(info);
  // }, 250), [switcher])

  const searchCust = useCallback(debounce(async (val, setPage) => {
    let query = switcher ? `&includeInactive=true` : ""
    let encodedVal = encodeURIComponent(val)

    const {rpp} = search
    const {data} = await axios.get(`/customers/details?term=${encodedVal}&page=0&limit=${rpp}${query}`)
    setPage(0)
    setQuery(`/customers/details?term=${encodedVal}`);
    setRows(data.docs?.filter(el => el.name !== 'Stock') || []);
    setCount(data.pages || 0)
  }, 250), [search, switcher])

  // useEffect(() => {
  //   if (props.location.pathname !== '/customers/') return
  //   load();
  // }, [props.location.pathname])

  useEffect(() => {
    const {p, rpp, sp, so} = search
    console.log(p, "chchchch");
    if (sp)
      load(0, rpp, sp, so, query)
  }, [switcher])

  const headers = [
    { id: 'name', label: 'Name' },
    // { id: 'email', label: 'Email' },
    { id: 'phone', render: (row) => formatPhone(row.phone), label: 'Phone' },
    { id: 'wallet', currency: true, label: 'Wallet' },
    { id: 'balance', currency: true, label: 'Balance' },
    { id: 'wigsInConsignment', render: row => {
      if (row.wigsInConsignment)
        return `${row.wigsInConsignment} / ${currencyFormat(row.totalCost)}`
      else
        return ""
    }, align: "center", label: 'Consigned Wigs' },
    {id: 'active', render: (row) => <Chip padx="10px" bgcolor={row.active ? theme.colors.success : theme.colors.error} size="small" label={row.active ? "Active" : "Not Active"}  />, label: "Active Status" }
  ];

  const path = props.match.url;

  const handleSwitch = (e) => { 
    // if (e.target.checked) {
    //   load("include")
    // } else {
    //   load()
    // }
    setSwitch(e.target.checked);
  };

 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeCustomer' component={EditCustomers} />
    <ProtectedRoute path={`${path}/edit`} guard='writeCustomer' component={EditCustomers} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readCustomer' component={ViewCustomer} />
    <Route path="/customers">
      <Table loading={loading} load={load} title="Customers" headers={headers} rows={rows} link="customers" count={count} ord="asc" ordField="name"
      switchField={<SwitchContainer
        control={
          <Switcher
            checked={switcher}
            onChange={handleSwitch}
            name="inactive"
            color="primary"
          />
        }
        label="Include Inactive"
        />}
      search={setPage => <TextField name="search" label="Search by Name"
      variant="outlined" size="small" fullWidth onChange={(e) => searchCust(e.target.value, setPage)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}