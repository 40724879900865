import React from 'react'
import {Route} from 'react-router-dom'
import Unauathorized from './Unauthorized'
import {useAuthContext} from '../auth'

const ProtectedRoute = ({ component: Component, guard, ...rest }) => {
  const {hasPermission} = useAuthContext()

  return (
    <Route {...rest} render={(props) => (
      hasPermission(guard)
        ? <Component {...props} />
        : <Unauathorized />
    )} />
  )
};

export default ProtectedRoute;