import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, 
  CircularProgress, InputLabel, MenuItem, Select, FormControl, Switch
, FormControlLabel, LinearProgress} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, regex } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import EditForm from '../../utils/editForm';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`
 
export default (props) => {

  const {id} =  props.match.params;

  const [loading, setLoading] = useState(id ? true : false)
 
  const [info, SetInfo] = useState({})

  const [roles, setRoles] = useState([])

  const handleSwitch = (e, setFieldValue) => {
    setFieldValue('isActive', e.target.checked)
  };

  const handleChange = (e, setFieldValue) => {
    const role = roles.find(el => el._id === e.target.value);
    setFieldValue('role', {id: role._id, name: role.name})
  }


  const loadData = async () => {
    const {data} = await axios.get(`/users/${id}`)
    SetInfo(data);
    setLoading(false);
  }

  const loadRoles = async () => {
    const {data: roles} = await axios.get('/roles/');
    setRoles(roles);
  }

  useEffect(() => {
    loadRoles();
    if (id) 
      loadData();
  }, [])

  // console.log(info);
  // console.log(role);

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "User successfully updated" : "User successfully added";

  let crumbs = [{path: '/users/', text: 'Users'}];

  if (action === "edit")
    crumbs.push({path: `/users/view/${id}`, text: 'View'})

  crumbs.push({path: '/users/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
    name: Yup
      .string(),
    displayName: Yup
    .string(),
    email: Yup
      .string()
      .required('Email is required.')
      .email('Not a valid email'),
    username: Yup
      .string(),
    role: Yup
      .string()
      .required('Role is required.'),
    password: Yup
      .string()
      .matches(regex, 'Password must be eight characters with at least one uppercase letter and a number'),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors, errors}) => {

    try {
      let query = !id ? axios.post('/users/', {...userInfo}) : axios.put(`/users/${id}`, {...userInfo});

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/users/`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }
 
 return (
 <EditForm title={action === "edit" ? "Edit User" : "New User"} header="User" crumbs={crumbs}>
   {loading ? <LinearProgress /> : 
   <Formik
    enableReinitialize
    initialValues={{
      name: info.name || '',
      email: info.email || '',
      username: info.username || '',
      role: info.role || '',
      displayName: info.displayName || '',
      password: '',
      confirmPassword: '',
      isActive: info.name ? info.isActive : true
    }}
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, values, errors, setFieldValue}) => (
        <Form>
          {/* {console.log(values)} */}
          <Grid container spacing={4}>
            <Row item xs={6} sm={6}>
            <Field
            component={TextField}
            name="name"
            type="text"
            label="Name"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={6}>
            <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={12} sm={6}>
            <Field
            component={TextField}
            name="displayName"
            type="text"
            label="Display Name"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={12} sm={8}>
            <Field
            component={TextField}
            name="username"
            type="text"
            label="Username"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={4}>
            <FormControl variant="outlined" style={{width: '100%'}} >
              <InputLabel id="role-label">Role</InputLabel>
              <Field
                  component={Select}
                  labelId="role-labe"
                  name="role"
                  id="role"
                  label="Role"
                  defaultValue={values.role.id || ""}
                  onChange={(e) => handleChange(e, setFieldValue)}
                  fullWidth
                >
                  {roles && roles.map((cur, i) => (
                  <MenuItem key={i} value={cur._id}>{cur.name}</MenuItem>
                  ))}
              </Field>
              </FormControl>
            </Row>
            {action !== 'edit' ? 
            <>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="password"
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
          />
            </Row>
            <Row item xs={6} sm={3}>
            <Field
            component={TextField}
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            variant="outlined"
            fullWidth
          />
            </Row>
            </>
            : null}
            <Row item xs={12} sm={3}>
            <FormControlLabel
            control={
              <Switch
                checked={values.isActive}
                onChange={(e) => handleSwitch(e, setFieldValue)}
                name="isActive"
                color="primary"
              />
            }
            label="Active"
            />
            </Row>
            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
   }
 </EditForm>
 )
}