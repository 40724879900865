import logo from '../images/favicon.ico';

const displayPDF = (blob, title="", icon="sfd") => {
    // console.log(blob)
    const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
        const base64data = reader.result; 
        // console.log(base64data)
        const win = window.open("", "_blank");
        const iconLink = icon ? `<link rel="shortcut icon" href="${logo}">` : ''
        if (win)
            win.document.write(`
                <html>
                <head>
                    <title>${title}</title>
                    ${iconLink}
                    <style>
                    * {
                        padding: 0;
                        margin: 0;
                    }
                    </style>
                </head>
                <body style="overflow: hidden">
                    // <iframe src="${base64data}" style="width: 100%; height: 100%" />
                </body>
                </html>
            `);
        }
}

export default displayPDF;