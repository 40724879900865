import React from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import FullDialog from '../../utils/FullDialog';
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { Button, Grid } from '@material-ui/core';


import { theme } from '../../utils/theme';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';


import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { Flex, FlexLine } from '../../utils/common';
import { currencyFormat, capitalize } from '../../utils/util';
import WigIcon from '../../images/wig';

import Delete from '@material-ui/icons/Delete';

import { useCartContext } from './cartContext';

const CardContainer = styled.div`
  /* width: 90%; */
  margin: 2rem auto;
`

const LaterContainer = styled.div`
  .title {
    font-size: 1.8rem;
    font-weight: normal;
    width: max-content;
    /* border-bottom: 1px solid #000000de; */
    border-radius: 5px;
    background-color: #ddda;
    color: #000000de;
    padding: .7rem;
  }
  margin-top: 8rem;
`

const WigCard = styled(Card)`
  margin-bottom: 2.5rem;
  height: 8rem;
`

const IconContainer = styled.div`
  color: ${props => props.theme.colors.icon1L};
  svg {
    width: 2.5rem;
  }
`

const WigInfo = styled.span`
  display: block;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.darkText};
  font-weight: 500;
`

const Summary = styled.div`
  margin-top: 2rem;
  min-height: 6rem;
  padding-bottom: .5rem;
  /* max-height: 26.5rem; */
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #0000001f;
  /* padding: 3.5rem 0; */
  border-radius: 5px;

  .circular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}
`

const Title = styled.h2`
  font-weight: normal;
  font-size: 1.8rem;
  padding: 0 1rem;
`

const Stats = styled.span`
  font-weight: 500;
  font-size: 1.6rem;
`

const Clear = styled(Button)`
  float: right;
  margin-top: 1rem;
`

const Cart = (props) => {
  const { itemQty, cartItems, removeItem, forLater, saveForLater, clearAll, clearCart } = useCartContext()

  const history = useHistory()

  const create = (type) => {
    let route = type === "order" ? "/orders/edit" : "/consignments/edit";
    if (cartItems.length) {
      history.push({
        pathname: route,
        state: {cart: true}
      })
      // window.location.href = `${route}?cart=true`
      return "redirect"
    }
  }

  // console.log(cartItems, itemQty, "cccc");
  return (
    <FullDialog toggle={
      <Tooltip title="Cart">
        <IconButton color='secondary'>
          <Badge badgeContent={itemQty} color="error" overlap="rectangular">
            <ShoppingCartRoundedIcon fontSize="large" color="primary" />
          </Badge>
        </IconButton>
      </Tooltip>
    } 
    title="Cart Items"
    onSave={(type) => create(type)}
    save="Create Sale"
    >
      <Grid container spacing={8}>
        <Grid item xs={9}>
          <CardContainer>
            {cartItems.map(el => 
            <WigCard key={el.wigId} elevation={4}>
              <CardContent>
                <Flex dir="row" pad="0" justify="space-between">
                <IconContainer><IconButton onClick={(e) => saveForLater(el.wigId, true)}><WigIcon htmlColor={theme.colors.icon1L} /></IconButton></IconContainer>
                <WigInfo>{el.wigId}</WigInfo>
                <WigInfo>{el.sku}</WigInfo>
                <WigInfo>{el.status === "stock" ? capitalize(el.status) : `${capitalize(el.status)} - `} &nbsp;{el.currentlyAt}</WigInfo>
                <WigInfo>{currencyFormat(el.sale?.subtotal)}</WigInfo>
                <IconContainer><IconButton onClick={(e) => removeItem(el.wigId, true)}><Delete color="secondary" /></IconButton></IconContainer>
                </Flex>
              </CardContent>
            </WigCard>
            )}
          </CardContainer>

              {forLater.length > 0 ? 
          <LaterContainer>
            <h4 className='title'>Saved For Later</h4>
            <CardContainer>
              {forLater.map(el => 
              <WigCard key={el.wigId} elevation={4}>
                <CardContent>
                  <Flex dir="row" pad="0" justify="space-between">
                  <IconContainer><IconButton onClick={(e) => saveForLater(el.wigId, false)}><WigIcon htmlColor="#dce2ef" /></IconButton></IconContainer>
                  <WigInfo>{el.wigId}</WigInfo>
                  <WigInfo>{el.sku}</WigInfo>
                  <WigInfo>{el.status === "stock" ? capitalize(el.status) : `${capitalize(el.status)} - `} &nbsp;{el.currentlyAt}</WigInfo>
                  <WigInfo>{currencyFormat(el.sale?.subtotal)}</WigInfo>
                  <IconContainer><IconButton onClick={(e) => removeItem(el.wigId, false)}><Delete color="secondary" /></IconButton></IconContainer>
                  </Flex>
                </CardContent>
              </WigCard>
              )}
            </CardContainer>
            </LaterContainer> : null }
        </Grid>
        <Grid item xs={3}>
            <Summary>
              <Title>Cart Summary</Title>
              <FlexLine dir="row" justify="space-between" padY=".8rem"><span>Number of wigs in cart</span><Stats>{itemQty}</Stats></FlexLine>
              <FlexLine padY=".8rem" dir="row" justify="space-between"><span>Total</span><Stats>{currencyFormat(cartItems.reduce((acc, cur) => acc + cur.sale?.subtotal, 0))}</Stats></FlexLine>
            </Summary>

            <Clear color="secondary" onClick={clearCart}>Clear Cart</Clear>
        </Grid>
    </Grid>
    </FullDialog>
  )
}

export default Cart;