import React from 'react'
import styled, { css } from 'styled-components';
// import { TextField } from 'formik-material-ui';
import {Field} from 'formik';
import Autocomplete, { createFilterOptions }  from '@material-ui/lab/Autocomplete';

import { TextField } from '@material-ui/core';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { theme } from './theme';
 
const Container = styled.div`
  width: 100%;
  .MuiAutocomplete-endAdornment {
    display: ${props => props.searchIcon ? "block" : "none"};
  }
`
 
export default ({list, handleChange = () => {}, handleSelect = () => {},
  setter, field, name, value,
label, variant="outlined", updates, blur, searchIcon = false, createText, ...props}) => {
  const filter = createFilterOptions();
 return (
 <Container searchIcon={searchIcon}>
  <Autocomplete
    {...props}
    options={list}
    onInputChange={(_, val) => handleChange(val)}
    defaultValue={value}
    onChange={(e, val, event) => {
      if (event === 'select-option' || (event === 'blur')) {
        handleSelect(val)
      }

      if (event === 'remove-option') {
        handleSelect(val)
      }

      if (event === 'clear')
        handleSelect("")
    }}
    filterOptions={(options, params) => {
      let filtered = filter(options, params);

      if (props.freeSolo && props.createNew) {
        // Suggest the creation of a new value
        filtered = props?.createNew(filtered, params.inputValue, createText)
      }
      return filtered;
    }}
    onBlur={(e) => {
      // console.log(e.target.value, "check vallll");
      if (blur) {
        handleSelect(e.target.value)
      }
    }}
    popupIcon={searchIcon ? <SearchRoundedIcon htmlColor={theme.colors.secondaryMain} /> : <ArrowDropDownIcon />}
    renderInput={(params) => 
    <TextField
      {...params} 
      label={label} 
      variant={variant}
      fullWidth
      
      />
  }
  />
 </Container>
 )
}