import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, LinearProgress,
  CircularProgress, MenuItem, InputLabel, FormControl, FormHelperText, FormControlLabel} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
} from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider} from "@material-ui/pickers";

import { debounce } from 'lodash'

import EditForm from '../../utils/editForm';

import Autocomplete from '../../utils/simpleAutoComplete';



const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`
 
export default (props) => {

  const {id} =  props.match.params;
 
  const [info, SetInfo] = useState({});

  const [names, setNames] = useState([]);

  const [loading, setLoading] = useState(true)

  const [brands, setBrands] = useState([])

  const [brand, setBrand] = useState("")

  const [selectedDate, setSelectedDate] = useState(new Date());


  const handleChangeNames = useCallback(debounce(async val => {
    
  }, 250), []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const loadData = async () => {
    try {
      setLoading(true)
      const [ { data }, {data: types}, {data: wigBrands} ] = await Promise.all([
        axios.get(`/expenses/${id}`),
        axios.get(`/expenses/types`),
        axios.get('/brands/names')
      ])

      SetInfo(data);
      setSelectedDate(data.date)
      setNames(types);

      const brandNames = wigBrands.map(el => {return {name: el.name, isDefault: el.isDefault}})
      let defaultBrand = brandNames.find(el => el.isDefault).name
      setBrands(brandNames)
      setBrand(defaultBrand)

      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const loadTypes = async () => {
    const [{data: types}, {data: wigBrands}] = await Promise.all([
      axios.get(`/expenses/types`),
      axios.get('/brands/names')
    ])

    setNames(types);

    const brandNames = wigBrands.map(el => {return {name: el.name, isDefault: el.isDefault}})
    let defaultBrand = brandNames.find(el => el.isDefault).name
    setBrands(brandNames)
    setBrand(defaultBrand)

    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      loadData()
    } else {
      loadTypes()
    }

  }, [])

  // console.log(info);

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Expense successfully updated" : "Expense successfully added";

  let crumbs = [{path: '/expenses/', text: 'Expenses'}];

  if (action === "edit")
    crumbs.push({path: `/expenses/view/${id}`, text: 'View'})

  crumbs.push({path: '/expenses/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
    expenseType: Yup
      .string()
      .required('Expense Type is required'),
    qty: Yup
      .number()
      .min(1)
      .required('qty is required.').positive(),
    rate: Yup
      .number()
      .min(1)
      .required('rate is required.').positive()
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    // console.log("getting here");

    try {
      let query = !id ? axios.post('/expenses/', {...userInfo}) : axios.put(`/expenses/${id}`, {...userInfo});
      

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/expenses/`)
      // props.history.push(`/payments/view/${id || data._id}`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

  let newDate;

  useEffect(() => {
    newDate = new Date();
  })
 
 return (
 <EditForm title={action === "edit" ? "Edit Expense" : "New Expense"} header="Expense" crumbs={crumbs}>
   {loading ? <LinearProgress /> :
   <Formik
    enableReinitialize
    initialValues={
      info.expenseType ? {...info} : 
      {
        expenseType: "",
        description: '',
        brand: brand,
        amount: '',
        date: newDate,
        qty: 0,
        rate: 0,
        charges: [],
        notes: '',
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, setFieldValue, setErrors, errors, touched, values}) => (
        <Form>
          {/* {console.log(errors, values, "checkem")} */}
          <Grid container spacing={4}>

          <Row item xs={12} md={6}>
            <Autocomplete
               list={names} name="expenseType" 
               label="Expense Type"
              handleSelect={(val) => { setFieldValue("expenseType", val)}}
               autoHighlight
              blur
               freeSolo
               value={info?.expenseType}
              />
            </Row>

            <Row item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Field
                component={KeyboardDatePicker}
                label="Date"
                name="date"
                inputVariant="outlined"
                openTo="year" 
                disableToolbar 
                views={["year", "month", "date"]}
                format="MM/dd/yyyy"
                variant="inline"
                fullWidth
              />
            </MuiPickersUtilsProvider>
            </Row>
            {console.log({values})}
            {brands?.length > 1 ? <Row item xs={8}>
            <FormControl variant="outlined" style={{width: '100%'}} >
              <InputLabel id="brand-label">Brand</InputLabel>
              <Field
                  component={Select}
                  labelId="brand-label"
                  name="brand"
                  id="brand"
                  label="Brand"
                  // value={brand}
                  // onChange={handleSelect}
                  fullWidth
                  // error={errors ? true : false}
                >
                  {brands && brands.map(({name, isDefault}) => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                  ))}
              </Field>
            </FormControl>
            </Row> : null }

            <Row item xs={12}>
              <Field
                component={TextField}
                type="text"
                label="Description"
                name="description"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12} lg={6}>
              <Field
                component={TextField}
                type="number"
                label="Enter Quantity"
                name="qty"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12} lg={6}>
              <Field
                component={TextField}
                type="number"
                label="Enter Rate"
                name="rate"
                variant="outlined"
                fullWidth
              />
            </Row>


            <Row item xs={12}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              name="notes"
              label="Notes"
              />
            </Row>

            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
  }
 </EditForm>
 )
}