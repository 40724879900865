import React, {useState, useEffect, useCallback} from 'react';
import { theme } from '../../utils/theme';
import Indicator from '../../utils/Indicator';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button'
import axios from '../../axios';
import {BasicFlex, Flex} from '../../utils/common';

import { DateTime } from 'luxon';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import WigIcon from '../../images/wig';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';

import { DateRangePicker } from '../generalReports/reports/common';

import ReportIcon from '@material-ui/icons/Report';

import { Available } from '../payments/paymentComponents';

import { currencyFormat, handleErrors, fxn } from '../../utils/util';

import SimpleTable from '../../utils/simpleTable';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';

import displayPdf from '../../utils/displayPdf';

import FormDialog from '../../utils/dialog';

import {useSBContext} from '../../utils/snackbar';


import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const invoiceHeaders = [
  { id: 'orderId', label: 'Sale ID' },
    { id: 'qty', label: 'Qty' },
    { id: 'to.name', label: 'Customer' },
    { id: 'total', currency: true, label: 'Total' },
    { id: 'balance', currency: true, label: 'Balance' },
    {id: 'status', render: (row) => {
      let status, text;
      if (row.balance === 0) {
        status = 'success'
        text = "Paid"
      }
      if (row.balance === row.total) {
        status = 'fail'
        text = "Not Paid"
      }
      if (row.totalPaid > 0 && row.totalPaid < row.total) {
        status = "partial"
        text = "Partially Paid"
      }
      return <Indicator status={status} text={text} />
    }, label: 'Status'},
    { id: 'date', date: true, label: 'Date'}
]

const paymentHeaders = [
  { id: 'customer.name', label: 'Customer'},
  { id: 'paymentAmount', currency: true, label: 'Amount' },
  { id: 'paymentType', label: 'Payment Method'},
  { id: 'paymentInfo', label: 'Info'},
  { id: 'applied', render: row => <Available row={row} />, label: 'Amount Available'},
  // { id: 'paymentNotes', label: 'Notes'},
  { id: 'date', date: true, label: 'Date'}
]

/* Components, Tab Content */

const OrderSelect = styled(FormControl)`
  width: 25rem;
`

const TooltipContainer = styled(Flex)`
`

const StatBoxStyles = styled.div`
  display: flex;
  /* width: 100%; */

  .icon-box {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 5px;
    box-shadow: rgb(58 53 65 / 42%) 0px 4px 8px -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    background-color: ${props => props.color || '#fff'};

      
    .wigIcon {
      width: 2rem;
    }
  }

  .stat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 100%; */
  }

  .stat-container-pay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .stat-header {
    color: ${props => props.theme.colors.lightText};
  }

  .stat {
    color: ${props => props.theme.colors.darkText};
    font-weight: 500;
    font-size: 1.8rem;
  }

  .stat-pay {
    margin-bottom: 1rem;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: ${props => props.bgColorLight};
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: ${props => props.bgColorDark};

  }
`

const Statistics = styled.div`
  width: 97%;
  margin: 0 auto;
`

const PayGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  `

const StatBox = ({color, icon, header, stat}) => {
  return (
    <StatBoxStyles color={color}>
      <div className='icon-box'>
        {icon}
      </div>
      <div className='stat-container'>
        <div className='stat-header'>{header}</div>
        <div className='stat'>{stat}</div>
      </div>
    </StatBoxStyles>
  )
}

const PaymentStat = ({bgColorDark, bgColorLight, percent, header, stat}) => {
  return (
    <StatBoxStyles bgColorDark={bgColorDark} bgColorLight={bgColorLight}>
      <div className='stat-container-pay'>
        <Flex dir="row" justify="space-between" pad="0" align="flex-end">
          <Flex dir="column" justify="flex-start" align="flex-start" pad="0">
          <div className='stat-header'>{header}</div>
          <div className='stat stat-pay'>{stat}</div>
          </Flex>
          <div className='percent'>{percent}%</div>
        </Flex>
        <div><LinearProgress variant='determinate' fullwidth = "true" value={percent} /></div>
      </div>
    </StatBoxStyles>
  )
}

const sendEmail = async ({email, id, loading, err, start, end, hc, detailed, brand}) => {

  if (!email) return err(true);
  const detail = detailed ? '&detailed=true' : "";

  loading(true)
  try {
    await axios.post(`/payments/${id}/statement?email=${email}&start=${start}&end=${end}&brand=${encodeURIComponent(brand)}${detail}`)
    hc('success', 'Email successfully sent')
    loading(false)
  } catch (e) {
    hc('error', 'Error sending email')
    loading(false)
  }

  return true;
}


const printInvoice = async ({id, loading, start, end, detailed, brand}) => {
  loading(true)
  const detail = detailed ? '&detailed=true' : "";
  const {data} = await axios({
    method: "POST",
    url: `/payments/${id}/statement?pdf=true&start=${start}&end=${end}&brand=${encodeURIComponent(brand)}${detail}`,
    responseType: 'blob',
    timeout: 60000,
    headers: {"Content-Type": "application/pdf"}
  });
  loading(false)
  displayPdf(data, `Payment Statement`)
}

export const OrderTab = ({cust, years}) => {

  const {handleClick} = useSBContext();


  const [loading, setLoading] = useState(true)
  const [invoices, setInvoices] = useState([])
  const [stats, setStats] = useState({orders: '', wigs: '', total: "", balance: ''})
  const [period, setPeriod] = useState("pm");
  const [current, setCurrent] = useState(DateTime.local().toLocaleString())
  const [time, setTime] = useState(DateTime.local().minus({ months: 1}).toLocaleString())

  const handleDateChange = (date, pos) => {
    // console.log(date, "Check it!!!");
    if (pos === 'start') {
      // console.log(DateTime.fromJSDate(date).toLocaleString());
      setTime(DateTime.fromJSDate(date).toLocaleString());
    }
    else {
      // console.log("here", date, pos);
      setCurrent(DateTime.fromJSDate(date).toLocaleString())}
  };

  const handleSelect = (e) => {
    setPeriod(e.target.value);
  }

  const loadOrders = async (pastTime, flag) => {
    try {
      // console.log(time, current, "check it out");
      const {data} = await axios.get(`/orders?term=${encodeURIComponent(cust.name)}&start=${flag ? pastTime : time}&end=${current}`)
      // if (data.length)
        setInvoices(data.docs)
        if (data.docs.length) 
          setStats({
            orders: data.docs.length,
            wigs: data.docs.reduce((acc, cur) => acc + +cur.qty, 0),
            total: data.docs.reduce((acc, cur) => acc + cur.total, 0),
            balance: data.docs.reduce((acc, cur) => acc + cur.balance, 0)
          })

    } catch (e) {
      // console.log(e);
      const displayMessage = handleErrors({e})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (period !== 'custom')
      loadOrders()
  }, [time])

  useEffect(() => {
    if (!Number(period))
      setCurrent(DateTime.local().toLocaleString())

    switch (period) {
      case "pm": 
        setTime(DateTime.local().minus({ months: 1}).toLocaleString());
        break;
      case "pq": 
        setTime(DateTime.local().minus({ months: 3}).toLocaleString());
        break;
      case "phy": 
        setTime(DateTime.local().minus({ months: 6}).toLocaleString());
        break;
      case "ytd": 
        setTime(DateTime.local().startOf('year').toLocaleString());
        break;
      case Number(period): 
        setCurrent(DateTime.fromJSDate(new Date(`1/1/${period}`)).endOf('year').toLocaleString())

        setTime(DateTime.fromJSDate(new Date(`1/1/${period}`)).startOf('year').toLocaleString());
        break;
      case "all": 
        setTime(DateTime.fromJSDate(new Date('01/01/2020')).toLocaleString());

        break;
      default: 
    }
  }, [period])



  return <Grid container spacing={3}>
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardHeader title={`Recent Sales`} action={
           <OrderSelect variant="outlined" fullwidth = "true">
           <InputLabel id="period-label">Select Period</InputLabel>
           <Select
             labelId="period-label"
             id="period-label-id"
             value={period}
             onChange={handleSelect}
             label="Select Period"
             fullwidth = "true"
           >
             <MenuItem value="pm">Past Month</MenuItem>
             <MenuItem value="pq">Past Quarter</MenuItem>
             <MenuItem value="phy">Past Half Year</MenuItem>
             <MenuItem value="ytd">Year to Date</MenuItem>
             {years?.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
             <MenuItem value="all">All</MenuItem>
             <MenuItem value="custom">Custom</MenuItem>
           </Select>
         </OrderSelect>
        } subheader={`${time} - ${current}`} />

        {period === "custom" ? <CardContent>
          <Grid item container xs={12} spacing={5} alignItems="flex-end">

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
                value={time} onChange={(e) => handleDateChange(e, "start")} label="Start Date" name="start-date" />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
                value={current} onChange={(e) => handleDateChange(e, "end")} label="End Date" name="end-date" />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={loadOrders}
            >
              Update
            </Button>
           </Grid>

          </Grid>
          
        </CardContent> : null }
      </Card>
    </Grid>

    

    <Grid item xs={12}>
      {invoices && invoices.length ? <Statistics>
      <Card elevation={5}>
        <CardHeader title="Statistics" subheader={`Period between ${time} - ${current}`}>
        </CardHeader>
        <CardContent>

        

        <Grid container spacing={5}>
         
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBg1} icon={<TrendingUpRoundedIcon htmlColor={theme.colors.icon1} />} header="Orders" stat={stats.orders} />
          </Grid>
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBg2} icon={<WigIcon htmlColor={theme.colors.icon2} />} header="Wigs" stat={stats.wigs} />
          </Grid>
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBg3} icon={<AttachMoneyIcon htmlColor={theme.colors.icon3} />} header="Total" stat={currencyFormat(stats.total)}/>
          </Grid>
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBgRed} icon={<ReportIcon htmlColor={theme.colors.iconRed} />} header="Balance" stat={currencyFormat(stats.balance)} />
          </Grid>
        </Grid>
        </CardContent>
      </Card>
      </Statistics> : null }
    </Grid>
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardContent>
          {loading ? <LinearProgress /> : <SimpleTable link="orders" headers={invoiceHeaders} rows={invoices}  />}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}

export const PaymentTab = ({cust, years, custEmail}) => {


  const {handleClick} = useSBContext();

  const [stats, setStats] = useState({payments: 0, total: 0, applied: 0, available: 0})
  const [current, setCurrent] = useState(DateTime.local().toLocaleString())

  const handleDateChange = (date, pos) => {
    // console.log(date, "Check it!!!");
    if (pos === 'start') {
      // console.log(DateTime.fromJSDate(date).toLocaleString());
      setTime(DateTime.fromJSDate(date).toLocaleString());
    }
    else {
      // console.log("here", date, pos);
      setCurrent(DateTime.fromJSDate(date).toLocaleString())}
  };

  const [loading, setLoading] = useState(true)
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [detailed, setDetailed] = useState(false);
  const [brand, setBrand] = useState()
  const [brandLoading, setBrandLoading] = useState(false)
  const [email, setEmail] = useState(custEmail)
  const [brands, setBrands] = useState([])
  const [emailErr, setEmailErr] = useState(false)
  const [payments, setPayments] = useState([])
  const [period, setPeriod] = useState("pm");
  const [time, setTime] = useState(DateTime.local().minus({ months: 1}).toLocaleString())

  const loadBrands = async () => {
    try {
      setBrandLoading(true)
      const { data } = await axios.get('/brands/names')
      const brandNames = data.map(el => el.name)
      const brand = data.find(el => el.isDefault)
      setBrand(brand.name)
      setBrands(brandNames)
      setBrandLoading(false)
    } catch (e) {
      setBrandLoading(false)
      // console.log(e);
    }
  }

  const handleSelect = (e, setFunc) => {
    setFunc(e.target.value);
  }


  const loadPayments = async () => {
    // console.log(time, current);
    try {
      const {data} = await axios.get(`/payments?term=${encodeURIComponent(cust.name)}&start=${time}&end=${current}&stats=true`)
      // if (data.length)
        setPayments(data.docs)
        let total = data.docs.reduce((acc, cur) => acc + cur.paymentAmount, 0)
        setStats({
          payments: data.docs.length,
          total: total,
          applied: data.statTotal,
          available: total - data.statTotal
        })
    } catch (e) {
      // console.log(e);
      const displayMessage = handleErrors({e})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (period !== 'custom')
      loadPayments()
  }, [time])

  useEffect(() => {
    if (!Number(period))
      setCurrent(DateTime.local().toLocaleString())
      
      switch (period) {
        case "pm": 
          setTime(DateTime.local().minus({ months: 1}).toLocaleString());
          break;
        case "pq": 
          setTime(DateTime.local().minus({ months: 3}).toLocaleString());

          break;
        case "phy": 
          setTime(DateTime.local().minus({ months: 6}).toLocaleString());

          break;
        case "ytd": 
          setTime(DateTime.local().startOf('year').toLocaleString());

        break;
        case Number(period): 
          setCurrent(DateTime.fromJSDate(new Date(`1/1/${period}`)).endOf('year').toLocaleString())

          setTime(DateTime.fromJSDate(new Date(`1/1/${period}`)).startOf('year').toLocaleString());
        break;
      case "all": 
        setTime(DateTime.fromJSDate(new Date('01/01/2020')).toLocaleString());

        break;
      default: 
      }
    //  loadPayments()
    }, [period])

  return <Grid container spacing={3}>
  <Grid item xs={12}>
    <Card elevation={0}>
      <CardHeader title={`Recent Payments`} action={
         <OrderSelect variant="outlined" fullwidth = "true">
         <InputLabel id="period-label">Select Period</InputLabel>
         <Select
           labelId="period-label"
           id="period-label-id"
           value={period}
           onChange={(e) => handleSelect(e, setPeriod)}
           label="Select Period"
           fullwidth = "true"
         >
           <MenuItem value="pm">Past Month</MenuItem>
           <MenuItem value="pq">Past Quarter</MenuItem>
           <MenuItem value="phy">Past Half Year</MenuItem>
           <MenuItem value="ytd">Year to Date</MenuItem>
           {years?.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
         </Select>
       </OrderSelect>
      } subheader={`${time} - ${current}`} />

          {period === "custom" ? <CardContent>
          <Grid item container xs={12} spacing={5} alignItems="flex-end">

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
                value={time} onChange={(e) => handleDateChange(e, "start")} label="Start Date" name="start-date" />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
                value={current} onChange={(e) => handleDateChange(e, "end")} label="End Date" name="end-date" />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={loadPayments}
            >
              Update
            </Button>
           </Grid>

          </Grid>
          
        </CardContent> : null }

    </Card>
    </Grid>

    <Grid item xs={12}>
      {payments && payments.length ? <Statistics>
      <Card elevation={5}>
        <CardHeader title={`Payments Summary - ${payments.length} Payments`} subheader={`Period between ${time} - ${current}`}  action={
            <TooltipContainer dir="row" pad="0" justify="space-between">
              <FormDialog toggle={
            <Tooltip title="Print Statement">
            <IconButton onClick={loadBrands}>
              {invoiceLoading ? <CircularProgress size={25} /> :
              <PrintIcon color="secondary" /> }
            </IconButton>
            </Tooltip>
            }
            title="Print Settings"
            onSave={() => {printInvoice({id: cust._id, loading: setInvoiceLoading, start: time, end: current, detailed, brand}); return true;}}
            save="Print Statement"
            size="xs"
            >
              {brandLoading ? <LinearProgress color="secondary" /> :
              <>
              <FormControl variant="outlined" fullWidth>
              <InputLabel id="brand-label">Select Brand</InputLabel>
                <Select
                  labelId="brand-label"
                  id="brand-label-id"
                  value={brand}
                  onChange={(e) => handleSelect(e, setBrand)}
                  label="Select Period"
                  fullWidth
                >
                  {brands?.map(el => 
                  <MenuItem key={el} value={el}>{el}</MenuItem>
                )}
                </Select>
              </FormControl>

              <FormControlLabel
              control={<Checkbox 
              onChange={(e) => setDetailed(e.target.checked)} color="primary" />}
              label="Detailed Report"
            />
            </> 
            }
            </FormDialog>
            
            <FormDialog toggle={
                <Tooltip title="Email Statement">
                <IconButton onClick={loadBrands}>
                {emailLoading ? <CircularProgress size={25} /> :
                  <EmailIcon color="secondary" /> }
                </IconButton>
                </Tooltip>
              } 
              title="Add Email Address"
              onSave={() => {return sendEmail({email, id: cust._id, loading: setEmailLoading, start: time, end: current, err: setEmailErr, hc: handleClick, detailed, brand});}}
              save="Send Email"
              size="xs"
              >
                {brandLoading ? <LinearProgress color="secondary" /> :
              <Grid container justify-content="center" spacing={4}>
                <Grid item xs={12}>
                  <TextField error={emailErr} variant="outlined" label="Email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth 
                  helperText={emailErr ? 'Email Required' : ''} />
               
                </Grid>
                <Grid item xs={12}>
                      
                      <FormControl variant="outlined" fullWidth>
                      <InputLabel id="brand-label">Select Brand</InputLabel>
                        <Select
                          labelId="brand-label"
                          id="brand-label-id"
                          value={brand}
                          onChange={(e) => handleSelect(e, setBrand)}
                          label="Select Period"
                          fullWidth
                        >
                          {brands?.map(el => 
                          <MenuItem key={el} value={el}>{el}</MenuItem>
                        )}
                        </Select>
                      </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox 
                      onChange={(e) => setDetailed(e.target.checked)} color="primary" />}
                      label="Detailed Report"
                    />
                </Grid>


                      
              </Grid>
              }
                  
              </FormDialog>
              </TooltipContainer>
          }>
        </CardHeader>
        <CardContent>


        <Grid container spacing={2} direction="column">
         
          <PayGrid item xs={12}>
           <PaymentStat bgColorDark={theme.colors.icon1L} bgColorLight={theme.colors.iconBg1} header="Total" percent={100} stat={currencyFormat(stats.total)} />
          </PayGrid>
          <PayGrid item xs={12}>
          <PaymentStat bgColorDark={theme.colors.icon3L} bgColorLight={theme.colors.iconBg3} header="Applied" percent={stats.applied !== 0 ? fxn(stats.applied / stats.total * 100, 1) : 0} stat={currencyFormat(stats.applied)} />
          </PayGrid>
          <PayGrid item xs={12}>
           <PaymentStat bgColorDark={theme.colors.icon2L} bgColorLight={theme.colors.iconBg2} header="Available" percent={stats.available !== 0 ? fxn(stats.available / stats.total * 100, 1) : 0} stat={currencyFormat(stats.available)} />
          </PayGrid>
        </Grid>
        </CardContent>
      </Card>
      </Statistics> : null }
    </Grid>

      {/* ALTERNATE DESIGN */}
    {/* <Grid item xs={12}>
      {payments && payments.length ? <Statistics>
      <Card elevation={5}>
        <CardHeader title="Payments Summary" subheader={`Period between ${time} - ${current}`}>
        </CardHeader>
        <CardContent>


        <Grid container spacing={5}>
         
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBg1} icon={<ReceiptIcon htmlColor={theme.colors.icon1} />} header="Payments" stat={stats.payments} />
          </Grid>
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBg2} icon={<AttachMoneyIcon htmlColor={theme.colors.icon2} />} header="Total" stat={currencyFormat(stats.total)} />
          </Grid>
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBg3} icon={<PaymentIcon htmlColor={theme.colors.icon3} />} header="Applied" stat={currencyFormat(stats.applied)}/>
          </Grid>
          <Grid item xs={3}>
           <StatBox color={theme.colors.iconBg4} icon={<AccountBalanceWalletIcon htmlColor={theme.colors.icon4} />} header="Available" stat={currencyFormat(stats.available)} />
          </Grid>
        </Grid>
        </CardContent>
      </Card>
      </Statistics> : null }
    </Grid> */}


    <Grid item xs={12}>
      <Card elevation={0}>
        <CardContent>
          {loading ? <LinearProgress /> : <SimpleTable link="payments" headers={paymentHeaders} rows={payments} />}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}

export const BalanceTab = ({cust}) => {
  let finalBalance = cust.balance - cust.wallet;
  let header = finalBalance > 0 ? 'Balance Due' : finalBalance < 0 ? 'Credit Available' : 'Balance';
  let iconBg = finalBalance > 0 ? theme.colors.iconBgRed : theme.colors.iconBg4;
  let iconColor = finalBalance > 0 ? theme.colors.iconRed : theme.colors.icon4;

  let Icon = finalBalance > 0 ? ReportIcon : AccountBalanceRoundedIcon;

  const [dates, setDates] = useState({range: "tm", startDate: null, endDate: null})
  const [printLoading, setPrintLoading] = useState(false)
  const [itemized, setItemized] = useState(false)

  const handleDateChange = (date, src) => {
    setDates(x => ({...x, [src]: DateTime.fromJSDate(date)}))
  }

  const printInvoice = async () => {
    setPrintLoading(true)
    try {
      const {data} = await axios({
        method: "GET",
        url: `/reports/customer-transaction-list/${cust._id}?pdf=true&startDate=${dates.startDate}&endDate=${dates.endDate}&itemized=${itemized}`,
        responseType: 'blob',
        timeout: 60000,
        headers: {"Content-Type": "application/pdf"}
      });
      displayPdf(data, `Balance History`)
    } catch (e) {
      console.log(e);
    } finally {
      setPrintLoading(false)
    }
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card elevation={0}>
          <CardHeader title="Account Balance" />
          <CardContent>
            <StatBox color={iconBg} icon={<Icon htmlColor={iconColor} />} header={header} stat={currencyFormat(Math.abs(finalBalance))} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card elevation={0}>
          <CardHeader title="Balance History" subheader={
            <span>{dates?.startDate?.toLocaleString()} - {dates?.endDate?.toLocaleString()}</span>
          } action={
            <Tooltip title="Print Balance History">
            <IconButton onClick={printInvoice}>
              {printLoading ? <CircularProgress size={25} color="secondary" />  : <PrintIcon color="secondary" />}
            </IconButton>
            </Tooltip>
          } />
          <CardContent>
            <div>
              <DateRangePicker options={dates} setOptions={setDates} />
            </div>

            {dates.range === "custom" ? (
              <BasicFlex dir="column" gap="2rem" my="2rem">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                variant="inline" inputVariant='outlined' openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
                value={dates?.startDate} onChange={(e) => handleDateChange(e, "startDate")} label="Start Date" />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                variant="inline" inputVariant='outlined' openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
                value={dates?.endDate} onChange={(e) => handleDateChange(e, "endDate")} label="End Date" />
              </MuiPickersUtilsProvider>
            </BasicFlex>
            ) : null}

            <FormControlLabel
              control={<Checkbox 
              onChange={(e) => setItemized(e.target.checked)} color="primary" />}
              label="Include Sale Items"
            />
            
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
