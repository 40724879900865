import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { CommonFlex, Flex, FlexLine } from '../../utils/common';

import { theme } from '../../utils/theme';

import { Card, CardHeader, IconButton, MenuItem, Tooltip,  } from '@material-ui/core';

import { capitalize } from 'lodash';

import { currencyFormat, formatDateTime2 } from '../../utils/util';

import OutlinedSelect from '../../utils/OutlinedSelect';

import Lottie from 'react-lottie'
import animationDataRings from '../../images/lotties/loading-boxes-colored.json'

import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import RedeemRoundedIcon from '@material-ui/icons/RedeemRounded';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import CallReceivedRoundedIcon from '@material-ui/icons/CallReceivedRounded';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

const StatusStyle = styled.span`
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  padding: .3rem .5rem;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: .5px;
  /* margin-right: 2rem; */
`

const InfoLineStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .8rem 0%;
  color: ${props => props.sortBy === props.id ? props.theme.colors.primaryMain : "inherit"}; 
  /* font-weight: ${props => props.sortBy === props.id ? "600" : "500"}; */
  

  span {
    display: block;
    font-weight: ${props => props.sortBy === props.id ? "600" : "500"};
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  .amount {
    font-weight: 600;
    font-size: 1.4rem;
  }
`

const StyleItem = styled.div`
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  border-radius: 6px;
  height: 25rem;
  width: 20rem;
  margin: 0 1.5rem;
  margin-bottom: 2.5rem;

  .color-inset {
    height: 30%;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${props => props.bg};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
      h3 {
        margin: 0;
        text-align: center;
      }    

      .expand {
        position: absolute;
        right: 0;
      }
  }
  .info {
    padding: .5rem 1rem;

    .warning {
      height: 1.5rem;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      color: ${props => props.stock < props.stockLevel ? props.theme.colors.error : props.theme.colors.successGreen};
      margin-bottom: 1.2rem;
      display: flex;
      align-items: center;
      svg {
        height: 1.5rem;
        width: auto;
        margin-right: .8rem;
      }
    }
    
  }
`

const CategoryStyles = styled.div`
  box-shadow: 1px 6px 8px rgba(0, 0, 0, .15);
  /* border: 1px solid ${props => props.theme.colors.border}; */
  border-radius: 6px;
  min-height: 18rem;
  width: 10rem;
  padding: 0 1rem;
  margin: 0 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #f8f8f8;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    font-size: 1.6rem;
    font-weight: 500;
  }

  .title-line {
    display: block;
    font-size: 1.2rem;
    /* margin-bottom: .5rem; */
    text-align: center;
  }
`

const CommonFlexMod = styled(CommonFlex)`
  height: max-content;  
  margin: .6rem 0;

  span {
    font-weight: 500;
  }

  svg {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
`

const StyleSingleStyles = styled.div`
  box-shadow: 0 10px 10px 5px rgba(0, 0, 0, .15);
  border-radius: 6px;
  width: 95%;
  height: 100%;
  margin-bottom: 3rem;
  margin-top: 1.5rem;

  .style-header {
    color: ${props => props.theme.colors.lightText};
    font-weight: 600;
  }

  .style {
    font-size: 2.2rem;
    font-weight: 600;
    color: ${props => props.theme.colors.primaryMain};
  }

  .inset {
    height: 14rem;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* background-color: #eee; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .headers-number {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .graph {
    flex-grow: 1;
  }

  .x {
    width: 4rem;
    padding: .5rem;
  }

  .categories {
    margin-top: .8rem;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const IconFlex = styled.div`
  /* max-width: 10rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 500;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`

const SingleStyleFlex = styled(CommonFlex)`
  margin: .1rem 0;
`

const SelectContainer = styled.div`
  margin: 0 1rem;
  margin-left: 1.5rem;
  margin-bottom: .5rem;
  width: ${props => props.width ? props.width : "18rem"};
`

const Progress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  /* height: 30rem; */
`

const TrendIcon = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`

const SideCard = styled(Card)`
  margin-top: ${props => props.first ? "0" : "3rem"};
`

const ViewMore = styled.div`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.lightText};
  font-weight: 500;
  padding: 0 .5rem;
  position: relative;
  top: .3rem;
  cursor: pointer;

  &:hover {
    color: inherit;
  }
`

const SidebarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 4rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  border-top: ${props => props.first ? `1px solid ${props.theme.colors.border}` : "none"};
  margin-top: ${props => props.first ? `1.5rem` : "0"};
  padding: 1.5rem;
  cursor: pointer;
  color: ${props => props.theme.colors.darkText};


  /* * {
    color: ${props => props.theme.colors.darkText};
  } */

  &:hover {
    background-color: #efefef;
  }

  .order-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* width: 12rem; */
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.5rem;
    min-width: 4.5rem;
    border-radius: 6px;
    background-color: aliceblue;
    margin-right: 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;

    &.pay {
      background-color: #fff0f0;
    }
    &.con {
      background-color: #f4f0ff;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-weight: 500;
    /* background-color: bisque; */

    svg {
      width: 2rem;
      height: 2rem;
    }

    &.end {
      align-items: end;
      justify-content: center;

      .pay-chip {
        color: ${props => props.theme.colors.lightText};
      }
    }
  }


  .date {
    font-size: 1.2rem;
    color: ${props => props.theme.colors.lightText};
  }
`

const Rounded = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  min-width: 4.5rem;
  border-radius: 6px;
  background-color: ${props => props.bg};
  margin-right: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
`

const defaultOptions = (data) => ({
  loop: true,
  autoplay: true,
  speed: 1,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    // preserveAspectRatio: "none slice"
  }
});

const Status = ({ total, balance }) => {
  let bgColor, color, statusText = "";

  if (balance <= 0) {
    color = theme.colors.icon2; bgColor = theme.colors.iconBg2; statusText = "Paid"
  } else {
    if (balance === total) {
      color = theme.colors.iconRed; bgColor = theme.colors.iconBgRed; statusText = "Not Paid"
    } else {
      color = theme.colors.iconCaution; bgColor = theme.colors.iconBgCaution; statusText = "Partially Paid"
    }
  }

  return (
    <StatusStyle bgColor={bgColor} color={color} >{statusText}</StatusStyle>
  )
}


const InfoLine = ({id, title, info, sortBy}) => {
  return (
    <InfoLineStyle id={id} sortBy={sortBy}>
      <span>{title}</span>
      <span className="amount">{info}</span>
    </InfoLineStyle>
  )
}

const Style = ({ind = 0, color, stock, consign, soldPast, soldAll, avgDays, stockLevel, sortBy, onClick }) => {

  return (<StyleItem bg={theme.colors.primary95} stock={stock} stockLevel={stockLevel}>
    <div className="color-inset">
      <h3>{color}</h3>
      <div className="expand"><IconButton size="small" onClick={() => onClick(color)}><MoreVertIcon /></IconButton></div>
    </div>
    <div className="info">
      {/* <div className="warning">
        {stock < stockLevel ? <><WarningRoundedIcon /> Low Stock</> : <><CheckBoxRoundedIcon /> Stocked</>}
      </div> */}
      <InfoLine title="Stock" id="stock" info={stock} sortBy={sortBy} />
      <InfoLine title="Consignment" id="consign" info={consign} sortBy={sortBy} />
      <InfoLine title="Sold Past Quarter" id="soldPast" info={soldPast} sortBy={sortBy} />
      <InfoLine title="Sold All Time" id="soldAll" info={soldAll} sortBy={sortBy} />
      <InfoLine title="Avg. Days to Sell" id="avgDays" info={avgDays} sortBy={sortBy} />
    </div>
  </StyleItem>)
}

const Category = ({stats}) => {

  let title = stats?.title?.all ? <div>{Object.keys(stats?.title).map(el => {

      if (el !== "all") {
       return <span className="title-line">{el && stats?.title[el] ? stats?.title[el] : "None"}</span>
      }
    })}</div> : stats?.title ? stats?.title : "None"

  return (
    <CategoryStyles>
      <div className="title">{title}</div>
      <CommonFlexMod><Tooltip title="Stock"><StoreRoundedIcon htmlColor={theme.colors.icon1L} /></Tooltip> <span>{stats?.stock}</span></CommonFlexMod>
      <CommonFlexMod><Tooltip title="Consignment"><RedeemRoundedIcon htmlColor={theme.colors.icon2L} /></Tooltip> <span>{stats?.consign}</span></CommonFlexMod>
      <CommonFlexMod><Tooltip title="Sold Past Quarter"><MonetizationOnRoundedIcon htmlColor={theme.colors.icon3L} /></Tooltip> <span>{stats?.soldPast}</span></CommonFlexMod>
      <CommonFlexMod><Tooltip title="Sold All Time"><ReceiptRoundedIcon htmlColor={theme.colors.iconRed} /></Tooltip> <span>{stats?.soldAll}</span></CommonFlexMod>
      <CommonFlexMod><Tooltip title="Avg. Days To Sell"><EventNoteRoundedIcon htmlColor={theme.colors.icon4L} /></Tooltip> <span>{stats?.avgDays}</span></CommonFlexMod>
    </CategoryStyles>
  )
}

const StyleSingle = ({show, color, stats, categories = {}, singleOptions = {}, handleSingle, loading, onClick }) => {

  const { totalStats, results } = stats

  let { caps, lengths, bases } = categories

  const { filter, sortBy, order } = singleOptions

  return (
    <Flex justify="center" align="center" height="100%" pad="0">
      <StyleSingleStyles>
        <div className="inset">
            <FlexLine width="20rem" align="start" justify="flex-start">
              <span className="style-header">Style</span>
              <span className="style">{color}</span>
            </FlexLine>
            <FlexLine width="25rem">
              <SingleStyleFlex dir="row"><IconFlex><StoreRoundedIcon htmlColor={theme.colors.icon1L} />Stock</IconFlex><span className="headers-number">{totalStats?.stock}</span></SingleStyleFlex>
              <SingleStyleFlex dir="row"><IconFlex><RedeemRoundedIcon htmlColor={theme.colors.icon2L} />Consignment</IconFlex><span className="headers-number">{totalStats?.consign}</span></SingleStyleFlex>
              <SingleStyleFlex dir="row"><IconFlex><MonetizationOnRoundedIcon htmlColor={theme.colors.icon3L} />Sold Past Quarter</IconFlex><span className="headers-number">{totalStats?.soldPast}</span></SingleStyleFlex>
              <SingleStyleFlex dir="row"><IconFlex><ReceiptRoundedIcon htmlColor={theme.colors.iconRed} />Sold All Time</IconFlex><span className="headers-number">{totalStats?.soldAll}</span></SingleStyleFlex>
              <SingleStyleFlex dir="row"><IconFlex><EventNoteRoundedIcon htmlColor={theme.colors.icon4L} />Avg. Days To Sell</IconFlex><span className="headers-number">{totalStats?.avgDays}</span></SingleStyleFlex>
            </FlexLine>
          <div className="graph"></div>
          <div className="x"><IconButton size="small" onClick={onClick}><CloseRoundedIcon /></IconButton></div>
        </div>

        <CommonFlex justify="flex-start" height="6rem">

        <SelectContainer>
          <OutlinedSelect label="Filter By" size="small" color={theme.colors.secondaryMain} 
            val={ filter } 
            onChange={(e) => handleSingle(e, "filter")}
          >
            <MenuItem value="lengthNum">Length</MenuItem>
            <MenuItem value="capSize">Cap Size</MenuItem>
            <MenuItem value="baseMaterial">Base Material</MenuItem>
            <MenuItem value="all">Combined</MenuItem>
          </OutlinedSelect>
        </SelectContainer>

        <SelectContainer>
          <OutlinedSelect label="Sort By" size="small" color={theme.colors.secondaryMain} 
           val={sortBy} 
           onChange={(e) => handleSingle(e, "sortBy")}
          >
            <MenuItem value="stock">Stock</MenuItem>
            <MenuItem value="consign">Consignment</MenuItem>
            <MenuItem value="soldPast">Sold Past Quarter</MenuItem>
            <MenuItem value="soldAll">Sold All Time</MenuItem>
            <MenuItem value="avgDays">Avg. Days To Sell</MenuItem>
          </OutlinedSelect>
        </SelectContainer>

        <SelectContainer>
          <OutlinedSelect label="Sort Order" size="small" color={theme.colors.secondaryMain} 
            val={order} 
            onChange={(e) => handleSingle(e, "order")}
          >
            <MenuItem value="desc">Higher To Lower</MenuItem>
            <MenuItem value="asc">Lower To Higher</MenuItem>
          </OutlinedSelect>
        </SelectContainer>

        </CommonFlex>

        {loading ? <Progress>
          
          <Lottie 
            options={defaultOptions(animationDataRings)}
              // height={150}
              width={300}
            />
        </Progress>  : 

        <div className="categories">
          {results && results.map(el => 
          <Category key={el?.title} stats={el}></Category>
            )}
        </div>

        }

      </StyleSingleStyles>
    </Flex>
  )
}

const Trend = ({dir = "flat", lastWeek, period = "week"}) => {
  let icon = <TrendingFlatRoundedIcon />, bg = theme.colors.iconBg3, color = theme.colors.icon3;
  if (dir === "up") {
    icon = <CallMadeRoundedIcon />; bg = theme.colors.iconBg1; color = theme.colors.icon1; 
  } else if (dir === "down") {
    icon = <CallReceivedRoundedIcon />; bg = theme.colors.iconBgRed; color = theme.colors.iconRed;
  }
  return (<Tooltip title={`${capitalize(period)} Before ${lastWeek}`}><TrendIcon bg={bg} color={color}>
    {icon}
  </TrendIcon></Tooltip>)
}


const SideBarCard = ({title, label, results, show, handler, first}) => {
  let key, name, amount;

  let color = title === "orders" ? "aliceblue" : title === "payments" ? "#fff0f0" : "#f4f0ff";

  let flexEnd;

  return (
    <SideCard first={first}>
      <CardHeader title={label} subheader={`Past Week`} action={<ViewMore onClick={() => handler(title)}>View More</ViewMore>} />
      {results?.slice(0, show === title ? -1 : 3)
        .map((el, i) =>  {
          switch (title) {
            case "orders":
              key = el.orderId; name = el.to?.name; amount = el.total;

              flexEnd = <div className="flex end">
                <span>{el.wigs?.length} / {currencyFormat(amount)}</span>
                <span><Status balance={el.balance} total={amount} /></span>
              </div>

            break;
            case "payments":
              key = el.paymentNum; name = el.customer?.name; amount = el.paymentAmount;

              flexEnd = <div className="flex end">
              <span>{currencyFormat(amount)}</span>
              <Tooltip title={`Available ${currencyFormat(el.available)}`}><CommonFlex className="pay-chip" justify="flex-end"> <CheckCircleOutlineRoundedIcon htmlColor={theme.colors.successGreen} /> &nbsp; {currencyFormat(el.available)} 
              </CommonFlex></Tooltip>
            </div>

            break;
            default:
              key = el.consignmentId; name = el.to?.name;

              flexEnd = <div className="flex end">
                    <span>{el.qty} Items</span>
                </div>
          }
        return (<Link to={`/${title}/view/${el._id}`} style={{textDecoration: 'none'}}><SidebarRow key={key} first={i === 0 ? true : false}>
          <div>
            <div className="order-title">
              <Rounded bg={color}>{key}</Rounded>
              <div className="flex">
                <span>{name}</span>
                <span className="date">{formatDateTime2(el.date)}</span>
              </div>
            </div>
          </div>
          {flexEnd}
        </SidebarRow></Link>)
        })}
      </SideCard>
  )
}

export { Status, Style, StyleSingle, Trend, SideBarCard, Category }