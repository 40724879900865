import React, { useState, useEffect } from "react";
import styled from 'styled-components';

import { FormHelperText, Paper, Grid, Select, MenuItem, FormControl, InputLabel, FormLabel, Button, TextField, LinearProgress, Card, CardHeader, CardContent, Checkbox, FormControlLabel, Tooltip, Icon } from "@material-ui/core";

import { theme } from "../../../utils/theme";

import { DateRangePicker, DateField, DoughnutChart } from "./common";

import { CommonFlex } from "../../../utils/common";

import { currencyFormat, formatNiceDate } from "../../../utils/util";

import CollapsibleTable from "../CollapsibleTable";

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import StyleRoundedIcon from '@material-ui/icons/StyleRounded';

const Container = styled.div`
  
`

const Summary = styled(Card)`
  background-color: ${props => props.theme.colors.secondary95};
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.5rem;
  height: 4.5rem;
  background-color: ${props => props.bg};
  border-radius: 5px;
  margin-right: 1.5rem;
`

const StatHeader = styled.h4`
  margin: 0;
  margin-bottom: 1.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => props.theme.colors.lightText};

  &.icon-header {
    margin-bottom: .5rem;
    font-weight: 600;
  }
  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StatLabel = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.lightText};
`

const StatInfo = styled.span`
  font-weight: 500;
`

const headers = [
  { id: '_id', label: 'Customer Name', expand: true, expandLabel: "list", subLabel: "List of wigs Sold" },
  { id: 'itrStr', label: 'Sale Turnover', labelComponent: <span className="flex"><span className="text">Sale Turnover</span> <Tooltip title="Turnover Rate is the avg amount of days to sell 1 wig. Smaller number is better"><InfoRoundedIcon color="secondary" /></Tooltip></span> },
  { id: 'qty', noZero: false, label: 'Qty' },
  { id: 'total', currency: true, total: true, label: 'Total Sales' },
];

const detailHeaders = [
  {id: 'wigId', label: "Wig ID"},
  {id: 'soldTo', label: "Customer"},
  {id: 'sku', label: "Description"},
  {id: 'total', currency: true, label: "Total"},
  // {id: 'total', currency: true, label: "Total"},
  // {id: 'balance', currency: true, label: "Open Balance"},
  {id: 'soldDate', date: true, label: "Sold Date"},
]

const SBCTopBar = ({ brands, SBCOptions, setSBCOptions }) => {

  const defaultBrand = brands.length > 1 ? brands?.find(el => el.isDefault)?.name : "all";

  const { startDate, endDate, sortBy, range, includeZeroSales } = SBCOptions;

  console.log(startDate, endDate)
  const handleSelect = (e, field) => {
    setSBCOptions(x => ({...x, [field]: e.target.value}));
  }

  const handleChecked = (e) => {
    setSBCOptions(x => ({...x, includeZeroSales: e.target.checked}));
  }

  return (
      <Grid item xs={12}>
        <Grid container spacing={3}>
      {brands?.length > 1 ? <Grid item xs={3} md={3}>
          <FormControl variant="outlined" fullWidth 
          >
            <InputLabel>Select Brand</InputLabel>
            <Select
              defaultValue={defaultBrand}
              name="brand"
              onChange={(e) => handleSelect(e, 'brand')}
              label="Select Brand"
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {brands?.map(el => 
                  <MenuItem key={el.name} value={el.name}>{el.name}</MenuItem>
                )}
              <MenuItem value="all">All</MenuItem>
            </Select>
          </FormControl>
        </Grid> : null }

        <Grid item xs={3}>
          <DateRangePicker options={SBCOptions} setOptions={setSBCOptions} />
        </Grid>

        <Grid item xs={3} md={3}>
          <FormControl variant="outlined" fullWidth 
          >
            <InputLabel>Sort By</InputLabel>
            <Select
              defaultValue={sortBy}
              onChange={(e) => handleSelect(e, 'sortBy')}
              label="Sort By"
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
            <MenuItem value="total">Total</MenuItem>
            <MenuItem value="qty">Qty</MenuItem>
            <MenuItem value="itr">Turnover Rate</MenuItem>
            </Select>
          </FormControl>
        </Grid>

       {range === "custom" ? <Grid item xs={12}>
          <Grid container spacing={3}>

            <Grid item xs={3}>
              <DateField options={SBCOptions} setOptions={setSBCOptions}
              label="Start Date" field="startDate" />
            </Grid>
            <Grid item xs={3}>
              <DateField options={SBCOptions} setOptions={setSBCOptions}
              label="End Date" field="endDate" />
            </Grid>

          </Grid>
        </Grid> : null }

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox 
            checked={includeZeroSales} 
            onChange={handleChecked} 
            name="includeZeroSales" />
          }
            label="Include Customers with No Sales"
          />
        </Grid>

        </Grid>
      </Grid>
  )
}

const SBCReport = ({ SBCResults, loading, options }) => {

  let { startDate, endDate } = options;
  startDate = startDate ? formatNiceDate(startDate) : null;
  endDate = endDate ? formatNiceDate(endDate) : null;
  return (
    <Container>
      { loading ? <LinearProgress /> : 
      SBCResults.results.length ? (
        <Grid container spacing={3}>

        <Grid item xs={12}>  
          <Summary elevation={4}>
            <CardHeader title="Summary" subheader={
              <span>{startDate} - {endDate}</span>
            } />
            <CardContent>
              <Grid container spacing={8}>

                <Grid item xs={12}>
                  <CommonFlex justify="flex-start">

                  <CommonFlex width="max-content" >
                    <IconBox bg={theme.colors.iconBg2}><AttachMoneyRoundedIcon htmlColor={theme.colors.icon2} /></IconBox>
                    <CommonFlex dir="column" align="flex-start" justify="center">
                      <StatHeader className="icon-header">Total Sales</StatHeader>
                      <StatInfo>{currencyFormat(SBCResults.sumTotal)}</StatInfo>
                    </CommonFlex>
                  </CommonFlex>

                  <CommonFlex width="max-content"  pad="6.5rem" padY=".3rem">
                    <IconBox bg={theme.colors.iconBg1}><StyleRoundedIcon htmlColor={theme.colors.icon1} /></IconBox>
                    <CommonFlex dir="column" align="flex-start" justify="center">
                      <StatHeader className="icon-header">Total Qty</StatHeader>
                      <StatInfo>{SBCResults.sumQty}</StatInfo>
                    </CommonFlex>
                  </CommonFlex>

                  </CommonFlex>
                  {/* <StatHeader>Summary of Total Sales</StatHeader>
                  <CommonFlex height="max-content" padY=".4rem">
                    <StatLabel>Total Sales</StatLabel>
                    <StatInfo>{currencyFormat(SBCResults.sumTotal)}</StatInfo>
                  </CommonFlex>
                  <CommonFlex height="max-content" padY=".4rem">
                    <StatLabel>Total Qty</StatLabel>
                    <StatInfo>{SBCResults.sumQty}</StatInfo>
                  </CommonFlex> */}
                </Grid>

                <Grid item xs={4}>
                  <StatHeader>Top 5 Customer By Total</StatHeader>
                  {SBCResults.topCustomersByTotal.map(el => 
                    <CommonFlex height="max-content" padY=".4rem" key={el._id}>
                      <StatLabel>{el._id}</StatLabel>
                      <StatInfo>{currencyFormat(el.total)}</StatInfo>
                    </CommonFlex>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <StatHeader>Top 5 Customer By Qty</StatHeader>
                  {SBCResults.topCustomerByQty.map(el => 
                    <CommonFlex height="max-content" padY=".4rem" key={el._id}>
                      <StatLabel>{el._id}</StatLabel>
                      <StatInfo>{el.qty}</StatInfo>
                    </CommonFlex>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <StatHeader className="flex"><span>Top 5 Customer By Turnover Rate</span> 
                  <Tooltip title="Turnover Rate is the avg amount of days to sell 1 wig. Smaller number is better">
                    <InfoRoundedIcon color="secondary" />
                  </Tooltip>
                  </StatHeader>
                  {SBCResults.topCustomerByItr.map(el => 
                    <CommonFlex height="max-content" padY=".4rem" key={el._id}>
                      <StatLabel>{el._id}</StatLabel>
                      <StatInfo>{el.itrStr}</StatInfo>
                    </CommonFlex>
                  )}
                </Grid>

              </Grid>
            </CardContent>
          </Summary>
        </Grid>

        <Grid item xs={12} md={8} xl={9}>
          <CollapsibleTable headers={headers} rows={SBCResults.results} detailHeaders={detailHeaders} /> 
        </Grid>

        <Grid item xs={12} md={4} xl={3}>
            <Card elevation={4}>
              <CardHeader title="Sales Qty By Top 20 Customers" />
              <CardContent>
                <DoughnutChart data={SBCResults.chartData} title="Sales By Customer" />
              </CardContent>
            </Card>
        </Grid>

        </ Grid> 
      )
      : null }
    </Container>
  )
}

export {  SBCTopBar, SBCReport };