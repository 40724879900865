import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../utils/theme';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import Skeleton from '@material-ui/lab/Skeleton';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';
import TabContainer from '../../utils/tabContainer';

import Chip from '../../utils/Chip';

import HomeIcon from '@material-ui/icons/Home';
// import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import CallReceivedRoundedIcon from '@material-ui/icons/CallReceivedRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';

import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';


import { currencyFormat, formatPhone, capitalize, getDaysDiff } from '../../utils/util';

import { FullDivider, Flex } from '../../utils/common'

import Accordion from './AccordionWigs';
import SimpleTable from '../../utils/simpleTable';

import {useSBContext} from '../../utils/snackbar';
import { useAuthContext } from '../../auth';

import { OrderTab, PaymentTab, BalanceTab} from './stats'


/* Styled Components */

const Container = styled(Paper)`
  padding: 2rem;

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #ccc;
  }

  .MuiAccordion-root {
    min-height: 8rem;
  }
`

const Info = styled.span`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  align-items: center;

  .email {
    font-style: italic;
  }
`
const Notes = styled.p`
  margin-top: 0;
`

const NotesLabel = styled.span`
  display: block;
  font-weight: 500;
  margin-right: 2rem;
`

const InfoLabel = styled.span`
  display: block;
  font-weight: 500;
  padding-right: 1.5rem;
  padding-top: .3rem;
`

const InfoText = styled.span`
  display: block;
`

const AddressHeader = styled.span`
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: .5rem;
`

const AddressText = styled.p`
  line-height: 2.2rem;
  margin-top: 1rem;
`

const AccordionContainer = styled.div`
  margin-top: 2rem;
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const Balance = styled.h3`
  /* font-family: 'Roboto'; */
  color: ${props => props.negative ? 'salmon' : 'inherit'};
`

const Wallet = styled(Card)`
  /* height: 8rem; */
  /* text-align: center; */
  /* margin-bottom: 5rem;
  margin-top: 2rem; */

  h3 {
    font-size: 2.6rem;
    font-weight: 500;
    /* text-align: center; */
    margin: .5rem 0;
  }

  .sub {
    font-size: 1.4rem;
  }
`
const Due = styled(Card)`
  /* height: 4rem; */
  /* text-align: center; */
  /* margin-bottom: 5rem; */

  h3 {
    font-size: 2.6rem;
    font-weight: 500;
    /* text-align: center; */
    margin: .5rem 0;
  }

  .sub {
    font-size: 1.4rem;
  }
`

const BalanceCards = styled(Card)`
 
`

const TabHeaders = styled.div`
  display: flex;

  .MuiSvgIcon-root {
    margin-right: 1rem;
  }
`

const StatHeader = styled.span`
  display: block;
  font-size: 1.2rem;
  text-align: center;
`

const Stat = styled.span`
  font-size: 2.2rem;
  font-weight: 500;
  text-align: center;
`

const TabContentStyles = styled.div`
  width: 100%;
`

/* Table Headers */



/* Components, Tab Content */

const StatContainer = ({icon, stat, color = "#eee"}) => {
  return (
    <Paper elevation={4}>
      <Grid container spacing={0}>
        <Grid item xs={4} style={{backgroundColor: color}}>{icon}</Grid>
        <Grid item xs={8}>{stat}</Grid>
      </Grid>
    </Paper>
  )
}

const TabContent = ({value, panel, children}) => {
  if (value !== panel) return <div></div>

  return children;
}



const ViewCustomer =  (props) => {

  const [value, setValue] = useState(0); // for the tabs

  const {hasPermission, user} = useAuthContext()

  const inv = user.settings?.inventory

  const stockHeaders = [
    { id: 'wigId', label: 'ID' },
    { id: 'brand', label: 'Brand' },
    { id: 'line', label: 'Line' },
    { id: 'sku', label: 'SKU' },
    { id: 'baseMaterial', label: 'Material' },
    { id: 'status', label: 'Status' },
    {id: 'duration', render: row => {
      let date = row?.history[row?.history?.length - 1]?.date
      let cInterval = inv?.cNote ? +inv?.cNote : 45
      let sInterval = inv?.sNote ? +inv?.sNote : 45
      let diff = Math.floor(getDaysDiff(date))
      if (row.status !== 'sold') {
  
        if (row.status === 'consignment') {
          return <Chip padx="3px" bgcolor={diff > cInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        } else {
          return <Chip padx="3px" bgcolor={diff > sInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        }
  
      } else {
        return "-"
      }
    }, label: 'Duration'},
  ]

  // first tab option: General, Details, Profile, Personal

  const tabNames = [
    <TabHeaders><HomeIcon />General</TabHeaders>,
    <TabHeaders><ReceiptIcon />Sales</TabHeaders>,
    <TabHeaders><AttachMoneyIcon />Payments</TabHeaders>,
    <TabHeaders><AccountBalanceWalletRoundedIcon />Balance</TabHeaders>
  ]

  const handleChange = (event, newValue) => {  // for the tabs
    setValue(newValue);
  };

  const [loading, setLoading] = useState(false)

  const [loadingStats, setLoadingStats] = useState(false)

  const [stats, setStats] = useState({})

  const [info, setInfo] = useState({});
  const [stock, setStock] = useState({});
  const [years, setYears] = useState([])

  const {id} = props.match.params;

  // console.log(id);
 
  const crumbs = [{path: '/customers/', text: 'Customers'}, 
  {path: `/customers/view/${id}`, text: 'View'}, {path: `/customers/view/${id}`, text: `${info?.name || ""}`}];

  const {handleClick} = useSBContext();

  const loadStats = async () => {
    setLoadingStats(true)
    try {
      const { data } = await axios.get(`/customers/${id}/stats`);
      // console.log(data, "datata");
      setStats(data)
      setLoadingStats(false)
    } catch (e) {
      // console.log(e, "erere");
      handleClick('error', "Failed to load stats, Please refresh page.")
      setLoadingStats(false)
    }
  }

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/customers/${id}`);
    const {data: stockInfo} = await axios.get(`/track/client/${id}?brand=all&onlyConsign=true`);
    setInfo(data);
    setStock(stockInfo);
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      loadData();
      loadStats()
      let currYear = new Date().getFullYear()
      setYears([currYear - 1, currYear - 2])
    } else
      props.history.push('/customers');
  }, [])


  const StatLine = ({statistic}) => {
    if (loadingStats) 
      return <Skeleton variant="text" />
    else 
      return <Stat>{statistic}</Stat>
  }

 return (
  <ViewInfo title="Customer Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Container variant='outlined'>

    <Grid container spacing={3}>

     <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabContainer elevation={0} tabNames={tabNames} value={value} handleChange={handleChange}/>
          </Box>
     </Grid>


      <TabContent panel={0} value={value}>
      <Grid item xs={12} md={5} lg={5}>

        <Card variant='outlined'>
          <CardHeader title="Customer Info"
          action={
            <IconLink to={`/customers/edit/${info._id}`}><EditIcon /></IconLink>
          } />
          <CardContent>
            <Info><InfoLabel><PersonOutlinedIcon fontSize='inherit' /></InfoLabel><InfoText>{info.name}</InfoText></Info>

            <Info><InfoLabel><EmailOutlinedIcon fontSize='inherit' /></InfoLabel><InfoText><span className="email">{info.email}</span></InfoText></Info>

            {info.phone ? <Info><InfoLabel><PhoneOutlinedIcon fontSize='inherit' /></InfoLabel><InfoText>{formatPhone(info.phone)}</InfoText></Info> : null }

            {info.altPhone ? <Info><InfoLabel><PhoneAndroidIcon fontSize='inherit' /></InfoLabel>{formatPhone(info.altPhone)}</Info> : null }

            <FullDivider style={{marginTop: '25px', marginBottom: '25px'}} />

            <Grid container spacing={3}>
              <Grid item xs={6}><AddressHeader>Billing Address</AddressHeader>
                <AddressText>
                  {info.address?.name || info.name} <br />
                  {info.address?.street} <br />
                  {capitalize(info.address?.city)} {info.address?.city ? ", " : null }{info.address?.state} {info.address?.zip}<br />
                  {info.address?.country?.toUpperCase() === "USA" ? null : info.address?.country}
                </AddressText>
               </Grid>
              <Grid item xs={6}><AddressHeader>Shipping Address</AddressHeader>
                { info.shippingAddress?.street ? <AddressText>
                  {info.shippingAddress?.name} <br />
                  {info.shippingAddress?.street} <br />
                  {capitalize(info.shippingAddress?.city)}{info.shippingAddress?.city ? ", " : null }{info.shippingAddress?.state} {info.shippingAddress?.zip} <br />
                  {info.shippingAddress?.country?.toUpperCase() === "USA" ? null : <>{info.shippingAddress?.country} <br /></>}
                  {info.shippingAddress?.notes}
                </AddressText> : <AddressText>Same as billing</AddressText>
                }
              </Grid>
            </Grid>

            <FullDivider style={{marginTop: '25px', marginBottom: '10px'}} />
            <Flex pad="0" justify="flex-start" align="flex-start" dir="row"><NotesLabel>Notes:</NotesLabel><Notes>{info.notes}</Notes></Flex>
          </CardContent>
        </Card>

      </Grid>


      {/* Items to include in customer overview: 
        a. HOME
        1. Wigs sold
        2. Wigs in Stock
        3. Wig Income
        4. Wig Profit
        5. Open Balance
        6. Wallet

        b. INVOICE
        1. Overdue
      */}

      <Grid item container xs={12} md={3} lg={4} xl={3}>

        <Grid item xs={12}>
            <StatContainer color={theme.colors.iconBg1} icon={<Flex><ReceiptIcon fontSize='large' htmlColor={theme.colors.icon1} /></Flex>} stat={
            <Flex>
              <StatLine statistic={stats.sold}></StatLine>
              <StatHeader>Wigs Sold</StatHeader>
            </Flex>
            } />
        </Grid>

        <Grid item xs={12}>
            <StatContainer color={theme.colors.iconBg2} icon={<Flex><ShoppingCartRoundedIcon fontSize='large' htmlColor={theme.colors.icon2} /></Flex>} stat={
            <Flex>
              <StatLine statistic={<>{stock.wigs?.filter(el => el.status === "consignment")?.length} {stock.wigs?.length ? ` - ${currencyFormat(stock.wigs.filter(el => el.status === "consignment").reduce((acc, cur) => acc + cur.sale.subtotal, 0))}` : ""}</>}></StatLine>
              <StatHeader>Wigs In Stock</StatHeader>
            </Flex>
            } />
        </Grid>

        <Grid item xs={12}>
            <StatContainer color={theme.colors.iconBg3} icon={<Flex><CallReceivedRoundedIcon fontSize='large' htmlColor={theme.colors.icon3} /></Flex>} stat={
            <Flex>
              <StatLine statistic={currencyFormat(stats.soldInPastMonth?.income)}></StatLine>
              <StatHeader>Income Past Month</StatHeader>
            </Flex>
            } />
        </Grid>

        {hasPermission('readAccounting') ? <Grid item xs={12}>
            <StatContainer color={theme.colors.iconBg4} icon={<Flex><TrendingUpRoundedIcon fontSize='large' htmlColor={theme.colors.icon4} /></Flex>} stat={
            <Flex>
              <StatLine statistic={currencyFormat(stats.soldInPastMonth?.profit)}></StatLine>
              <StatHeader>Profit Past Month</StatHeader>
            </Flex>
            } />
        </Grid> : null }

      </Grid>
     
      <Grid item xs={12} md={4} lg={3} xl={3}>
        
        <Card elevation={0}>
        <CardHeader title="Balance Details"
           />
          <CardContent>

            <Grid container spacing={2}>

              <Grid item xs={12}>
                <BalanceCards elevation={3}>
                  <Wallet elevation={0}>

                    <CardHeader title={<span className='sub'>Available on account</span>} />

                  <CardContent>
                    <Flex dir="row" pad="0" justify="space-between">
                    <h3>{currencyFormat(info.wallet)}</h3>
                    <AccountBalanceWalletRoundedIcon htmlColor='#2b6e3c' fontSize='large' />
                    </Flex>
                  </CardContent>
                  </Wallet>
                </BalanceCards>
              </Grid>

              {/* <Divider style={{margin: '30px 0'}} /> */}

              <Grid item xs={12}>
                <BalanceCards elevation={3}>
                  <Due elevation={0}>
                    <CardHeader title={<span className='sub'>Amount Due:</span>} />
                    <CardContent>
                      <Flex dir="row" pad="0" justify="space-between">
                        <Balance negative={info.balance && info.balance > 0}>{currencyFormat(info.balance)}</Balance>
                      <LocalAtmIcon htmlColor='#2b5d6e' fontSize='large' />
                      </Flex>
                    </CardContent>
                    
                  </Due>
                </BalanceCards>
              </Grid>

            </Grid>
            {/* <Info><InfoLabel>Amount Owed:</InfoLabel>
            </Info> */}

          {/* <Info><InfoLabel>Available on account (wallet):</InfoLabel>
            <Balance>{currencyFormat(info.wallet)}</Balance></Info> */}
          </CardContent>
        </Card>

      </Grid>
      
      <Grid item xs={12}>
        <AccordionContainer>
          <Accordion title={`${stock.wigs?.length || "No"} Wigs currently in consignment`} square>
              <SimpleTable link="inventory" title="Inventory" headers={stockHeaders} rows={stock.wigs} idReplace="wigId" />
          </Accordion>
        </AccordionContainer>
      </Grid>
      
      {/* <Grid item xs={12}>
        <AccordionContainer>
          <Accordion title={`${stock.wigs?.ml?.length || 0} Millennium Wigs currently in consignment`}>
            <SimpleTable link="inventory" headers={stockHeaders} rows={stock.wigs?.ml} idReplace="wigId" />
          </Accordion>
        </AccordionContainer>
      </Grid> */}

      </TabContent>

      <TabContent panel={1} value={value}>
        <OrderTab cust={info} years={years} />
      </TabContent>

      <TabContent panel={2} value={value}>
        <PaymentTab cust={info} years={years} custEmail={info.email} />
      </TabContent>

      <TabContent panel={3} value={value}>
        <BalanceTab cust={info} />
      </TabContent>

     </Grid>
    </Container>
    }
  </ViewInfo>
 )
}

export default ViewCustomer;