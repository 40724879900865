import React from 'react';
import styled from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';

const LinkText = styled.span`
  font-weight: ${props => props.end ? '500' : '300'};
  text-decoration: none;
  color: #333;
`
 
export default ({links}) => {
  
  const last = links.length - 1;

 return (
  <Breadcrumbs aria-label="breadcrumb">
    {links.map((cur, i) => (
    <Link key={i} to={cur.path} style={{textDecoration: 'none'}} >
      <LinkText end={i === last ? 'last' : ''}>{cur.text}</LinkText>
    </Link>
    ))}
  </Breadcrumbs>
 )
}