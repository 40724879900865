import React from "react";

import { Field } from "formik";
import {
  // TimePicker,
  DatePicker,
  // DateTimePicker,
  KeyboardDatePicker
} from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

const DateSelector = ({dtb, label, name, format="MM/dd/yyyy", views=["year", "month", "date"], openTo="year", variant="inline", ...props}) => {
  return  (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Field 
        disableToolbar={dtb}
        component={KeyboardDatePicker} 
        name={name}
        // views={views}
        format={format}
        views={views}
        openTo={openTo}
        label={label}
        variant={variant}
        fullWidth 
        inputVariant="outlined"
        {...props}
        />
    </MuiPickersUtilsProvider>
)
}

export default DateSelector;