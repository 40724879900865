import React, { useRef } from "react";
import styled from 'styled-components'
import {Link} from 'react-router-dom';

import { useReactToPrint } from 'react-to-print';

import { CommonFlex, FlexLine, Flex } from "../../utils/common";

import Indicator from "../../utils/Indicator";
import Status from '../../utils/Status';

import { theme } from "../../utils/theme";

import { currencyFormat, splitArr, formatPhone, formatGenericDate, capitalizeWords, calculatePayIndicator } from "../../utils/util";

import { IconButton } from "@material-ui/core";

import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import PrintIcon from '@material-ui/icons/Print';
import Search from '@material-ui/icons/Search';


const Container = styled.div`
  padding: 1.5rem;
  padding-top: .3rem;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
  border: 2px dashed ${props => props.theme.colors.border};
  
  @media print {
    border: none;
    .print-invisible {
      display: none;
    }
  }
`

const Title = styled.h2`
  /* font-weight: 500; */
  color: ${props => props.theme.colors.lightText};
  text-transform: uppercase;
  font-size: 1.7rem;
  margin-bottom: 2rem;
  margin-right: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 2.7rem;
    width: 2.7rem;
    margin-right: 1rem;
  }
`

// const Status = styled.span`
//   background-color: ${props => props.bgColor};
//   color: ${props => props.color};
//   padding: .8rem 2.5rem;
//   border-radius: 5px;
//   font-weight: 600;
//   text-transform: uppercase;
//   font-size: 1.4rem;
//   letter-spacing: .5px;
//   margin-right: 2rem;
// `

const BillTo = styled(FlexLine)`
  span {
    padding: .2rem 0;
  }
`

const BillToHeader = styled.h5`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.4rem;
  margin: .5rem 0;
`

const ItemDetails = styled.div`
  width: calc(100% / 5);
  display: flex;
  justify-content: flex-start;

  div {
    width: max-content;
  }
  
  span {
    /* text-align: center; */
    width: max-content;
    font-weight: 500;
  }
  .details {
    font-size: 1.3rem;
    color: ${props => props.theme.colors.lightText};
  }
  .discount-header {
    margin-top: .8rem;
  }
  .discount, .discount-header {
    font-size: 1.1rem;
    color: ${props => props.theme.colors.lightText};
  }
`

const HeaderDetails = styled(CommonFlex)`

`

const HeaderContainer = styled.div`
  border: 2px solid ${props => props.theme.colors.headerBg};
  padding: .3rem 0;
  margin-bottom: 2.5rem;
  border-right: none;
  border-left: none;
`

const Summary = styled(FlexLine)`
  /* border: 1px solid ${props => props.theme.colors.border}; */
  border-radius: 3px;
  width: 35%;
  min-width: 27rem;

  span {
    font-weight: 500;
  }

  .label {
    display: inline;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 400;
    padding-right: .8rem;
    /* color: ${props => props.theme.colors.lightText}; */
  }

  .date {
    border-bottom: 2px dotted ${props => props.theme.colors.border};
    margin-bottom: 1rem;
    padding-bottom: .5rem;
  }
`

const LineItemHeader = styled(CommonFlex)`
  background-color: ${props => props.theme.colors.headerBg};

  & > span {
    /* text-align: center; */
    font-weight: 500;
    width: calc((100% - 5rem) / 7);
    text-transform: uppercase;
    font-size: 1.3rem;
  }
  .title {
    width: calc(100% / 7 + 5rem);
  }
  .link {
    width: 5rem;
  }
`

const LineItems = styled.div``

const LineItem = styled(CommonFlex)`
margin-bottom: 1.5rem;
border: 1px solid ${props => props.theme.colors.border};
border-left: 2px solid ${props => props.theme.colors.secondaryMain};
border-radius: 3px;

  & > span {
    /* text-align: center; */
    width: calc((100% - 5rem) / 7);
  }
  .title {
    width: calc(100% / 7 + 5rem);
  }
  .link {
    width: 5rem;
  }
  .indicator {
    justify-content: flex-start;
  }
`

const ChargesTotal = styled.h4`
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 3rem;
  margin-bottom: 1rem;
`

const Total = styled.div`
  border-radius: 5px;
  background-color: ${props => props.theme.colors.headerBg};
  min-height: 4rem;
  padding: 1rem 1.5rem;
  margin-top: 2.5rem;
`

const TotalLine = styled(CommonFlex)`
`

const TotalHeader = styled.span`
  margin-right: 2rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
`
const TotalNumber = styled.span`
  min-width: 10rem;
  text-align: right;
  font-weight: 500;
`

const Invoice = ({ order, showHeader }) => {

  const invoice = useRef()

  const { orig, filtered } = splitArr(order.payments, el => {return el.remainingBalance})

  let wigTotal = order.wigs?.reduce((acc, cur) => acc + cur.total, 0)
  let orderPaymentsTotal = orig.reduce((acc, cur) => acc + cur.paymentAmount, 0)
  let remainPaymentsTotal = filtered.reduce((acc, cur) => acc + cur.paymentAmount, 0)

  let rest = Math.max(orderPaymentsTotal - wigTotal, 0) + remainPaymentsTotal

  console.log({orig, filtered});

  let color, bgColor, statusText;

  if (order.balance <= 0) {
    color = theme.colors.icon2; bgColor = theme.colors.iconBg2; statusText = "Paid"
  } else {
    if (order.totalPaid > 0) {
      color = theme.colors.iconCaution; bgColor = theme.colors.iconBgCaution; statusText = "Partially Paid"
    } else {
      color = theme.colors.iconRed; bgColor = theme.colors.iconBgRed; statusText = "Not Paid"
    }
  }

  let customer = order.to?.id || ""

  let address = customer ? customer?.address : {}

  const handlePrint = useReactToPrint({
    content: () => invoice.current
  });
 
  return (
    <Container ref={invoice}>
      <CommonFlex>

      <CommonFlex width="45rem" justify="flex-start" align="center" padY=".5rem">
        <Title>
        <ReceiptRoundedIcon color="primary" /> Invoice #{order.orderId}
        </Title>
        <Status color={color} bgColor={bgColor} statusText={statusText} size="large"></Status>

      </CommonFlex>

      <IconButton onClick={handlePrint} className="print-invisible"><PrintIcon color="primary" /></IconButton>
        
      </CommonFlex>

      <HeaderDetails padY="2rem" align="baseline">
      <BillTo pad="1.5rem" padY="1rem">
        <BillToHeader>Bill To</BillToHeader>
        <span>{order.to?.name}</span>
        {customer ? <>
          <span>{address?.street}</span>
          <span>{address?.city} {address?.state} {address?.zip}</span>
          <span>{formatPhone(customer.phone)}</span>
        </> : null}
      </BillTo>

      <Summary pad=".5rem">
        <span><span className="label">Sale Date:</span> {formatGenericDate(order.date)}</span>
        <span className="date"><span className="label">Due Date:</span> {formatGenericDate(order.dueDate)}</span>
        <span><span className="label">Wigs Sold:</span> {order.wigs?.length}</span>
        <span><span className="label">Grand Total:</span> {currencyFormat(order.total)}</span>
        <span><span className="label">Total Paid:</span> {currencyFormat(order.totalPaid)}</span>
        <span><span className="label">Amount Due:</span> {currencyFormat(order.balance)}</span>
      </Summary>

      </HeaderDetails>

      <HeaderContainer>
        <LineItemHeader pad="2.5rem" padY=".8rem">
          <span className="title">Item</span>
          <span>Status</span>
          <span>Subtotal</span>
          <span>Total</span>
          <span>Amount Paid</span>
          <span>Open Balance</span>
          <span className="link">View</span>
        </LineItemHeader>
      </HeaderContainer>

      <LineItems>
        {order.wigs?.map(el => {
          let spans = []
          let charges = order.charges?.filter(cur => cur.wigId === el.wigId || (cur.chargeType === "discount" && !cur.wigId)).forEach(elem => {

            let charge = elem.calculated
            if (elem.chargeType === "discount" && !elem.wigId) {
              charge = elem.rateType === "percentage" ? (elem.amount / 100) * el.price : elem.calculated / order.wigs.length
            }

            spans.push(<span className="discount">- {capitalizeWords(elem.description)} {elem.chargeType === "discount" && elem.rateType === "percentage" ? `(-${elem.amount + "%"})` : ""} {elem.chargeType === "discount" ? "-" : ""}{currencyFormat(charge)}</span>)
          })

          return (
            <LineItem pad="2.5rem" padY="1.2rem" key={el.wigId}>
              <ItemDetails className="title">
                <FlexLine pad="0">
                <span>{el.wigId}</span>
                <span className="details">{el.sku}</span>
                {spans.length ? <span className="discount-header">Item Charges & Discounts</span> : null }
                {spans}
                </FlexLine>
              </ItemDetails>
              <span><Indicator {...calculatePayIndicator(el.total, el.paid)} /></span>
              <span>{currencyFormat(el.price)}</span>
              <span>{currencyFormat(el.total)}</span>
              <span>{currencyFormat(el.paid)}</span>
              <span>{currencyFormat(el.total - el.paid)}</span>
              <Link className="link" to={`/inventory/view/${el.wigId}`} target="_blank"><IconButton><Search color="primary" /></IconButton></Link>
            </LineItem>
          )}
          )}
          {order.wigs?.length && order.charges?.filter(cur => !cur.wigId && cur.chargeType === "surcharge").length ? 
          <ChargesTotal>Additional Charges</ChargesTotal> : null }

          {order.charges?.
          filter(cur => !cur.wigId && cur.chargeType === "surcharge").
          map(el => {
            let paid = Math.min(el.calculated, rest)
            if (el.calculated >= rest) {
              rest = 0
            } else {
              rest = rest - el.calculated
            }
            return (
              <LineItem pad="2.5rem" padY="1.2rem" key={el.description}>
                <span className="title">{el.description}</span>
                <span><Indicator {...calculatePayIndicator(el.calculated, paid)} /></span>
                <span>{currencyFormat(el.calculated)}</span>
                <span>{currencyFormat(el.calculated)}</span>
                <span>{currencyFormat(paid)}</span>
                <span>{currencyFormat(el.calculated - paid)}</span>
                <span className="link">-</span>
              </LineItem>
            )}
            )}
      </LineItems>

      <Total>
        <TotalLine justify="flex-end" pad="1rem">
          <TotalHeader>Subtotal:</TotalHeader><TotalNumber>{currencyFormat(order.subtotal)}</TotalNumber>
        </TotalLine>
        <TotalLine justify="flex-end" pad="1rem">
          <TotalHeader>Charges & Discounts:</TotalHeader><TotalNumber>{currencyFormat(order.total - order.subtotal)}</TotalNumber>
        </TotalLine>
        <TotalLine justify="flex-end" pad="1rem">
          <TotalHeader>Total:</TotalHeader><TotalNumber>{currencyFormat(order.total)}</TotalNumber>
        </TotalLine>
        <TotalLine justify="flex-end" pad="1rem">
          <TotalHeader>Total Paid:</TotalHeader><TotalNumber>{currencyFormat(order.totalPaid)}</TotalNumber>
        </TotalLine>
        <TotalLine justify="flex-end" pad="1rem">
          <TotalHeader>Amount Due:</TotalHeader><TotalNumber>{currencyFormat(order.balance)}</TotalNumber>
        </TotalLine>
      </Total>
    </Container>
  )
}

export default Invoice;