import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import { useReactToPrint } from 'react-to-print';

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CustomChip from '../../utils/CustomChip';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Print from '@material-ui/icons/Print';
import CallReceivedRoundedIcon from '@material-ui/icons/CallReceivedRounded';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import EmailIcon from '@material-ui/icons/Email';
import Trophy from '../../images/trophy';

import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title as ChartTitle,
  Filler,
} from 'chart.js';
import { Doughnut, Line, Bar } from 'react-chartjs-2';

import { theme } from '../../utils/theme';

import FormDialog from '../../utils/dialog';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';

import {DateTime} from 'luxon';

import { currencyFormat, getPercent, capitalize } from '../../utils/util';
import { Flex, FlexLine, FullDivider, SectionHeader, SectionHeaderRow } from '../../utils/common';

import { useSBContext } from '../../utils/snackbar';
import { useAuthContext } from '../../auth';


import SimpleAutoComplete from '../../utils/simpleAutoComplete';

import SimpleTable from '../../utils/simpleTable';

import displayPdf from '../../utils/displayPdf';
import { CircularProgress } from '@material-ui/core';

import CCBg from '../../images/cc-bg.jpg';

import StyleDialog from '../../utils/dialog';

// bs items stats icons
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StyleIcon from '@material-ui/icons/Style';
import HeightIcon from '@material-ui/icons/Height';
import WigIcon from '../../images/wig';
// end

ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ChartTitle,
  Filler,
  );

const Container = styled.div`
  width: 90%;
  height: auto;
  margin: 3rem auto;

  @media ${props => props.theme.breakpoints.down('xl')} {
    width: 90%;
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
  }
`
const DividerLegend = styled(Divider)`
  flex-grow: 1;
`

const BoxFade = styled.div`
  background-image: linear-gradient(to bottom, #fff, transparent);
  padding: 3rem;
  /* padding-top: 0; */
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 -5px 10px -4px rgba(0, 0, 0, .14);
`

const PrintFlex = styled(Flex)`
  height: 3rem;
`

const ReportBar = styled(Paper)`
  padding: 2rem;
`

const Header = styled.div`
  height: 12rem;
 `

const Title = styled.h2`
  font-size: 2.4rem;
  padding-top: 1rem;
  font-weight: 500;
  /* text-align: center; */
`

const PLCard = styled(Card)`
  background-image: linear-gradient(to right, #fff5, #fff3), url(${props => CCBg});
  background-repeat: no-repeat;
  background-size: cover;

  @media print {
    background-image: none;
    box-shadow: none;

    @page {
      margin-top: 2.5rem;
    }
    
    .MuiCardHeader-action {
      display: none;
    }
  }
`

const PLDivider = styled(FullDivider)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

const PLHeader = styled.h4`
  font-size: 1.4rem;
  margin-top: 6rem;
  margin-bottom: 0;
  text-transform: uppercase;

  &.first {
    margin-top: 2.5rem;
  }
`

const PLTitle = styled.h5`
  font-weight: normal;
  font-size: 1.4rem;
  margin: 1.5rem 0;
  margin-left: 1.5rem;
`

const PLTotalTitle = styled.h5`
  font-weight: 600;
  margin: 0rem;
  margin-left: 1.5rem;
  font-size: 1.4rem;
`

const PLTotalStat = styled.span`
  font-weight: 600;
`

const PLNetTitle = styled.h5`
  font-weight: 600;
  margin: 0rem;
  margin-left: 1.5rem;
  font-size: 1.4rem;
  text-transform: uppercase;
`

const PLNetStat = styled.span`
  font-weight: 600;
  font-size: 1.6rem;
`

const PLStat = styled.span`
  font-weight: 600;
`
const NetDivider = styled.hr`
  background-color: ${props => props.theme.colors.lightText};
  border-color: ${props => props.theme.colors.lightText};
  height: 2px;
  min-width: 6rem;
  border-style: solid;
`

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Stat = styled.span`
  display: block;
  font-size: 3.4rem;
  font-weight: 500;
`

const IconCircle = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  padding: .7rem;
  background-color: ${props => props.color || "transparent"};
  margin-left: 1rem;
`

const DoughnutContainer = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  /* max-width: 40rem; */
  `

const AreaChartContainer = styled.div`
  max-height: 30rem;
`

const AccountingHeader = styled.h2`
  text-align: center;
  font-weight: normal;
  margin: 0 2rem;
`

const CardInfoHeader = styled.span`
  display: block;
  text-align: center;
  margin-top: 1.5rem;
  color: ${props => props.theme.colors.lightText};
`
const CardStat = styled.span`
  display: block;
  text-align: center;
  margin: 1rem 0 3rem 0;
  font-weight: 500;
  font-size: 2.6rem;
`

const Main = styled.div`
  /* margin-top: 1rem; */
`

const InfoItem = styled.span`
  display: block;
  margin-bottom: .5rem;
  font-size: 1.6rem;
  letter-spacing: .5px;
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const TooltipContainer = styled.div`
  display: flex;
`

const ResultContainer = styled.div`
  margin-top: 4rem;

  .MuiCardHeader-action {
    margin-top: 0;
    margin-right: 3px;
  }
`

const ResultContent = styled(Grid)`
  padding: 0 1.5rem;
`

const CardTitle = styled.span`
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  .amount {
    display: block;
    font-weight: bold;
    color: #5d5d5d;
    margin-top: 1rem;
    font-size: 2.2rem;
  }
`

const CardPoint = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
`

const CardPointHeader = styled.span`
  display: block;
  font-size: 1.2rem;
`

const BalanceAmount = styled.span`
  display: block;
  text-align: right;
  font-weight: 500;
  font-size: 2rem;
`

const CustTitles = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 3rem;
`

const TrophyIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 4px;
  background-color: #a39261;
  margin-right: 1.5rem;
`

const TopCust = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .3px;
  margin-top: 1.2rem;
  margin-bottom: .5rem;
`

const CustHeader = styled.span`
  display: block;
  font-size: 1.2rem;
`

const CustLine = styled.div`
  margin: 1.5rem auto;

  .MuiGrid-item {
    /* padding: 1rem 1.6rem 1rem .5rem; */
    padding-right: 0;
  }

  .indicator-dot {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-right: .8rem;
    background-color: ${props => props.dotColor};
    border-radius: 50%;
  }

  .cust-total {
    font-weight: 500;
  }

  .cust-qty {
    font-weight: 500;
  }
  
`

// best selling items stats

const StatBoxStyles = styled.div`
  display: flex;
  /* width: 100%; */


  .icon-box {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 5px;
    box-shadow: rgb(58 53 65 / 42%) 0px 4px 8px -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    background-color: ${props => props.color || '#fff'};

      
    .wigIcon {
      width: 3rem;
    }
  }

  .stat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 100%; */
  }

  .stat-container-pay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .stat-header {
    color: ${props => props.theme.colors.lightText};
  }

  .stat {
    color: ${props => props.theme.colors.darkText};
    font-weight: 500;
    font-size: 1.5rem;
  }

  .stat-pay {
    margin-bottom: 1rem;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: ${props => props.bgColorLight};
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: ${props => props.bgColorDark};

  }
`

const Top3Header = styled.span`
  color: ${props => props.theme.colors.lightText};
`

const TotalQtyLength = styled.div`
  margin: 3rem auto;
  /* border: 1px solid ${props => props.theme.colors.lightText};
  border-radius: 5px; */
  .title {
    color: ${props => props.theme.colors.lightText};
    margin-top: .5rem;
  }

  .number {
    font-size: 3rem;
  }
`

const SizeContainer = styled.div`
  padding-right: 3rem;
  border-right: ${p => p.last ? "" : "1px solid #3a35411f"};

  .style-name {
    background-color: ${p => p.color || "#f7eded"};
    /* height: 2rem; */
    width: max-content;
    padding: .5rem 1rem;
    border-radius: 5px;
    font-weight: 500;
  }

  .style-qty {
    color: ${p => p.theme.palette.primary.main};
    font-weight: 500;
  }
`

const SizeHeader = styled.h4`
  font-weight: 400;
  font-size: 1.8rem;
`

const SizeView = styled.span`
 color: #3a354199;
 cursor: pointer;
`

const StatBox = ({color, icon, header, stat, total}) => {
  return (
    <StatBoxStyles color={color}>
      <div className='icon-box'>
        {icon}
      </div>
      <div className='stat-container'>
        <div className='stat-header'>{header}</div>
        <div className='stat'>{stat}</div>
        <div className='stat'>{total}</div>
      </div>
    </StatBoxStyles>
  )
}


const SizeList = ({size, styles, last, color, view = true, load}) => {
  return <SizeContainer last={last} color={color}>
    <FlexLine dir="row" pad="0" justify="space-between"><SizeHeader>{size} Length</SizeHeader>{ view ? <SizeView onClick={load}>View All</SizeView> : null }</FlexLine>
    <Flex pad="0">
      {styles.map(el => 
        <FlexLine key={el.style} dir="row" pad="0" padY="1rem" justify="space-between"><span className='style-name'>{el.style}</span><span className='style-qty'>{el.count}</span></FlexLine>
      )}
    </Flex>
  </SizeContainer>
}

// end bs items stats

const colors = [
  '#b95f5f',
  '#4e98b1',
  '#b1b14e',
  '#4eb14e',
  '#804eb1',
  "#b9765f",
  "#5fb9b9",
  "#a3b95f",
  "#5fb95f",
  "#b95fb9",
  "#b95f8c",
  "#5f76b9",
  "#5fb9a3",
  "#a3b95f",
  "#b95fb9"
]

const lightColors = [
  "#f7eded",
  "#edf7f5",
  "#f5edf7",
  "#f7edf5",
  "#f2f7ed",
  "#edf7f2",
  "#f7edf7",
  "#f7edf2",
  "#f7f0ed"
]

const months = ['Jan', 'Febr', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] 


export default () => {

  const [pageLoading, setPageLoading] = useState(true);

  const prlRef = useRef(); // profit and loss

  const handlePrint = useReactToPrint({
    content: () => prlRef.current
  });

  const [expenseData, setExpenseData] = useState({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple'],
    datasets: [
      {
        label: 'Total Expense',
        data: [15000, 6500, 5500, 1500, 200],
        backgroundColor: [
          '#b95f5f',
          '#4e98b1',
          '#b1b14e',
          '#4eb14e',
          '#804eb1',
        ],
        hoverOffset: 6,
        radius: "90%",
        cutout: "40%"
        // circumference: 180,
        // rotation: 270
      },
    ],
  })

  const { user } = useAuthContext()

  const measurement = user.system?.settings?.shipment?.measurement || "in"

  let symbol = measurement === "in" ? '″' : 'cm'

  const [expenses, setExpenses] = useState([]);

  const [totalExpenses, setTotalExpenses] = useState("")

  const [totalIncome, setTotalIncome] = useState(0);

  const [totalCost, setTotalCost] = useState(0);

  // graph states

  const [graphInfo, setGraphInfo] = useState([])

  const [graphLabels, setGraphLabels] = useState(months)

  const [graphTimeline, setGraphTimeline] = useState('Month')

  // end graph states

  const [topCusts, setTopCusts] = useState([{name: "", total: 0, count: 0}])

  const [topSellers, setTopSellers] = useState([])

  // top selling stats

  const [topStats, setTopStats] = useState({
    topStyle: {_id: "", count: ''},
    topLength: {_id: "", count: ''},
    topCombined: {_id: "", count: ''}
  })

  // end

  const [loading, setLoading] = useState(false);

  const [cust, setCust] = useState();

  const [custs, setCusts] = useState([]);

  const [brands, setBrands] = useState()

  const [brandError, setBrandError] = useState(false)

  const [rangeError, setRangeError] = useState(false)

  const [results, setResults] = useState()

  const [balance, setBalance] = useState();

  const [summary, setSummary] = useState([])

  const [range, setRange] = useState('');

  const [brand, setBrand] = useState('');

  const [searchBy, setSearchBy] = useState('accrual');

  const [report, setReport] = useState("basic")

  const [invoiceLoad, setInvoiceLoad] = useState(false)

  const [email, setEmail] = useState('');

  const [eLoading, setELoading] = useState(false);

  const [eErr, setEErr] = useState(false);

  const [openDialog, setOpenDialog] = useState(false)

  const [lengthLoad, setLengthLoad] = useState(false)

  const [stylesByLength, setStylesByLength] = useState();

  const handleEmailChange = (e) => {
    setEErr(false)
    setEmail(e.target.value)
  }

  let newDate = new Date().toISOString()


  const [start, setStart] = useState(newDate);
  const [end, setEnd] = useState(newDate);

  const [currYear, setCurrYear] = useState(new Date().getFullYear())

  const [selectedStartDate, setStartDate] = useState(new Date());
  const [selectedEndDate, setEndDate] = useState(new Date());


  const handleSelect = (event, func) => {
    func(event.target.value);
  };

  const handleRadioReport = (e) => {
    setReport(e.target.value);
  };

  const handleRadioSearch = (e) => {
    setSearchBy(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleCustSelect = (val) => {
    setCust(val)
  }

  const getStylesByLength = async (len, cust) => {
    // console.log(cust);
    let custId = cust ? `&customer=${cust._id}` : "";
    try {
      setOpenDialog(true)
      setLengthLoad(true)
      const {data} = await axios.get(`/accounting/styles?brand=${encodeURIComponent(brand)}&start=${start}&end=${end}&lengthNum=${len}${custId}&report=${report}`)
        // console.log(data, "ccckckckckc");

      setStylesByLength(data)
      setLengthLoad(false)
    } catch (e) {
      // console.log(e);
    }
  }


  const account = async () => {

    if (!range){
      setRangeError(true)
      return;
    }

    if (!brand){
      setBrandError(true)
      return;
    }

    setRangeError(false)
    setBrandError(false)

    let start;
    let end = DateTime.local().toISO();

    switch(range) {
      case 'week': 
      start = DateTime.local().minus({weeks: 1}).toISO()
      break;
      case 'month': 
      start = DateTime.local().minus({months: 1}).toISO()
      break;
      case 'quarter': 
      start = DateTime.local().minus({quarters: 1}).toISO()
      break;
      case 'year': 
      start = DateTime.local().minus({years: 1}).toISO()
      break;
      case 'ytd': 
      start = DateTime.local().startOf('year').toISO()
      break;
      case Number(+range): 
          end = DateTime.fromJSDate(new Date(`1/1/${range}`)).endOf('year').toISO()

          start = DateTime.fromJSDate(new Date(`1/1/${range}`)).startOf('year').toISO();
          // console.log(start, end, "hhhfhfhfhfhf");
        break;
      default:
        start = selectedStartDate.toISOString();
        end = selectedEndDate.toISOString();
    }

    setStart(start)
    setEnd(end)

    const customer = cust?._id || ''

    try {
      // console.log(start, end, "check it out");
      setLoading(true)
      const {data} = await axios.get(`/accounting/${report}/?brand=${encodeURIComponent(brand)}&start=${start}&end=${end}&customer=${customer}&type=${searchBy}`)
      // console.log(data, "accountintttt");
      setResults(data.results)
      setSummary(data.summary)

      setTopSellers(data.summary[0]?.bestSellers)
      setTopStats({
        topStyle: {...data.summary[0]?.bestStyle[0]},
        topLength: {...data.summary[0]?.bestLength[0]},
        topCombined: {_id: `${data.summary[0]?.bestCombined[0]?._id?.style}-${data.summary[0]?.bestCombined[0]?._id?.lengthNum}`, count: data.summary[0]?.bestCombined[0]?.count}
      })

      // set graph info
      setGraphInfo(data.graphInfo)
      setGraphTimeline(capitalize(data.timeline.split("").slice(0, -1).join("")))

      if (data.graphInfo.length) {
      if (data.timeline === "months") {
        setGraphLabels(months.slice(data.graphInfo[0]._id - 1, data.graphInfo[data.graphInfo.length - 1]._id))
      } else if (data.timeline === "years") {
        setGraphLabels(data.graphInfo.map(el => el._id))
      } else if (data.timeline === "weeks") {
        // console.log(data.graphInfo.map(el => DateTime.fromISO(el.soldDate).weekNumber), "Helllooooo");
        setGraphLabels(data.graphInfo.map(el => DateTime.fromISO(el.soldDate).startOf('week').toLocaleString({month: "short", day: "2-digit"})))
      } 
    } else {
      
    }

      if (report === "basic") {  // only for basic reports

      setBalance(data.balance)
      setTopCusts(data.summary[0]?.bestCustomer)

      let totalEx = data.totalAccount[0][0]?.totalExpenses[0]?.total

      console.log({totalEx});

    if (totalEx) {

      let exTypes = []
      let amounts = []
      data.totalAccount[0][0]?.expenseCats.forEach(el => {
        exTypes.push(el.name)
        amounts.push(el.total)
        let dataSet = expenseData.datasets[0]
        setExpenseData(x => {
          return {labels: exTypes, datasets: [{...x.datasets[0], data: amounts, backgroundColor: colors.slice(0, amounts.length)}]}
        })
      })

        // console.log("heeheheh");
        setTotalExpenses(totalEx)
        setExpenses(data.totalAccount[0][0].expenseCats)
      } else {
        setTotalExpenses(0)
        setExpenses([])
      }

      setTotalCost(totalEx ? totalEx + data.summary[0].total[0].cost : data.summary[0].total[0]?.cost || 0)


      setTotalIncome(data.totalAccount[1][0]?.total || 0);
      // console.log(data.totalAccount[1][0].total, "JJJJJ");
    } else {
      // by customer remove expenses details
      console.log("CUSTTTTTTTT");
      setTotalExpenses(0)
      setExpenses([])

      setTotalCost(data.summary[0].total[0]?.cost || 0)
      setTotalIncome(data.totalAccount[0][0]?.total || 0);
    }
      
      if (customer) 
        setEmail(data.email)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }

  }

  const soldHeaders = [
    { id: 'wigId', label: 'ID' },
    { id: 'sku', label: 'SKU' },
    { id: 'baseMaterial', label: 'Material' },
    { id: 'currentlyAt.name', additional: true, label: 'Sold To' },
    { id: 'profit', currency: true, additional: true, label: 'Profit' },
    { id: 'history.date', date: true, additional: true, label: 'Date',
    //  render: row => {
    //     return row.history.slice(-1)[0].date
    //   }
    }
  ];

  const { handleClick } = useSBContext()

 
  const loadInfo = async () => {
    
    try {
      const { data } = await axios.get('/brands/names')
      const brandNames = data.map(el => el.name)
      const custs = await axios.get('/customers');
      const brand = data.find(el => el.isDefault)
      setBrand(brand.name)
      setBrands(brandNames)
      setCusts(custs.data);
      setPageLoading(false)
    } catch (e) {
      setPageLoading(false)
      handleClick('error', 'Please reload page')
    }
  }


  useEffect(() => {
    loadInfo()
  }, [])

  const printInvoice = async () => {
    setInvoiceLoad(true)
    const {data} = await axios.get(`/accounting/pdf?brand=${encodeURIComponent(brand)}&start=${start}&end=${end}&customer=${cust?._id}&pdf=true`, {
    responseType: 'blob',
    timeout: 60000,
    headers: {"Content-Type": "application/pdf"}
    });
    setInvoiceLoad(false)
    displayPdf(data, `statement`)
  }

  const sendEmail = async () => {
    if (!email) return setEErr(true);
    setELoading(true)
    try {
      await axios.get(`/accounting/pdf?brand=${encodeURIComponent(brand)}&start=${start}&end=${end}&customer=${cust?._id}&email=${email}`)
      handleClick('success', 'Email successfully sent')
      setELoading(false)
    } catch (e) {
      handleClick('error', 'Error sending email')
      setELoading(false)
    }
    return 'sent'
  }

  // console.log(summary || "nothing", "check it out");
 
 return (
  <Container >
    <Header>
      <Title>Business Accounting</Title>
    </Header>
    <Main>
      {pageLoading ? <LinearProgress /> :

      <ReportBar elevation={3} square>
      <Grid container spacing={3} alignItems="center">

      <Grid item xs={6}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Report Type</FormLabel>
        <RadioGroup aria-label="report-type" name="report-type" value={report} onChange={handleRadioReport} row>
          <FormControlLabel value="basic" control={<Radio />} label="Basic Report" />
          {/* <FormControlLabel value="tax" control={<Radio />} label="Tax Report" /> */}
          <FormControlLabel value="customer" control={<Radio />} label="Customer Report" />
        </RadioGroup>
        {/* <FormHelperText error={true}>Tax Report not available yet (Need more info from you)</FormHelperText> */}
      </FormControl>
      </Grid>

      <Grid item xs={6}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Search By</FormLabel>
        <RadioGroup aria-label="search-by" name="search-by" value={searchBy} onChange={handleRadioSearch} row>
          <FormControlLabel value="accrual" control={<Radio />} label="Accrual" />
          <FormControlLabel value="cash" control={<Radio />} label="Cash" />
        </RadioGroup>
      </FormControl>
      </Grid>
      {/* <Grid item xs={4}></Grid> */}

      {brands?.length > 1 ? <Grid item md={3}>
          <FormControl variant="outlined" fullWidth error={brandError ? true : false}>
            <InputLabel id="brand-sel-label">Select Brand</InputLabel>
            <Select
              labelId="brand-sel-label"
              id="cust-search-brand"
              value={brand}
              onChange={(e) => handleSelect(e, setBrand)}
              label="Select Brand"
              fullWidth
              
            >
              {brands?.map(el => 
                  <MenuItem key={el} value={el}>{el}</MenuItem>
                )}
              <MenuItem value="all">All</MenuItem>
            </Select>
            <FormHelperText>{brandError ? 'Please select brand' : null}</FormHelperText>
          </FormControl>
        </Grid> : null }

        <Grid item md={brands?.length > 1 ? 2 : 4}>
          <FormControl variant="outlined" fullWidth error={rangeError ? true : false}>
            <InputLabel id="cust-search-options-label">Select Range</InputLabel>
            <Select
              labelId="cust-search-options-label"
              id="cust-search-options"
              value={range}
              onChange={(e) => handleSelect(e, setRange)}
              label="Select Range"
              fullWidth
            >
              <MenuItem value="week">Past Week</MenuItem>
              <MenuItem value="month">Past Month</MenuItem>
              <MenuItem value="quarter">Past Quarter</MenuItem>
              <MenuItem value="year">Past Year</MenuItem>
              <MenuItem value="ytd">Year to Date</MenuItem>
              {[currYear - 1, currYear - 2].map(el => 
              <MenuItem key={el} value={el}>{el}</MenuItem>
                )}
              <MenuItem value="range">Custom</MenuItem>
            </Select>
            <FormHelperText>{rangeError ? 'Please select range' : null}</FormHelperText>
          </FormControl>
        </Grid>

       { report === "customer" ?  <Grid item xs={4}>
          <SimpleAutoComplete 
            list={custs} name="search-cust" label="Search by Customer Name"
            getOptionLabel={(opt) => opt.name || ''}
            getOptionSelected={({name}, val) => name === val.name}
            renderOption={(opt) => <span>{opt.name}</span>}
            autoHighlight
            autoSelect
            // handleChange={handleChangeCust}
            handleSelect={handleCustSelect}
          />
        </Grid> : null }


        <Grid item xs={3}>
          <Button 
            color="primary"
            variant="contained"
            onClick={account}
            >Generate Report</Button>
        </Grid>

        {range && range === "range" &&
        <>
         
         {report !== "customer" ? <Grid item xs={4}></Grid> : null}
          <Grid item md={6} lg={4} style={{height: '150px'}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
            variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} inputVariant="outlined"
            value={selectedStartDate} format="MM/dd/yyyy" onChange={handleStartDateChange}  label="Start Date" name="date" />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={6} lg={4} style={{height: '150px'}}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
            variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} inputVariant="outlined"
            value={selectedEndDate} format="MM/dd/yyyy"  onChange={handleEndDateChange} label="End Date" name="date" />
            </MuiPickersUtilsProvider>
          </Grid>
        </>
      }

        </Grid>
      </ReportBar>
      }

      {loading ? <LinearProgress style={{marginTop: '80px'}} /> :  
    
      <ResultContainer>
        { results ? <><Grid container spacing={4} alignItems="baseline">

          <Grid item xs={12}>
            <BoxFade>
              
            <Flex dir="row" pad="1rem" justify="space-between">
              <DividerLegend variant="fullWidth" /> <AccountingHeader>{report === "basic" ? `Basic Accounting Report` : `Customer Report`}</AccountingHeader><DividerLegend />
            </Flex>
            <Flex dir="row" pad="0" align="flex-start" justify="space-between">
              <div>
                <InfoItem><InfoLabel>Report Generated:</InfoLabel>{DateTime.local().toLocaleString()}</InfoItem>
                {/* Report generated -  {DateTime.local().toLocaleString()} */}
                <InfoItem><InfoLabel>Report Period:</InfoLabel>
                  {DateTime.fromISO(start).toLocaleString()} - {DateTime.fromISO(end).toLocaleString()}
                </InfoItem>
                {report === "customer" ? <InfoItem><InfoLabel>Customer Name:</InfoLabel>
                  {cust?.name}
                </InfoItem> : null }
              </div>

              <div dir="row" justify="flex-end" pad="0">
              {
                <TooltipContainer>
                  {report !== "basic" ? 
                  <>
                 {invoiceLoad ? <CircularProgress style={{marginTop: '10px'}} size={25} /> : <Tooltip title="Print">
                    <IconButton 
                    onClick={printInvoice} 
                    aria-label="settings">
                      <Print color="primary" />
                    </IconButton>
                  </Tooltip>
                   }
                  <FormDialog toggle={
                    <Tooltip title="Email">
                    <IconButton>
                      <EmailIcon  color="primary" />
                    </IconButton>
                    </Tooltip>
                    } 
                    title="Add Email Address"
                    onSave={sendEmail}
                    save="Send Email"
                    size="sm"
                    >
                  <Grid container justify-content="center">
                    <Grid item xs={8}>
                      {eLoading ? <LinearProgress /> : 
                      <TextField error={eErr} variant="outlined" label="Email" name="email" type="email" fullWidth defaultValue={email} onChange={handleEmailChange}
                      helperText={eErr ? 'Email Required' : ''} />
                    }
                    </Grid>
                 </Grid>
                </FormDialog>
                </>
                : null }
              </TooltipContainer>
            }
              </div>
            </Flex>
            </BoxFade>
           
          </Grid>


          {/* <Grid item xs={12}>
            
          <Card elevation={0} style={{backgroundColor: "transparent"}}>
              <CardHeader title={`Report generated -  ${DateTime.local().toLocaleString()}`} action={
                
                  <TooltipContainer>
                    {cust || report !== "wigs" ? 
                    <>
                   {invoiceLoad ? <CircularProgress style={{marginTop: '10px'}} size={25} /> : <Tooltip title="Print">
                      <IconButton 
                      onClick={printInvoice} 
                      aria-label="settings">
                        <Print color="primary" />
                      </IconButton>
                    </Tooltip>
                     }
                    <FormDialog toggle={
                      <Tooltip title="Email">
                      <IconButton>
                        <EmailIcon  color="primary" />
                      </IconButton>
                      </Tooltip>
                      } 
                      title="Add Email Address"
                      onSave={sendEmail}
                      save="Send Email"
                      size="sm"
                      >
                    <Grid container justify-content="center">
                      <Grid item xs={8}>
                        {eLoading ? <LinearProgress /> : 
                        <TextField error={eErr} variant="outlined" label="Email" name="email" type="email" fullWidth defaultValue={email} onChange={handleEmailChange}
                        helperText={eErr ? 'Email Required' : ''} />
                      }
                      </Grid>
                   </Grid>
                  </FormDialog>
                  </>
                  : null }
                </TooltipContainer>
              }
               />
              <CardContent>
                <InfoItem><InfoLabel>Report Period:</InfoLabel>
                {DateTime.fromISO(start).toLocaleString()} - {DateTime.fromISO(end).toLocaleString()}
                </InfoItem>


              </CardContent>
              
            </Card>
          </Grid> */}

          
           {/* {!cust ? <> */}
            {/* <Grid container item xs={12} spacing={2}> */}
<Grid item xs={12}>

  <ResultContent container spacing={4} alignItems="baseline">

<Grid item xs={4}>
  <Card elevation={4} style={{backgroundColor: theme.colors.iconBg3, color: theme.colors.icon3}}>
    <CardHeader title={<CardTitle>Total Expenses
    </CardTitle>}></CardHeader>
    <CardContent>
    <StatContainer>
      <Stat>{currencyFormat(totalCost)}</Stat> <IconCircle color={theme.colors.icon3}><CallMadeRoundedIcon htmlColor='#e8fdea' /></IconCircle>
    </StatContainer>
    </CardContent>
  </Card>
</Grid>

<Grid item xs={4}>
  <Card elevation={4} style={{backgroundColor: theme.colors.iconBg2, color: theme.colors.icon2}}>
    <CardHeader title={<CardTitle>Income
    </CardTitle>} action={<CustomChip level="icon2">{getPercent(summary[0]?.total[0]?.total, totalCost, "str")}</CustomChip>}></CardHeader>
    <CardContent>
    <StatContainer>
      <Stat>{currencyFormat(totalIncome)}</Stat> <IconCircle color={theme.colors.icon2}><CallReceivedRoundedIcon  htmlColor='#fffae6' /></IconCircle>
    </StatContainer>
    </CardContent>
  </Card>
</Grid>

<Grid item xs={4}>
  <Card elevation={4} style={{backgroundColor: theme.colors.iconBg4, color: theme.colors.icon4}}>
    <CardHeader title={<CardTitle>Profit
    </CardTitle>} action={<CustomChip level="icon4">{getPercent(totalIncome - totalCost, totalCost, "str")}</CustomChip>}></CardHeader>
    <CardContent>
    <StatContainer>
      <Stat>{currencyFormat(totalIncome - totalCost)}</Stat> <IconCircle color={theme.colors.icon4}><TrendingUpRoundedIcon htmlColor='#e6f9ff' /></IconCircle>
    </StatContainer>
    </CardContent>
  </Card>
</Grid>

{/* Only for basic report */}
{report === "basic" ? 
<>

<Grid item xs={12}>
  <Grid container spacing={4}>

  <Grid item xs={7}>
  <PLCard elevation={4} ref={prlRef}>
    <CardHeader title="Profit & Loss Statement" subheader={`Statement Period:
      ${DateTime.fromISO(start).toLocaleString()} - ${DateTime.fromISO(end).toLocaleString()}`} action={<IconButton onClick={handlePrint}><Print color='primary' /></IconButton>} /> 

      <CardContent>
        <PLHeader className='first'>Revenue</PLHeader>
        <FlexLine dir='row' pad="0" justify="space-between"><PLTitle>Total Sales - (by {searchBy})</PLTitle><PLStat>{currencyFormat(totalIncome)}</PLStat></FlexLine>

        <FlexLine dir='row' pad="0" justify="space-between" ><PLTotalTitle>&nbsp;</PLTotalTitle><PLStat><NetDivider /></PLStat></FlexLine>

        <FlexLine dir='row' pad="0"  justify="space-between" ><PLTotalTitle>Total Revenue</PLTotalTitle><PLTotalStat>{currencyFormat(totalIncome)}</PLTotalStat></FlexLine>
        


        <PLHeader>Cost of Goods Sold</PLHeader>
        <FlexLine dir='row' pad="0" justify="space-between"><PLTitle>Cost of Goods Sold</PLTitle><PLStat>{currencyFormat(summary[0]?.total[0]?.cost)}</PLStat></FlexLine>

        <FlexLine dir='row' pad="0" justify="space-between" ><PLTotalTitle>&nbsp;</PLTotalTitle><PLStat><NetDivider /></PLStat></FlexLine>

        <FlexLine dir='row' pad="0"  justify="space-between"><PLTotalTitle>Total CoGS</PLTotalTitle><PLTotalStat>{currencyFormat(summary[0]?.total[0]?.cost)}</PLTotalStat></FlexLine>


        <PLDivider />

        <FlexLine dir='row' pad="0"  justify="space-between"><PLNetTitle>Gross Profit / Loss</PLNetTitle><PLNetStat>{currencyFormat(totalIncome - summary[0]?.total[0]?.cost || 0)}</PLNetStat></FlexLine>

        <PLDivider />


        <PLHeader>Expenses</PLHeader>
        {expenses.map(el => 
          <FlexLine key={el.name} dir='row' pad="0" justify="space-between"><PLTitle>{el.name}</PLTitle><PLStat>{currencyFormat(el.total)}</PLStat></FlexLine>
          )}

          <FlexLine dir='row' pad="0" justify="space-between" ><PLTotalTitle>&nbsp;</PLTotalTitle><PLStat><NetDivider /></PLStat></FlexLine>

        <FlexLine dir='row' pad="0"  justify="space-between"><PLTotalTitle>Total Expenses</PLTotalTitle><PLTotalStat>{currencyFormat(totalExpenses)}</PLTotalStat></FlexLine>

        <PLDivider />

        <FlexLine dir='row' pad="0"  justify="space-between"><PLNetTitle>Net Profit / Loss</PLNetTitle><PLNetStat>{currencyFormat(totalIncome - totalCost)}</PLNetStat></FlexLine>

        <PLDivider />

      </CardContent>
    
  </PLCard>
  </Grid>

  <Grid item xs={5}>

    <Grid container spacing={4}>

  <Grid item xs={12}>
    <Card elevation={4} style={{backgroundColor: "#ededed", color: "#5d5d5d"}}>
    {/* <Card elevation={4} style={{backgroundColor: "#434e56", color: "#f1f1f4"}}> */}
      <CardHeader title={<CardTitle>Current Balance
      <br /><span className="amount">{balance?.eBal?.count} Wigs - {currencyFormat(balance?.eBal?.total)}</span></CardTitle>}></CardHeader>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <CardPointHeader>Balance at beginning of period</CardPointHeader>
            <CardPoint>{balance?.bBal?.count} Wigs - {currencyFormat(balance?.bBal?.total)}</CardPoint>
            {/* <CardPoint>Total Cost: $10.430.00</CardPoint> */}
          </Grid>
          <Grid item xs={3}><BalanceAmount>{balance?.bBal?.count}</BalanceAmount></Grid>

          <Grid item xs={9}>
            <CardPointHeader>wigs added during period</CardPointHeader>
            <CardPoint>{balance?.mBal?.stock?.count} Wigs - {currencyFormat(balance?.mBal?.stock?.total)}</CardPoint>
            {/* <CardPoint>Total Cost: $10.430.00</CardPoint> */}
          </Grid>
          <Grid item xs={3}><BalanceAmount>+ {balance?.mBal?.stock?.count}</BalanceAmount></Grid>

          <Grid item xs={9}>
            <CardPointHeader>wigs sold during period</CardPointHeader>
            <CardPoint>{balance?.mBal?.sold?.count} Wigs - {currencyFormat(balance?.mBal?.sold?.total)}</CardPoint>
            {/* <CardPoint>Total Cost: $10.430.00</CardPoint> */}
          </Grid>
          <Grid item xs={3}><BalanceAmount>- {balance?.mBal?.sold?.count}</BalanceAmount></Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={9}>Current Balance</Grid>
          <Grid item xs={3}><BalanceAmount>{balance?.eBal?.count}</BalanceAmount></Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>

  {expenses?.length ? <Grid item xs={12}>
    <Card elevation={3}>
      <CardHeader title="Expenses Summary">

      </CardHeader>
      <CardContent>
        <CardInfoHeader>Total Expenses:</CardInfoHeader>
        <CardStat>{currencyFormat(totalExpenses)}</CardStat>
        <FullDivider />
        {/* <CardInfoHeader>Top 5 Expenses:</CardInfoHeader> */}
        <DoughnutContainer>
          <Doughnut data={expenseData} options={{
            plugins: {legend: {
              labels: {
                // boxHeight: 20,
                padding: 15
              }
            }}
        }} />
        </DoughnutContainer>
      </CardContent>
    </Card>
  </Grid> : null }

  </Grid>

  </Grid>
  </Grid>
</Grid>

</>
: null }

{ report === "basic" ? <Grid item xs={8}>
  <Card elevation={4}>
    <CardHeader title={`Sales By ${graphTimeline}`}subheader={`Sales Period:
      ${DateTime.fromISO(start).toLocaleString()} - ${DateTime.fromISO(end).toLocaleString()}`} />
    <CardContent>
      <Line data={{
        labels: graphLabels,
        datasets: [
          {
            fill: true,
            tension: 0.4,
            label: 'Qty of Wigs Sold',
            data: graphInfo.map(el => el.total), // [3, 6, 7, 14, 3, 6, 8, 20, 15, 17, 3, 10]
            // borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
        }} options={{elements: {point: {radius: 4, hoverRadius: 6}}}} />
    </CardContent>
  </Card>
</Grid> : null}

{report === "basic" ? <Grid item xs={4}>
  <Card elevation={4}>
    <CardHeader title="Top Selling Customers"></CardHeader>
    <CardContent>
      <Flex dir="row" pad="0" justify="flex-start" align="flex-end">
        <TrophyIcon>
          <Trophy size="2.5rem" />
        </TrophyIcon>
        <div>
          <TopCust>{topCusts[0]?.name}!</TopCust>
          <CustHeader>#1 Top Selling Customer</CustHeader>
        </div>
      </Flex>
      <CustTitles>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={6}>Name</Grid>
          <Grid item xs={3}>Total Sales</Grid>
          <Grid item xs={1}>Qty</Grid>
        </Grid>
      </CustTitles>
        {topCusts.map((el, ind) => 
          <>
          <FullDivider b="1.5rem" t=".5rem" key={el.name} />
            <CustLine dotColor={colors[ind]}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <div className='cust-div'>
                    <span className='indicator-dot'></span>
                    <span className='cust'>{el.name}</span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <span className='cust-total'>{currencyFormat(el.total)}</span>
                </Grid>
                <Grid item xs={1}>
                  <span className='cust-qty'>{el.count}</span>
                </Grid>
              </Grid>
            </CustLine>
          </>
          )}
      
    </CardContent>
  </Card>
</Grid>

: null }



<Grid item xs={12}>
  <Card elevation={4}>
    <CardHeader title="Best Selling Items" subheader="Quantities by style and length">

    </CardHeader>

    <CardContent>

    <Grid container spacing={4}>
         
         <Grid item xs={4}>
          <StatBox color={theme.colors.iconBg1} icon={<StyleIcon fontSize='large' htmlColor={theme.colors.icon1} />} header="Top Selling Style" stat={topStats.topStyle._id} total={topStats.topStyle.count} />
         </Grid>
         <Grid item xs={4}>
          <StatBox color={theme.colors.iconBg2} icon={<HeightIcon fontSize='large' htmlColor={theme.colors.icon2} />}  header="Top Selling Length" stat={`${topStats.topLength._id}${symbol}`} total={topStats.topLength.count} />
         </Grid>
         <Grid item xs={4}>
          <StatBox color={theme.colors.iconBg3} icon={<WigIcon fontSize='large' htmlColor={theme.colors.icon3} />} header="Combined"  stat={`${topStats.topCombined._id}${symbol}`} total={topStats.topCombined.count} />
         </Grid>

         <Grid item xs={12}>
          {/* <FullDivider /> */}
         </Grid>

         <SectionHeaderRow item xs={12}>
          <Top3Header>Top 3 Best Selling Styles For Each Length</Top3Header>
         </SectionHeaderRow>

         <StyleDialog
          title="View Styles By Length"
          forceOpen={openDialog}
          // forceOpen={true}
          onSave={() => {setOpenDialog(false); return "close"}}
          save="Close"
          cancel={false}
          size="xs"
          >
            <DialogContentText>Period: {DateTime.fromISO(start).toLocaleString()} - {DateTime.fromISO(end).toLocaleString()}</DialogContentText>
             {/* <br /> */}
        <TotalQtyLength>
        <div className='number'> {stylesByLength?.reduce((acc, cur) => acc + cur.count, 0)}</div>
          <div className="title">Total qty for this length</div> </TotalQtyLength>
            {lengthLoad ? <LinearProgress /> : <>
              {stylesByLength ? <SizeList last={true} view={false} size={`${stylesByLength[1].len}${symbol}`} color={lightColors[1]} styles={stylesByLength} /> : null}
            </>}
          </StyleDialog>

         {topSellers.map(({_id, docs}, ind) => 
            <Grid item xs={4} key={_id}><SizeList last={(ind + 1) % 3 === 0} load={() => getStylesByLength(_id, cust)} size={`${_id}${symbol}`} color={lightColors[ind]} styles={docs} /></Grid>
         )}

       </Grid>
     
    </CardContent>
  </Card>
</Grid>

        
          {/* </> : null } */}

  </ResultContent>
      
        <Grid container>


          <Grid item xs={12}>
            <Card variant="outlined" style={{marginTop: '50px'}}>
              <CardHeader title="Detailed List of Items sold" />

              <CardContent>
                <SimpleTable headers={soldHeaders} title="Inventory" rows={results} link="inventory" rpp={10} />
              </CardContent>
            </Card>
          </Grid>


          </Grid>

          </Grid>
          
        </Grid>
        
        </>
      : null}
      </ResultContainer>
      }
    </Main>
 </Container>
 )
}


// backgroundColor: "#fffae6", color: "#4d3d00" IconCircle color="#806600"
// backgroundColor: "#e8fdea", color: "#042f08" IconCircle color="#0a7515"
// backgroundColor: "#e6f9ff", color: "#002733" IconCircle color="#006280"